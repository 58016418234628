import useSWR from 'swr';
import axios, { AxiosRequestConfig } from 'axios';
import { commonAPI } from '@/api';
import { IMe } from '@/types';
import { createErrorObject } from '@/helpers/createErrorObject';

const useUser = () => {
  const {
    data,
    mutate,
    error,
  }: { data?: IMe; mutate: any; error?: any } = useSWR('me', commonAPI.fetch, {
    revalidateOnFocus: false,
  });

  const isLoading = !data && (!error || axios.isCancel(error));
  const isError = !!error;

  return {
    mutate,
    isLoading,
    isError,
    user: data,
    error: error ? createErrorObject(error) : null,
  };
};

const updateProfile = async (data: any) => {
  const response = await commonAPI.fetch('/me/profile/update', data, {
    requestConfig: { method: 'put' } as AxiosRequestConfig,
  });

  return response;
};

const sendConfirmationCode = async () => {
  const response = await commonAPI.fetch(
    '/me/profile/email/confirmation',
    {},
    {
      requestConfig: { method: 'post' } as AxiosRequestConfig,
    },
  );

  return response;
};

const confirmEmail = async (code: string) => {
  const response = await commonAPI.fetch(
    `/me/profile/email/confirm/${code}`,
    {},
    {
      requestConfig: { method: 'put' } as AxiosRequestConfig,
    },
  );

  return response;
};

export { useUser, updateProfile, sendConfirmationCode, confirmEmail };
