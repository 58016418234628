

import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgYellowTriangle: FC<ISvgProps> = (props: ISvgProps) => {
	return (

		<svg viewBox="0 0 486 487" {...props}>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M24.3518 282.565C-7.93094 266.423 -7.9309 220.354 24.3518 204.213L422.612 5.08264C451.735 -9.4787 486 11.6984 486 44.2585V442.519C486 475.079 451.735 496.256 422.612 481.695L24.3518 282.565Z" fill="#FFC107" />
		</svg>





	);
};

export default SvgYellowTriangle;
