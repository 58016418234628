import React, { ReactNode, useState, forwardRef, HTMLProps } from 'react';
import styled, { css } from 'styled-components';
import { colors, common } from '@/constants';
import { SvgClear } from '@/components/Svg';
import FormFieldError from './FormFieldError';

const { PREVENT_CLICK_OUTSIDE_CLASS } = common;

export interface IProps extends HTMLProps<HTMLInputElement> {
  id?: string;
  name?: string;
  type?: string;
  mark?: string;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  disabled?: boolean;
  isError?: boolean;
  errorMessage?: string;
  withErrorIcon?: boolean;
  withClearButton?: boolean;
  clearButtonPosition?: IClearButtonPosition;
  onInputChange?: (value: string) => void;
  onInputFocus?: () => void;
  onInputBlur?: () => void;
  onClick?: () => void;
  onClearClick?: () => void;
  renderClearIcon?: () => JSX.Element;
  className?: string;
  autocomplete?: 'on' | 'off';
  formatValue?: (value?: string) => string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
}

interface IClearButtonPosition {
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
}

interface IClearButtonProps {
  position: IClearButtonPosition;
}

interface IInputWrapperProps {
  withClearButton: boolean;
  focused: boolean;
  isError: boolean;
}

interface IInputProps {
  isError: boolean;
  withClearButton: boolean;
}

const InputWrapper = styled.div<IInputWrapperProps>`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 54px;
  overflow: hidden;
  border-radius: 8px;

  ${({ focused, isError }: IInputWrapperProps) => {
    if (focused) {
      return css`
        box-shadow: inset 0 0 0 2px ${colors.BLUE};
      `;
    }
    if (isError) {
      return css`
        box-shadow: inset 0 0 0 2px ${colors.RED};
      `;
    }
    return css`
      box-shadow: inset 0 0 0 1px ${colors.LILAC_WHITE};
    `;
  }}
`;

const Mark = styled.p`
  margin: 4px 0 0 0;
  font-size: 9px;
  font-weight: normal;
  line-height: normal;
  color: ${colors.MONSOON};
`;

const Input = styled.input<IInputProps>`
  width: 100%;
  height: 100%;
  max-height: 54px;
  padding: 17px 12px 18px 12px;
  font-size: 16px;
  font-weight: 400;
  color: ${colors.BLACK};
  border: 0;
  border-radius: 8px;
  background: transparent;

  ${({ withClearButton }: IInputProps) => {
    if (withClearButton) {
      return css`
        padding-right: 42px;
      `;
    }
    return '';
  }}

  &::placeholder {
    color: ${colors.MISCHKA};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:disabled {
    color: ${colors.MISCHKA};
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const ClearButton = styled.button<IClearButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 3;
  top: ${({ position }: IClearButtonProps) =>
    position.top ? `${position.top}px` : 0};
  right: ${({ position }: IClearButtonProps) =>
    position.right ? `${position.right}px` : 0};
  left: ${({ position }: IClearButtonProps) =>
    position.left ? `${position.left}px` : 'auto'};
  bottom: ${({ position }: IClearButtonProps) =>
    position.bottom ? `${position.bottom}px` : 'auto'};
  width: 40px;
  height: 100%;
  background: transparent;
  border: 0;

  svg {
    pointer-events: none;
  }
`;

const BaseInput = forwardRef<any, IProps>((props, ref) => {
  const {
    id = '',
    name = '',
    mark,
    type = 'text',
    autocomplete,
    placeholder = '',
    value = null,
    defaultValue = '',
    disabled = false,
    isError = false,
    errorMessage = '',
    withErrorIcon = true,
    withClearButton = false,
    clearButtonPosition = {},
    className,
    onInputChange,
    onInputBlur = () => {},
    onInputFocus = () => {},
    onClick = () => {},
    onClearClick = () => {},
    renderClearIcon = undefined,
    formatValue,
    startIcon,
    endIcon,
  } = props;

  const [inputValue, setInputValue] = useState<string>(defaultValue);
  const [isFocused, setFocused] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = formatValue
      ? formatValue(e.currentTarget.value)
      : e.currentTarget.value;

    if (value === null) {
      setInputValue(currentValue);
    }

    if (onInputChange) {
      onInputChange(currentValue);
    }
  };

  const handleFocus = () => {
    setFocused(true);
    onInputFocus();
  };

  const handleBlur = () => {
    setFocused(false);
    onInputBlur();
  };

  const handleClick = () => {
    onClick();
  };

  const handleClearClick = () => {
    if (value === null) {
      setInputValue('');
    }

    if (onInputChange) {
      onInputChange('');
    }
    onClearClick();
  };

  const otherProps: { id?: string; name?: string } = {};

  if (id) {
    otherProps.id = id;
  }

  if (name) {
    otherProps.name = name;
  }

  const finalValue = value !== null ? value : inputValue;

  return (
    <>
      <InputWrapper
        className={className}
        focused={isFocused}
        isError={isError}
        withClearButton={withClearButton}
      >
        {startIcon}

        <Input
          ref={ref}
          {...otherProps}
          autoComplete={autocomplete}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          value={finalValue}
          isError={isError}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onClick={handleClick}
          withClearButton={withClearButton}
        />
        {withClearButton && (inputValue || value) && (
          <ClearButton
            type="button"
            className={PREVENT_CLICK_OUTSIDE_CLASS}
            onClick={handleClearClick}
            position={clearButtonPosition}
          >
            {renderClearIcon ? (
              renderClearIcon()
            ) : (
              <SvgClear width={16} height={16} fill={colors.MISCHKA} />
            )}
          </ClearButton>
        )}

        {endIcon}
      </InputWrapper>

      {mark && <Mark>{mark}</Mark>}

      {isError && errorMessage ? (
        <FormFieldError withIcon={withErrorIcon} message={errorMessage} />
      ) : null}
    </>
  );
});

export default BaseInput;
