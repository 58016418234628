import React, { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Container } from 'styled-bootstrap-grid';
import { cn } from '@bem-react/classname';

import { useAuth } from '@/data/useAuth';

import { Layout } from '@/components/Layout';
import { FilterButtons } from '@/components/FilterButtons';
import { Loader } from '@/components/Loader';
import { PromotionCard } from '@/components/PromotionCard';


import { filters } from './config';
import { TFavoritesFilter } from './types';
import './FavoritesPage.less';
import { IFavoritePromotion } from '@/types';
import { useFavoritePromotions } from '@/data/useFavoritePromotions';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const b = cn('FavoritesPage');
const PromotionLink = styled(Link)`
  text-decoration: none;
`;

const FavoritesPage = () => {
  const { formatMessage } = useIntl();
  const { isAuth } = useAuth();

  const [activeFilter, setMyPromoFilter] = useState<TFavoritesFilter>(filters[0]);
  const { promoactions, isLoading } = useFavoritePromotions();





  const tNoFavoritesTitle = formatMessage({ id: 'favorites.empty.title' });
  const tNoFavoritesText = formatMessage({ id: 'favorites.empty.text' });

  const handleFilter = useCallback((filter: TFavoritesFilter) => {
    const { id, filterBy } = filter;
    setMyPromoFilter((currentFilter) => ({
      id,
      filterBy: {
        ...currentFilter.filterBy,
        ...filterBy,
      },
    }));
  }, []);




  const cards = promoactions.map((card: IFavoritePromotion) => {
    const image = card?.images?.find((item) => item.type === 's665x378');

    return (
      <div className={b('Card')} key={card.id}>
        <PromotionLink to={`/promotions/${card.id}`} target="_blank">
          <PromotionCard
            isFavorite={true}
            title={card.title}
            startDateTime={card.startDateTime}
            endDateTime={card.endDateTime}
            startPromoCodeActivationDateTime={card.startPromocodeActivationDateTime}
            endPromoCodeActivationDateTime={card.endPromocodeActivationDateTime}
            company={card.business.name}
            rewards={card.rewards}
            imageSrc={image?.media.path}
            id={card.id}
          />
        </PromotionLink>
      </div>);
  });





  return (
    <Layout
      isAuth={isAuth}
      i18nSeoTitle="seo.title.favorites"
      i18nTitle="common.favorites"
    >
      <Container>
        <FilterButtons
          className={b('Filters')}
          defaultId={activeFilter.id}
          filters={filters}
          handleFilter={handleFilter}
        />

        <div className={b('Cards')}>
          {isLoading && (
            <div className={b('Loader-Wrapper')}>
              <Loader size={52} />
            </div>
          )}

          {!isLoading && cards}

          {!isLoading && !cards.length && (
            <div>
              <p className={b('NoPromo-Title')}>{tNoFavoritesTitle}</p>


              <div className={b('NoPromo-Text')}>{tNoFavoritesText}</div>
            </div>
          )}
        </div>
      </Container>
    </Layout>
  );
};

export default FavoritesPage;
