import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgBillSuccess: FC<ISvgProps> = (props: ISvgProps) => {
  return (
    <svg viewBox="0 0 14 13" {...props}>
      <g fill="#00E676">
        <path d="M12.06 3.83A6.31 6.31 0 003.83.5 6.31 6.31 0 00.5 8.72a6.31 6.31 0 008.23 3.34 6.31 6.31 0 003.34-8.23zm-.77 4.56a5.48 5.48 0 01-7.13 2.9 5.48 5.48 0 01-2.9-7.13 5.48 5.48 0 017.13-2.9 5.48 5.48 0 012.9 7.13z"/>
        <path d="M13.71.54a.43.43 0 00-.57 0L6.17 7.27l-2.6-2.44a.43.43 0 00-.58 0 .37.37 0 000 .54l2.9 2.7c.15.16.41.16.57 0l7.25-6.99a.37.37 0 000-.54z"/>
      </g>
    </svg>
  );
};

export default SvgBillSuccess;
