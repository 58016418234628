import { createContext, Dispatch, SetStateAction } from 'react';

export interface Context {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  options: any[];
  setOptions: Dispatch<SetStateAction<any[]>>;
  onChange: (value: any) => void;
  fetchOptions: any;
  getOptionsScheme: any;
}

export const AutocompleteContext = createContext<Context | undefined>(
  undefined,
);
