import React from 'react';
import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';
import { useLocation } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import qs, { ParsedQuery } from 'query-string';
import hexToRgba from 'hex-to-rgba';
import { TextButton } from '@/components/Buttons';
import { SearchFilters } from '@/components/SearchFilters';
import { SvgMarker } from '@/components/Svg';
import { useSearchFiltersState } from '@/hooks/useSearchFiltersState';
import { useSearchFilters } from '@/hooks/useSearchFilters';
import { colors } from '@/constants';

interface IFilterButtonProps {
  isActive: boolean;
}

const Toolbar = styled.div`
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SearchFiltersWrapper = styled.div`
  display: block;

  ${down('md')} {
    display: none;
  }
`;

const SearchFiltersMenuButtonWrapper = styled.div`
  display: none;
  ${down('md')} {
    display: block;
  }
`;

const MapButtonWrapper = styled.div`
  width: 140px;
`;

const FilterButton = styled.button<IFilterButtonProps>`
  padding: 10px 20px;
  font-size: 12px;
  font-weight: bold;
  color: ${colors.BLACK};
  background-color: transparent;
  border: solid 0.7px ${colors.LILAC_WHITE};
  border-radius: 22px;

  ${({ isActive }: IFilterButtonProps) => {
    if (isActive) {
      return css`
        background-color: ${hexToRgba(colors.HAWKES_BLUE, 0.3)};
        border-color: ${colors.BLUE};
      `;
    }
    return '';
  }}
`;

const PromotionsToolbar = () => {
  const intl = useIntl();
  const location = useLocation();
  const { dispatch: filterStateDispatch } = useSearchFiltersState();
  const { checkIsFilterActive } = useSearchFilters();

  const tShowMap = intl.formatMessage({
    id: 'common.showMap',
  });

  const handleFiltersMenuClick = () => {
    filterStateDispatch({
      type: 'SET_ACTIVE_MENU_STATUS',
      payload: { status: true },
    });
  };

  const handleMapClick = () => {
    const {
      query,
    }: {
      query: ParsedQuery<any>;
    } = qs.parseUrl(location.search);

    const { page, ...rest } = query;
    const searchString = qs.stringify(rest);

    window.open(searchString ? `/map?${searchString}` : '/map', '_blank');
  };

  return (
    <Toolbar>
      <SearchFiltersWrapper>
        <SearchFilters />
      </SearchFiltersWrapper>

      <SearchFiltersMenuButtonWrapper>
        <FilterButton
          type="button"
          onClick={handleFiltersMenuClick}
          isActive={checkIsFilterActive()}
        >
          <FormattedMessage id="common.filters" />
        </FilterButton>
      </SearchFiltersMenuButtonWrapper>

      <MapButtonWrapper>
        <TextButton
          text={tShowMap}
          icon={<SvgMarker width={12} height={15} fill={colors.BLUE} />}
          fontSize="small"
          onClick={handleMapClick}
        />
      </MapButtonWrapper>
    </Toolbar>
  );
};

export default PromotionsToolbar;
