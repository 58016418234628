import React, {
  FC,
  Children,
  useState,
  useEffect,
  useRef,
  ReactElement,
  ReactNode,
} from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { usePopper } from 'react-popper';
import { useOutsideClick } from '@/hooks/useOutsideClick';
import { common } from '@/constants';

interface IProps {
  children: ReactElement;
  overlay: ReactNode;
  visible?: boolean | undefined;
  placement?:
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end';
  onChange?: (v: boolean) => void;
}

const OverlayWrapper = styled.div`
  z-index: 9999;
`;

const Dropdown: FC<IProps> = ({
  children,
  overlay,
  visible = undefined,
  placement = 'bottom-end',
  onChange = () => { },
}: IProps) => {
  const modalRoot = document.getElementById('modal-root');

  const [localVisible, setLocalVisibility] = useState<boolean>(false);

  const triggerRef = useRef<HTMLDivElement | null>(null);
  const overlayRef = useRef<HTMLDivElement | null>(null);

  const { styles, attributes, update } = usePopper(
    triggerRef.current,
    overlayRef.current,
    {
      placement,
      modifiers: [
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [0, 10],
          },
        },
      ],
    },
  );

  useEffect(() => {
    if (visible !== undefined) {
      if (update) {
        update();
      }

      setLocalVisibility(visible);
    }
  }, [visible, update]);

  useOutsideClick(
    overlayRef,
    () => {
      if (localVisible) {
        setLocalVisibility(false);
        onChange(false);
      }
    },
    common.PREVENT_CLICK_OUTSIDE_CLASS,
  );

  const handleTriggerClick = () => {
    if (update) {
      update();
    }
    onChange(!localVisible);
    setLocalVisibility(!localVisible);
  };

  const handleOverlayClick = () => {
    if (visible === undefined) {
      // onChange(!localVisible);
      // setLocalVisibility(!localVisible);
    }
  };

  const renderOverlay = () => (
    <OverlayWrapper
      ref={overlayRef}
      style={styles.popper}
      onClick={handleOverlayClick}
      {...attributes.popper}
    >
      {localVisible ? overlay : null}
    </OverlayWrapper>
  );

  const renderChildren = () => {
    return Children.map(children, (child: ReactElement, index: number) => {
      if (index === 0) {
        return React.cloneElement(child, { onClick: handleTriggerClick });
      }
      return child;
    });
  };

  return (
    <>
      <div ref={triggerRef}>{renderChildren()}</div>
      {modalRoot ? createPortal(renderOverlay(), modalRoot) : null}
    </>
  );
};

export default Dropdown;
