import React, { FC, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';
import { cn } from '@bem-react/classname';
import hexToRgba from 'hex-to-rgba';

import { promoCodesAPI } from '@/api';

import useDownload from '@/hooks/useDownload';

import { Loader } from '@/components/Loader';
import { MainTitle } from '@/components/MainTitle';
import { Button, TextButton } from '@/components/Buttons';
import { Modal } from '@/components/Modal';
import { PromoCodeBySMSForm, PromoCodeByEmailForm } from '@/components/Forms';
import { PromoCodeHolder } from '@/components/PromoCodeHolder';
import { QRCode } from '@/components/QRCode';
import {
  SvgAppleWallet,
  SvgGooglePay,
  SvgSMS,
  SvgEmail,
} from '@/components/Svg';
import { colors } from '@/constants';

import './PromoCodeForClients.less';


interface IProps {
  promoCodeToken?: string;
  promoCode: string;
  promotionUrl: string;
  shareToken?: string;
  isEnd?: boolean;
  asCard?: boolean;
}

interface IWrapperProps {
  asCard: boolean;
}

interface IButtonWalletWrapperProps {
  last?: boolean;
}

const Wrapper = styled.div<IWrapperProps>`
  width: 100%;
  background-color: ${colors.WHITE};
  ${({ asCard }: IWrapperProps) => {
    if (asCard) {
      return css`
        padding: 28px;
        border-radius: 16px;
        box-shadow: 0 6px 20px 0 ${hexToRgba(colors.BLACK, 0.08)};
      `;
    }
    return '';
  }}
`;

const TitleWrapper = styled.div`
  margin-bottom: 24px;
  text-align: center;
`;

const SubtitleWrapper = styled.div`
  margin: 0 0 8px;
  padding: 8px 0 0 0;
  text-align: center;
`;

const PromoCodeWrapper = styled.div`
  margin-bottom: 16px;
`;

const QRCodeWrapper = styled.div`
  margin-bottom: 24px;
`;

const ButtonWalletWrapper = styled.div<IButtonWalletWrapperProps>`
  margin-bottom: ${({ last }: IButtonWalletWrapperProps) => (last ? 24 : 16)}px;
`;

const ButtonInner = styled.span`
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ButtonIconWrapper = styled.span`
  width: 40px;
`;

const ButtonTextWrapper = styled.span`
  width: 100%;
  display: block;
  text-align: center;
`;

const EndLabel = styled.div`
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: ${colors.RED};
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${colors.LILAC_WHITE};
`;

const FormWrapper = styled.div`
  width: 294px;
`;

const cnPromoCodeForClient = cn('PromoCodeForClient');

const PromoCodeForClient: FC<IProps> = ({
  promoCodeToken,
  promoCode,
  promotionUrl,
  shareToken,
  isEnd = false,
  asCard = false,
}: IProps) => {
  const [isOpenedEmailModal, setOpenedEmailModal] = useState(false);
  const [isOpenedSMSModal, setOpenedSMSModal] = useState(false);
  const [googleWalletToken, setGoogleWalletToken] = useState('');

  const appleWalletDownload = useDownload({
    downloadHandler: () =>
      promoCodesAPI.addPromoCodeToAppleWallet(promoCodeToken || ''),
    title: promoCodeToken && `Fartsa-${promoCodeToken}.pkpass`,
  });


  const intl = useIntl();

  const tMainTitle = intl.formatMessage({ id: 'promoCodes.yourPromoCode' });
  const tSaveTo = intl.formatMessage({ id: 'common.saveTo' });

  const handleAppleWalletClick = () => {
    appleWalletDownload.handleDownload();
  };

  useEffect(() => {
    const fetchData = async () => {
      const googleToken = await promoCodesAPI.addPromoCodeToGoogleWallet(promoCodeToken || '');
      setGoogleWalletToken(googleToken);

    };
    fetchData();

  }, []);


  const handleGooglePayClick = () => {
    // TODO: delete console
    console.log('Add Google Pay');


  };

  const handleSendByEmailClick = () => {
    setOpenedEmailModal(true);
  };

  const handleSendBySMSClick = () => {
    setOpenedSMSModal(true);
  };

  const handleCloseEmailModal = () => {
    setOpenedEmailModal(false);
  };

  const handleCloseSMSModal = () => {
    setOpenedSMSModal(false);
  };

  const renderWalletButtons = () => (
    <>
      <ButtonWalletWrapper>
        <Button
          className={cnPromoCodeForClient('Button')}
          type="button"
          displayType="primary"
          fluid
          onClick={handleAppleWalletClick}
        >
          <ButtonInner>
            {appleWalletDownload.pending ? (
              <Loader size={22} thickness={3} color={colors.WHITE} />
            ) : (
              <>
                <ButtonIconWrapper>
                  <SvgAppleWallet width={30} height={26} />
                </ButtonIconWrapper>
                <ButtonTextWrapper>
                  <FormattedMessage id="common.wallet.addApple" />
                </ButtonTextWrapper>
              </>
            )}
          </ButtonInner>
        </Button>
      </ButtonWalletWrapper>
      {
        <a href={`https://pay.google.com/gp/v/save/${googleWalletToken}`} target='_blank'>
          <ButtonWalletWrapper last>
            <Button
              className={cnPromoCodeForClient('Button')}
              type="button"
              displayType="primary"
              fluid
              onClick={handleGooglePayClick}
            >
              <ButtonInner>
                <ButtonIconWrapper>
                  <SvgGooglePay width={30} height={26} />
                </ButtonIconWrapper>
                <ButtonTextWrapper>
                  <FormattedMessage id="common.wallet.addGooglePay" />
                </ButtonTextWrapper>
              </ButtonInner>
            </Button>
          </ButtonWalletWrapper>
        </a>
      }
    </>
  );

  const renderSavePromoCode = () => (
    <>
      <SubtitleWrapper>
        <MainTitle text={tSaveTo} fontSize={16} fontWeight={700} />
      </SubtitleWrapper>
      <TextButton
        icon={<SvgEmail width={18} fill={colors.BLACK} />}
        text="Email"
        onClick={handleSendByEmailClick}
      />
      <Divider />
      <TextButton
        icon={<SvgSMS width={18} fill={colors.BLACK} />}
        text="SMS"
        onClick={handleSendBySMSClick}
      />
    </>
  );

  return (
    <Wrapper asCard={asCard}>
      <TitleWrapper>
        <MainTitle
          text={tMainTitle}
          fontSize={22}
          fontWeight={900}
          hLevel={5}
        />
      </TitleWrapper>
      {isEnd ? (
        <EndLabel>
          <FormattedMessage id="promoCodes.promoCodeEnd" />
        </EndLabel>
      ) : null}
      <PromoCodeWrapper>
        <PromoCodeHolder value={promoCode} />
      </PromoCodeWrapper>
      <QRCodeWrapper>
        <QRCode value={promotionUrl} />
      </QRCodeWrapper>
      {renderWalletButtons()}
      {renderSavePromoCode()}
      <Modal visible={isOpenedEmailModal} onCancel={handleCloseEmailModal}>
        <FormWrapper>
          <PromoCodeByEmailForm
            onCancel={handleCloseEmailModal}
            onSuccess={handleCloseEmailModal}
            promoCodeToken={promoCodeToken}
            shareToken={shareToken}
          />
        </FormWrapper>
      </Modal>
      <Modal visible={isOpenedSMSModal} onCancel={handleCloseSMSModal}>
        <FormWrapper>
          <PromoCodeBySMSForm
            onCancel={handleCloseSMSModal}
            onSuccess={handleCloseSMSModal}
            promoCodeToken={promoCodeToken}
            shareToken={shareToken}
          />
        </FormWrapper>
      </Modal>
    </Wrapper>
  );
};

export default PromoCodeForClient;
