import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgSMS: FC<ISvgProps> = (props: ISvgProps) => {
  return (
    <svg viewBox="0 0 18 15" {...props}>
      <path d="M14.309 0H3.69A3.695 3.695 0 000 3.691v5.702a3.63 3.63 0 002.612 3.491l1.782 1.783a.525.525 0 00.746 0l1.636-1.635h7.533A3.696 3.696 0 0018 9.34V3.691A3.695 3.695 0 0014.309 0zm2.636 9.34a2.64 2.64 0 01-2.636 2.637H6.557a.528.528 0 00-.373.155l-1.417 1.417-1.509-1.51a.53.53 0 00-.245-.138 2.58 2.58 0 01-1.958-2.508V3.69a2.64 2.64 0 012.636-2.636H14.31a2.64 2.64 0 012.636 2.636v5.65z" />
      <path d="M12.348 5.056H5.652a.528.528 0 000 1.055h6.696a.527.527 0 100-1.055zm0 1.864H5.652a.528.528 0 000 1.055h6.696a.527.527 0 100-1.054z" />
    </svg>
  );
};

export default SvgSMS;
