import React from 'react';
import { useIntl } from 'react-intl';
import { Container } from 'styled-bootstrap-grid';
import { Helmet } from 'react-helmet';
import { Header } from '@/components/Header';
import { Footer } from '@/components/Footer';
import { PageErrorMessage } from '@/components/PageErrorMessage';
import { Page, PageContent } from '@/components/shared';

const Error404Page = () => {
  const intl = useIntl();

  const tMessage = intl.formatMessage({ id: 'errorPage.title404' });

  const tSeoTitle = intl.formatMessage({
    id: 'seo.title.error404',
  });

  return (
    <Page>
      <Helmet>
        <title>{tSeoTitle}</title>
      </Helmet>
      <Header />
      <PageContent>
        <Container>
          <PageErrorMessage statusCode={404} title={tMessage} />
        </Container>
      </PageContent>
      <Footer />
    </Page>
  );
};

export default Error404Page;
