import { IError } from '@/types';

const DEFAULT_ERROR: IError = {
  statusCode: null,
  title: '',
  message: '',
  errors: {},
};

const TILE_URL = 'https://b.tilessputnik.ru/{z}/{x}/{y}.png';

const DADATA_TOKEN = 'fc496eb921337e2ee51ef7457bb08f28e3e93a9c';

const DEFAULT_MARKER_WIDTH = 32;
const DEFAULT_MARKER_POPUP_WIDTH = 232;
const DEFAULT_MARKER_POPUP_HEIGHT = 200;
const DEFAULT_DIAL_CODE = '+7';
const DEFAULT_COUNTRY_CODE = 'RU';

const MARKER_CLUSTER_CLASS_PREFIX = 'fartsa-marker-cluster';
const MARKER_CLASS_PREFIX = 'fartsa-marker';
const MARKER_CLUSTER_ACTIVE_CLASS = `${MARKER_CLUSTER_CLASS_PREFIX}-active`;

const PREVENT_CLICK_OUTSIDE_CLASS = 'prevent-click-outside';

export default {
  DADATA_TOKEN,
  DEFAULT_ERROR,
  DEFAULT_MARKER_WIDTH,
  DEFAULT_MARKER_POPUP_WIDTH,
  DEFAULT_MARKER_POPUP_HEIGHT,
  DEFAULT_DIAL_CODE,
  DEFAULT_COUNTRY_CODE,
  TILE_URL,
  MARKER_CLUSTER_CLASS_PREFIX,
  MARKER_CLASS_PREFIX,
  MARKER_CLUSTER_ACTIVE_CLASS,
  PREVENT_CLICK_OUTSIDE_CLASS,
};
