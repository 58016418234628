import React, { useState, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Container } from 'styled-bootstrap-grid';
import { cn } from '@bem-react/classname';
import moment from 'moment';

import { datetime } from '@/constants';

import { useAuth } from '@/data/useAuth';
import { useMyPromotions } from '@/data/useMyPromotions';

import { processRewards } from '@/helpers/processRewards';

import { Layout } from '@/components/Layout';
import { FilterButtons } from '@/components/FilterButtons';
import { PromotionCardPersonal } from '@/components/PromotionCard';
import { Loader } from '@/components/Loader';

import { filters } from './config';
import { TMyPromoFilter } from './types';
import './PromoPage.less';

const cnPromoPage = cn('PromoPage');

const PromoPage = () => {
  const { formatMessage } = useIntl();
  const { isAuth } = useAuth();

  const [activeFilter, setMyPromoFilter] = useState<TMyPromoFilter>(filters[0]);

  const { promoactions, isLoading } = useMyPromotions(activeFilter.filterBy);

  const [showLoader, setShowLoader] = useState(isLoading);

  useEffect(() => {
    if (showLoader) {
      setTimeout(() => setShowLoader(false), 500);
    }
  }, [showLoader]);

  const tNoPromoTitle = formatMessage({ id: 'myPromo.empty.title' });
  const tNoPromoText = formatMessage({ id: 'myPromo.empty.text' });

  const handleFilter = useCallback((filter: TMyPromoFilter) => {
    const { id, filterBy } = filter;
    setMyPromoFilter((currentFilter) => ({
      id,
      filterBy: {
        ...currentFilter.filterBy,
        ...filterBy,
      },
    }));
  }, []);

  const cards = promoactions.map(
    ({ id, title, ambassadorParticipation, images, ambassadorRewards }) => {
      const processedRewards = processRewards(ambassadorRewards, 'rouble');


      const distributionReward = processedRewards.find(
        (i) => i.type === 'PromocodeDistribution',
      )?.value;

      const activationReward = processedRewards.find(
        (i) => i.type === 'PromocodeActivation',
      )?.value;

      const purchaseReward = processedRewards.find(
        (i) => i.type === 'PromocodePurchase',
      )?.value;

      const data = {
        id,
        status: activeFilter.id,
        participatingSince: moment(ambassadorParticipation?.approvedAtUtc).format(
          datetime.DATE_FORMAT,
        ),
        img: images?.[0]?.media?.path || '',
        title,
        distributionReward,
        activationReward,
        purchaseReward,
      };

      return (
        <PromotionCardPersonal
          key={id}
          data={data}
          className={cnPromoPage('Card')}
        />
      );
    },
  );

  return (
    <Layout
      isAuth={isAuth}
      i18nSeoTitle="seo.title.myPromo"
      i18nTitle="common.myPromo"
    >
      <Container>
        <FilterButtons
          className={cnPromoPage('Filters')}
          defaultId={activeFilter.id}
          filters={filters}
          handleFilter={handleFilter}
        />

        <div className={cnPromoPage('Cards')}>
          {showLoader && (
            <div className={cnPromoPage('Loader-Wrapper')}>
              <Loader size={52} />
            </div>
          )}

          {!showLoader && cards}

          {!showLoader && !cards.length && (
            <>
              <p className={cnPromoPage('NoPromo-Title')}>{tNoPromoTitle}</p>

              <p className={cnPromoPage('NoPromo-Text')}>{tNoPromoText}</p>
            </>
          )}
        </div>
      </Container>
    </Layout>
  );
};

export default PromoPage;
