import React, { FC, createRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import { MainTitle } from '@/components/MainTitle';
import { FieldLabel, PhoneInput } from '@/components/FormControls';
import { Button } from '@/components/Buttons';
import { Loader } from '@/components/Loader';
import { FormField, FormControlWrapper } from '@/components/shared';
import { colors, common } from '@/constants';
import { IError, TAuthFormData, TAuthMode } from '@/types';
import ReCAPTCHA from 'react-google-recaptcha';


interface IProps {
  loading: boolean;
  error: IError;
  insideModal?: boolean;
  setAuthActiveMode: (mode: TAuthMode) => void;
  signIn: (data: TAuthFormData) => void;
}

type TFormData = {
  phoneNumber: string;
};

const Wrapper = styled.div`
  width: 330px;
`;

const TitleWrapper = styled.div`
  margin: 0 0 34px 0;
  text-align: center;
`;

const SMSInfo = styled.div`
  margin-top: 2px;
  font-size: 12px;
  color: ${colors.MONSOON};
`;

const SubmitButtonWrapper = styled.div`
  padding: 16px 0;
  width: 100%;
`;

const SignUpWrapper = styled.div`
  padding: 16px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Question = styled.div`
  margin: 0 6px 0 0;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  color: ${colors.BLACK};
`;

const signUpButtonCss = css`
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  color: ${colors.BLUE};
  background-color: transparent;
  border: 0;
  cursor: pointer;

  &:hover {
    color: ${colors.AZUL};
  }
`;

const SignUpButton = styled.button`
  ${() => signUpButtonCss}
`;

const SignUpLink = styled(Link)`
  ${() => signUpButtonCss}
  text-decoration: none;
`;

const { DEFAULT_DIAL_CODE, DEFAULT_COUNTRY_CODE } = common;

const SignInForm: FC<IProps> = ({
  signIn,
  loading,
  error,
  insideModal = false,
  setAuthActiveMode,
}: IProps) => {
  // const [reCaptchaVerifierState, setReCaptchaVerifierState] = useState<
  //   TReCaptchaVerifier
  // >();
  // const reCaptchaRef = createRef<HTMLDivElement>();
  const intl = useIntl();
  const capthaRef = createRef<ReCAPTCHA>();



  const [reCaptchaState, setReCaptchaState] = useState('');

  const tMainTitle = intl.formatMessage({ id: 'auth.login' });

  const tPhoneNumberPlaceholder = intl.formatMessage({
    id: 'form.placeholders.phoneNumber',
  });
  const tFooterQuestion = intl.formatMessage({ id: 'auth.noAccount' });
  const tFooterButtonLabel = intl.formatMessage({ id: 'auth.signUp' });
  const tErrorRequiredPhoneNumber = intl.formatMessage({
    id: 'form.errors.phoneNumber.required',
  });

  const validationSchema = yup.object().shape({
    phoneNumber: yup.string().required(tErrorRequiredPhoneNumber),
  });

  const { register, setValue, handleSubmit, errors, clearErrors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  let phoneNumberErrorText = '';

  if (errors.phoneNumber && !error.statusCode) {
    phoneNumberErrorText = errors.phoneNumber.message;
  } else if (error.statusCode) {
    phoneNumberErrorText = error.title || '';
  }

  useEffect(() => {
    register('phoneNumber');
  }, [register]);



  const onSubmit = (data: TFormData) => {

    signIn({
      phoneNumber: data.phoneNumber,
      reCaptchaVerifier: reCaptchaState
    });
  };





  const handleInputChange = (v: string) => {
    setValue('phoneNumber', v);
  };

  const handleInputFocus = () => {
    clearErrors('phoneNumber');
  };

  const handleToggleAuthButtonClick = () => {
    setAuthActiveMode('signUp');
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <MainTitle text={tMainTitle} />
      </TitleWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormField>
          <FieldLabel forAttr="phone-number-auth">
            <FormattedMessage id="form.labels.phoneNumber" />
            <FormControlWrapper>
              <PhoneInput
                id="phone-number-auth"
                dialCode={DEFAULT_DIAL_CODE}
                countryCode={DEFAULT_COUNTRY_CODE}
                placeholder={tPhoneNumberPlaceholder}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                disabled={loading}
                isError={errors.phoneNumber || error.statusCode}
                errorMessage={phoneNumberErrorText}
              />
            </FormControlWrapper>
          </FieldLabel>
        </FormField>
        <FormField>
          {/* <div ref={reCaptchaRef} id="recaptcha-container" /> */}
        </FormField>
        <SMSInfo>
          <FormattedMessage id="form.info.checkPhone.phoneTariffs" />
        </SMSInfo>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_SITE_RECAPTCHA_KEY!}
          ref={capthaRef}
          onChange={() => setReCaptchaState(capthaRef.current?.getValue()!)} />
        <SubmitButtonWrapper>
          <Button fluid type="submit" displayType="primary" disabled={loading}>
            {loading ? (
              <Loader size={22} thickness={3} color={colors.WHITE} />
            ) : (
              <FormattedMessage id="common.continue" />
            )}
          </Button>
        </SubmitButtonWrapper>
      </form>
      <SignUpWrapper>
        <Question>{tFooterQuestion}</Question>
        {insideModal ? (
          <SignUpButton type="button" onClick={handleToggleAuthButtonClick}>
            {tFooterButtonLabel}
          </SignUpButton>
        ) : (
          <SignUpLink to="/signup">{tFooterButtonLabel}</SignUpLink>
        )}
      </SignUpWrapper>
    </Wrapper>
  );
};

export default SignInForm;
