import React, { useState } from 'react';
import { Dropdown } from '../Dropdown';
import NotificationsList from './NotificationList';
import { SvgNotification, SvgNotificationsActive, SvgNotificationsAlert } from '../Svg';
import styled from 'styled-components';
import { DropdownOverlay } from '../shared';
import { useNotifications } from '@/data/useNotifications';




const NotificationsWrapper = styled.div`
  width: 400px;
  max-height: 290px;
  min-height: 125px;
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-color:  ligth;
  padding: 10px 10px 10px 10px;
  &::-webkit-scrollbar {
	background-color: white;
	width:0px;
	color: gray;
  }
  &::-webkit-scrollbar-thumb {
	
	border-radius: 6px;
	background-color: gray;
  
	
  }
  `;

const SvgWrapper = styled.div`
cursor: pointer;
transition:  top 1s ease-out 0.5s;
margin-left: 10px;
`;








export const NotificationDropdow = () => {
	const [isActive, setActive] = useState(false);
	const { notifications, mutate } = useNotifications();
	const renderSvg = () => {
		if (notifications.length == 0) {
			return <SvgNotification
				width={22}
				height={22}
			/>;
		}
		if (isActive) {
			return <SvgNotificationsActive
				width={22}
				height={22}


			/>;

		}
		return <SvgNotificationsAlert width={22}
			height={22} />;
	};

	const renderNotifications = () => (
		<DropdownOverlay>
			<NotificationsWrapper>
				<NotificationsList notifications={notifications}
				/>
			</NotificationsWrapper>
		</DropdownOverlay>
	);



	const handleDropdownChange = (status: boolean) => {
		mutate();
		setActive(status);
	};


	return (
		<Dropdown overlay={renderNotifications()} onChange={handleDropdownChange} >
			<SvgWrapper>
				{renderSvg()}
			</SvgWrapper>
		</Dropdown>

	);
};
