import basicFetcher from './utils/basicFetcher';

export default {
  async getQuickSearchResult(url: string, text: string) {
    if (!text) {
      return {
        data: {
          promoactions: [],
          categories: [],
          brands: [],
        },
      };
    }

    try {
      const response = await basicFetcher(url, { text });
      return response;
    } catch (error) {
      throw error;
    }
  },
};
