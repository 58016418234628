import React, { FC, useReducer } from 'react';
import { produce } from 'immer';
import SearchFiltersContext from '@/context/SearchFiltersContext';
import {
  ISearchFiltersState,
  TSearchFiltersAction,
  ISearchFilterValues,
  TPayloadFilterCoordinates,
  TPayloadFilterStatus,
} from '@/types';

interface IProps {
  children: JSX.Element;
}

const INITIAL_FILTERS: ISearchFilterValues = {
  coordinates: null,
};

const INITIAL_STATE: ISearchFiltersState = {
  filterValues: INITIAL_FILTERS,
  activeFiltersMenu: false,
};

const reducer = (state: ISearchFiltersState, action: TSearchFiltersAction) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'SET_FILTER': {
        const draftState = draft;

        const payloadFilterCoordinates = action.payload as TPayloadFilterCoordinates;

        if (payloadFilterCoordinates.filter === 'coordinates') {
          draftState.filterValues.coordinates = payloadFilterCoordinates.value;
          return draftState;
        }

        return draftState;
      }

      case 'SET_ACTIVE_MENU_STATUS': {
        const draftState = draft;
        const payload = action.payload as TPayloadFilterStatus;
        draftState.activeFiltersMenu = payload.status;
        return draftState;
      }

      default:
        return state;
    }
  });

const SearchFiltersProvider: FC<IProps> = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const value = {
    state,
    dispatch,
  };

  return (
    <SearchFiltersContext.Provider value={value}>
      {children}
    </SearchFiltersContext.Provider>
  );
};

export default SearchFiltersProvider;
