import useSWR from 'swr';
import axios from 'axios';
import { brandsAPI } from '@/api';
import { createErrorObject } from '@/helpers/createErrorObject';

interface IBrand {
  id: number | string;
  title: string;
}

const useBrands = (categories?: string | string[]) => {
  const {
    data,
    mutate,
    error,
  }: { data?: IBrand[]; mutate: any; error?: any } = useSWR(
    ['catalog/brands', categories],
    brandsAPI.getBrands,
    {
      revalidateOnFocus: false,
    },
  );

  const isLoading = !data && (!error || axios.isCancel(error));
  const isError = !!error;

  return {
    mutate,
    isLoading,
    isError,
    brands: data || [],
    error: error ? createErrorObject(error) : null,
  };
};

export { useBrands };
