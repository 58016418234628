import React, { useMemo } from 'react';
import { Switch, Route } from 'react-router-dom';

import { Error404Page } from '@/pages';

import { routes } from './config';

const Routes = () => {
  const data = useMemo(
    () =>
      routes.map(({ path, component }) => (
        <Route key={path} exact path={path} component={component} />
      )),
    [],
  );

  return (
    <Switch>
      {data}

      <Route component={Error404Page} />
    </Switch>
  );
};

export default Routes;
