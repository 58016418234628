import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { FixedSizeList as VList } from 'react-window';
import { CustomScrollbarsVirtualList } from '@/components/CustomScrollbars';
import {
  CheckboxGroup,
  SelectionButton,
  BaseInput,
} from '@/components/FormControls';
import { Button } from '@/components/Buttons';
import { useFilteredSelectorOptions } from '@/hooks/useFilteredSelectorOptions';
import { colors, common } from '@/constants';
import { ISelectOption } from '@/types';

const VLIST_ITEM_HEIGHT = 42;

interface IProps {
  items: Array<ISelectOption>;
  fieldsName: string;
  defaultValue?: Array<string>;
  withSearch?: boolean;
  listSize?: IListSize;
  resetOnly?: boolean;
  onChange: (v: Array<string>) => void;
  onApply?: () => void;
}

interface IListSize {
  width: number | string;
  height: number;
}

interface IRenderer {
  index: number;
  style: any;
}

const CheckboxButtonWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.GHOST_WHITE};
`;

const TopBar = styled.div`
  padding: 16px;
`;

const BottomBar = styled(TopBar)`
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  max-height: 67px;
  border-top: 1px solid ${colors.GHOST_WHITE};
`;

const MultiSelection: FC<IProps> = ({
  items,
  fieldsName,
  onChange,
  defaultValue = [],
  withSearch = false,
  resetOnly = false,
  listSize = {
    width: '100%',
    height: 300,
  },
  onApply = () => {},
}: IProps) => {
  const intl = useIntl();
  const [activeValue, setActiveValue] = useState<string[]>([]);
  const { options, onSearchChange } = useFilteredSelectorOptions(
    items,
    activeValue,
  );

  const tSearch = intl.formatMessage({
    id: 'common.search',
  });
  const tClear = intl.formatMessage({
    id: 'common.reset',
  });
  const tApply = intl.formatMessage({
    id: 'common.apply',
  });

  useEffect(() => {
    setActiveValue(defaultValue);
    // eslint-disable-next-line
  }, []);

  const renderRow = (onSelect: (v: string) => void, currentValue: string[]) => (
    data: IRenderer,
  ) => {
    const { index, style } = data;

    const handleSelect = (value: string) => {
      onSelect(value);
    };

    const isChecked = currentValue.indexOf(options[index].value) !== -1;
    const name = `${fieldsName}-${options[index].value}`;

    return (
      <div style={style}>
        <CheckboxButtonWrapper>
          <SelectionButton
            classNames={common.PREVENT_CLICK_OUTSIDE_CLASS}
            name={name}
            value={options[index].value}
            label={options[index].label}
            type="checkbox"
            onSelect={handleSelect}
            checked={isChecked}
          />
        </CheckboxButtonWrapper>
      </div>
    );
  };

  return (
    <>
      <CheckboxGroup defaultValue={defaultValue} onChange={onChange}>
        {(onSelect, currentValue) => {
          const handleReset = () => {
            onSelect([]);
          };

          const handleApply = () => {
            onApply();
          };

          return (
            <>
              {withSearch && (
                <TopBar>
                  <BaseInput
                    placeholder={tSearch}
                    onInputChange={onSearchChange}
                    withClearButton
                  />
                </TopBar>
              )}
              <VList
                itemCount={options.length}
                itemSize={VLIST_ITEM_HEIGHT}
                width={listSize.width}
                height={listSize.height}
                outerElementType={CustomScrollbarsVirtualList}
              >
                {renderRow(onSelect, currentValue)}
              </VList>

              <BottomBar>
                {resetOnly ? (
                  <Button onClick={handleReset} fluid displayType="default">
                    {tClear}
                  </Button>
                ) : (
                  <>
                    <Button onClick={handleReset} displayType="link">
                      {tClear}
                    </Button>
                    <Button onClick={handleApply} displayType="primary">
                      {tApply}
                    </Button>
                  </>
                )}
              </BottomBar>
            </>
          );
        }}
      </CheckboxGroup>
    </>
  );
};

export default MultiSelection;
