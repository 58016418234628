import React, { FC, memo, useState, useEffect } from 'react';
import {
  IPromotion,
} from '@/types';
import { useSearchFiltersState } from '@/hooks/useSearchFiltersState';
import { SvgSettings } from '../Svg';
import { colors } from '@/constants';
import { MarkerList } from './MarkerList';
import yandexMap from '@/helpers/yandexMapImport';

interface IProps {
  center: [number, number];
  zoom?: number;
  promotions?: IPromotion[];
  isGeolocationError?: boolean;
  hiddenRender?: boolean;


}



const YandexMap: FC<IProps> = ({
  center,
  zoom = 12,
  promotions = [],



}: IProps) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  let location = ({ center: [center[1], center[0]], zoom });


  const { dispatch: filterStateDispatch } = useSearchFiltersState();


  const renderedPlacemarks = [...promotions].reverse();



  const handleFiltersClick = () => {
    filterStateDispatch({
      type: 'SET_ACTIVE_MENU_STATUS',
      payload: { status: true },
    });
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (

    <yandexMap.YMap location={location} >
      <yandexMap.YMapDefaultSchemeLayer />
      <yandexMap.YMapDefaultFeaturesLayer />
      <yandexMap.YMapControls position="left">
        <yandexMap.YMapZoomControl />
      </yandexMap.YMapControls>
      {windowWidth < 1200 ?
        <yandexMap.YMapControls position="top left">
          <yandexMap.YMapControlButton
            onClick={handleFiltersClick}
          >
            <SvgSettings width={24} fill={colors.BLACK} />
          </yandexMap.YMapControlButton>
        </yandexMap.YMapControls>
        : null}
      <yandexMap.YMapControls position="top right" >
        <yandexMap.YMapGeolocationControl />
      </yandexMap.YMapControls>
      <MarkerList renderedPlacemarks={renderedPlacemarks} />
    </yandexMap.YMap>
  );
};




export default memo(YandexMap, (prevProps: IProps, nextProps: IProps) => {
  if (nextProps.promotions === undefined || nextProps.hiddenRender === true) {

    return true;
  }

  return false;
});
