import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import { colors } from '@/constants';

const DropdownOverlay = styled.div`
  border-radius: 16px;
  box-shadow: 0px 10px 37px ${hexToRgba(colors.BLACK, 0.15)};
  background-color: ${colors.WHITE};
  overflow: hidden;
`;

export { DropdownOverlay };
