import styled from 'styled-components';

const FormField = styled.div`
  padding: 6px 0;
`;

const FormControlWrapper = styled.div`
  margin-top: 6px;
`;

export { FormField, FormControlWrapper };
