import React, { FC, useState, useEffect, useRef, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { gsap, Power4 } from 'gsap';
import hexToRgba from 'hex-to-rgba';
import { SvgClose } from '@/components/Svg';
import { colors } from '@/constants';

interface IProps {
  visible: boolean;
  children: ReactNode;
  onCancel: () => void;
}

interface IModalBoxProps {
  topPosition?: number;
  visible: boolean;
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
`;

const Overlay = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${hexToRgba(colors.WHITE, 0.9)};
  opacity: 0;
`;

const ModalBox = styled.div<IModalBoxProps>`
  position: relative;
  z-index: 3;
  top: ${({ topPosition }: IModalBoxProps) => topPosition}px;
  padding: 30px;
  background-color: ${colors.WHITE};
  border-radius: 16px;
  box-shadow: 0 6px 20px 0 ${hexToRgba(colors.BLACK, 0.08)};
  visibility: ${({ visible }: IModalBoxProps) =>
    visible ? 'visible' : 'hidden'};
  opacity: 0;
`;

const CloseButtonWrapper = styled.div`
  position: fixed;
  z-index: 3;
  top: 14px;
  right: 14px;
  width: 64px;
  height: 64px;
  transform: scale(0);
  opacity: 0;
`;

const CloseButton = styled.button`
  width: 64px;
  height: 64px;
  background-color: ${colors.GHOST_WHITE};
  border: none;
  border-radius: 32px;
`;

type TElements = {
  overlay: HTMLDivElement | null;
  closeButton: HTMLDivElement | null;
  modal: HTMLDivElement | null;
};

const openModal = (elements: TElements, yPosition: number) => {
  const { overlay, modal, closeButton } = elements;

  if (overlay && closeButton && modal) {
    const bodyEl = document.querySelector('body');
    bodyEl!.classList.add('is-open-modal');

    const timeline = gsap.timeline({ ease: Power4.easeIn });
    timeline.to(overlay, { opacity: 1, duration: 0.3 });
    timeline.to(closeButton, { opacity: 1, scale: 1, duration: 0.3 }, '-=0.3');
    timeline.to(
      modal,
      {
        opacity: 1,
        y: yPosition,
        duration: 0.3,
      },
      '-=0.3',
    );
  }
};

const closeModal = (
  elements: TElements,
  yPosition: number,
  onComplete: () => void = () => { },
) => {
  const { overlay, modal, closeButton } = elements;

  if (overlay && closeButton && modal) {
    const bodyEl = document.querySelector('body');
    bodyEl!.classList.remove('is-open-modal');

    const timeline = gsap.timeline({
      onComplete,
      ease: Power4.easeOut,
    });
    timeline.to(overlay, { opacity: 0, duration: 0.3 });
    timeline.to(closeButton, { opacity: 0, scale: 0, duration: 0.3 }, '-=0.3');
    timeline.to(
      modal,
      {
        opacity: 0,
        y: yPosition,
        duration: 0.3,
      },
      '-=0.3',
    );
  }
};

const Modal: FC<IProps> = ({ visible, onCancel, children }: IProps) => {
  const [modalRootEl, setModalRootEl] = useState<any>(null);
  const [targetEl, setTargetEl] = useState<any>(null);
  const [topPosition, setTopPosition] = useState(0);
  const [visibleModalBox, setVisibleModalBox] = useState(false);
  const [isRenderModal, setIsRenderModal] = useState(false);

  const overlayRef = useRef<HTMLDivElement>(null);
  const closeButtonWrapperRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const el = document.createElement('div');
    const modalRoot = document.getElementById('modal-root');
    setModalRootEl(modalRoot);
    setTargetEl(el);
  }, []);

  useEffect(() => {
    if (modalRootEl) {
      targetEl.className = Math.random().toString(36).substring(7);
      modalRootEl.appendChild(targetEl);
    }
    return () => {
      if (modalRootEl) {
        modalRootEl.removeChild(targetEl);
      }
    };
  }, [modalRootEl, targetEl]);

  useEffect(() => {
    if (isRenderModal && modalRef.current) {
      setTopPosition(-modalRef.current.offsetHeight - 100);
      setVisibleModalBox(true);
    }
  }, [isRenderModal]);

  useEffect(() => {
    if (visibleModalBox) {
      openModal(
        {
          overlay: overlayRef.current,
          closeButton: closeButtonWrapperRef.current,
          modal: modalRef.current,
        },
        Math.abs(topPosition) + 48,
      );
    }
  }, [visibleModalBox, topPosition]);

  useEffect(() => {
    if (overlayRef && closeButtonWrapperRef && modalRef) {
      if (visible) {
        setIsRenderModal(true);
      } else if (!visible) {
        closeModal(
          {
            overlay: overlayRef.current,
            closeButton: closeButtonWrapperRef.current,
            modal: modalRef.current,
          },
          topPosition,
          () => {
            setVisibleModalBox(false);
            setIsRenderModal(false);
            onCancel();
          },
        );
      }
    }
  }, [
    visible,
    overlayRef,
    closeButtonWrapperRef,
    modalRef,
    onCancel,
    topPosition,
  ]);

  const renderModal = () =>
    isRenderModal ? (
      <Wrapper>
        <Overlay ref={overlayRef} onClick={onCancel} />

        <CloseButtonWrapper ref={closeButtonWrapperRef}>
          <CloseButton type="button" onClick={onCancel}>
            <SvgClose width={16} height={16} fill={colors.BLUE} />
          </CloseButton>
        </CloseButtonWrapper>

        <ModalBox
          ref={modalRef}
          visible={visibleModalBox}
          topPosition={topPosition}
        >
          {children}
        </ModalBox>
      </Wrapper>
    ) : null;

  return modalRootEl && targetEl ? createPortal(renderModal(), targetEl) : null;
};

export default Modal;
