import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Container, Row } from 'styled-bootstrap-grid';
import { Header } from '@/components/Header';
import { Footer } from '@/components/Footer';
import { MainTitle } from '@/components/MainTitle';
import { MainColumn } from '@/components/MainColumn';
import { SidebarColumn } from '@/components/SidebarColumn';
import { PromotionMainContent } from '@/components/PromotionMainContent';
import { AboutCompanySidebar } from '@/components/AboutCompanySidebar';
import { PromoCodeGenerator } from '@/components/PromoCodeGenerator';
import { PromoCodeForClient } from '@/components/PromoCodeForClient';
import { LabelWithIcon } from '@/components/LabelWithIcon';
import { Loader } from '@/components/Loader';
import { PageErrorMessage } from '@/components/PageErrorMessage';
import { SvgCase } from '@/components/Svg';
import { Page, PageContent, SidebarBlock } from '@/components/shared';
import { usePromotionByShareToken } from '@/data/usePromotionByShareToken';
import { colors } from '@/constants';

type TUrlParams = {
  token?: string;
};

interface TBuildSuccessResult {
  promoCode?: string;
  promotionUrl: string;
  shareToken: string;
}

const PromotionBaseInfoWrapper = styled.div`
  padding: 46px 0 10px 0;
`;

const PromotionTitleWrapper = styled.div`
  margin-bottom: 22px;
`;

const PromotionBaseInfoRow = styled.div`
  margin-bottom: 16px;
`;

const LoaderWrapper = styled.div`
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SharedPromotionPage = () => {
  const [promoCodeValue, setPromoCodeValue] = useState('');
  const [promotionUrlValue, setPromotionUrlValue] = useState('');

  const intl = useIntl();
  const { token }: TUrlParams = useParams();
  const [newToken, setNewToken] = useState('');

  const {
    data: { promotion, promoCode },
    isLoading,
    error,
  } = usePromotionByShareToken(token || '');

  useEffect(() => {
    const splited = window.location.href.split('/');

    setNewToken(splited[splited.length - 1]);

  }, []);

  useEffect(() => {
    if (promoCode?.value) {
      setPromoCodeValue(promoCode?.value);
    }
  }, [promoCode]);

  const pageTitle = promotion?.title || '';

  const tError404Title = intl.formatMessage({ id: 'errorPage.title404' });
  const tSeoTitle = intl.formatMessage(
    { id: 'seo.title.promotion' },
    {
      name: pageTitle,
    },
  );

  const handleBuildPromoCode = (data: TBuildSuccessResult) => {
    setPromoCodeValue(data.promoCode || '');
    setNewToken(data.shareToken);
    setPromotionUrlValue(data.promotionUrl);
    window.history.replaceState(null, '', `/code/token/${data.shareToken}`);

  };

  const promoCodeForClient = promoCodeValue || promoCode?.value || '';
  const promotionUrlForClient = promotionUrlValue || window.location.href;

  const renderContent = () => (
    <>
      <PromotionBaseInfoWrapper>
        <PromotionTitleWrapper>
          <MainTitle
            text={pageTitle}
            hLevel={1}
            fontSize={30}
            fontWeight={900}
          />
        </PromotionTitleWrapper>
        {promotion?.business?.name ? (
          <PromotionBaseInfoRow>
            <LabelWithIcon
              icon={<SvgCase width={18} fill={colors.BLUE} />}
              text={promotion.business.name}
            />
          </PromotionBaseInfoRow>
        ) : null}
      </PromotionBaseInfoWrapper>
      <Row justifyContent="between">
        <MainColumn>
          {promotion ? <PromotionMainContent promotion={promotion} /> : null}
        </MainColumn>
        <SidebarColumn>
          <aside>
            {token && !(promoCodeForClient && promotionUrlForClient) ? (
              <SidebarBlock>
                <PromoCodeGenerator
                  shareToken={token}
                  asCard
                  onPromoCodeBuildSuccess={handleBuildPromoCode}
                />
              </SidebarBlock>
            ) : null}
            {promoCodeForClient && promotionUrlForClient ? (
              <SidebarBlock>
                <PromoCodeForClient
                  shareToken={newToken}
                  promoCode={promoCodeForClient}
                  promoCodeToken={newToken}
                  promotionUrl={promotionUrlForClient}
                  asCard
                />
              </SidebarBlock>
            ) : null}
            {promotion?.legalEntity.name ? (
              <SidebarBlock>
                <AboutCompanySidebar promotion={promotion} />
              </SidebarBlock>
            ) : null}
          </aside>
        </SidebarColumn>
      </Row>
    </>
  );

  if (error && error.statusCode === 404) {
    return (
      <PageContent>
        <Container>
          <PageErrorMessage
            statusCode={error.statusCode}
            title={tError404Title}
          />
        </Container>
      </PageContent>
    );
  }

  return (
    <Page>
      <Helmet>
        <title>{tSeoTitle}</title>
      </Helmet>
      <Header />
      <PageContent>
        <Container>
          {isLoading ? (
            <LoaderWrapper>
              <Loader size={52} thickness={4} color={colors.BLUE} />
            </LoaderWrapper>
          ) : (
            renderContent()
          )}
        </Container>
      </PageContent>
      <Footer />
    </Page>
  );
};

export default SharedPromotionPage;
