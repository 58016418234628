const DATE_TIME_FORMAT = 'DD.MM.YYYY, HH:mm';
const DATE_FORMAT = 'DD.MM.YYYY';
const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';
const DAY_MONTH_TIME_FORMAT = 'hh:mm, DD.MM ';
const HOUR_MINUTE_FORMAT = 'HH:mm';

export default {
  BACKEND_DATE_FORMAT,
  DATE_TIME_FORMAT,
  DATE_FORMAT,
  DAY_MONTH_TIME_FORMAT,
  HOUR_MINUTE_FORMAT
};
