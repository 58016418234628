import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgPassportMain: FC<ISvgProps> = (props: ISvgProps) => {
  return (
    <svg viewBox="0 0 80 56" {...props}>
      <defs>
        <path
          id="a"
          d="M0 2C0 .9.9 0 2 0h16.43a2 2 0 012 2v11.06a2 2 0 01-2 2H2a2 2 0 01-2-2V2z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M0 4a4 4 0 014-4h72a4 4 0 014 4v48a4 4 0 01-4 4H4a4 4 0 01-4-4V4z"
          fill="#DFE5FF"
          fillRule="nonzero"
        />
        <path
          d="M17.06 5.5a1 1 0 000 2v-2zm10.12 2a1 1 0 100-2v2zm-10.12 0h10.12v-2H17.06v2zM50.87 5.5a1 1 0 000 2v-2zm10.12 2a1 1 0 100-2v2zm-10.12 0h10.12v-2H50.87v2z"
          fill="#2945E4"
          fillRule="nonzero"
        />
        <path d="M40 0v56h2V0z" fill="#F8F9FF" fillRule="nonzero" />
        <path
          d="M8.88 15.4a1 1 0 00-2 0h2zm-2 25.2a1 1 0 002 0h-2zm0-25.2v25.2h2V15.4h-2zM14.28 20a1 1 0 00-2 0h2zm-2 16a1 1 0 002 0h-2zm0-16v16h2V20h-2zM27.88 14.4a1 1 0 00-2 0h2zm-2 12.8a1 1 0 002 0h-2zm0-12.8v12.8h2V14.4h-2zM27.88 36a1 1 0 00-2 0h2zm-2 9.6a1 1 0 002 0h-2zm0-9.6v9.6h2V36h-2zM51.28 16.57a1 1 0 00-2 0h2zm-2 9.32a1 1 0 102 0h-2zm0-9.32v9.32h2v-9.32h-2zM64.08 14.8a1 1 0 00-2 0h2zm-2 12.86a1 1 0 102 0h-2zm0-12.87v12.87h2V14.79h-2zM57.34 18.64a1 1 0 00-2 0h2zm-2 5.17a1 1 0 002 0h-2zm0-5.17v5.17h2v-5.17h-2z"
          fill="#304FFE"
          fillRule="nonzero"
        />
        <path
          d="M32.07 34.8a5.19 5.19 0 11-10.37 0 5.19 5.19 0 0110.37 0z"
          fill="#FF1744"
          fillRule="nonzero"
          opacity=".49"
        />
        <path
          d="M70.52 2.02h5.5a2 2 0 011.99 2L77.99 52a2 2 0 01-2 2l-5.5-.01.03-51.96z"
          fill="#F8F9FF"
          fillRule="nonzero"
        />
        <path
          d="M46.18 35.3c0-1.1.9-2 2-2h16.44a2 2 0 012 2v11.06a2 2 0 01-2 2H48.18a2 2 0 01-2-2V35.31z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <g transform="translate(46.67 33.28)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <g mask="url(#b)">
            <path
              fill="#304FFE"
              fillRule="nonzero"
              d="M9.22 3.61a4 4 0 110 8.01 4 4 0 010-8z"
            />
            <path
              fill="#304FFE"
              fillRule="nonzero"
              d="M5.8 3.7a4.32 4.32 0 110 8.65 4.32 4.32 0 010-8.65z"
            />
            <path
              fill="#304FFE"
              fillRule="nonzero"
              d="M5.31 3.04c.69 0 3.06-.12 3.06 2.1 0 1.2-1.52 2.28-3.05 2.28s-2.79-.97-2.79-2.17c0-1.37 1.25-2.21 2.78-2.21zM8.93 10.98c.6-.12 1.15.1 1.2.49.07.38-.38.78-1 .9-.6.1-1.15-.12-1.2-.5-.06-.38.39-.78 1-.9z"
            />
            <path
              fill="#304FFE"
              fillRule="nonzero"
              d="M8.93 4.37c.6.11 1.15-.1 1.2-.49.07-.38-.38-.78-1-.9-.6-.1-1.15.11-1.2.5-.07.38.38.78 1 .89zM12.12 9.98V5.23h8.26v4.75z"
            />
            <path
              fill="#304FFE"
              fillRule="nonzero"
              d="M17.96 16.59c.75.5 1.93.56 2.42.53v-8.8h-6.7l-.7 1.4c.3.02 1.03.15 1.61.57.73.54.98 2.24 1.42 3.6a4.6 4.6 0 001.95 2.7zM17.96-1.41c.75-.5 1.93-.57 2.42-.54v8.81h-6.7l-.7-1.41c.3-.01 1.03-.14 1.61-.57.72-.53.98-2.23 1.42-3.59a4.6 4.6 0 011.95-2.7z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgPassportMain;
