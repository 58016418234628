import { useContext } from 'react';
import MapStateContext from '@/context/MapStateContext';

const useMapState = () => {
  const ctx = useContext(MapStateContext);

  return {
    state: ctx!.state,
    dispatch: ctx!.dispatch,
  };
};

export { useMapState };
