import {
  AccountPage,
  AccountSettingsPage,
  AccountStatusPage,
  BillPage,
  PromoPage,
  StatPage,
  FavoritesPage,
  FAQPage,
  HomePage,
  PromotionPage,
  SharedPromotionPage,
  SignUpPage,
  SignInPage,
  MapPage,
  SearchPage,
  DocumentsPage,
  RequisitesPage,
  LegalStatusPage,
  WithdrawPage,
  EmailConfirmationPage,
} from '@/pages';
import CompanyPage from 'src/pages/CompanyPage/CompanyPage';
import LandingBusinessPage from 'src/pages/LandingBusinessPage/LandingBusinessPage';
import LandingAmbassadorPage from 'src/pages/LandingAmbassadorPage/LandingAmbassadorPage';
import PrivacyPolicyPage from 'src/pages/PrivacyPolicyPage/PrivacyPolicyPage';
import UserAgreementPage from 'src/pages/UserAgreementPage/UserAgreementPage';
import ProfileHelperPage from 'src/pages/ProfileHelperPage/ProfileHelperPage';
import OptionPage from 'src/pages/OptionPage/OptionPage';

export const routes = [
  { path: '/', name: 'common.promotions', component: HomePage },
  { path: '/promotions/:id', name: '', component: PromotionPage },
  { path: '/account', name: 'common.account', component: AccountPage },
  { path: '/documents', name: 'common.documents', component: DocumentsPage },
  { path: '/profileHelper', name: '', component: ProfileHelperPage },
  { path: '/option', name: '', component: OptionPage },
  {
    path: '/documents/requisites',
    name: 'common.requisites',
    component: RequisitesPage,
  },
  {
    path: '/documents/legal-status',
    name: 'common.legalStatus',
    component: LegalStatusPage,
  },
  {
    path: '/email-confirmation/:code',
    name: 'common.emailConfirmation',
    component: EmailConfirmationPage,
  },
  {
    path: '/account/settings',
    name: 'common.settings',
    component: AccountSettingsPage,
  },
  { path: '/company/:id', name: '', component: CompanyPage },


  {
    path: '/account/status',
    name: 'common.status',
    component: AccountStatusPage,
  },
  { path: '/account/bill', name: '', component: BillPage },
  { path: '/account/promo', name: '', component: PromoPage },
  { path: '/account/statistics', name: '', component: StatPage },
  { path: '/account/favorites', name: '', component: FavoritesPage },
  { path: '/account/faq', name: '', component: FAQPage },
  { path: '/account/withdraw', name: '', component: WithdrawPage },
  { path: '/code/token/:token', name: '', component: SharedPromotionPage },
  { path: '/map', name: '', component: MapPage },
  { path: '/search', name: '', component: SearchPage },
  { path: '/signup', name: '', component: SignUpPage },
  { path: '/signin', name: '', component: SignInPage },
  { path: '/business', name: '', component: LandingBusinessPage },
  { path: '/ambassador', name: '', component: LandingAmbassadorPage },
  { path: '/user-agreement', name: '', component: UserAgreementPage },
  { path: '/privacy-policy', name: '', component: PrivacyPolicyPage },

];

type TRoutesMeta = Record<string, { path: string; name: string }>;

export const routesMeta: TRoutesMeta = routes.reduce(
  (acc, { path, name }) => ({ ...acc, [path]: { path, name } }),
  {},
);
