import { useState } from 'react';
import { MyMarker } from './YmapMarker';
import React from 'react';

export const MarkerList = (props: any) => {
	const [MarkerPopupId, setMarkerPopupId] = useState(null);


	return (
		<>
			{props.renderedPlacemarks.map((item: any) => (
				item.ambassadorGeos.map((value: any) => (
					<MyMarker popupId={MarkerPopupId} renderedPlacemarks={props.renderedPlacemarks} setPopupId={setMarkerPopupId} item={item} coordinates={[value.longitude, value.latitude]} />
				))))}
		</>
	);

};