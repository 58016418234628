import React, { FC } from 'react';
import styled from 'styled-components';
import { down, up } from 'styled-breakpoints';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import hexToRgba from 'hex-to-rgba';
import { Bonuses } from '@/components/Bonuses';
import { SvgCalendar, SvgLogoWithoutText } from '@/components/Svg';
import { useDateRange } from '@/hooks/useDateRange';
import { colors } from '@/constants';
import { processRewards } from '@/helpers/processRewards';
import { IPromoterReward } from '@/types';

interface IProps {
  promotionId: string | number;
  title: string;
  startDateTime?: string;
  endDateTime?: string;
  startPromoCodeActivationDateTime?: string;
  endPromoCodeActivationDateTime?: string;
  imageSrc?: string;
  rewards: IPromoterReward[];
}

interface IDataRowProps {
  last?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IBonusProps {
  bgColor?: string;
  last?: boolean;
}

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 160px;
  border-radius: 8px;
  box-shadow: 0px 8px 28px ${hexToRgba(colors.BLACK, 0.28)};
  background-color: ${colors.WHITE};
  overflow: hidden;
`;

const CardDate = styled.div`
  position: relative;
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 800;
  color: ${colors.BLUE};
`;

const ColumnImage = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 0%;
  width: 100%;
  max-width: 210px;
  background-color: ${colors.LILAC_WHITE};
  overflow: hidden;

  ${up('lg')} {
    max-width: 400px;
  }

  ${down('xs')} {
    max-width: 96px;
  }
`;

const ColumnText = styled.div`
  padding: 8px 12px;
  position: relative;
  flex: 1 1 0%;
`;

const CoverImage = styled.img`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  object-fit: cover;
  vertical-align: middle;
`;

const Title = styled.h5`
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 800;
  color: ${colors.BLACK};
  max-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const DataRow = styled.div<IDataRowProps>`
  margin-bottom: ${({ last }: IDataRowProps) => (last ? 0 : 10)}px;
`;

const PromoCodeRangeTitle = styled.div`
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 600;
  color: ${colors.BLACK};
`;

const DataItem = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: ${colors.BLACK};
`;

const DataItemIconWrapper = styled.div`
  margin-right: 8px;
`;

const WrapperBonuses = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 8px;
  right: 8px;
`;

const PromotionLink = styled(Link)`
  display: block;
  width: 100%;
  text-decoration: none;
`;

const PromotionCard: FC<IProps> = ({
  promotionId,
  title,
  imageSrc = '',
  startDateTime,
  endDateTime,
  startPromoCodeActivationDateTime,
  endPromoCodeActivationDateTime,
  rewards,
}: IProps) => {
  const startDate = startDateTime
    ? moment(startDateTime).format('DD MMM')
    : null;
  const endDate = endDateTime ? moment(endDateTime).format('DD MMM') : null;

  const startPromoCodeDate = startPromoCodeActivationDateTime
    ? moment(startPromoCodeActivationDateTime).format('DD.MM.YYYY')
    : null;
  const endPromoCodeDate = endPromoCodeActivationDateTime
    ? moment(endPromoCodeActivationDateTime).format('DD.MM.YYYY')
    : null;

  const cardDate = useDateRange(startDate, endDate);
  const promoCodeDate = useDateRange(startPromoCodeDate, endPromoCodeDate);

  const processedRewards = processRewards(rewards, 'rouble');

  const distributionReward = processedRewards.find(
    (i) => i.type === 'PromocodeDistribution',
  );

  const activationReward = processedRewards.find(
    (i) => i.type === 'PromocodeActivation',
  );

  const purchaseReward = processedRewards.find(
    (i) => i.type === 'PromocodePurchase',
  );

  return (
    <PromotionLink to={`/promotions/${promotionId}`} target="_blank">
      <Card>
        <ColumnImage>
          {imageSrc ? (
            <CoverImage src={imageSrc} alt={title} />
          ) : (
            <SvgLogoWithoutText
              width={40}
              fill={hexToRgba(colors.MISCHKA, 0.2)}
            />
          )}
        </ColumnImage>
        <ColumnText>
          <CardDate>{cardDate}</CardDate>
          <Title>{title}</Title>
          <DataRow last>
            <PromoCodeRangeTitle>
              <FormattedMessage id="promoCodes.durationRange" />
            </PromoCodeRangeTitle>
            <DataItem>
              <DataItemIconWrapper>
                <SvgCalendar width={13} height={12} fill={colors.BLUE} />
              </DataItemIconWrapper>
              {promoCodeDate}
            </DataItem>
          </DataRow>
          <WrapperBonuses>
            <Bonuses
              distributionReward={distributionReward?.value}
              activationReward={activationReward?.value}
              purchaseReward={purchaseReward?.value}
            />
          </WrapperBonuses>
        </ColumnText>
      </Card>
    </PromotionLink>
  );
};

export default PromotionCard;
