import React, { useCallback, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useAuth } from '@/data/useAuth';
import { cn } from '@bem-react/classname';
import { FilterButtons } from '@/components/FilterButtons';

import { filters } from './config';
import './StatPage.less';

import { Layout } from '@/components/Layout';
import { Container } from 'styled-bootstrap-grid';
import { TMyStatFilter } from './types';
import StatCard from '@/components/StatCard/StatCard';

import { useMyStatPromotions } from '@/data/useMyStatPromotions';
import { Loader } from '@/components/Loader';
import { processRewards } from '@/helpers/processRewards';
import { datetime } from '@/constants';
import moment from 'moment';
import { promotionsAPI } from '@/api';
import { IStatPromotion } from '@/types';

const cnStatPage = cn('StatPage');

const StatPage = () => {
  const { isAuth } = useAuth();
  const { formatMessage } = useIntl();
  const [pageNumber, setPageNumber] = useState(1);


  const [activeFilter, setMyPromoFilter] = useState<TMyStatFilter>(filters[0]);



  const tNoPromoTitle = formatMessage({ id: 'myPromo.empty.title' });
  const tNoPromoText = formatMessage({ id: 'myPromo.empty.text' });

  const { isLoading } = useMyStatPromotions(activeFilter.filterBy);
  const [showLoader, setShowLoader] = useState(isLoading);
  const [fetching, setFetching] = useState(false);
  const [promoStatActions, setPromoStatActions] = useState<IStatPromotion[]>([]);
  const [reachingEnd, setReachingEnd] = useState(false);



  useEffect(() => {


    if (fetching) {

      promotionsAPI.getMyStatPromotions('me/promocodes/statistic', pageNumber, activeFilter.filterBy.formed, activeFilter.filterBy.expired, activeFilter.filterBy.extinguished, activeFilter.filterBy.all).then(response => {


        setPromoStatActions([...promoStatActions!, ...response]);

        setPageNumber(pageNumber + 1);

        if (response.length < 9) {
          setReachingEnd(true);
        }

      }
      ).finally(() => {
        setFetching(false);
      }
      );



    }

  }, [fetching]);

  useEffect(() => {
    promotionsAPI.getMyStatPromotions('me/promocodes/statistic', pageNumber, activeFilter.filterBy.formed, activeFilter.filterBy.expired, activeFilter.filterBy.extinguished, activeFilter.filterBy.all).then(response => {


      setPromoStatActions(response);

      if (response.length < 9) {
        setReachingEnd(true);
      }



    });
    setPageNumber(2);


  }, [activeFilter.filterBy]);

  const scrollHandler = (e: any) => {
    if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 150 && !reachingEnd) {
      setFetching(true);
    }
  };


  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);

    return function () {
      document.removeEventListener('scroll', scrollHandler);
    };

  }, [reachingEnd]);


  useEffect(() => {
    if (showLoader) {
      setTimeout(() => setShowLoader(false), 500);
    }
  }, [showLoader]);

  const handleFilter = useCallback((filter: TMyStatFilter) => {
    setPageNumber(1);
    setPromoStatActions([]);
    setReachingEnd(false);
    const { id, filterBy } = filter;
    setMyPromoFilter((currentFilter) => ({
      id,
      filterBy: {
        ...currentFilter.filterBy,
        ...filterBy,
      },
    }));
  }, []);


  const cardis = promoStatActions!.map(({ promoactionId, shortDescription, picture, promoCodeValue, rewards, operationTime, operationType }, index) => {
    const processedRewards = processRewards(rewards, 'rouble');


    const distributionReward = processedRewards.find(
      (i) => i.type === 'PromocodeDistribution',
    )?.value;

    const activationReward = processedRewards.find(
      (i) => i.type === 'PromocodeActivation',
    )?.value;

    const purchaseReward = processedRewards.find(
      (i) => i.type === 'PromocodePurchase',
    )?.value;



    const data = {
      id: promoactionId,
      status: operationType,

      img: picture?.media?.path,
      promoCodeValue,
      distributionReward,
      activationReward,
      purchaseReward,
      shortDescription,
      operationTime: moment(operationTime).format(
        datetime.DATE_TIME_FORMAT,
      ),
    };

    return (
      <StatCard
        key={index}
        data={data}
        className={cnStatPage('Card')} />
    );
  });


  return (
    <Layout
      isAuth={isAuth}
      i18nSeoTitle="seo.title.statistics"
      i18nTitle="common.statistics"
    >
      <Container>
        <FilterButtons
          className={cnStatPage('Filters')}
          defaultId={activeFilter.id}
          filters={filters}
          handleFilter={handleFilter}
        />
        <div className={cnStatPage('Cards')}>
          {showLoader && (
            <div className={cnStatPage('Loader-Wrapper')}>
              <Loader size={52} />
            </div>
          )}

          {!showLoader && cardis}


          {!cardis.length && (
            <>
              <p className={cnStatPage('NoPromo-Title')}>{tNoPromoTitle}</p>

              <p className={cnStatPage('NoPromo-Text')}>{tNoPromoText}</p>
            </>
          )}
        </div>

      </Container>







    </Layout >
  );
};

export default StatPage;
