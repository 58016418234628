





import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgLittleBlueTriangle: FC<ISvgProps> = (props: ISvgProps) => {
	return (
		<svg viewBox="0 0 106 90" {...props}>
			<g filter="url(#filter0_d_4388_15427)">
				<path fillRule="evenodd" clipRule="evenodd" d="M36.815 12.7165C31.437 11.1301 26.556 16.3643 28.5137 21.6186L46.4795 69.8373C48.2456 74.5772 54.3824 75.8015 57.8321 72.1021L89.2207 38.442C92.6705 34.7426 91.021 28.7061 86.1695 27.275L36.815 12.7165Z" fill="url(#paint0_linear_4388_15427)" />
			</g>
			<path fillRule="evenodd" clipRule="evenodd" d="M38.6706 10.7258C33.2925 9.13937 28.4115 14.3736 30.3692 19.6279L48.3351 67.8465C50.1012 72.5865 56.238 73.8108 59.6877 70.1114L91.0763 36.4513C94.526 32.7519 92.8766 26.7154 88.025 25.2843L38.6706 10.7258Z" fill="url(#paint1_linear_4388_15427)" />
			<defs>
				<filter id="filter0_d_4388_15427" x="19.5316" y="5.02571" width="85.7561" height="84.6163" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
					<feOffset dx="2.84703" dy="3.98584" />
					<feGaussianBlur stdDeviation="5.69405" />
					<feColorMatrix type="matrix" values="0 0 0 0 0.239216 0 0 0 0 0 0 0 0 0 0.870588 0 0 0 0.303294 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4388_15427" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4388_15427" result="shape" />
				</filter>
				<linearGradient id="paint0_linear_4388_15427" x1="46.9652" y1="-16.0159" x2="96.7294" y2="30.39" gradientUnits="userSpaceOnUse">
					<stop stopColor="#4600FF" />
					<stop offset="1" stopColor="#AF00FF" />
				</linearGradient>
				<linearGradient id="paint1_linear_4388_15427" x1="48.8207" y1="-18.0066" x2="98.5849" y2="28.3993" gradientUnits="userSpaceOnUse">
					<stop stopColor="#3D00DE" />
					<stop offset="1" stopColor="#304FFE" />
				</linearGradient>
			</defs>
		</svg>





	);
};

export default SvgLittleBlueTriangle;


