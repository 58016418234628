import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { cn } from '@bem-react/classname';

import { Bonuses } from '../Bonuses';
import { Button } from '../Buttons';

import { TPromotionCardPersonal } from './types';
import './PromotionCardPersonal.less';

const cnPromotionCard = cn('PromotionCardPersonal');

interface IProps {
  data: TPromotionCardPersonal;
  className?: string;
}

const PromotionCard: FC<IProps> = (props) => {
  const { className, data } = props;

  const intl = useIntl();

  const {
    id,
    status,
    participatingSince,
    img,
    title,
    distributionReward,
    activationReward,
    purchaseReward,
  } = data;

  const tStatus = intl.formatMessage({
    id: `myPromo.status.${status}`,
    defaultMessage: ' ',
  });

  const archived = status === 'archived';

  return (
    <article className={cnPromotionCard({ archived }, [className])}>
      <div className={cnPromotionCard('Row', { flow: 'space' })}>
        {tStatus.trim() && (
          <span
            className={cnPromotionCard('Status', {
              [status]: true,
            })}
          >
            {tStatus}
          </span>
        )}

        <span className={cnPromotionCard('Participating', { archived })}>
          Участвуете с {participatingSince}
        </span>
      </div>

      <div className={cnPromotionCard('Row', { flow: 'start' })}>
        {img && (
          <img
            className={cnPromotionCard('Image', { archived })}
            alt=""
            src={img}
          />
        )}

        <h2 className={cnPromotionCard('Title', { archived })}>{title}</h2>
      </div>

      <div className={cnPromotionCard('Row', { flow: 'space' })}>
        <Bonuses
          className={cnPromotionCard('Bonuses', { archived })}
          distributionReward={distributionReward}
          activationReward={activationReward}
          purchaseReward={purchaseReward}
        />

        <div>
          <Button
            to="/account/statistics"
            className={cnPromotionCard('Button')}
            displayType="secondary"
          >
            Моя статистика
          </Button>

          <Button
            to={`/promotions/${id}`}
            className={cnPromotionCard('Button')}
            displayType="primary"
          >
            Открыть
          </Button>
        </div>
      </div>
    </article>
  );
};

export default PromotionCard;
