import React, { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { cn } from '@bem-react/classname';

import { removeNullKeys } from '@/helpers/removeNullKeys';

import { TFilter } from './types';
import './FilterButtons.less';

interface IProps<F extends object = {}> {
  filters: TFilter<F>[];
  handleFilter: (filter: TFilter) => void;
  defaultId?: string;
  className?: string;
  activeByFilter?: boolean;
  separateAfter?: number[];
}

const cnFilterButtons = cn('FilterButtons');

const FilterButtons: FC<IProps> = (props) => {
  const { defaultId = '', filters, activeByFilter, handleFilter, className, separateAfter } = props;

  const intl = useIntl();

  const [activeFilterId, setActiveFilterId] = useState(defaultId);
  const [activeFilters, setActiveFilters] = useState<Record<string, unknown> | undefined>(filters.find(({ id }) => id === defaultId)?.filterBy);

  const handleClick = (filter: TFilter) => () => {
    if (activeByFilter) {
      setActiveFilters(activeFilters
        ? { ...removeNullKeys(activeFilters), ...filter.filterBy }
        : filter.filterBy
      );
    } else {
      setActiveFilterId(filter.id);
    }
    
    handleFilter(filter);
  };

  const hasActiveKeys = useCallback((filterBy: Record<string, unknown>) => {
    if (activeFilters) {
      const filtersKeys = Object.keys(activeFilters);
      const commonKeys: string[] = [];
  
      Object.keys(filterBy).forEach((f) => {
        if (filtersKeys.includes(f)) {
          commonKeys.push(f);
        }
      });

      return commonKeys.some((key) => activeFilters[key] === filterBy[key]);
    }
  }, [activeFilters]);

  const filtersList = filters.map((filter, idx) => {
    const { id, title, filterBy } = filter;

    return (
      <button
        key={id}
        type="button"
        className={cnFilterButtons('Filter', {
          active: activeByFilter ? hasActiveKeys(filterBy) : activeFilterId === id,
          'separate-after': separateAfter?.includes(idx)
        })}
        onClick={handleClick(filter)}
      >
        {intl.formatMessage({ id: title })}
      </button>
    );
  });

  return <div className={cnFilterButtons({}, [className])}>{filtersList}</div>;
};

export default FilterButtons;
