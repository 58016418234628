import React, { FC, ReactElement, ReactNode } from 'react';
import { Col } from 'styled-bootstrap-grid';

interface IProps {
  children: ReactElement[] | ReactElement | ReactNode;
}

const Main: FC<IProps> = ({ children }: IProps) => (
  <Col md={12} lg>
    {children}
  </Col>
);

export default Main;
