import { produce } from 'immer';
import { authActions } from '@/store/actions';
import { reduxActions, common } from '@/constants';
import {
  IReduxAction,
  IError,
  TAuthState,
  TAuthStage,
  TAuthMode,
} from '@/types';

interface IAction {
  type: string;
  payload?: IActionPayload;
}

interface IActionPayload {
  stage: TAuthStage;
  mode: TAuthMode;
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  failedAction: IReduxAction;
  error: IError;
}

const {
  AUTH_SET_ACTIVE_STAGE,
  AUTH_SET_ACTIVE_MODE,
  AUTH_SET_DATA,

  AUTH_SIGN_OUT,
} = reduxActions;

const { signInWithPhoneToken } = authActions;

const { DEFAULT_ERROR } = common;

const INITIAL_STATE: TAuthState = {
  accessToken: '',
  refreshToken: '',
  expiresIn: 0,
  loading: false,
  error: DEFAULT_ERROR,
  activeMode: 'signUp',
  activeSignUpStage: 'dataInput',
  activeSignInStage: 'dataInput',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = INITIAL_STATE, action: IAction) =>
  produce(state, (draft) => {
    const { type, payload } = action;

    switch (type) {
      case AUTH_SET_ACTIVE_MODE: {
        const draftState = draft;
        draftState.activeMode = payload!.mode;
        return draftState;
      }

      case AUTH_SET_ACTIVE_STAGE: {
        const draftState = draft;
        if (payload?.mode === 'signUp') {
          draftState.activeSignUpStage = payload!.stage;
        } else if (payload?.mode === 'signIn') {
          draftState.activeSignInStage = payload!.stage;
        }
        return draftState;
      }

      case AUTH_SIGN_OUT: {
        return INITIAL_STATE;
      }

      case signInWithPhoneToken.TRIGGER: {
        const draftState = draft;
        draftState.error = DEFAULT_ERROR;
        draftState.loading = true;
        return draftState;
      }



      case AUTH_SET_DATA:
      case signInWithPhoneToken.SUCCESS: {
        const draftState = draft;
        const { accessToken, expiresIn, refreshToken } = payload!;
        draftState.accessToken = accessToken;
        draftState.refreshToken = refreshToken;
        draftState.expiresIn = expiresIn;
        return draftState;
      }

      case signInWithPhoneToken.FAILURE: {

        const draftState = draft;
        draftState.error = { ...DEFAULT_ERROR, ...payload?.error! };
        return draftState;
      }

      case signInWithPhoneToken.FULFILL: {
        const draftState = draft;
        draftState.loading = false;
        return draftState;
      }

      default:
        return state;
    }
  });
