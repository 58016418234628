import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgError: FC<ISvgProps> = (props: ISvgProps) => {
  const { fill } = props;
  return (
    <svg viewBox="0 0 60 60" {...props}>
      <defs>
        <clipPath id="b">
          <use xlinkHref="#a" />
        </clipPath>
        <path d="M30 60a30 30 0 100-60 30 30 0 000 60z" id="a" />
      </defs>
      <g clipPath="url(#b)">
        <use xlinkHref="#a" fill="none" stroke={fill} strokeWidth="6" />
      </g>
      <path
        d="M30 27.16l-7.82-7.82a2 2 0 00-2.84 2.84L27.16 30l-7.82 7.82a2 2 0 002.84 2.84L30 32.84l7.82 7.82a2 2 0 002.84-2.84L32.84 30l7.82-7.82a2 2 0 00-2.84-2.84L30 27.16z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default SvgError;
