import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'styled-bootstrap-grid';
import { down } from 'styled-breakpoints';
import { colors } from '@/constants';
import SvgWhiteLogo from '../Svg/SvgWhiteLogo';
import SvgVK from '../Svg/SvgVK';
import { Link } from 'react-router-dom';
import SvgTelegram from '../Svg/SvgTelegram';

const StyledFooter = styled.footer`
  padding: 36px 0;
  background-color: ${colors.BLUE};
`;

const Copyright = styled.div`

  align-items: center;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${colors.WHITE};
  margin-top: 30px;

  & p {
    margin-bottom: 6px;
  }
  @media (max-width: 768px) {
flex-direction: column;
align-items: center;
	justify-content: center;
  }
`;

const Contact = styled.div`
  padding: 0;
  color: ${colors.WHITE};

  ${down('sm')} {
    padding-bottom: 30px;
  }
  @media (max-width: 768px) {
	margin-top: 15px;
	padding-bottom: 15px;
	  }
`;
const ContactContainer = styled.div`
display: flex;
flex-direction: columnn;
margin-left: 55px;
@media (max-width: 768px) {
	margin-left: 0px;
	  }
`;

const ContactTitle = styled.h6`
  margin-bottom: 6px;
  color: ${colors.WHITE};
`;

const EmailLink = styled.a`
  font-weight: 700;
  text-decoration: none;
  color: ${colors.WHITE};
  transition: all 0.2s;

  &:hover {
   cursor: pointer;
  }
`;
const PhoneText = styled.div`
  font-weight: 700;
  text-decoration: none;
  color: ${colors.WHITE};
`;
const FooterContainer = styled.div`
display: flex;
flex-direction: column;
@media (max-width: 768px) {
	align-items: center;
	justify-content: center;

	  }

`;
const LogoContainer = styled.div`
color: ${colors.WHITE};
width: 100%;
display: flex;
flex-direction: row;
font-size: 14px;
justify-content: space-between;
align-items: center;
@media (max-width: 768px) {
	flex-direction: column;
	align-items: center;
	justify-content: center;
		  }
	  
`;

const FooterLink = styled(Link)`
 color: white;
  text-decoration: none;
  @media (max-width: 768px) {
	padding-bottom: 5px;
		  }
`;
const ContactsContainer = styled.div`

display: flex;
flex-direction: row;
@media (max-width: 768px) {
	flex-direction: column;
	justify-content: center;
	  }
`;

const FooterLinkContainer = styled.div`
@media (max-width: 768px) {
margin-top: 15px;
white-space: pre-wrap;
display: flex;
flex-direction: column;
	  }
`;


const CopyrightContainer = styled.div`
display: flex;
@media (max-width: 768px) {

flex-direction: column;
	  }
`;



const LandingFooter = () => {
	const currentYear = new Date().getFullYear();

	return (
		<StyledFooter>
			<Container>
				<FooterContainer >
					<LogoContainer >
						<SvgWhiteLogo height={32} width={100} />
						<FooterLinkContainer >
							<FooterLink to='/user-agreement' target="_blank">Пользовательское соглашение  |</FooterLink>   <FooterLink to='privacy-policy' target="_blank">Политика конфиденциальности</FooterLink>
						</FooterLinkContainer>

					</LogoContainer>


					<Copyright>
						<CopyrightContainer style={{ display: 'flex' }}>
							<div>
								<p>{`© ${currentYear} Fartsa`}</p>
								<p>
									<FormattedMessage id="copyright.allRightsReserved" />
								</p>
							</div>
							<ContactsContainer >
								<ContactContainer >
									<Contact>
										<ContactTitle>
											<FormattedMessage id="contacts.forGeneralQuestions" />
										</ContactTitle>
										<EmailLink
											href={`mailto:${process.env.REACT_APP_GENERAL_EMAIL}`}
										>
											{process.env.REACT_APP_GENERAL_EMAIL}
										</EmailLink>
									</Contact>
								</ContactContainer>
								<ContactContainer >
									<Contact>
										<ContactTitle>
											<FormattedMessage id="contacts.cooperation" />
										</ContactTitle>
										<EmailLink
											href={`mailto:${process.env.REACT_APP_PARTNERS_EMAIL}`}
										>
											{process.env.REACT_APP_PARTNERS_EMAIL}
										</EmailLink>
									</Contact>
								</ContactContainer>
								<ContactContainer >
									<Contact>
										<ContactTitle>
											<FormattedMessage id="common.phone" />
										</ContactTitle>
										<PhoneText>+{process.env.REACT_APP_GENERAL_PHONE}</PhoneText>
									</Contact>
								</ContactContainer>
							</ContactsContainer>
						</CopyrightContainer>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<a style={{ marginRight: 7 }} href='https://vk.com/fartsa_official' target='_blank'>
								<SvgVK width={30} height={30} />
							</a>
							<a href='https://t.me/fartsa_official' target='_blank'>
								<SvgTelegram width={30} height={30} />
							</a>
						</div>
					</Copyright>

				</FooterContainer>
			</Container >
		</StyledFooter >
	);
};

export default LandingFooter;
