import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from '@/constants';

interface IProps {
  isMain?: boolean;
}

const getFontWeight = (isMain: boolean) => {
  if (isMain) {
    return css`
      font-weight: bold;
    `;
  }
  return css`
    font-weight: normal;
  `;
};

const cssLink = css`
  padding: 12px 16px;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: inherit;
  color: ${colors.BLACK};
  background-color: ${colors.WHITE};
  text-decoration: none;
  transition: all 0.2s;

  &:hover {
    background-color: ${colors.GHOST_WHITE};
  }
`;

const MenuList = styled.ul`
  margin: 8px 0;
  width: 100%;
  list-style: none;
`;

const MenuListItem = styled.li<IProps>`
  width: 100%;
  height: 42px;
  ${({ isMain = false }: IProps) => getFontWeight(isMain)};
`;

const MenuLink = styled.a`
  ${cssLink}
`;

const MenuRouterLink = styled(Link)`
  ${cssLink}
`;

const MenuButton = styled.button`
  padding: 12px 16px;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: inherit;
  text-align: left;
  color: ${colors.BLACK};
  border: none;
  background-color: ${colors.WHITE};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${colors.GHOST_WHITE};
  }
`;

const MenuDivider = styled.hr`
  width: 100%;
  height: 1px;
  border: 0;
  border-bottom: 1px solid ${colors.LILAC_WHITE};
`;

export {
  MenuList,
  MenuListItem,
  MenuLink,
  MenuRouterLink,
  MenuButton,
  MenuDivider,
};
