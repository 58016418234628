import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';
import hexToRgba from 'hex-to-rgba';
import { MainTitle } from '@/components/MainTitle';
import { colors } from '@/constants';

interface IProps {
  companyName: string;
  addressItems?: Array<string>;
  phoneItems?: Array<string>;
  emailItems?: Array<string>;
  asCard?: boolean;
}

interface IWrapperProps {
  asCard: boolean;
}

const Wrapper = styled.div<IWrapperProps>`
  width: 100%;
  background-color: ${colors.WHITE};
  ${({ asCard }: IWrapperProps) => {
    if (asCard) {
      return css`
        padding: 28px;
        border-radius: 16px;
        box-shadow: 0 6px 20px 0 ${hexToRgba(colors.BLACK, 0.08)};
      `;
    }
    return '';
  }}
`;

const TitleWrapper = styled.div`
  margin-bottom: 24px;
  text-align: center;
`;

const Section = styled.div`
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${colors.LILAC_WHITE};

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }
`;

const SectionTitle = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 26px;
  color: ${colors.MONSOON};
`;

const Value = styled.p`
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 26px;
  color: ${colors.BLACK};

  &:last-child {
    margin-bottom: 0;
  }
`;

const AboutCompany: FC<IProps> = ({
  companyName,
  addressItems = [],
  phoneItems = [],
  emailItems = [],
  asCard = false,
}: IProps) => {
  const intl = useIntl();

  const tMainTitle = intl.formatMessage({ id: 'aboutCompany.title' });

  const isEmpty = !addressItems.length && !phoneItems.length;

  const renderSection = (titleId: string, items: Array<string>) =>
    items.length ? (
      <Section>
        <SectionTitle>
          <FormattedMessage id={titleId} />
        </SectionTitle>
        {items.map((i) => (
          <Value key={i}>{i}</Value>
        ))}
      </Section>
    ) : null;

  return (
    <Wrapper asCard={asCard}>
      <TitleWrapper>
        <MainTitle
          text={tMainTitle}
          fontSize={22}
          fontWeight={900}
          hLevel={5}
        />
      </TitleWrapper>

      <Section>
        <Value>{companyName}</Value>
      </Section>

      {!isEmpty ? (
        <>
          {renderSection('common.address', addressItems)}
          {renderSection('common.email', emailItems)}
          {renderSection('common.phone', phoneItems)}
        </>
      ) : (
        <Value>
          <FormattedMessage id="aboutCompany.empty" />
        </Value>
      )}
    </Wrapper>
  );
};

export default AboutCompany;
