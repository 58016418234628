import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container } from 'styled-bootstrap-grid';
import moment from 'moment';
import { cn } from '@bem-react/classname';
import { yupResolver } from '@hookform/resolvers';

import { useAuth } from '@/data/useAuth';

import { colors, datetime } from '@/constants';

import { Button } from '@/components/Buttons';
import { BaseInput, DateInput } from '@/components/FormControls';
import { Layout } from '@/components/Layout';
import { Status } from '@/components/Status';
import {
  SvgComputerScreen,
  SvgMan,
  SvgPassportPhoto,
  SvgPassportMain,
  SvgPassportAddress,
} from '@/components/Svg';
import FormFieldError from '@/components/FormControls/FormFieldError';
import { Checkbox } from '@/components/Checkbox';
import Textarea from '@/components/FormControls/Textarea';

import './LegalStatusPage.less';
import { UploadPassport } from '@/components/UploadPassport';
import Autocomplete from '@/components/FormControls/Autocomplete';
import { dadataAPI } from '@/api';
import { LegalEntityType, TOrganizationDadataSuggest } from '@/types';
import { useLegal, updateLegalEntity, uploadPassport } from '@/data/useLegal';
import { Loader } from '@/components/Loader';
import { Modal } from '@/components/Modal';
import { SuccessChanges } from '@/components/SuccessChanges';

import { useConfig } from './hooks';

const b = cn('LegalStatusPage');

type TRegisterDadata = {
  registerDate?: number;
  registerCertificate: string;
};

const createNumsSymbNumsFormatter = (
  separator: string,
  separatorPosition: number,
  maxLength: number,
) => (value?: string) => {
  if (!value) {
    return '';
  }

  if (value.length >= separatorPosition + 1) {
    const valAsArr = value.replace(/\D/g, '').split('');
    valAsArr.splice(separatorPosition, 0, separator);
    return valAsArr.join('').substr(0, maxLength);
  }

  return value.replace(/\D/g, '');
};

const formatDocumentNumberValue = createNumsSymbNumsFormatter(' ', 4, 11);
const formatDocumentIssuedByCodeValue = createNumsSymbNumsFormatter('-', 3, 7);

const LegalStatusPage = () => {
  const { isAuth } = useAuth();
  const { legalEntity, statusType, isLoading } = useLegal();
  const { translations: trln, validationSchema } = useConfig();
  const [isEntrepreneur, setEntrepreneur] = useState(
    legalEntity?.type === LegalEntityType.INDIVIDUAL_ENTERPRENEUR,

  );
  const [enterpreneurInn, setEnterpreneurInn] = useState('');
  const [showAllFields, setShowAllFields] = useState(false);
  const [isSuccessModalShown, setSuccessModalShown] = useState(false);
  const [saveError, setSaveError] = useState('');
  const [isSaving, setSaving] = useState(false);
  const [registerDadata, setRegisterDadata] = useState<TRegisterDadata | null>(
    null,
  );

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    errors,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleInputChange = useCallback(
    (name: string) => (value: string) => {
      clearErrors(name);
      setValue(name, value);
    },
    [clearErrors, setValue],
  );

  const handleCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      clearErrors(e.target.name);
      setValue(e.target.name, e.target.checked);
    },
    [clearErrors, setValue],
  );

  const handleLegalStatusClick = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setEntrepreneur(e.target.value === LegalEntityType.INDIVIDUAL_ENTERPRENEUR);

      setShowAllFields(true);
    },
    [],
  );

  const handleCloseModal = useCallback(() => setSuccessModalShown(false), []);

  useEffect(() => {
    register('dateOfBirth');
    register('documentIssuedDateAt');
    register('inn');
    register('registrationDate');
  }, [register]);



  useEffect(() => {
    setShowAllFields(Boolean(legalEntity?.type));
    setEntrepreneur(legalEntity?.type === LegalEntityType.INDIVIDUAL_ENTERPRENEUR);

    setValue('inn', legalEntity?.inn || '');
    setValue(
      'dateOfBirth',
      legalEntity?.dateOfBirth
        ? moment(legalEntity?.dateOfBirth).format(datetime.BACKEND_DATE_FORMAT)
        : null,
    );
    setValue(
      'documentIssuedDateAt',
      legalEntity?.documentIssuedDateAt
        ? moment(legalEntity.documentIssuedDateAt).format(
          datetime.BACKEND_DATE_FORMAT,
        )
        : null,
    );
    setValue(
      'registrationDate',
      legalEntity?.registrationDate
        ? moment(legalEntity?.registrationDate).format(
          datetime.BACKEND_DATE_FORMAT,
        )
        : null,
    );
  }, [legalEntity, setValue]);

  useEffect(() => {
    if (registerDadata) {
      clearErrors('registrationDate');
      clearErrors('registrationDocumentNumber');

      setValue(
        'registrationDocumentNumber',
        registerDadata.registerCertificate,
      );
      setValue(
        'registrationDate',
        registerDadata.registerDate
          ? moment(registerDadata.registerDate).format(
            datetime.BACKEND_DATE_FORMAT,
          )
          : null,
      );
    }
  }, [registerDadata, clearErrors, setValue]);



  useEffect(() => {
    const fetchRegisterData = async () => {
      const result = await dadataAPI.getOrganizationDadataById(enterpreneurInn);

      const registerCertificate =
        result?.data?.documents?.fts_registration?.number || '';
      const registerDate =
        result?.data?.documents?.fts_registration?.issue_date;

      setRegisterDadata({ registerCertificate, registerDate });
    };

    if (enterpreneurInn) {
      fetchRegisterData();
    }
  }, [enterpreneurInn]);

  const handleChangeAutocompleteInn = useCallback(
    (option: any) => {
      setEnterpreneurInn(option.value);

      clearErrors('inn');
      clearErrors('ogrn');
      clearErrors('address');
      clearErrors('firstName');
      clearErrors('lastName');
      clearErrors('middleName');

      setValue('inn', option.value);
      setValue('address', option.address);
      setValue('ogrn', option.ogrn);
      setValue('firstName', option.firstName);
      setValue('lastName', option.lastName);
      setValue('middleName', option.middleName);
    },
    [clearErrors, setValue],
  );


  const uploadFile = (file: any, requestId: number, inn: string) => {
    const passportPhotoFormData = new FormData();
    passportPhotoFormData.append('File', file);
    passportPhotoFormData.append('RequestId', requestId.toString());
    passportPhotoFormData.append('Inn', inn);
    passportPhotoFormData.append('Type', 'Passport');
    uploadPassport(passportPhotoFormData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }

    });
  };

  const handleAutocompleteInn = useCallback(
    (value: string | number) => {
      clearErrors('inn');
      return dadataAPI.getOrganizationDadata(value);
    },
    [clearErrors],
  );


  const onSubmit = useCallback(async (data: any) => {
    setSaving(true);
    setSaveError('');

    updateLegalEntity(data)
      .then((x) => {
        console.log(getValues('passportPhoto')[0]);
        uploadFile(getValues('passportPhoto')[0], x.requestId, getValues('inn'));
        uploadFile(getValues('passportMain')[0], x.requestId, getValues('inn'));
        uploadFile(getValues('passportAddress')[0], x.requestId, getValues('inn'));
        setSuccessModalShown(true);
      }).catch((e: Error) =>
        setSaveError(`Не удалось сохранить данные: ${e.message}`),
      )
      .finally(() => setSaving(false));
  }, []);

  return (
    <Layout
      withBreadcrumbs
      isAuth={isAuth}
      i18nSeoTitle="seo.title.legalStatus"
      i18nTitle="common.legalStatus"
    >
      <Container className={b()}>
        {isLoading && (
          <div className={b('Loader-Wrapper')}>
            <Loader size={52} />
          </div>
        )}

        {!isLoading && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <h2 className={b('Legal-Title')}>{trln.tTitleLegal}</h2>

            <ul className={b('Radio-List')}>


              <li className={b('Radio-Wrapper')}>
                <input
                  className="visually-hidden"
                  ref={register}
                  id="SelfEmployed"
                  type="radio"
                  name="legalEntityType"
                  defaultChecked={
                    legalEntity?.type === LegalEntityType.SELF_EMPLOYED
                  }
                  value={LegalEntityType.SELF_EMPLOYED}
                  onChange={handleLegalStatusClick}
                />

                <label htmlFor="SelfEmployed" className={b('Radio')}>
                  <SvgComputerScreen height={32} />

                  <span className={b('Radio-Text')}>
                    {trln.tOptionSelfWorking}
                  </span>
                </label>
              </li>

              <li className={b('Radio-Wrapper')}>
                <input
                  className="visually-hidden"
                  ref={register}
                  id="IndividualEnterpreneur"
                  type="radio"
                  name="legalEntityType"
                  defaultChecked={
                    legalEntity?.type === LegalEntityType.INDIVIDUAL_ENTERPRENEUR
                  }
                  value={LegalEntityType.INDIVIDUAL_ENTERPRENEUR}
                  onChange={handleLegalStatusClick}
                />

                <label htmlFor="IndividualEnterpreneur" className={b('Radio')}>
                  <SvgMan height={32} />

                  <span className={b('Radio-Text')}>
                    {trln.tOptionEnterpreneur}
                  </span>
                </label>
              </li>

              <li className={b('Radio-Wrapper')}>
                <input
                  className="visually-hidden"
                  ref={register}
                  id="Individual"
                  type="radio"
                  name="legalEntityType"
                  defaultChecked={
                    legalEntity?.type === LegalEntityType.INDIVIDUAL
                  }
                  value={LegalEntityType.INDIVIDUAL}
                  onChange={handleLegalStatusClick}

                />


                <label htmlFor="Individual" className={b('Radio')}>
                  <SvgMan height={32} />

                  <span className={b('Radio-Text')}>
                    {trln.tOptionIndividual}

                  </span>
                  <br />
                </label>

              </li>

              <li className={b('Radio-Wrapper', { error: true })}>
                {errors.legalEntityType && (
                  <FormFieldError
                    withIcon
                    message={errors.legalEntityType?.message}
                  />
                )}
              </li>
            </ul>

            {showAllFields && (
              <>
                <div className={b('Block-Wrapper')}>
                  <div className={b('Block')}>
                    <label className={b('Label')} htmlFor="inn">
                      {trln.tLabelInn}
                    </label>

                    {isEntrepreneur ? (
                      <Autocomplete
                        className={b('Field')}
                        type="number"
                        id="inn"
                        name="inn"
                        controlledValue={getValues('inn') || legalEntity?.inn}
                        onChange={handleChangeAutocompleteInn}
                        fetchOptions={handleAutocompleteInn}
                        getOptionsScheme={({
                          data,
                          value,
                        }: TOrganizationDadataSuggest) => ({
                          label: [
                            data.inn,
                            value,
                            data.address.value,
                            moment(data.state.registration_date).format(
                              datetime.DATE_FORMAT,
                            ),
                          ].join(', '),
                          value: data.inn,
                          ogrn: data.ogrn,
                          address: data.address.unrestricted_value,
                          firstName: data.fio.name,
                          lastName: data.fio.surname,
                          middleName: data.fio.patronymic,
                        })}
                        placeholder={trln.tPlaceholderInn}
                        isError={errors.inn}
                        errorMessage={errors.inn?.message}
                      />
                    ) : (
                      <BaseInput
                        type="number"
                        className={b('Field')}
                        id="inn"
                        name="inn"
                        defaultValue={legalEntity?.inn}
                        value={getValues('inn')}
                        onInputChange={handleInputChange('inn')}
                        placeholder={trln.tPlaceholderInn}
                        isError={errors.inn}
                        errorMessage={errors.inn?.message}
                      />
                    )}
                  </div>

                  <Status className={b('Status')} status={statusType} />
                </div>
              </>
            )}

            {showAllFields && isEntrepreneur && (
              <>
                <h2 className={b('Title')}>{trln.tTitleEnterpreneur}</h2>

                <div className={b('Container')}>
                  <div className={b('Container-Item')}>
                    <div className={b('Block')}>
                      <label className={b('Label')} htmlFor="ogrn">
                        {trln.tLabelOgrnip}
                      </label>

                      <BaseInput
                        ref={register}
                        type="number"
                        className={b('Field')}
                        id="ogrn"
                        name="ogrn"
                        value={getValues('ogrn')}
                        defaultValue={legalEntity?.ogrn}
                        onInputChange={handleInputChange('ogrn')}
                        placeholder={trln.tPlaceholderOgrnip}
                        isError={errors.ogrn}
                        errorMessage={errors.ogrn?.message}
                      />
                    </div>

                    <div className={b('Block')}>
                      <label className={b('Label')} htmlFor="address">
                        {trln.tLabelLegalAddress}
                      </label>

                      <BaseInput
                        ref={register}
                        className={b('Field')}
                        id="address"
                        name="address"
                        value={getValues('address')}
                        defaultValue={legalEntity?.address}
                        onInputChange={handleInputChange('address')}
                        placeholder={trln.tPlaceholderLegalAddress}
                        isError={errors.address}
                        errorMessage={errors.address?.message}
                      />
                    </div>
                  </div>

                  <div className={b('Container-Item')}>
                    <div className={b('Block')}>
                      <label
                        className={b('Label')}
                        htmlFor="registrationDocumentNumber"
                      >
                        {trln.tLabelRegisterCertificate}
                      </label>

                      <BaseInput
                        ref={register}
                        className={b('Field')}
                        id="registrationDocumentNumber"
                        name="registrationDocumentNumber"
                        value={getValues('registrationDocumentNumber')}
                        defaultValue={legalEntity?.registrationDocumentNumber}
                        onInputChange={handleInputChange(
                          'registrationDocumentNumber',
                        )}
                        placeholder={trln.tPlaceholderRegisterCertificate}
                        isError={errors.registrationDocumentNumber}
                        errorMessage={
                          errors.registrationDocumentNumber?.message
                        }
                      />
                    </div>

                    <div className={b('Block')}>
                      <label className={b('Label')} htmlFor="registrationDate">
                        {trln.tLabelRegisterDate}
                      </label>

                      <DateInput
                        className={b('Field')}
                        id="registrationDate"
                        name="registrationDate"
                        value={getValues('registrationDate')}
                        defaultValue={legalEntity?.registrationDate}
                        onInputChange={handleInputChange('registrationDate')}
                        placeholder={trln.tPlaceholderRegisterDate}
                        isError={errors.registrationDate}
                        errorMessage={errors.registrationDate?.message}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            {showAllFields && (
              <>
                <h2 className={b('Title')}>{trln.tTitleData}</h2>

                <div className={b('Container')}>
                  <div className={b('Container-Item')}>
                    <div className={b('Block')}>
                      <label className={b('Label')} htmlFor="lastName">
                        {trln.tLabelSurname}
                      </label>

                      <BaseInput
                        ref={register}
                        className={b('Field')}
                        id="lastName"
                        name="lastName"
                        value={getValues('lastName')}
                        defaultValue={legalEntity?.lastName}
                        onInputChange={handleInputChange('lastName')}
                        placeholder={trln.tPlaceholderSurname}
                        isError={errors.lastName}
                        errorMessage={errors.lastName?.message}
                      />
                    </div>

                    <div className={b('Block')}>
                      <label className={b('Label')} htmlFor="firstName">
                        {trln.tLabelName}
                      </label>

                      <BaseInput
                        ref={register}
                        className={b('Field')}
                        id="firstName"
                        name="firstName"
                        value={getValues('firstName')}
                        defaultValue={legalEntity?.firstName}
                        onInputChange={handleInputChange('firstName')}
                        placeholder={trln.tPlaceholderName}
                        isError={errors.firstName}
                        errorMessage={errors.firstName?.message}
                      />
                    </div>

                    <div className={b('Block-Wrapper')}>
                      <div className={b('Block')}>
                        <label className={b('Label')} htmlFor="middleName">
                          {trln.tLabelMiddleName}
                        </label>

                        <BaseInput
                          ref={register}
                          className={b('Field')}
                          id="middleName"
                          name="middleName"
                          value={getValues('middleName')}
                          defaultValue={legalEntity?.middleName}
                          onInputChange={handleInputChange('middleName')}
                          mark={trln.tMarkMiddleName}
                          placeholder={trln.tPlaceholderMiddleName}
                          isError={errors.middleName}
                          errorMessage={errors.middleName?.message}
                        />
                      </div>

                      <Checkbox
                        ref={register}
                        className={b('Checkbox')}
                        name="hasMiddleName"
                        label={trln.tLabelHasMiddleName}
                        checked={getValues('hasMiddleName')}
                        onChange={handleCheckboxChange}
                      />
                    </div>

                    <div className={b('Block')}>
                      <label className={b('Label')} htmlFor="dateOfBirth">
                        {trln.tLabelBirthday}
                      </label>

                      <DateInput
                        className={b('Field')}
                        id="dateOfBirth"
                        name="dateOfBirth"
                        value={getValues('dateOfBirth')}
                        defaultValue={legalEntity?.dateOfBirth}
                        onInputChange={handleInputChange('dateOfBirth')}
                        placeholder={trln.tPlaceholderBirthday}
                        isError={errors.dateOfBirth}
                        errorMessage={errors.dateOfBirth?.message}
                      />
                    </div>

                    <div className={b('Block')}>
                      <label className={b('Label')} htmlFor="placeOfBirth">
                        {trln.tLabelBirthplace}
                      </label>

                      <Textarea
                        ref={register}
                        className={b('Field')}
                        rows={2}
                        id="placeOfBirth"
                        name="placeOfBirth"
                        value={getValues('placeOfBirth')}
                        defaultValue={legalEntity?.placeOfBirth}
                        onChange={handleInputChange('placeOfBirth')}
                        placeholder={trln.tPlaceholderBirthplace}
                        isError={errors.placeOfBirth}
                        errorMessage={errors.placeOfBirth?.message}
                      />
                    </div>
                  </div>

                  <div className={b('Container-Item')}>
                    <div className={b('Block')}>
                      <label className={b('Label')} htmlFor="documentNumber">
                        {trln.tLabelPassportNumber}
                      </label>

                      <BaseInput
                        ref={register}
                        className={b('Field')}
                        id="documentNumber"
                        name="documentNumber"
                        value={getValues('documentNumber')}
                        defaultValue={legalEntity?.documentNumber}
                        onInputChange={handleInputChange('documentNumber')}
                        placeholder={trln.tPlaceholderPassportNumber}
                        isError={errors.documentNumber}
                        errorMessage={errors.documentNumber?.message}
                        formatValue={formatDocumentNumberValue}
                      />
                    </div>

                    <div className={b('Block')}>
                      <label className={b('Label')} htmlFor="documentIssuedBy">
                        {trln.tLabelByWhom}
                      </label>

                      <BaseInput
                        ref={register}
                        className={b('Field')}
                        id="documentIssuedBy"
                        name="documentIssuedBy"
                        value={getValues('documentIssuedBy')}
                        defaultValue={legalEntity?.documentIssuedBy}
                        onInputChange={handleInputChange('documentIssuedBy')}
                        mark={trln.tMarkByWhom}
                        placeholder={trln.tPlaceholderByWhom}
                        isError={errors.documentIssuedBy}
                        errorMessage={errors.documentIssuedBy?.message}
                      />
                    </div>

                    <div className={b('Block')}>
                      <label
                        className={b('Label')}
                        htmlFor="documentIssuedByCode"
                      >
                        {trln.tLabelPlaceCode}
                      </label>

                      <BaseInput
                        ref={register}
                        className={b('Field')}
                        id="documentIssuedByCode"
                        name="documentIssuedByCode"
                        value={getValues('documentIssuedByCode')}
                        defaultValue={legalEntity?.documentIssuedByCode}
                        onInputChange={handleInputChange(
                          'documentIssuedByCode',
                        )}
                        placeholder={trln.tPlaceholderPlaceCode}
                        isError={errors.documentIssuedByCode}
                        errorMessage={errors.documentIssuedByCode?.message}
                        formatValue={formatDocumentIssuedByCodeValue}
                      />
                    </div>

                    <div className={b('Block')}>
                      <label
                        className={b('Label')}
                        htmlFor="documentIssuedDateAt"
                      >
                        {trln.tLabelIssueDate}
                      </label>

                      <DateInput
                        className={b('Field')}
                        id="documentIssuedDateAt"
                        name="documentIssuedDateAt"
                        value={getValues('documentIssuedDateAt')}
                        defaultValue={legalEntity?.documentIssuedDateAt}
                        onInputChange={handleInputChange(
                          'documentIssuedDateAt',
                        )}
                        placeholder={trln.tPlaceholderIssueDate}
                        isError={errors.documentIssuedDateAt}
                        errorMessage={errors.documentIssuedDateAt?.message}
                      />
                    </div>

                    <div className={b('Block')}>
                      <label
                        className={b('Label')}
                        htmlFor="addressRegistration"
                      >
                        {trln.tLabelRegistration}
                      </label>

                      <Textarea
                        ref={register}
                        className={b('Field')}
                        rows={2}
                        id="addressRegistration"
                        name="addressRegistration"
                        value={getValues('addressRegistration')}
                        defaultValue={legalEntity?.addressRegistration}
                        onChange={handleInputChange('addressRegistration')}
                        placeholder={trln.tPlaceholderRegistration}
                        isError={errors.addressRegistration}
                        errorMessage={errors.addressRegistration?.message}
                      />
                    </div>
                  </div>
                </div>

                <h2 className={b('Title')}>{trln.tTitlePhoto}</h2>

                <div
                  className={b('Info')}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: trln.tPhotoInfo }}
                />

                <div className={b('Uploads')}>
                  <UploadPassport
                    className={b('Upload')}
                    ref={register}
                    name="passportPhoto"
                    title={trln.tLabelPassportPhoto}
                    mark={trln.tPlaceholderPassportPhoto}
                    Icon={SvgPassportPhoto}
                    isError={errors.passportPhoto}
                    errorMessage={errors.passportPhoto?.message}


                  />

                  <UploadPassport
                    className={b('Upload')}
                    ref={register}
                    name="passportMain"
                    title={trln.tLabelPassportMain}
                    mark={trln.tPlaceholderPassportMain}
                    Icon={SvgPassportMain}
                    isError={errors.passportMain}
                    errorMessage={errors.passportMain?.message}


                  />

                  <UploadPassport
                    className={b('Upload')}
                    ref={register}
                    name="passportAddress"
                    title={trln.tLabelPassportAddress}
                    mark={trln.tPlaceholderPassportAddress}
                    Icon={SvgPassportAddress}
                    isError={errors.passportAddress}
                    errorMessage={errors.passportAddress?.message}


                  />
                </div>

                <div className={b('Button-Wrapper')}>
                  <Button
                    displayType="primary"
                    type="submit"
                    className={b('Button')}
                    onClick={handleSubmit(onSubmit)}
                    disabled={statusType === 'InReview'}
                  >
                    {isSaving ? (
                      <Loader size={22} thickness={3} color={colors.WHITE} />
                    ) : (
                      trln.tSave
                    )}
                  </Button>

                  {saveError && <FormFieldError message={saveError} />}
                </div>
              </>
            )}
          </form>
        )}

        <Modal visible={isSuccessModalShown} onCancel={handleCloseModal}>
          <SuccessChanges onClose={handleCloseModal} />
        </Modal>
      </Container>
    </Layout >
  );
};

export default LegalStatusPage;
