import React, { FC, useState, useEffect, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { HeaderMenu } from '@/components/HeaderMenu';
import { SvgArrowDown } from '@/components/Svg';
import { colors } from '@/constants';

interface IProps {
  children: ReactElement[] | ReactElement;
  title: string;
  selection: string;
  disabled?: boolean;
  onClose?: () => void;
}

interface IButtonProps {
  disabled: boolean;
}

const Title = styled.span`
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: ${colors.BLACK};
`;

const Selection = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 16px 0 0 0;
  overflow: hidden;
  max-width: 100%;
  font-size: 16px;
  line-height: 1.5;
  font-weight: normal;
  text-align: left;
  color: ${colors.MISCHKA};
`;

const Button = styled.button<IButtonProps>`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid ${colors.LILAC_WHITE};

  ${({ disabled }: IButtonProps) => {
    if (disabled) {
      return css`
        & ${Title} {
          opacity: 0.4;
        }
        & ${Selection} {
          opacity: 0.4;
        }
      `;
    }
    return '';
  }}
`;

const SelectionsMenu = styled(motion.div)`
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: ${colors.WHITE};
  overflow: hidden;
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transform-origin: 50% 50%;
  transition: all 0.2s;
  transform: rotate(90deg);
`;

const selectionMenuVariants = {
  showSelectionMenu: {
    x: 0,
    transition: {
      duration: 0.2,
    },
  },
  hiddenSelectionMenu: {
    x: '100%',
    transition: {
      duration: 0.2,
    },
  },
};

const FilterSelector: FC<IProps> = ({
  children,
  title,
  selection = '',
  disabled = false,
  onClose = () => {},
}: IProps) => {
  const [active, setActive] = useState(false);
  const intl = useIntl();
  const controls = useAnimation();

  const tNothingSelected = intl.formatMessage({
    id: 'form.placeholders.nothingSelected',
  });

  useEffect(() => {
    if (active) {
      controls.start('showSelectionMenu');
    } else {
      controls.start('hiddenSelectionMenu');
    }
    // eslint-disable-next-line
  }, [active]);

  const handleButtonClick = () => {
    setActive(true);
  };

  const handleCloseButtonClick = () => {
    setActive(false);
    onClose();
  };

  const renderLeftButtonContent = () => (
    <ArrowWrapper>
      <SvgArrowDown width={16} height={16} fill={colors.BLACK} />
    </ArrowWrapper>
  );

  return (
    <>
      <Button onClick={handleButtonClick} disabled={disabled}>
        <Title>{title}</Title>
        <Selection>{selection || tNothingSelected}</Selection>
      </Button>
      <SelectionsMenu
        initial="hiddenSelectionMenu"
        variants={selectionMenuVariants}
        animate={controls}
      >
        <HeaderMenu
          title={title}
          leftButtonLabel={renderLeftButtonContent()}
          onClickLeftButton={handleCloseButtonClick}
        />
        {active ? children : null}
      </SelectionsMenu>
    </>
  );
};

export default FilterSelector;
