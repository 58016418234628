
import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgBarbell: FC<ISvgProps> = (props: ISvgProps) => {


	return (
		<svg viewBox="0 0 32 32" {...props} >
			<g id="Barbell">
				<g id="Vector">
					<path fillRule="evenodd" clipRule="evenodd" d="M6 8C6 8 6 7.17157 6.58579 6.58579C6.58579 6.58579 7.17157 6 8 6H11C11 6 11.8284 6 12.4142 6.58579C12.4142 6.58579 13 7.17157 13 8V24C13 24 13 24.8284 12.4142 25.4142C12.4142 25.4142 11.8284 26 11 26H8C8 26 7.17157 26 6.58579 25.4142C6.58579 25.4142 6 24.8284 6 24V8ZM8 8V24H11V8H8Z" fill="#1C1C1C" />
					<path fillRule="evenodd" clipRule="evenodd" d="M19 8C19 8 19 7.17157 19.5858 6.58579C19.5858 6.58579 20.1716 6 21 6H24C24 6 24.8284 6 25.4142 6.58579C25.4142 6.58579 26 7.17157 26 8V24C26 24 26 24.8284 25.4142 25.4142C25.4142 25.4142 24.8284 26 24 26H21C21 26 20.1716 26 19.5858 25.4142C19.5858 25.4142 19 24.8284 19 24V8ZM21 8V24H24V8H21Z" fill="#1C1C1C" />
					<path d="M28 21H25C24.4477 21 24 21.4477 24 22C24 22.5523 24.4477 23 25 23H28C28.8284 23 29.4142 22.4142 29.4142 22.4142C30 21.8284 30 21 30 21V11C30 10.1716 29.4142 9.58578 29.4142 9.58578C28.8284 9 28 9 28 9H25C24.4477 9 24 9.44772 24 10C24 10.5523 24.4477 11 25 11H28V21Z" fill="#1C1C1C" />
					<path d="M4 11H7C7.55228 11 8 10.5523 8 10C8 9.44772 7.55228 9 7 9H4C3.17157 9 2.58579 9.58579 2.58579 9.58579C2 10.1716 2 11 2 11V21C2 21.8284 2.58579 22.4142 2.58579 22.4142C3.17157 23 4 23 4 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H4V11Z" fill="#1C1C1C" />
					<path d="M12 17H20C20.5523 17 21 16.5523 21 16C21 15.4477 20.5523 15 20 15H12C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z" fill="#1C1C1C" />
					<path d="M28 16C28 16.5523 28.4477 17 29 17H31C31.5523 17 32 16.5523 32 16C32 15.4477 31.5523 15 31 15H29C28.4477 15 28 15.4477 28 16Z" fill="#1C1C1C" />
					<path d="M0 16C0 16.5523 0.447715 17 1 17H3C3.55228 17 4 16.5523 4 16C4 15.4477 3.55228 15 3 15H1C0.447715 15 0 15.4477 0 16Z" fill="#1C1C1C" />
				</g>
			</g>
		</svg>
	);
};

export default SvgBarbell;

