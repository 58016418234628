import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from '@/constants';

interface IProps {
  logoSrc?: string;
  text: string;
}

const Wrapper = styled.span`
  margin-right: 6px;
`;

const Inner = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 12px 3px 4px;
  font-size: 14px;
  border-radius: 12px;
  background-color: ${colors.HAWKES_BLUE};
  transition: all 0.2s;

  &:hover {
    color: ${colors.WHITE};
    background-color: ${colors.BLUE};
  }
`;

const LogoWrapper = styled.div`
  margin-right: 6px;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  overflow: hidden;
  background-color: ${colors.BLUE};
`;

const Logo = styled.img`
  display: block;
  width: 100%;
`;

const Chip: FC<IProps> = ({ logoSrc = '', text }: IProps) => {
  return (
    <Wrapper>
      <Inner>
        <LogoWrapper>{logoSrc && <Logo src={logoSrc} />}</LogoWrapper>
        {text}
      </Inner>
    </Wrapper>
  );
};

export default Chip;
