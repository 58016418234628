import React, { FC, memo, ReactNode } from 'react';
import { cn } from '@bem-react/classname';

import Button from './Button';

import './TextButton.less';

interface IProps {
  text: string;
  icon: ReactNode;
  onClick: () => void;
  fontSize?: 'small' | 'default';
  contentAlign?: 'left' | 'right' | 'center';
  to?: string;
}

const cnTextButton = cn('TextButton');

const TextButton: FC<IProps> = ({
  text,
  icon,
  contentAlign = 'left',
  fontSize = 'default',
  onClick,
  to,
}: IProps) => {
  return (
    <Button fluid type="button" displayType="link" onClick={onClick} to={to}>
      <span
        className={cnTextButton({
          align: contentAlign,
          small: fontSize === 'small',
        })}
      >
        <span className={cnTextButton('Icon')}>{icon}</span>

        {text}
      </span>
    </Button>
  );
};

export default memo(TextButton);
