
import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgVideoCamera: FC<ISvgProps> = (props: ISvgProps) => {

	return (
		<svg viewBox="0 0 32 32" {...props}>
			<g id="VideoCamera">
				<g id="Vector">
					<path fillRule="evenodd" clipRule="evenodd" d="M1.58579 7.08579C1.58579 7.08579 2.17157 6.5 3 6.5H19C19 6.5 21.0711 6.5 22.5355 7.96447C22.5355 7.96447 24 9.42894 24 11.5V23.5C24 23.5 24 24.3284 23.4142 24.9142C23.4142 24.9142 22.8284 25.5 22 25.5H6C6 25.5 3.92894 25.5 2.46447 24.0355C2.46447 24.0355 1 22.5711 1 20.5V8.5C1 8.5 1 7.67157 1.58579 7.08579ZM3 8.5V20.5C3 20.5 3 21.7426 3.87868 22.6213C3.87868 22.6213 4.75736 23.5 6 23.5H22V11.5C22 11.5 22 10.2574 21.1213 9.37868C21.1213 9.37868 20.2426 8.5 19 8.5H3Z" fill="#1C1C1C" />
					<path d="M29 20.2768L23.4961 17.1318C23.345 17.0454 23.174 17 23 17L22.9775 17.0003C22.8962 17.0021 22.8154 17.0138 22.7369 17.0352C22.481 17.105 22.2633 17.2736 22.1318 17.5039C22.0454 17.655 22 17.826 22 18C22 18.02 22.0006 18.0399 22.0018 18.0598C22.022 18.3968 22.2108 18.7008 22.5039 18.8682L29.5039 22.8682C29.9834 23.1423 30.5942 22.9757 30.8682 22.4961C30.9546 22.345 31 22.174 31 22V10C31 9.44772 30.5523 9 30 9C29.826 9 29.655 9.04542 29.5039 9.13176L22.5039 13.1318C22.1923 13.3098 22 13.6411 22 14C22 14.014 22.0003 14.028 22.0009 14.0419C22.0076 14.2016 22.0525 14.3574 22.1318 14.4961C22.2633 14.7264 22.481 14.895 22.7369 14.9648C22.8226 14.9881 22.9111 15 23 15L23.0271 14.9996C23.1919 14.9952 23.353 14.95 23.4961 14.8682L29 11.7232V20.2768Z" fill="#1C1C1C" />
				</g>
			</g>
		</svg>
	);
};

export default SvgVideoCamera;
