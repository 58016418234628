import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgMarker: FC<ISvgProps> = (props: ISvgProps) => {
  return (
    <svg viewBox="0 0 15 20" {...props}>
      <path d="M7.5 0C3.364 0 0 3.365 0 7.5c0 2.67.812 3.74 4.715 8.885.677.893 1.445 1.905 2.323 3.077a.579.579 0 00.924 0 596.95 596.95 0 012.312-3.064C14.186 11.238 15 10.165 15 7.5 15 3.364 11.636 0 7.5 0zm1.855 15.701c-.554.731-1.169 1.542-1.855 2.454-.69-.918-1.309-1.733-1.866-2.467-3.796-5.005-4.48-5.906-4.48-8.188C1.154 4 4 1.154 7.5 1.154S13.846 4 13.846 7.5c0 2.277-.686 3.182-4.491 8.201z" />
      <path d="M7.5 3.462A4.042 4.042 0 003.462 7.5 4.042 4.042 0 007.5 11.538 4.042 4.042 0 0011.538 7.5 4.042 4.042 0 007.5 3.462zm0 6.923A2.889 2.889 0 014.615 7.5 2.889 2.889 0 017.5 4.615 2.889 2.889 0 0110.385 7.5 2.889 2.889 0 017.5 10.385z" />
    </svg>
  );
};

export default SvgMarker;
