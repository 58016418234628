import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { Col } from 'styled-bootstrap-grid';

interface IProps {
  children: ReactElement[] | ReactElement;
}

const SidebarCol = styled(Col)`
  margin-left: 60px;
  min-width: 350px;

  ${down('md')} {
    margin-left: 0;
  }
`;

const Sidebar: FC<IProps> = ({ children }: IProps) => (
  <SidebarCol md={12} lg={4}>
    {children}
  </SidebarCol>
);

export default Sidebar;
