import React from 'react';
import MapStateProvider from '@/containers/MapStateProvider';
import PromotionsMap from './PromotionsMap';

const PromotionsMapWrapper = () => {
  return (
    <MapStateProvider>
      <PromotionsMap />
    </MapStateProvider>
  );
};

export default PromotionsMapWrapper;
