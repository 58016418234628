import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgMan: FC<ISvgProps> = (props: ISvgProps) => {
  return (
    <svg viewBox="0 0 27 32" {...props}>
      <path
        d="M13.54 18.8c4.58 0 8.3-3.77 8.3-8.43V6.58c0-3.63-2.9-6.58-6.48-6.58h-3.65a6.54 6.54 0 00-6.48 6.58v3.7a8.43 8.43 0 008.3 8.52zM11.7 2.07h3.65a4.49 4.49 0 014.45 4.51v.78c-2.87.56-5.88.23-8.63-1.03a1.01 1.01 0 00-1.94-.6 3.08 3.08 0 01-1.98 1.99V6.58c0-2.49 2-4.51 4.45-4.51zM7.26 9.84a5.03 5.03 0 002.92-1.7 16.2 16.2 0 009.63 1.32c0 1.19.15 3.4-1.85 5.42-3.95 4-10.7 1.12-10.7-4.6v-.44zm16.96 12.48c-5.3-2.4-5-2.3-5.3-2.3-11.69 0-10.97-.04-11.25.09l-4.89 2.21A4.78 4.78 0 000 26.68v4.29C0 31.54.46 32 1.02 32s1.01-.46 1.01-1.03v-4.3c0-1.06.62-2.03 1.58-2.46l4.05-1.84 4.96 8.58a1 1 0 001.76 0l4.96-8.58 4.05 1.84a2.7 2.7 0 011.58 2.47v4.29c0 .57.45 1.03 1.01 1.03S27 31.54 27 30.97v-4.3c0-1.87-1.1-3.58-2.78-4.35zm-8.15 1.63l-.52-1.87h1.6l-1.08 1.87zm-6.22-1.87h1.6l-.52 1.87-1.08-1.87zm3.65 6.3l-1.14-1.96 1.14-4.12 1.14 4.11-1.14 1.97zm-2.34-15.32a1 1 0 011.42-.2c.55.41 1.3.4 1.83 0a1 1 0 011.43.2c.33.45.24 1.1-.2 1.44a3.52 3.52 0 01-4.28 0 1.04 1.04 0 01-.2-1.44z"
        fill="#304FFE"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default SvgMan;
