import useSWR from 'swr';
import axios from 'axios';
import { commonAPI } from '@/api';
import { createErrorObject } from '@/helpers/createErrorObject';
import { IPromotionFaqItem } from '@/types';

const usePromotionFaq = (id: number) => {
  const {
    data,
    mutate,
    error,
  }: { data?: Array<IPromotionFaqItem>; mutate: any; error?: any } = useSWR(
    `catalog/promoactions/${id}/faq`,
    commonAPI.fetch,
    {
      revalidateOnFocus: false,
    },
  );

  const isLoading = !data && (!error || axios.isCancel(error));
  const isError = !!error;

  return {
    mutate,
    isLoading,
    isError,
    faqItems: data,
    error: error ? createErrorObject(error) : null,
  };
};

export { usePromotionFaq };
