export const tinkoffButton = ({ size, text }) => {

	const authParams = {
		redirectUri: process.env.REACT_APP_TINKOFF_REDIRECT_URI,
		scopeParameters: '',

		responseType: 'code',
		clientId: process.env.REACT_APP_TINKOFF_CLIENT_ID,
		state: process.env.REACT_APP_TINKOFF_STATE
	};

	const uiParams = {
		container: '#container-for-tid-button',
		size: size,
		color: 'primary',
		text: text,
		target: 'self'
	};

	const tidSdk = new TidSDK(authParams);

	tidSdk.addButton(uiParams);
};