import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import SignUpContainer from '@/containers/SignUpContainer';
import { Header } from '@/components/Header';
import { Footer } from '@/components/Footer';
import { Page, PageContent } from '@/components/shared';
import { useAuth } from '@/data/useAuth';
import { useUser } from '@/data/useUser';


const SignUpPage = () => {
  const intl = useIntl();
  const [redirectState, setRedirectState] = useState('');
  const { isAuth } = useAuth();
  const { user } = useUser();

  const tSeoTitle = intl.formatMessage({
    id: 'seo.title.signUp',
  });

  useEffect(() => {
    if (isAuth && user) {
      setRedirectState('/?profileHelper=true');
    }
  }, [isAuth, user, setRedirectState]);

  const handleSuccessAuth = () => {
    // setRedirectState('/');

  };

  return redirectState ? (
    <Redirect to={redirectState} />
  ) : (
    <Page>
      <Helmet>
        <title>{tSeoTitle}</title>
      </Helmet>
      <Header />
      <PageContent>
        <SignUpContainer onSuccessAuth={handleSuccessAuth} />
      </PageContent>
      <Footer />
    </Page>
  );
};

export default SignUpPage;
