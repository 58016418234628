import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgSuccess: FC<ISvgProps> = (props: ISvgProps) => {
  const { fill } = props;
  return (
    <svg viewBox="0 0 80 80" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={40} cy={40} r={38} stroke={fill} strokeWidth={4} />
        <path
          fill={fill}
          fillRule="nonzero"
          d="M36.046 52c-.638 0-1.276-.319-1.755-.797L23.84 40.045c-.878-.956-.878-2.47.16-3.347.956-.877 2.472-.877 3.35.159l8.696 9.245 16.675-17.374c.877-.957 2.393-.957 3.35-.08.958.877.958 2.39.08 3.347l-18.35 19.288a2.476 2.476 0 01-1.755.717z"
        />
      </g>
    </svg>
  );
};

export default SvgSuccess;
