import { useState, useEffect } from 'react';
import { debounce } from 'throttle-debounce';
import { ISelectOption } from '@/types';

const getItems = (
  items: Array<ISelectOption>,
  value: string | Array<string>,
) => {
  if (value && Array.isArray(value)) {
    const activeItems = items.filter((i) => value.indexOf(i.value) !== -1);
    const inactiveItems = items.filter((i) => value.indexOf(i.value) === -1);

    return activeItems.concat(inactiveItems);
  }

  const activeItems = items.filter((i) => value === i.value);
  const inactiveItems = items.filter((i) => value !== i.value);

  return activeItems.concat(inactiveItems);
};

const useFilteredSelectorOptions = (
  items: Array<ISelectOption>,
  currentValue?: string | Array<string>,
) => {
  const [options, setOptions] = useState(items);

  const handleSearchChange = (value: string) => {
    const result = items.filter(
      (i) =>
        i.label
          .toString()
          .toLocaleLowerCase()
          .indexOf(value.toLocaleLowerCase()) !== -1,
    );

    if (currentValue) {
      const orderedItems = getItems(result, currentValue);
      setOptions(orderedItems);
    } else {
      setOptions(result);
    }
  };

  const onSearchChange = debounce(300, (value: string) =>
    handleSearchChange(value),
  );

  useEffect(() => {
    if (currentValue) {
      const orderedItems = getItems(items, currentValue);
      setOptions(orderedItems);
    }
    // eslint-disable-next-line
  }, [currentValue]);

  useEffect(() => {
    return () => {
      if (onSearchChange) {
        onSearchChange.cancel();
      }
    };
  }, [onSearchChange]);

  return { options, onSearchChange };
};

export { useFilteredSelectorOptions };
