import { commonAPI } from '@/api';
import { AxiosRequestConfig } from 'axios';

const uploadMedia = async (data: any) => {
  const response = await commonAPI.fetch('/media/upload', data, {
    requestConfig: {
      method: 'post',
    } as AxiosRequestConfig,
  });

  return response;
};

export { uploadMedia };
