import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs, { ParsedQuery } from 'query-string';
import isEqual from 'react-fast-compare';
import { useCategories } from '@/data/useCategories';

import {
  ISearchFiltersControlValues,
  IPromotionsFilterParams,
  IUrlParamWithValue,
} from '@/types';

type TSetFiltersInUrlOptions = {
  pathname?: string;
  isResetPage?: boolean;
};

interface IGetFiltersFromUrlResult {
  urlParams: IPromotionsFilterParams;
  parsedUrlParams: ISearchFiltersControlValues;
}

const useSearchFilters = () => {
  const [isInit, setInit] = useState<boolean>(false);
  const [currentBrandIds, setCurrentBrandIds] = useState<string[]>([]);
  const [currentCategoryId, setCurrentCategoryId] = useState<string>('');
  const [currentSubcategoryIds, setCurrentSubcategoryIds] = useState<string[]>(
    [],
  );

  const location = useLocation();
  const history = useHistory();

  const { categoriesFlat } = useCategories();

  const getFiltersFromUrl = () => {
    const {
      query,
    }: {
      query: ParsedQuery<any>;
    } = qs.parseUrl(location.search);

    const result: IGetFiltersFromUrlResult = {
      urlParams: {
        categoryIds: '',
        brandIds: '',
      },
      parsedUrlParams: {
        categories: '',
        subcategories: [],
        brands: [],
      },
    };

    if (!categoriesFlat.length) {
      return result;
    }

    if (query.categoryIds) {
      const queryCategoryIds = query.categoryIds;
      const firstValue = Array.isArray(queryCategoryIds)
        ? queryCategoryIds[0]
        : queryCategoryIds;

      const categoryItem = categoriesFlat.find(
        (i) => i.id.toString() === firstValue,
      );

      const subcategory = categoryItem!.parentCategory;

      if (!subcategory) {
        result.parsedUrlParams.categories = queryCategoryIds;
        result.parsedUrlParams.subcategories = [];
        result.urlParams.categoryIds = queryCategoryIds;
      } else {
        result.parsedUrlParams.categories = subcategory.id.toString();
        result.parsedUrlParams.subcategories = queryCategoryIds;
        result.urlParams.categoryIds = queryCategoryIds;
      }
    }

    if (query.brandIds) {
      result.parsedUrlParams.brands = query.brandIds;
      result.urlParams.brandIds = query.brandIds;
    }

    return result;
  };

  const setFiltersInUrl = (
    params: IUrlParamWithValue[],
    options: TSetFiltersInUrlOptions = {},
  ) => {
    const {
      query,
    }: {
      query: ParsedQuery<any>;
    } = qs.parseUrl(location.search);

    const paramsObj: { [key: string]: string | string[] | undefined } = {};

    params.forEach((i) => {
      paramsObj[i.param] = i.value || undefined;
    });

    const newQuery = {
      ...query,
      page: options.isResetPage ? undefined : query.page,
      ...paramsObj,
    };

    const searchString = qs.stringify(newQuery);

    const newLocation = options.pathname
      ? {
        pathname: options.pathname,
        search: searchString,
      }
      : {
        search: searchString,
      };

    history.push(newLocation);
  };

  const checkIsFilterActive = () => {
    const {
      query,
    }: {
      query: ParsedQuery<any>;
    } = qs.parseUrl(location.search);

    if (query.categoryIds && query.categoryIds.length) {
      return true;
    }
    if (query.brandIds && query.brandIds.length) {
      return true;
    }
    return false;
  };

  // eslint-disable-next-line
  useEffect(() => {
    if (isInit) {
      const { parsedUrlParams } = getFiltersFromUrl();
      const isEqualSubcategories = isEqual(
        parsedUrlParams.subcategories,
        currentSubcategoryIds,
      );
      const isEqualBrands = isEqual(parsedUrlParams.brands, currentBrandIds);

      if (parsedUrlParams.categories !== currentCategoryId) {
        setCurrentCategoryId(parsedUrlParams.categories);
      }

      if (!isEqualSubcategories) {
        setCurrentSubcategoryIds(parsedUrlParams.subcategories);
      }

      if (!isEqualBrands) {
        setCurrentBrandIds(parsedUrlParams.brands);
      }
    }
  });

  useEffect(() => {
    if (categoriesFlat && categoriesFlat.length) {
      const { parsedUrlParams } = getFiltersFromUrl();
      setCurrentBrandIds(parsedUrlParams.brands);
      setCurrentCategoryId(parsedUrlParams.categories);
      setCurrentSubcategoryIds(parsedUrlParams.subcategories);
      setInit(true);
    }
  }, [categoriesFlat]); // eslint-disable-line

  return {
    isFiltersInit: isInit,

    currentBrandIds,
    currentCategoryId,
    currentSubcategoryIds,

    setCurrentBrandIds,
    setCurrentCategoryId,
    setCurrentSubcategoryIds,

    getFiltersFromUrl,
    setFiltersInUrl,
    checkIsFilterActive,
  };
};

export { useSearchFilters };
