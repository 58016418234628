import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { cn } from '@bem-react/classname';
import { accountAPI } from '@/api';

import { Button } from '@/components/Buttons';

import './DeleteAccount.less';
import { BaseInput } from '../FormControls';
import { useUser } from '@/data/useUser';

const cnDeleteAccount = cn('DeleteAccount');

interface IProps {
  onCancel: () => void;
}

const DeleteAccount: FC<IProps> = ({ onCancel }) => {

  const { user } = useUser();
  const intl = useIntl();
  const [email, setEmail] = useState('');

  const tTitle = intl.formatMessage({
    id: 'account.cancel.modalTitle',
  });

  const tText1 = intl.formatMessage({
    id: 'account.cancel.modalText1',
  });

  const tText2 = intl.formatMessage({
    id: 'account.cancel.modalText2',
  });

  const tYes = intl.formatMessage({
    id: 'account.cancel.modalYes',
  });

  const tNo = intl.formatMessage({
    id: 'account.cancel.modalNo',
  });

  return (
    <div className={cnDeleteAccount()}>
      <h2 className={cnDeleteAccount('Title')}>{tTitle}</h2>

      <p className={cnDeleteAccount('Text')}>{tText1}</p>

      <p className={cnDeleteAccount('Text')}>{tText2}</p>
      {user?.profile.email ? <BaseInput
        //@ts-ignore
        placeholder='Введите свой e-mail' value={email} onInputChange={(value) => setEmail(value)} /> : null
      }

      <div style={{ marginTop: 20 }}>
        <Button className={cnDeleteAccount('Button')} displayType="secondary" onClick={() => { accountAPI.deleteAccount(); }} disabled={user?.profile.email ? (user?.profile.email != email) : false}>
          {tYes}
        </Button>

        <Button
          className={cnDeleteAccount('Button')}
          displayType="primary"
          onClick={onCancel}
        >
          {tNo}
        </Button>
      </div>
    </div>
  );
};

export default DeleteAccount;
