import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';
import hexToRgba from 'hex-to-rgba';
import { MainTitle } from '@/components/MainTitle';
import { Button } from '@/components/Buttons';
import { Loader } from '@/components/Loader';
import { promoCodesAPI } from '@/api';
import { colors } from '@/constants';
import { ISharedTokenWithPromoCode } from '@/types';

interface IProps {
  shareToken: string;
  asCard?: boolean;
  onPromoCodeBuildSuccess: (result: TBuildSuccessResult) => void;
}

interface TBuildSuccessResult {
  promoCode?: string;
  promotionUrl: string;
  shareToken: string;
}

interface IWrapperProps {
  asCard: boolean;
}

const Wrapper = styled.div<IWrapperProps>`
  width: 100%;
  background-color: ${colors.WHITE};
  ${({ asCard }: IWrapperProps) => {
    if (asCard) {
      return css`
        padding: 28px;
        border-radius: 16px;
        box-shadow: 0 6px 20px 0 ${hexToRgba(colors.BLACK, 0.08)};
      `;
    }
    return '';
  }}
`;

const TitleWrapper = styled.div`
  margin-bottom: 24px;
  text-align: center;
`;

const Message = styled.div`
  margin: 0 0 24px 0;
  font-size: 14px;
  text-align: center;
`;

const PromoCodeGenerator: FC<IProps> = ({
  shareToken,
  asCard = false,
  onPromoCodeBuildSuccess,
}: IProps) => {
  const [isLoading, setLoading] = useState(false);

  const intl = useIntl();

  const tMainTitle = intl.formatMessage({ id: 'promoCodes.yourPromoCode' });

  const buildPromoCode = async () => {
    setLoading(true);
    try {
      const res: {
        data: ISharedTokenWithPromoCode;
      } = await promoCodesAPI.buildPromoCode(shareToken);
      const promoCode = res.data.promoCode?.value;
      const promotionUrl = res.data.token.url;
      const newShareToken = res.data.token.token;

      setLoading(false);

      onPromoCodeBuildSuccess({
        promoCode,
        promotionUrl,
        shareToken: newShareToken,
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleBuildPromoCodeClick = () => {
    buildPromoCode();
  };

  return (
    <Wrapper asCard={asCard}>
      <TitleWrapper>
        <MainTitle
          text={tMainTitle}
          fontSize={22}
          fontWeight={900}
          hLevel={5}
        />
      </TitleWrapper>
      <Message>
        <FormattedMessage id="promotions.promoCodeGenerator.message" />
      </Message>
      <Button
        type="button"
        displayType="primary"
        fluid
        onClick={handleBuildPromoCodeClick}
      >
        {isLoading ? (
          <Loader size={22} thickness={3} color={colors.WHITE} />
        ) : (
          <FormattedMessage id="promoCodes.generate" />
        )}
      </Button>
    </Wrapper>
  );
};

export default PromoCodeGenerator;
