import React, { FC } from 'react';

import { cn } from '@bem-react/classname';
import { Button } from '../Buttons';

import { TStatCard } from './types';
import './StatCard.less';

import { Bonuses } from '../Bonuses';

const cnStatCard = cn('StatCard');

interface IProps {
	data: TStatCard;
	className?: string;
}






const StatCard: FC<IProps> = (props) => {
	const { className, data } = props;

	const {
		id,
		status,

		img,
		promoCodeValue,
		distributionReward,
		activationReward,
		purchaseReward,
		shortDescription,
		operationTime,


	} = data;

	const formed = status === 'Reserve';
	const expired = status === 'Expire';
	const extinguished = status === 'Pay';
	const Description = shortDescription?.replace(/<[^>]*>/g, '');

	return (
		<article className={cnStatCard({}, [className])}>
			<div className={cnStatCard('Row', { flow: 'space' })}>

				{promoCodeValue.trim() && (
					<span
						className={cnStatCard('Status', {
							formed,
							expired,
							extinguished,
						})}
					>
						{promoCodeValue}
					</span>
				)}
				{(status == 'Reserve') && (
					<span className={cnStatCard('Participating')}>
						Создан  {operationTime}
					</span>
				)}
				{(status == 'Expire') && (
					<span className={cnStatCard('Participating')}>
						Истек {operationTime}
					</span>
				)}
				{(status == 'Pay') && (
					<span className={cnStatCard('Participating')}>
						Дата и время погашения {operationTime}
					</span>
				)}
			</div>

			<div className={cnStatCard('Row', { flow: 'start' })}>
				{img && (
					<img
						className={cnStatCard('Image')}
						alt=""
						src={img}
					/>
				)}

				<h2 className={cnStatCard('Title', { formed, expired, extinguished })}>{Description}</h2>
			</div>

			<div className={cnStatCard('Row', { flow: 'space' })}>
				<Bonuses
					className={cnStatCard('Bonuses')}
					distributionReward={distributionReward}
					activationReward={activationReward}
					purchaseReward={purchaseReward}
				/>

				<div>


					<Button
						to={`/promotions/${id}`}
						className={cnStatCard('Button')}
						displayType="primary"
					>
						Открыть
					</Button>
				</div>
			</div>
		</article>
	);
};

export default StatCard;
