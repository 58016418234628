import React, { FC, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { MainTitle } from '@/components/MainTitle';
import { Button } from '@/components/Buttons';
import { BaseInput, FieldLabel } from '@/components/FormControls';
import { Loader } from '@/components/Loader';
import { FormField, FormControlWrapper } from '@/components/shared';
import { colors } from '@/constants';
import { promoCodesAPI } from '@/api';
import { IPromoCode, ISharedToken } from '@/types';
import { SuccessfulAlert } from '@/components/SuccessfulAlert';
import { FailureAlert } from '@/components/FailureAlert';

interface IProps {
  promoCodeToken?: string;
  shareToken?: string;
  onSuccess?: (args?: { token: ISharedToken; promoCode?: IPromoCode }) => void;
  onCancel?: () => void;
}

type TFormData = {
  email: string;
};

const Wrapper = styled.div`
  width: 100%;
`;

const TitleWrapper = styled.div`
  margin: 0 0 30px 0;
  text-align: center;
`;

const Message = styled.p`
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${colors.BLACK};
`;

const SubmitButtonWrapper = styled.div`
  padding: 16px 0 0 0;
  width: 100%;
`;

const ButtonCancelWrapper = styled.div`
  padding: 16px 0 0 0;
`;

const PromoCodeByEmailForm: FC<IProps> = ({
  promoCodeToken,
  shareToken,
  onSuccess = () => { },
  onCancel,
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [createdPromoCode, setCreatedPromoCode] = useState<
    | {
      token: ISharedToken;
      promoCode?: IPromoCode;
    }
    | undefined
  >();

  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);

  const tEmailPlaceholder = intl.formatMessage({
    id: 'form.placeholders.email',
  });
  const tErrorEmail = intl.formatMessage({
    id: 'form.errors.email.incorrect',
  });
  const tRequiredField = intl.formatMessage({
    id: 'form.errors.email.required',
  });
  const tFailureTitle = intl.formatMessage({
    id: 'promoCodes.title.failureEmail',
  });
  const tSuccessTitle = intl.formatMessage({
    id: 'promoCodes.title.successEmail',
  });
  const tSuccessText = intl.formatMessage(
    {
      id: 'promoCodes.message.sentTo',
    },
    { source: email },
  );
  const tClose = intl.formatMessage({
    id: 'promoCodes.close',
  });

  const validationSchema = yup.object().shape({
    email: yup.string().email(tErrorEmail).required(tRequiredField),
  });

  const { register, setValue, handleSubmit, errors, clearErrors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    // иначе не работает автофокус внутри модалки
    requestAnimationFrame(() => {
      inputRef.current?.focus();
    });
  }, [promoCodeToken]);

  useEffect(() => {
    register('email');
  }, [register]);

  const onSubmit = async (data: TFormData) => {
    setLoading(true);
    try {
      if (shareToken) {
        if (!promoCodeToken) {
          const { data: buildedPromoCode } = await promoCodesAPI.buildPromoCode(
            shareToken,
          );

          await promoCodesAPI.sendPromoCodeByEmail(
            data.email,
            buildedPromoCode.token.token,
          );

          setCreatedPromoCode(buildedPromoCode);
        } else {
          await promoCodesAPI.sendPromoCodeByEmail(data.email, promoCodeToken);

          setCreatedPromoCode(undefined);
        }

        setError('');
        setEmail(data.email);
        setOpenSuccessAlert(true);
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSuccess = () => {
    setOpenSuccessAlert(false);
    onSuccess(createdPromoCode);
  };

  const handleFailure = () => {
    setOpenSuccessAlert(false);
    onCancel?.();
  };

  const handleInputChange = (v: string) => {
    setValue('email', v);
  };

  const handleInputFocus = () => {
    clearErrors('email');
  };

  if (error) {
    return (
      <FailureAlert
        title={tFailureTitle}
        message={error}
        failureButtonLabel={tClose}
        onFailure={handleFailure}
      />
    );
  }

  if (openSuccessAlert) {
    return (
      <SuccessfulAlert
        title={tSuccessTitle}
        message={tSuccessText}
        successButtonLabel={tClose}
        onSuccess={handleSuccess}
      />
    );
  }

  return (
    <>
      <Wrapper>
        <TitleWrapper>
          <MainTitle text="Email" fontWeight={900} />
        </TitleWrapper>
        <Message>
          <FormattedMessage id="promoCodes.message.sendByEmail" />
        </Message>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormField>
            <FieldLabel forAttr="email-input-send-by-email">
              <FormattedMessage id="form.labels.email" />
              <FormControlWrapper>
                <BaseInput
                  ref={inputRef}
                  id="email-input-send-by-email"
                  type="text"
                  name="email"
                  placeholder={tEmailPlaceholder}
                  onInputChange={handleInputChange}
                  onInputFocus={handleInputFocus}
                  disabled={loading}
                  isError={errors.email}
                  errorMessage={errors.email?.message}
                />
              </FormControlWrapper>
            </FieldLabel>
          </FormField>
          <SubmitButtonWrapper>
            <Button
              fluid
              type="submit"
              displayType="primary"
              disabled={loading}
            >
              {loading ? (
                <Loader size={22} thickness={3} color={colors.WHITE} />
              ) : (
                <FormattedMessage id="common.send" />
              )}
            </Button>
          </SubmitButtonWrapper>
        </form>
      </Wrapper>
      <ButtonCancelWrapper>
        <Button type="button" displayType="link" fluid onClick={onCancel}>
          <FormattedMessage id="common.cancel" />
        </Button>
      </ButtonCancelWrapper>
    </>
  );
};

export default PromoCodeByEmailForm;
