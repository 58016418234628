interface ISelectorItem {
  value: string;
  label: string;
}

const getSelectedLabels = (
  items: ISelectorItem[],
  selectedItems: string | string[],
) => {
  if (!selectedItems.length) {
    return '';
  }

  if (typeof selectedItems === 'string') {
    const selectedItemsString = selectedItems as string;

    const result = items.find((i) => i.value === selectedItemsString);

    return result?.label || '';
  }

  const activeItems = selectedItems.map((i) => {
    const item = items.find((y) => y.value === i);

    return item?.label || null;
  });

  return activeItems.length ? activeItems.join(', ').toString() : '';
};

export { getSelectedLabels };
