import React, {
  useEffect,
  forwardRef,
  SyntheticEvent,
  useCallback,
} from 'react';
import { useIntl } from 'react-intl';
import { cn } from '@bem-react/classname';

import useUpload from '@/hooks/useUpload';

import { Button } from '../Buttons';
import FormFieldError from '../FormControls/FormFieldError';
import './UploadPassport.less';

interface IProps {
  name: string;
  id?: string;
  src?: string;
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void;
  onClear?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  className?: string;
  Icon: any;
  title: string;
  mark?: string;
  isError?: boolean;
  errorMessage?: string;
}

const b = cn('UploadPassport');

const UploadPassport = forwardRef<any, IProps>((props, ref) => {
  const {
    onChange,
    onClear,
    className,
    id,
    name,
    src,
    Icon,
    title,
    mark,
    isError,
    errorMessage,
  } = props;

  const { link, inputRef, handleUpload, handleUploadClear } = useUpload(src);
  const { formatMessage } = useIntl();

  const tChange = formatMessage({ id: 'components.uploadPassport.change' });
  const tDelete = formatMessage({ id: 'components.uploadPassport.delete' });

  const hasLink = Boolean(link);

  useEffect(() => {
    if (inputRef.current && typeof ref === 'function') {
      ref(inputRef.current);
    }
  }, [ref, inputRef]);

  const handleFile = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      if (inputRef.current && e.currentTarget.files?.length) {
        inputRef.current.disabled = true;
      }

      handleUpload(e);

      if (onChange) {
        onChange(e);
      }
    },
    [handleUpload, inputRef, onChange],
  );

  const handleClear = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();

      handleUploadClear();

      if (inputRef.current) {
        inputRef.current.disabled = false;
        inputRef.current.focus();
      }

      if (onClear) {
        onClear(e);
      }
    },
    [handleUploadClear, inputRef, onClear],
  );

  const handleChangeImage = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.disabled = false;
      inputRef.current.click();
    }
  }, [inputRef]);

  return (
    <div className={b('Container', [className])}>
      <div className={b({ uploaded: hasLink })}>
        <input
          className="visually-hidden"
          ref={inputRef}
          name={name}
          id={id || name}
          onChange={handleFile}
          type="file"
          accept="image/*"
        />

        <label
          className={b('Label', { error: Boolean(isError) })}
          htmlFor={id || name}
        >
          {hasLink && (
            <Button
              displayType="link"
              className={b('Change')}
              onClick={handleChangeImage}
            >
              {tChange}
            </Button>
          )}

          {hasLink && (
            <div className={b('Preview-Wrapper')}>
              <img className={b('Preview')} src={link} alt="Скан" />

              <button
                type="button"
                className={b('Clear')}
                onClick={handleClear}
                title={tDelete}
              >
                {tDelete}
              </button>
            </div>
          )}

          {!hasLink && <Icon className={b('Icon')} />}

          <p className={b('Title')}>{title}</p>

          <p className={b('Mark')}>{mark}</p>
        </label>
      </div>

      {isError && errorMessage ? (
        <FormFieldError withIcon message={errorMessage} />
      ) : null}
    </div>
  );
});

export default UploadPassport;
