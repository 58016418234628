import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgAccountDocuments: FC<ISvgProps> = (props: ISvgProps) => {
  return (
  <svg viewBox="0 0 32 32" {...props}>
    <g fill="#304FFE">
      <path d="M31.678 13.324L28.5 10.56V3.97a.937.937 0 00-.938-.938h-3.75a.938.938 0 00-.937.938v1.703L16.615.23a.938.938 0 00-1.23 0L.322 13.324a.938.938 0 001.23 1.415l1.698-1.476v16.862h-.687a.938.938 0 000 1.875h26.874a.937.937 0 100-1.875h-.934V13.048l1.944 1.69a.935.935 0 001.323-.092.938.938 0 00-.092-1.322zM24.75 7.03V4.906h1.875v4.026l-1.908-1.659a.933.933 0 00.033-.242zM5.125 30.125V11.654l-.001-.02L16 2.18l10.625 9.236.003 18.709H5.125z"/>
      <path d="M23.688 13.5h-4.31v-1.345a1.73 1.73 0 00-1.74-1.717h-3.524a1.73 1.73 0 00-1.739 1.717V13.5h-4.31a.938.938 0 00-.937.938v3.377c0 1.003.423 1.923 1.125 2.638v6.922c0 .518.42.938.937.938h13.373c.517 0 .937-.42.937-.938v-6.922c.702-.715 1.125-1.635 1.125-2.638v-3.377a.938.938 0 00-.937-.938zm-9.438-1.187h3.253V13.5H14.25v-1.187zm-5.247 3.062H22.75v2.44c0 1.218-1.36 2.248-2.968 2.248h-1.093v-.878a.938.938 0 00-.938-.937h-3.75a.938.938 0 00-.937.937v.878H11.97c-1.61 0-2.968-1.03-2.968-2.248v-2.44zm7.81 4.748v1.875H14.94v-1.875h1.875zm4.812 6.315H10.128v-4.811c.568.2 1.19.31 1.843.31h1.093v.998c0 .518.42.938.937.938h3.75c.518 0 .938-.42.938-.938v-.997h1.093c.652 0 1.275-.111 1.843-.311v4.81z"/>
    </g>
  </svg>
  );
};

export default SvgAccountDocuments;
