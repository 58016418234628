import moment from 'moment';
import React, {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Calendar, { OnChangeDateCallback } from 'react-calendar';
import { cn } from '@bem-react/classname';

import { colors, datetime } from '@/constants';
import { useOutsideClick } from '@/hooks/useOutsideClick';

import { SvgArrowLeft, SvgArrowRight, SvgCalendar } from '../Svg';
import BaseInput, { IProps as BaseInputProps } from './BaseInput';

import './DateInput.less';

const formatValue = (value?: string) => {
  const maxLength = 10;
  if (!value) {
    return '';
  }

  if (value.length >= maxLength) {
    return value.substring(0, maxLength);
  }

  if (value.match(/\./g)?.length === 2) {
    return value;
  }

  const valAsArr = value.replace(/\D/g, '').split('');

  if (valAsArr.length > 4) {
    const [d1, d2, m1, m2, ...year] = valAsArr;
    return [d1, d2, '.', m1, m2, '.', ...year].join('');
  }

  if (value.length > 2) {
    const [d1, d2, ...month] = valAsArr;
    return [d1, d2, '.', ...month].join('');
  }

  return value.replace(/\D/g, '');
};

interface Props extends BaseInputProps {
  value?: string;
  valueFormat?: string;
}

const b = cn('DateInputField');

const DateInput = forwardRef<any, Props>(
  (
    {
      value,
      valueFormat = datetime.BACKEND_DATE_FORMAT,
      onInputChange,
      className,
      ...otherProps
    },
    ref,
  ) => {
    const dateInputRef = createRef<HTMLDivElement>();
    const [openCalendar, setOpenCalendar] = useState(false);
    const [localValue, setLocalValue] = useState(
      value ? moment(value, valueFormat).format(datetime.DATE_FORMAT) : '',
    );

    useEffect(() => {
      setLocalValue(
        value ? moment(value, valueFormat).format(datetime.DATE_FORMAT) : '',
      );
    }, [value, valueFormat]);

    const handleCalendarValueChange: OnChangeDateCallback = useCallback(
      (newValue) => {
        onInputChange?.(moment(newValue).format(valueFormat));
        setOpenCalendar(false);
      },
      [onInputChange, valueFormat],
    );

    const handleInputBlur = useCallback(() => {
      if (moment(localValue, datetime.DATE_FORMAT, true).isValid()) {
        onInputChange?.(
          moment(localValue, datetime.DATE_FORMAT).format(valueFormat),
        );
      } else {
        setLocalValue('');
      }
    }, [localValue, onInputChange, valueFormat]);

    const handleCalendarButtonClick = useCallback(
      () => setOpenCalendar((val) => !val),
      [],
    );

    const calendarButton = useMemo(
      () => (
        <button
          type="button"
          className={b('Button')}
          onClick={handleCalendarButtonClick}
        >
          <SvgCalendar fill={colors.AZUL} height={18} width={22} />
        </button>
      ),
      [handleCalendarButtonClick],
    );

    useOutsideClick(dateInputRef, () => setOpenCalendar(false));

    return (
      <div className={b('Wrapper')} ref={dateInputRef}>
        <BaseInput
          className={b({}, [className])}
          {...otherProps}
          value={localValue}
          onInputBlur={handleInputBlur}
          onInputChange={setLocalValue}
          ref={ref}
          formatValue={formatValue}
          endIcon={calendarButton}
        />

        {openCalendar && (
          <Calendar
            className={b('Calendar')}
            value={value ? new Date(value) : null}
            onChange={handleCalendarValueChange}
            showNeighboringMonth={false}
            nextLabel={<SvgArrowRight width={13} height={13} />}
            prevLabel={<SvgArrowLeft width={13} height={13} />}
            next2Label={null}
            prev2Label={null}
            minDetail="decade"
          />
        )}
      </div>
    );
  },
);

export default DateInput;
