import React, { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { cn } from '@bem-react/classname';

import './Status.less';
import { SvgBillDeclined, SvgBillProcessing, SvgBillSuccess } from '../Svg';
import { TEntityStatus } from '@/types';

interface IProps {
  status?: TEntityStatus;
  className?: string;
}

const b = cn('Status');

const Status: FC<IProps> = (props) => {
  const { status, className } = props;
  const { formatMessage } = useIntl();

  const getStatus = useCallback(() => {
    if (status === 'InReview') {
      return (
        <>
          <SvgBillProcessing height={16} />

          <p className={b('Text')}>
            {formatMessage({ id: `components.status.${status}` })}
          </p>
        </>
      );
    }
    if (status === 'Rejected') {
      return (
        <>
          <SvgBillDeclined height={16} />

          <p className={b('Text')}>
            {formatMessage({ id: `components.status.${status}` })}
          </p>
        </>
      );
    }
    if (status === 'Confirmed') {
      return (
        <>
          <SvgBillSuccess height={16} />

          <p className={b('Text')}>
            {formatMessage({ id: `components.status.${status}` })}
          </p>
        </>
      );
    }
    if (status === 'None') {
      return null;

    }

    return null;
  }, [status, formatMessage]);

  return <div className={b({}, [className])}>{getStatus()}</div>;
};

export default Status;
