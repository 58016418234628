import { Selector } from 'reselect';
import { IReduxState } from '@/types';

const activeMode: Selector<IReduxState, any> = ({ auth }) => auth.activeMode;

const activeSignInStage: Selector<IReduxState, any> = ({ auth }) =>
  auth.activeSignInStage;

const activeSignUpStage: Selector<IReduxState, any> = ({ auth }) =>
  auth.activeSignUpStage;

const accessToken: Selector<IReduxState, any> = ({ auth }) => auth.accessToken;

const refreshToken: Selector<IReduxState, any> = ({ auth }) =>
  auth.refreshToken;

const loadingAuth: Selector<IReduxState, any> = ({ auth }) => auth.loading;

const errorAuth: Selector<IReduxState, any> = ({ auth }) => auth.error;

export default {
  activeMode,
  activeSignUpStage,
  activeSignInStage,
  accessToken,
  refreshToken,
  loadingAuth,
  errorAuth,
};
