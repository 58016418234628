import { common } from '@/constants';
import {
  TBankDadata,
  TOrganizationDadata,
  TOrganizationDadataSuggest,
} from '@/types';

export default {
  async getBankDadataSuggest(query: string | number): Promise<TBankDadata> {
    const url =
      'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/bank';

    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${common.DADATA_TOKEN}`,
      },
      body: JSON.stringify({ query }),
    };

    return fetch(url, options as any)
      .then((response) => response.json())
      .then((result) => result?.suggestions)
      .catch((error) => console.log('error', error));
  },

  async getOrganizationDadata(
    query: string | number,
    searchByLegal = false,
  ): Promise<TOrganizationDadata> {
    const url =
      'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party';

    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${common.DADATA_TOKEN}`,
      },
      body: JSON.stringify({
        query,
        type: searchByLegal ? 'LEGAL' : 'INDIVIDUAL',
      }),
    };

    return fetch(url, options as any)
      .then((response) => response.json())
      .then((result) => result?.suggestions)
      .catch((error) => console.log('error', error));
  },

  async getOrganizationDadataById(
    query: string | number,
    searchByLegal = false,
  ): Promise<TOrganizationDadataSuggest> {
    const url =
      'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party';
    const token = 'fc496eb921337e2ee51ef7457bb08f28e3e93a9c';

    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        query,
        type: searchByLegal ? 'LEGAL' : 'INDIVIDUAL',
      }),
    };

    return fetch(url, options as any)
      .then((response) => response.json())
      .then((result) => result?.suggestions?.[0])
      .catch((error) => console.log('error', error));
  },
};
