import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { cn } from '@bem-react/classname';



import { useAuth } from '@/data/useAuth';

import { Layout } from '@/components/Layout';
import { Accordion, AccordionItem } from '@/components/Accordion';
import { Article } from '@/components/Article';
import { Container } from 'styled-bootstrap-grid';
import './FAQPage.less';
import { useFaqs } from '@/data/useFaqs';

export const cnFAQPage = cn('FAQPage');

const FAQPage = () => {
  const { formatMessage } = useIntl();
  const { isAuth } = useAuth();
  const { faqs } = useFaqs();

  const tMailTitle = formatMessage({ id: 'myFAQ.mailTitle' });
  const tMailDescription = formatMessage({ id: 'myFAQ.mailDescription' });

  const [activeAccordionKey, setActiveAccordionKey] = useState<
    number | string | null
  >(null);

  const handleAccordionChange = (key: number | string) => {
    if (key === activeAccordionKey) {
      setActiveAccordionKey(null);
    } else {
      setActiveAccordionKey(key);
    }
  };
  const htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;

  const renderFaq = () =>
    faqs?.length ? (
      <Accordion
        activeKey={activeAccordionKey}
        onChange={handleAccordionChange}
      >
        {faqs.map((faq) => (
          <AccordionItem
            itemKey={faq.question}
            title={faq.question.replace(htmlRegexG, '')}
            key={faq.question}

          >
            <Article
              markup={faq.answer.replace(htmlRegexG, '')}
            />
          </AccordionItem>
        ))}
      </Accordion>
    ) : null;

  return (
    <Layout
      isAuth={isAuth}
      i18nSeoTitle="seo.title.myFAQ"
      i18nTitle="common.myFAQ"
    >
      <Container>
        <div className={cnFAQPage('Common')}>
          <p className={cnFAQPage('Common-Block')}>
            {tMailTitle}
            <a
              className={cnFAQPage('Mail')}
              href={`mailto:${process.env.REACT_APP_GENERAL_EMAIL}`}
            >
              {process.env.REACT_APP_GENERAL_EMAIL}
            </a>
          </p>

          <p className={cnFAQPage('Common-Block', { description: true })}>
            {tMailDescription}
          </p>
        </div>

        {faqs ? renderFaq() : null}
      </Container>
    </Layout>
  );
};

export default FAQPage;
