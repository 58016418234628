import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from '@/constants';

interface IProps {
  distributionReward?: string;
  activationReward?: string;
  purchaseReward?: string;
  className?: string;
}

interface IBonusProps {
  bgColor?: string;
  last?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Bonus = styled.div<IBonusProps>`
  margin-right: ${({ last }: IBonusProps) => (last ? 0 : 8)}px;
  padding: 6px 8px;
  display: inline-block;
  font-size: 12px;
  color: ${colors.WHITE};
  font-weight: 800;
  border-radius: 20px;
  background-color: ${({ bgColor = colors.BLUE }: IBonusProps) => bgColor};
`;

const Bonuses: FC<IProps> = ({
  className,
  activationReward,
  distributionReward,
  purchaseReward,
}: IProps) => {
  return (
    <Wrapper className={className}>
      {distributionReward && <Bonus>{distributionReward}</Bonus>}
      {activationReward && (
        <Bonus bgColor={colors.GREEN}>{activationReward}</Bonus>
      )}
      {purchaseReward && <Bonus bgColor={colors.RED}>{purchaseReward}</Bonus>}
    </Wrapper>
  );
};

export default Bonuses;
