import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import hexToRgba from 'hex-to-rgba';
import { MainTitle } from '@/components/MainTitle';
import { Button } from '@/components/Buttons';
import { Modal } from '@/components/Modal';
import { DistributorFaq } from '@/components/DistributorFaq';
import { Loader } from '@/components/Loader';
import { SuccessfulAlert } from '@/components/SuccessfulAlert';
import { PromoCodeForDistributor } from '@/components/PromoCodeForDistributor';
import { processRewards } from '@/helpers/processRewards';
import { colors } from '@/constants';
import { usePromotion } from '@/data/usePromotion';
import { promotionsAPI, promoCodesAPI } from '@/api';
import { IPromoterReward, ISharedToken } from '@/types';
import { useStatus } from '@/data/useStatus';

interface IProps {
  rewards: IPromoterReward[];
  isActiveJoin?: boolean;
  isEnd?: boolean;
  asCard?: boolean;
}

interface IWrapperProps {
  asCard: boolean;
}

interface IRewardProps {
  isMain?: boolean;
}

const Wrapper = styled.div<IWrapperProps>`
  width: 100%;
  background-color: ${colors.WHITE};
  ${({ asCard }: IWrapperProps) => {
    if (asCard) {
      return css`
        padding: 28px;
        border-radius: 16px;
        box-shadow: 0 6px 20px 0 ${hexToRgba(colors.BLACK, 0.08)};
      `;
    }
    return '';
  }}
`;

const TitleWrapper = styled.div`
  margin-bottom: 24px;
  text-align: center;
`;

const SubtitleWrapper = styled.div`
  margin-bottom: 16px;
`;

const Subtitle = styled.h6`
  font-size: 14px;
  font-weight: 600;
  color: ${colors.BLACK};
`;

const EndLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: ${colors.RED};
`;

const JoinLabel = styled.div`
  padding: 8px 0 16px 0;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: ${colors.FRUIT_SALAD};
`;

const RewardSection = styled.div`
  margin: 0 0 16px 0;
`;

const Reward = styled.div<IRewardProps>`
  margin-bottom: 10px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: solid 1px ${colors.LILAC_WHITE};

  background-color: ${({ isMain = false }: IRewardProps) =>
    isMain ? colors.GHOST_WHITE : colors.WHITE};
`;

const RewardLabel = styled.span`
  margin-right: 10px;
  font-size: 14px;
  color: ${colors.MONSOON};
`;

const RewardValue = styled.span`
  min-width: 70px;
  font-size: 18px;
  font-weight: 800;
  text-align: right;
  color: ${colors.BLACK};
`;

const ButtonJoinWrapper = styled.div`
  padding: 12px 0;
`;

const ButtonFAQWrapper = styled.div`
  padding: 12px 0 0 0;
`;

const ButtonFAQInner = styled.span`
  font-size: 16px;
`;

const PromoCodeForDistributorWrapper = styled.div`
  width: 100%;
  max-width: 350px;
`;

const PromotionJoin: FC<IProps> = ({
  rewards,
  isActiveJoin = false,
  isEnd = false,
  asCard = false,
}: IProps) => {
  const [isOpenedModal, setOpenedModal] = useState(false);
  const [isOpenedSuccessfulModal, setOpenedSuccessfulModal] = useState(false);
  const [isOpenedQRCodeModal, setOpenedQRCodeModal] = useState(false);
  const [isLoadingJoin, setLoadingJoin] = useState(false);
  const [isLoadingShare, setLoadingShare] = useState(false);
  const [promoCodeData, setPromoCodeData] = useState<ISharedToken | null>(null);

  const intl = useIntl();
  const { id } = useParams();
  const { mutate } = usePromotion(id);
  const { status } = useStatus();
  const history = useHistory();

  const tMainTitle = intl.formatMessage({ id: 'promotions.join.title' });
  const tSuccessTitle = intl.formatMessage({
    id: 'promotions.join.thanksForJoin.title',
  });
  const tSuccessMessage = intl.formatMessage({
    id: 'promotions.join.thanksForJoin.message',
  });
  const tSuccessButtonLabel = intl.formatMessage({
    id: 'promoCodes.generate',
  });

  const processedRewards = processRewards(rewards, 'rouble');

  const distributionReward = processedRewards.find(
    (i) => i.type === 'PromocodeDistribution',
  );
  const activationReward = processedRewards.find(
    (i) => i.type === 'PromocodeActivation',
  );
  const purchaseReward = processedRewards.find(
    (i) => i.type === 'PromocodePurchase',
  );



  const buttonTextId = isActiveJoin
    ? 'promoCodes.qrCode.generate'
    : 'promotions.join.participate';

  const sharePromoCode = async () => {
    if (promoCodeData) {
      setOpenedQRCodeModal(true);
    } else {
      setLoadingShare(true);
      try {
        const { data } = await promoCodesAPI.sharePromoCode(id);
        setPromoCodeData(data);
        setOpenedQRCodeModal(true);
      } catch (error) {
        return;
      } finally {
        setLoadingShare(false);
      }
    }
  };




  const joinPromotion = async () => {
    setLoadingJoin(true);
    try {
      await promotionsAPI.joinPromotion(id);
      setOpenedSuccessfulModal(true);
      mutate();
    } catch (error) {
      return;
    } finally {
      setLoadingJoin(false);
    }
  };

  const handleOpenModal = () => {
    setOpenedModal(true);
  };

  const handleCloseModal = () => {
    setOpenedModal(false);
  };

  const handleCloseSuccessfulModal = () => {
    setOpenedSuccessfulModal(false);
  };

  const handleCloseQRCodeModal = () => {
    setOpenedQRCodeModal(false);
  };

  const handleGeneratePromoCodeClick = () => {
    setOpenedSuccessfulModal(false);
    sharePromoCode();
  };

  const handleMainButtonClick = async () => {
    if (status?.status === 'Empty') {
      history.push('/profileHelper');
      history.go(0);
      return;
    }

    if (!isActiveJoin) {
      joinPromotion();
    } else {
      sharePromoCode();
    }
  };

  const renderMainReward = () =>
    distributionReward && !isEnd ? (
      <RewardSection>
        <SubtitleWrapper>
          <Subtitle>
            <FormattedMessage id="promotions.join.mainSubtitle" />
          </Subtitle>
        </SubtitleWrapper>
        <Reward isMain>
          <RewardLabel>
            <FormattedMessage id="promotions.join.distributionReward" />
          </RewardLabel>
          <RewardValue>{distributionReward.value}</RewardValue>
        </Reward>
      </RewardSection>
    ) : null;

  const renderOtherRewards = () => {
    if ((!activationReward && !purchaseReward) || isEnd) {
      return null;
    }

    const purchaseRewardId = purchaseReward?.isAbsolute
      ? 'promotions.join.purchaseReward'
      : 'promotions.join.purchaseRewardPercent';

    return (
      <RewardSection>
        <SubtitleWrapper>
          <Subtitle>
            <FormattedMessage id="promotions.join.additionalSubtitle" />
          </Subtitle>
        </SubtitleWrapper>
        {activationReward ? (
          <Reward>
            <RewardLabel>
              <FormattedMessage id="promotions.join.activationReward" />
            </RewardLabel>
            <RewardValue>{activationReward.value}</RewardValue>
          </Reward>
        ) : null}
        {purchaseReward ? (
          <Reward>
            <RewardLabel>
              <FormattedMessage id={purchaseRewardId} />
            </RewardLabel>
            <RewardValue>{purchaseReward.value}</RewardValue>
          </Reward>
        ) : null}
      </RewardSection>
    );
  };

  return (
    <Wrapper asCard={asCard}>
      <TitleWrapper>
        <MainTitle
          text={tMainTitle}
          fontSize={22}
          fontWeight={900}
          hLevel={5}
        />
      </TitleWrapper>

      {renderMainReward()}

      {renderOtherRewards()}

      {isEnd ? (
        <EndLabel>
          <FormattedMessage id="promotions.promotionEnd" />
        </EndLabel>
      ) : (
        <>
          {isActiveJoin ? (
            <JoinLabel>
              <FormattedMessage id="promotions.join.youParticipate" />
            </JoinLabel>
          ) : null}
          <ButtonJoinWrapper>
            <Button
              type="button"
              displayType="primary"
              disabled={isEnd}
              fluid
              onClick={handleMainButtonClick}
            >
              {isLoadingJoin || isLoadingShare ? (
                <Loader size={22} thickness={3} color={colors.WHITE} />
              ) : (
                <FormattedMessage id={buttonTextId} />
              )}
            </Button>
          </ButtonJoinWrapper>
          <ButtonFAQWrapper>
            <Button
              type="button"
              displayType="link"
              fluid
              onClick={handleOpenModal}
            >
              <ButtonFAQInner>
                <FormattedMessage id="common.distributorsFAQ" />
              </ButtonFAQInner>
            </Button>
          </ButtonFAQWrapper>
        </>
      )}

      <Modal visible={isOpenedModal} onCancel={handleCloseModal}>
        <DistributorFaq />
      </Modal>

      <Modal
        visible={isOpenedSuccessfulModal}
        onCancel={handleCloseSuccessfulModal}
      >
        <SuccessfulAlert
          title={tSuccessTitle}
          message={tSuccessMessage}
          successButtonLabel={tSuccessButtonLabel}
          onSuccess={handleGeneratePromoCodeClick}
        />
      </Modal>
      <Modal visible={isOpenedQRCodeModal} onCancel={handleCloseQRCodeModal}>
        <PromoCodeForDistributorWrapper>
          {promoCodeData ? (
            <PromoCodeForDistributor
              promotionUrl={promoCodeData.url}
              shareToken={promoCodeData.token}
              onSuccessClick={handleCloseQRCodeModal}
            />
          ) : null}
        </PromoCodeForDistributorWrapper>
      </Modal>
    </Wrapper>
  );
};

export default PromotionJoin;
