import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';
import { MainTitle } from '@/components/MainTitle';
import { Button, TextButton } from '@/components/Buttons';
import { PromoCodeHolder } from '@/components/PromoCodeHolder';
import { QRCode } from '@/components/QRCode';
import { PromoCodeByEmailForm, PromoCodeBySMSForm } from '@/components/Forms';
import { SvgSMS, SvgEmail } from '@/components/Svg';
import { colors } from '@/constants';
import { IPromoCode, ISharedToken } from '@/types';
import { promoCodesAPI } from '@/api';
import SvgTelegramBlack from '../Svg/SvgTelegramBlack';

interface IProps {
  promotionUrl: string;
  promoCode?: string;
  shareToken: string;
  onSuccessClick: () => void;
}

type TActiveBlock = 'general' | 'formSendByEmail' | 'formSendBySMS' | 'formSendByTelegram';

const Wrapper = styled.div`
  width: 100%;
  background-color: ${colors.WHITE};
`;


const TitleWrapper = styled.div`
  margin-bottom: 30px;
  text-align: center;
`;

const SubtitleWrapper = styled.div`
  margin: 0 0 8px;
  padding: 8px 0 0 0;
  text-align: center;
`;

const Message = styled.p`
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${colors.BLACK};
`;

const PromoCodeWrapper = styled.div`
  margin-bottom: 16px;
`;

const QRCodeWrapper = styled.div`
  margin-bottom: 24px;
`;

const ButtonSuccessWrapper = styled.div`
  padding: 24px 0 0 0;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${colors.LILAC_WHITE};
`;

const PromoCodeForDistributor: FC<IProps> = ({
  promoCode,
  promotionUrl,
  shareToken,
  onSuccessClick,
}: IProps) => {
  const [activeBlock, setActiveBlock] = useState<TActiveBlock>('general');
  const intl = useIntl();
  const [guid, setGuid] = useState<string>('');

  const [createdPromoCode, setCreatedPromoCode] = useState<{
    token: ISharedToken;
    promoCode?: IPromoCode;
  } | null>(null);

  const tMainTitle = intl.formatMessage({
    id: `promoCodes.${createdPromoCode ? 'client' : 'forClient'}`,
  });
  const tSendBy = intl.formatMessage({ id: 'common.sendBy' });

  useEffect(() => {

    const fetchData = async () => {
      const response = await promoCodesAPI.getGuidForTelegram(shareToken);
      setGuid(response);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setCreatedPromoCode(null);
  }, [promotionUrl, promoCode]);


  const handleSendByEmailClick = () => {
    setActiveBlock('formSendByEmail');
  };

  const handleSendByTelegramClick = async () => {
    setActiveBlock('formSendByTelegram');
  };
  const handleSendBySMSClick = () => {
    setActiveBlock('formSendBySMS');
  };

  const handleGoToGeneralBlock = () => {
    setActiveBlock('general');
  };

  const handleSuccess = (val?: {
    token: ISharedToken;
    promoCode?: IPromoCode;
  }) => {
    if (val) {
      setCreatedPromoCode(val);
    }

    handleGoToGeneralBlock();
  };

  const renderSendPromoCodeButtons = () => (
    <>
      <SubtitleWrapper>
        <MainTitle text={tSendBy} fontSize={18} fontWeight={900} />
      </SubtitleWrapper>
      <TextButton
        icon={<SvgEmail width={18} fill={colors.BLACK} />}
        text="Email"
        onClick={handleSendByEmailClick}
      />
      <Divider />
      <TextButton
        icon={<SvgSMS width={18} fill={colors.BLACK} />}
        text="SMS"
        onClick={handleSendBySMSClick}
      />
      <Divider />

      <TextButton
        icon={<SvgTelegramBlack width={18} fill={colors.BLACK} />}
        text="Telegram"
        onClick={handleSendByTelegramClick}
      />
    </>
  );

  if (activeBlock === 'general') {
    return (
      <Wrapper>
        <TitleWrapper>
          <MainTitle text={tMainTitle} fontSize={30} fontWeight={900} />
        </TitleWrapper>
        {!createdPromoCode && (
          <Message>
            <FormattedMessage id="promoCodes.message.askToScan" />
          </Message>
        )}
        {createdPromoCode?.promoCode?.value ? (
          <PromoCodeWrapper>
            <PromoCodeHolder value={createdPromoCode?.promoCode?.value} />
          </PromoCodeWrapper>
        ) : null}
        <QRCodeWrapper>
          <QRCode value={createdPromoCode?.token?.url || promotionUrl} />
        </QRCodeWrapper>
        {renderSendPromoCodeButtons()}
        <ButtonSuccessWrapper>
          <Button
            type="button"
            displayType="primary"
            fluid
            onClick={onSuccessClick}
          >
            <FormattedMessage id="common.okReady" />
          </Button>
        </ButtonSuccessWrapper>
      </Wrapper>
    );
  }

  if (activeBlock === 'formSendByEmail') {
    return (
      <PromoCodeByEmailForm
        promoCodeToken={createdPromoCode?.token?.token}
        shareToken={shareToken}
        onSuccess={handleSuccess}
        onCancel={handleGoToGeneralBlock}
      />
    );
  }

  if (activeBlock === 'formSendBySMS') {
    return (
      <PromoCodeBySMSForm
        promoCodeToken={createdPromoCode?.token?.token}
        shareToken={shareToken}
        onSuccess={handleSuccess}
        onCancel={handleGoToGeneralBlock}
      />
    );
  }
  if (activeBlock === 'formSendByTelegram') {
    return (
      <>
        <TitleWrapper>
          <MainTitle text="Telegram" fontWeight={900} />
        </TitleWrapper>
        <Message>
          Отсканировав данный QR пользователь перейдет в нашего Telegram бота и получит купон.
        </Message>
        <QRCodeWrapper>
          <QRCode value={`${`tg://resolve?domain=${process.env.REACT_APP_TELEGRAM_BOT_LINK}&start=${guid}`}`} />
        </QRCodeWrapper>
        <ButtonSuccessWrapper>
          <Button
            type="button"
            displayType="primary"
            fluid
            onClick={onSuccessClick}
          >
            <FormattedMessage id="common.okReady" />
          </Button>
        </ButtonSuccessWrapper>
      </>
    );
  }

  return null;
};

export default PromoCodeForDistributor;
