import React, { FC } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Loader } from '@/components/Loader';
import { NotFoundMessage } from '@/components/NotFoundMessage';
import { colors } from '@/constants';
import { IPromotion } from '@/types';
import MapPromotionsItem from './MapPromotionsItem';

interface IProps {
  items?: Array<IPromotion>;
  loading: boolean;
}

const Items = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NotFoundWrapper = styled.div`
  padding: 0 15px;
`;

const MapPromotionsItems: FC<IProps> = ({ items = [], loading }: IProps) => {
  const intl = useIntl();

  const tNotFoundDescription = intl.formatMessage({
    id: 'promotions.search.notFound.description',
  });

  if (loading) {
    return (
      <LoaderWrapper>
        <Loader size={52} thickness={4} color={colors.BLUE} />
      </LoaderWrapper>
    );
  }

  if (!loading && !items.length) {
    return (
      <NotFoundWrapper>
        <NotFoundMessage description={tNotFoundDescription} />
      </NotFoundWrapper>
    );
  }

  return (
    <Items>
      {items.map((i) => (
        <MapPromotionsItem key={i.id} promotion={i} />
      ))}
    </Items>
  );
};

export default MapPromotionsItems;
