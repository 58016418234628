import React, { FC } from 'react';
import styled from 'styled-components';
import {
  SvgLogoWithoutText,
} from '@/components/Svg';
import { colors } from '@/constants';
import { TCategoryAlias } from '@/types';
import SvgForkKnife from '../Svg/SvgForkKnife';
import SvgBarbell from '../Svg/SvgBarbell';
import SvgVideoCamera from '../Svg/SvgVideoCamera';
import SvgBank from '../Svg/SvgBank';
import SvgCar from '../Svg/SvgCar';
import SvgCrownSimple from '../Svg/SvgCrownSimple';
import SvgFirstAid from '../Svg/SvgFirstAid';
import SvgBuildings from '../Svg/SvgBuildings';

interface IProps {
  categoryType: TCategoryAlias;
  text: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 16px;
  font-size: 14px;
  transition: all 0.2s;

  &:hover {
    background-color: ${colors.GHOST_WHITE};
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  overflow: hidden;
`;
const categoriesIcons = {
  food: {
    icon: SvgForkKnife,
    width: 16,
  },
  sport: {
    icon: SvgBarbell,
    width: 16,
  },
  media: {
    icon: SvgVideoCamera,
    width: 16,
  },
  banks: {
    icon: SvgBank,
    width: 16,
  },
  cars: {
    icon: SvgCar,
    width: 16,
  },
  beauty: {
    icon: SvgCrownSimple,
    width: 16,
  },
  health: {
    icon: SvgFirstAid,
    width: 16,
  },
  supermarket: {
    icon: SvgForkKnife,
    width: 16,
  },
  building: {
    icon: SvgBuildings,
    width: 16,
  },
};




const SearchResultCategoryItem: FC<IProps> = ({
  categoryType,
  text,
}: IProps) => {
  const target = categoriesIcons[categoryType];
  const SvgIcon = target ? target.icon : null;

  return (
    <Wrapper>
      <LogoWrapper>
        {!SvgIcon ? (
          <SvgLogoWithoutText width={16} height={16} fill={colors.WHITE} />
        ) : (
          <SvgIcon width={target.width} fill={colors.WHITE} />
        )}
      </LogoWrapper>
      {text}
    </Wrapper>
  );
};

export default SearchResultCategoryItem;
