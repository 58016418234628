import { getCurrencySymbol } from '@/helpers/getCurrencySymbol';
import { IPromoterReward, TCurrency } from '@/types';

const processRewards = (rewards: IPromoterReward[], currency: TCurrency) => {
  const result = rewards.map((i) => {
    const currencySymbol = i.isAbsolute ? getCurrencySymbol(currency) : '%';

    return {
      type: i.rewardType,
      isAbsolute: i.isAbsolute,
      value: i.value ? `${i.value}${currencySymbol}` : undefined,
    };
  });

  return result;
};

export { processRewards };
