import { Footer } from '@/components/Footer';
import { Header } from '@/components/Header';
import NewAccountHelpModal from '@/components/Modal/NewAccountHelpModal';
import { Page, PageContent } from '@/components/shared';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

export default function ProfileHelperPage() {

	const intl = useIntl();

	const tSeoTitle = intl.formatMessage({
		id: 'seo.title.profileHelper',
	});



	return (
		<Page>
			<Helmet>
				<title>{tSeoTitle}</title>
			</Helmet>
			<Header />
			<PageContent>
				<NewAccountHelpModal />
			</PageContent>
			<Footer />
		</Page>
	);
}

