import { useState, useCallback } from 'react';

interface UseDownloadProps {
  downloadHandler: () => Promise<Blob>;
  title?: string;
}

const useDownload = ({ downloadHandler, title = '' }: UseDownloadProps) => {
  const [pending, setPending] = useState(false);

  const handleDownload = useCallback(async () => {
    setPending(true);

    downloadHandler()
      .then((response) => {
        const url = URL.createObjectURL(response);
        const a = document.createElement('a');

        a.download = title;
        a.href = url;
        a.click();
      })
      .finally(() => setPending(false));
  }, [downloadHandler, title]);

  return { handleDownload, pending };
};

export default useDownload;
