import { removeNullKeys } from '@/helpers/removeNullKeys';

import basicFetcher from './utils/basicFetcher';
import apiClient from './utils/apiClient';

export default {
  async getOperations(
    url: string,
    from: any,
    to: any,
    type: any,
    status: any,
    skip: any,
    take: any,
  ) {
    const params = {
      from,
      to,
      type,
      status,
      skip,
      take,
    };

    try {
      const response = await basicFetcher(url, removeNullKeys(params));
      return response;
    } catch (error) {
      throw error;
    }
  },
  async deleteAccount() {
    const api = apiClient({});
    try {
      await api.delete(`${process.env.REACT_APP_API_URL}me/me`);
    } catch (e) {
      console.error(e);
    }
  },
  async getStatus() {
    const api = apiClient({});
    try {
      const response = await api.get(`${process.env.REACT_APP_API_URL}ambassador/status`);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  }
};
