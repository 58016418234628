import useSWR from 'swr';
import axios from 'axios';
import { accountAPI } from '@/api';
import { createErrorObject } from '@/helpers/createErrorObject';

export type TOperationType = 'Undefined' | 'Incoming' | 'Withdrawal';
export type TOperationStatus =
  | 'Undefined'
  | 'Success'
  | 'Processing'
  | 'Declined'
  | 'Pending';

interface IOperation {
  id: number;
  dateTimeUtc: string;
  description: string;
  operationAmount: number;
  type: TOperationType;
  status: TOperationStatus;
}

interface IParams {
  from?: string;
  to?: string;
  type?: TOperationType | null;
  status?: TOperationStatus | null;
  skip?: number;
  take?: number;
}

const useOperations = ({ from, to, type, status, skip, take }: IParams) => {
  const {
    data,
    mutate,
    error,
  }: { data?: { list: IOperation[] }; mutate: any; error?: any } = useSWR(
    ['account/operations', from, to, type, status, skip, take],
    accountAPI.getOperations,
  );

  const isLoading = !data && (!error || axios.isCancel(error));
  const isError = !!error;

  return {
    mutate,
    isLoading,
    isError,
    operations: data?.list || [],
    error: error ? createErrorObject(error) : null,
  };
};

export { useOperations };
