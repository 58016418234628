import React, { forwardRef, SyntheticEvent, ReactNode } from 'react';

import { cn } from '@bem-react/classname';

import './RadioButton.less';

const cnRadioButton = cn('RadioButton');

interface IProps {
  id: string;
  name: string;
  value: string;
  currentValue: string;
  onChange: (e: SyntheticEvent<HTMLInputElement>) => void;
  className?: string;
  children?: ReactNode;
}

const RadioButton = forwardRef<any, IProps>((props, ref) => {
  const {
    id,
    name,
    onChange,
    value,
    currentValue,
    children,
    className,
  } = props;

  return (
    <div className={cnRadioButton({}, [className])}>
      <input
        hidden
        ref={ref}
        id={id}
        name={name}
        checked={currentValue === value}
        onChange={onChange}
        type="radio"
        value={value}
      />

      <label htmlFor={id} className={cnRadioButton('Label')}>
        {children}
      </label>
    </div>
  );
});

export default RadioButton;
