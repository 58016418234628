import { IMapBounds, IPromotionsFilterParams } from '@/types';
import apiClient from './utils/apiClient';
import basicFetcher from './utils/basicFetcher';
import { AxiosRequestConfig } from 'axios';

interface IGetPromotionsParams {
  take?: number;
  skip?: number;
  neLat?: number;
  neLng?: number;
  swLat?: number;
  swLng?: number;
  text?: string;
  categoryIds?: string[];
  brandIds?: string[];
  actualNow?: boolean
}

export default {
  async getPromotions(
    url: string,
    take?: number,
    skip?: number,
    bounds?: IMapBounds,
    searchString?: string,
    filterParams?: IPromotionsFilterParams,
    actualNow?: boolean,
  ) {
    let params: IGetPromotionsParams = {};

    if (take !== undefined && skip !== undefined) {
      params.take = take;
      params.skip = skip;
    }

    if (bounds !== undefined) {
      params = { ...params, ...bounds };
    }


    if (searchString) {
      params = { ...params, text: searchString };
    }


    if (filterParams !== undefined) {
      Object.keys(filterParams).forEach((i: string) => {
        // @ts-ignore
        if (filterParams[i]) {
          // @ts-ignore
          params[i] = filterParams[i];
        }
      });
    }

    if (actualNow !== undefined) {
      params = { ...params, actualNow };
    }


    try {
      const response = await basicFetcher(url, params);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async getMyPromotions(url: string, actualNow?: boolean, archived?: boolean) {
    const params = { actualNow, archived };

    try {
      const response = await basicFetcher(url, params);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async joinPromotion(id: number | string) {
    const api = apiClient();

    try {
      const response = await api.post(`promoaction/participate/${id}`);

      const { data } = response;

      return {
        data,
      };
    } catch (error) {
      throw error;
    }
  },
  async getFavouritePromotions(url: string) {

    try {
      const response = await basicFetcher(url);
      return response;
    } catch (error) {
      throw error;
    }
  },


  async setFavouritePromotion(promoactionId: Number | string | undefined) {
    const api = apiClient();


    try {
      const response = await api.post('favorites/promoaction', null, {
        params: {
          promoactionId: promoactionId
        }
      } as AxiosRequestConfig);


      const data = { response };

      return {
        data,
      };
    } catch (error) {
      throw error;
    }
  },

  async deleteFavouritePromotion(promoactionId: Number | string | undefined) {
    const api = apiClient();


    try {
      const response = await api.delete('favorites/promoaction', {
        params: {
          promoactionId: promoactionId
        }
      } as AxiosRequestConfig);

      const data = { response };

      return {
        data,
      };
    } catch (error) {
      throw error;
    }
  },
  async getFavouriteIds(url: string) {

    try {
      const response = await basicFetcher(url);
      return response;
    } catch (error) {
      throw error;
    }
  },


  async getMyStatPromotions(url: string, pageNumber: number, formed?: boolean, expired?: boolean, extinguished?: boolean, all?: boolean) {

    let operationType;
    let skip = (pageNumber * 9) - 9;
    let take = 9;



    if (formed) {
      operationType = 'Reserve';
    } else if (expired) {
      operationType = 'Expire';
    } else if (extinguished) {
      operationType = 'Pay';
    } else if (all) {
      operationType = '';
    }
    const params = operationType ? { skip, take, operationType } : { skip, take };


    try {
      const response = await basicFetcher(url, params);
      return response;
    } catch (error) {
      throw error;
    }
  },



};









