import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  MenuList,
  MenuListItem,
  MenuRouterLink,
  MenuButton,
  MenuDivider,
} from '@/components/shared';
import { useAuth } from '@/data/useAuth';
import { TAuthMode } from '@/types';

interface IProps {
  isSmallScreen: boolean;
  showAuthModal: (mode: TAuthMode) => void;
}

const ProfileMenu: FC<IProps> = ({ isSmallScreen, showAuthModal }) => {
  const { isAuth, signOut } = useAuth();

  const handleSignOutClick = () => {
    signOut();
  };

  const handleSignInClick = () => {
    showAuthModal('signIn');
  };

  const handleSignUpClick = () => {
    showAuthModal('signUp');
  };

  const renderMenu = () => (
    <>
      <MenuList>
        <MenuListItem isMain>
          <MenuRouterLink to="/account/promo">
            <FormattedMessage id="header.profile.menu.promo" />
          </MenuRouterLink>
        </MenuListItem>
        <MenuListItem isMain>
          <MenuRouterLink to="/account/statistics">
            <FormattedMessage id="header.profile.menu.stat" />
          </MenuRouterLink>
        </MenuListItem>
        <MenuListItem isMain>
          <MenuRouterLink to="/account/bill">
            <FormattedMessage id="header.profile.menu.bill" />
          </MenuRouterLink>
        </MenuListItem>
        <MenuListItem isMain>
          <MenuRouterLink to="/account/favorites">
            <FormattedMessage id="header.profile.menu.favorites" />
          </MenuRouterLink>
        </MenuListItem>
        <MenuListItem isMain>
          <MenuRouterLink to="/account/faq">
            <FormattedMessage id="header.profile.menu.faq" />
          </MenuRouterLink>
        </MenuListItem>
      </MenuList>
      <MenuDivider />
      <MenuList>
        <MenuListItem>
          <MenuRouterLink to="/account">
            <FormattedMessage id="header.profile.menu.account" />
          </MenuRouterLink>
        </MenuListItem>
        <MenuListItem>
          <MenuButton type="button" onClick={handleSignOutClick}>
            <FormattedMessage id="header.profile.menu.signOut" />
          </MenuButton>
        </MenuListItem>
      </MenuList>
    </>
  );

  const renderNonAuthMenu = () =>
    isSmallScreen ? (
      <MenuList>
        <MenuListItem isMain>
          <MenuRouterLink to="/signup">
            <FormattedMessage id="auth.signUp" />
          </MenuRouterLink>
        </MenuListItem>
        <MenuListItem>
          <MenuRouterLink to="/signin">
            <FormattedMessage id="auth.signIn" />
          </MenuRouterLink>
        </MenuListItem>
      </MenuList>
    ) : (
      <MenuList>
        <MenuListItem isMain>
          <MenuButton onClick={handleSignUpClick}>
            <FormattedMessage id="auth.signUp" />
          </MenuButton>
        </MenuListItem>
        <MenuListItem>
          <MenuButton onClick={handleSignInClick}>
            <FormattedMessage id="auth.signIn" />
          </MenuButton>
        </MenuListItem>
      </MenuList>
    );

  return isAuth ? renderMenu() : renderNonAuthMenu();
};

export default ProfileMenu;
