import { call, put, take, takeLatest } from 'redux-saga/effects';
import { phoneAuthActions, authActions } from '@/store/actions';
import matchAccountApi from 'src/api/matchAccount';

import {
	IReduxAction,
	TReCaptchaVerifier,
	TConfirmationResult,
	TAuthMode,
} from '@/types';
import smsAuth from '../../api/smsAuth';



interface IActionSignInWithPhone extends IReduxAction {
	payload: {
		mode: TAuthMode;
		phoneNumber: string;
		reCaptchaVerifier: TReCaptchaVerifier;
	};
}

interface IActionVerifyCode extends IReduxAction {
	payload: {
		mode: TAuthMode;
		code: string;
		phoneNumber: TConfirmationResult;
	};
}

interface IActionTinkoffCode extends IReduxAction {
	payload: {
		mode: TAuthMode,
		code: string
	};
}
interface ICheckMatch {
	userName: string;
	phone: string
}



interface ISignInWithPhoneResult {
	confirmationResult: TConfirmationResult;
}

interface IVerifySmsCodeResult {
	idToken: string;
}

interface IVerifyTinkoffCodeResult {
	accessToken: string;
}

function* signInWithPhoneNumber(action: IActionSignInWithPhone) {
	const { phoneNumber, reCaptchaVerifier, mode } = action.payload;
	const signInWithPhoneNumberActions =
		phoneAuthActions.signInWithPhoneNumber;

	try {
		yield put(signInWithPhoneNumberActions.request());

		const result: {
			data: ISignInWithPhoneResult;
		} = yield call(smsAuth.signInWithPhoneNumber, {
			phoneNumber,
			reCaptchaVerifier,
		});

		yield put(authActions.setAuthActiveStage(mode, 'codeVerification'));
		yield put(
			signInWithPhoneNumberActions.success({
				confirmationResult: result.data.confirmationResult,
			}),
		);
	} catch (error) {
		yield put(
			signInWithPhoneNumberActions.failure({
				error: {
					statusCode: error.code,
					message: error.message,
					title: error.response?.data.error?.detail
				},
			}),
		);
	} finally {
		yield put(signInWithPhoneNumberActions.fulfill());
	}
}

function* verifySmsCode(action: IActionVerifyCode) {
	const { code, phoneNumber, mode } = action.payload;

	const verifySmsCodeActions =
		phoneAuthActions.verifySmsCode;

	try {
		yield put(verifySmsCodeActions.request());

		const result: {
			data: IVerifySmsCodeResult;
		} = yield call(smsAuth.verifySmsCode, {
			code,
			phoneNumber
		});

		yield put(
			verifySmsCodeActions.success({
				idToken: result.data.idToken,
			}),
		);
		yield put(
			authActions.signInWithPhoneToken.trigger({
				token: result.data.idToken,
				grantType: 'Phone'
			}),
		);
		yield put(authActions.setAuthActiveStage(mode, 'success'));
	} catch (error) {
		yield put(
			verifySmsCodeActions.failure({
				error: {
					statusCode: error.code,
					message: error.message,
				},
			}),
		);
	} finally {
		yield put(verifySmsCodeActions.fulfill());
	}
}



function* signInWithTinkoffToken(action: IActionTinkoffCode) {
	const { code, mode } = action.payload;
	const { signInWithPhoneToken } = authActions;


	const verifyTinkoffCodeActions =
		phoneAuthActions.signInWithTinkoffToken;

	try {
		yield put(verifyTinkoffCodeActions.request());



		const result: {
			data: IVerifyTinkoffCodeResult;
		} = yield call(smsAuth.verifyTinkoffCode, {
			code

		});



		yield put(
			verifyTinkoffCodeActions.success({
				idToken: result.data.accessToken,
			}),
		);
		yield put(
			authActions.signInWithPhoneToken.trigger({
				token: result.data.accessToken,
				grantType: 'TinkoffId'
			}),
		);
		yield take(signInWithPhoneToken.SUCCESS);

		const checkMatchResult: {
			data: ICheckMatch;
		} = yield call(matchAccountApi.MatchAccount, {
			token: result.data.accessToken,
			grantType: 'TinkoffId'

		});
		const activeStage = checkMatchResult.data ? 'tinkoffMatch' : 'success';
		localStorage.setItem('tinkoffMatchData', JSON.stringify({ token: result.data.accessToken, name: checkMatchResult.data.userName, phone: checkMatchResult.data.phone }));


		yield put(authActions.setAuthActiveStage(mode, activeStage));

	} catch (error) {
		yield put(
			verifyTinkoffCodeActions.failure({
				error: {
					statusCode: error.code,
					message: error.message,
				},
			}),
		);
	} finally {
		yield put(verifyTinkoffCodeActions.fulfill());
	}
}


export default function* watcherSaga() {
	yield takeLatest(
		phoneAuthActions.signInWithPhoneNumber.TRIGGER,
		signInWithPhoneNumber,
	);
	yield takeLatest(
		phoneAuthActions.verifySmsCode.TRIGGER,
		verifySmsCode,
	);
	yield takeLatest(
		phoneAuthActions.signInWithTinkoffToken.TRIGGER,
		signInWithTinkoffToken,
	);
}
