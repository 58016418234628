import React, { FC, useReducer } from 'react';
import MapStateContext from '@/context/MapStateContext';
import { IMapState, TMapAction } from '@/types';

interface IProps {
  children: JSX.Element;
}

const INITIAL_STATE: IMapState = {
  selectedId: '',
  activeCardId: '',
  markerPopupVerticalPosition: null,
  markerPopupHorizontalPosition: null,
};

function reducer(state: IMapState, action: TMapAction) {
  const verticalPosition =
    action.payload?.verticalPosition === undefined
      ? null
      : action.payload?.verticalPosition;

  const horizontalPosition =
    action.payload?.horizontalPosition === undefined
      ? null
      : action.payload?.horizontalPosition;

  switch (action.type) {
    case 'SELECT_MARKER':
      return {
        ...state,
        selectedId: action.payload!.id,
        markerPopupVerticalPosition: verticalPosition,
        markerPopupHorizontalPosition: horizontalPosition,
      };
    case 'SET_ACTIVE_CARD_ID':
      return {
        ...state,
        activeCardId: action.payload!.id,
      };
    case 'RESET_MARKER_SELECTION':
      return INITIAL_STATE;
    default:
      return state;
  }
}

const MapStateProvider: FC<IProps> = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const value = {
    state,
    dispatch,
  };

  return (
    <MapStateContext.Provider value={value}>
      {children}
    </MapStateContext.Provider>
  );
};

export default MapStateProvider;
