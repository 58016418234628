import React, { FC } from 'react';
import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import { Bonuses } from '@/components/Bonuses';
import { colors } from '@/constants';
import { SvgLogoWithoutText } from '../Svg';

interface IProps {
  title: string;
  imageSrc?: string;
  distributionReward?: string;
  activationReward?: string;
  purchaseReward?: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px;
  transition: all 0.2s;

  &:hover {
    background-color: ${colors.GHOST_WHITE};
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const Title = styled.div`
  margin-bottom: 20px;
  font-family: Lato;
  font-size: 14px;
  color: ${colors.BLACK};
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  width: 60px;
  height: 60px;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${hexToRgba(colors.HAWKES_BLUE, 0.3)};
`;

const ImageCover = styled.img`
  display: block;
  width: 100%;
`;

const SearchResultPromotionItem: FC<IProps> = ({
  title,
  imageSrc = '',
  activationReward,
  distributionReward,
  purchaseReward,
}: IProps) => {
  const bonusesProps = {
    activationReward,
    distributionReward,
    purchaseReward,
  };

  return (
    <Wrapper>
      <ImageWrapper>
        {imageSrc ? (
          <ImageCover src={imageSrc} alt={title} />
        ) : (
          <SvgLogoWithoutText
            width={32}
            height={32}
            fill={colors.HAWKES_BLUE}
          />
        )}
      </ImageWrapper>
      <Main>
        <Title>{title}</Title>
        <Bonuses {...bonusesProps} />
      </Main>
    </Wrapper>
  );
};

export default SearchResultPromotionItem;
