import React from 'react';
import styled, { css } from 'styled-components';
import { SvgUser } from '@/components/Svg';
import { colors } from '@/constants';

interface IProps {
  imageSrc?: string;
  imageAlt?: string;
  width: string;
  height?: string;
  className?: string;
}

interface IAvatarRootProps {
  width: number | string;
  height: number | string;
}

const Wrapper = styled.div<IAvatarRootProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ width, height }: IAvatarRootProps) => {
    return css`
      width: ${width};
      height: ${height};
    `;
  }}
  background-color: ${colors.BLUE};
  border-radius: 99%;
  overflow: hidden;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  
`;

const Avatar = ({
  className,
  width,
  height = width,
  imageSrc,
  imageAlt = '',
}: IProps) => {
  return (
    <Wrapper className={className} width={width} height={height}>
      {imageSrc ? (
        <Image src={imageSrc} alt={imageAlt} />
      ) : (
        <SvgUser width="40%" fill={colors.WHITE} />
      )}
    </Wrapper>
  );
};

export default Avatar;
