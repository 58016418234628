import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs, { ParsedQuery } from 'query-string';

const useSearchString = () => {
  const [searchString, setSearchString] = useState<string>('');
  const history = useHistory();
  const location = useLocation();

  const setSearchStringInUrl = (value: string) => {
    const {
      query,
    }: {
      query: ParsedQuery<any>;
    } = qs.parseUrl(location.search);

    let newQuery = {};

    // eslint-disable-next-line
    const { q, ne_lat, ne_lng, sw_lat, sw_lng, zoom, ...rest } = query;

    if (value) {
      newQuery = {
        ...rest,
        q: value,
      };
    } else {
      newQuery = rest;
    }

    const search = qs.stringify(newQuery);

    if (location.pathname !== '/search') {
      history.push({
        pathname: '/search',
        search,
      });
    } else {
      history.push({
        search,
      });
    }
  };

  const getSearchStringFromUrl = () => {
    const {
      query,
    }: {
      query: ParsedQuery<any>;
    } = qs.parseUrl(location.search);

    if (query.q) {
      return query.q;
    }

    return '';
  };

  // eslint-disable-next-line
  useEffect(() => {
    const searchStringFromUrl = getSearchStringFromUrl();

    if (searchStringFromUrl !== searchString) {
      setSearchString(searchStringFromUrl);
    }
  });

  useEffect(() => {
    const searchStringFromUrl = getSearchStringFromUrl();

    setSearchString(searchStringFromUrl);
  }, []); // eslint-disable-line

  return {
    searchString,
    setSearchString,
    setSearchStringInUrl,
    getSearchStringFromUrl,
  };
};

export { useSearchString };
