import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import QrCodeWithLogo from 'qrcode-with-logos';
import { colors } from '@/constants';

interface IProps {
  value: string;
  codeSize?: number;
  logoSize?: number;
  logoSrc?: string;
}

const Wrapper = styled.div`
  height: 350px;
  width: 350px;
  padding: 22px;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 60px;
  border: 1px solid ${colors.LILAC_WHITE};

  & img {
    width: 100%;
    display: block;
    border-radius: 60px;
  }
`;

const QRCodeCanvas = styled.canvas`
  display: none;
`;

const QRCode: FC<IProps> = ({
  value,
  codeSize = 300,
  logoSize = 0.22,
  logoSrc = '/assets/images/qr-code-logo.svg',
}: IProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (canvasRef && imageRef) {
      // @ts-ignore
      const qrCode = new QrCodeWithLogo({
        canvas: canvasRef.current || undefined,
        content: value,
        width: codeSize,
        image: imageRef.current || undefined,
        logo: {
          src: logoSrc,
          logoSize,
          borderRadius: 0,
        },
      });

      qrCode.toCanvas().then(() => {
        qrCode.toImage();
      });
    }
  }, [canvasRef, imageRef, value, codeSize, logoSize, logoSrc]);

  return (
    <Wrapper>
      <QRCodeCanvas ref={canvasRef} />
      <img ref={imageRef} src="" alt="Fartsa promo code" />
    </Wrapper>
  );
};

export default QRCode;
