import React, { FC } from 'react';
import { ISvgColorfulProps } from '@/types';

const SvgAppleWallet: FC<ISvgColorfulProps> = (props: ISvgColorfulProps) => {
  return (
    <svg width={34} height={26} viewBox="0 0 34 26" {...props}>
      <g fill="none">
        <path
          fill="#D3D1C8"
          d="M33.645 3.581v20.932H.957V3.558C.957 1.783 2.339.493 4.044.493h26.513c1.705 0 3.087 1.279 3.087 3.065l.001.023z"
        />
        <path
          fill="#319DCC"
          d="M2.319 17.171V4.203c0-.086.001-.17.004-.257.005-.187.016-.374.049-.559a1.845 1.845 0 01.508-.984 1.83 1.83 0 01.993-.503c.186-.034.375-.043.563-.048.087-.003.172-.004.259-.004h24.821-1.091 1.482c.087 0 .172.001.259.004.188.005.378.016.564.048.188.034.364.089.536.175.168.085.322.196.455.33a1.782 1.782 0 01.509.983c.033.185.044.373.049.56.002.085.003.17.003.256v12.969H2.319v-.001z"
        />
        <path
          fill="#FDB921"
          d="M2.319 19.875V6.908c0-.086.001-.171.004-.257.005-.188.016-.375.049-.56a1.85 1.85 0 01.508-.984 1.817 1.817 0 01.992-.504c.185-.033.375-.043.563-.048.087-.003.172-.004.259-.004h24.82-1.09 1.483c.087 0 .172.001.259.004.188.005.378.016.563.048.189.034.364.089.536.175.169.085.323.196.456.33.133.132.246.285.331.452.088.17.143.345.177.532.034.184.044.373.05.559.002.086.003.17.003.257v12.967H2.319z"
        />
        <path
          fill="#71BF49"
          d="M2.319 22.58V9.612c0-.086.001-.17.004-.257.005-.187.016-.374.049-.559.034-.187.089-.361.177-.531.085-.167.197-.32.331-.453.133-.132.287-.243.456-.33.17-.085.346-.14.536-.174.185-.034.375-.044.563-.049.087-.002.172-.003.259-.003h24.82-1.09 1.483c.087 0 .172 0 .259.003.188.005.378.017.563.049.189.034.364.089.536.175a1.768 1.768 0 01.787.782c.088.17.143.344.177.531.034.185.044.373.05.56.002.085.003.17.003.256V22.58H2.319z"
        />
        <path
          fill="#F37163"
          d="M2.319 22.58V12.317c0-.086.001-.171.004-.257.005-.187.016-.375.049-.56a1.86 1.86 0 01.177-.53 1.796 1.796 0 011.323-.957c.185-.034.375-.044.563-.05.087-.002.172-.003.259-.003h24.82-1.09 1.483c.087 0 .172.001.259.004.188.005.378.016.563.049.189.033.364.088.536.174.169.085.323.196.456.33.133.132.246.285.331.452.088.17.143.345.177.532.034.184.044.373.05.559.002.086.003.17.003.257V22.58H2.319z"
        />
        <path
          fill="#D3D1C8"
          d="M33.645 24.833H.957v-12.17H9.48c.164.002.329.003.492.008.358.01.718.03 1.072.094a3.61 3.61 0 011.02.333c-.07-.035.897.359 1.745 1.67.613.946 1.803 1.949 3.468 1.949 1.666 0 2.857-1.003 3.468-1.949.806-1.245 1.702-1.65 1.745-1.67a3.59 3.59 0 011.02-.333 7.126 7.126 0 011.071-.094c.165-.005.33-.006.492-.007.195-.002.39-.002.585-.002h7.986v12.17h.001z"
        />
      </g>
    </svg>
  );
};

export default SvgAppleWallet;
