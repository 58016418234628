import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { settingsSelectors } from '@/store/selectors';
import ru from '@/i18n/ru.json';

interface IProps {
  children: JSX.Element;
}

const DEFAULT_LOCALE = 'ru';

const locales: { [key: string]: any } = {
  ru,
};

const LocaleProvider: React.FC<IProps> = ({ children }: IProps) => {
  const locale = useSelector(settingsSelectors.locale);

  moment.locale(locale);

  return (
    <IntlProvider
      defaultLocale={DEFAULT_LOCALE}
      locale={locale}
      messages={locales[locale]}
    >
      {children}
    </IntlProvider>
  );
};

export default LocaleProvider;
