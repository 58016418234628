import React, { FC, Children, ReactElement } from 'react';

interface IProps {
  children: ReactElement[] | ReactElement;
  activeKey: number | string | null;
  onChange: (k: number | string) => void;
}

const Accordion: FC<IProps> = ({ children, activeKey, onChange }: IProps) => {
  const handleSelect = (key: number | string) => {
    onChange(key);
  };

  const renderChildren = () => {
    return Children.map(children, (child: ReactElement) => {
      return React.cloneElement(child, {
        onClick: handleSelect,
        isOpen: activeKey === child.props.itemKey,
      });
    });
  };

  return <>{renderChildren()}</>;
};

export default Accordion;
