import React, { FC, ReactNode, RefObject } from 'react';
import {
  useTable,
  useExpanded,
  useSortBy,
  Column,
  TableState,
} from 'react-table';

import { cn } from '@bem-react/classname';

type TColumn = {
  Header: string | ReactNode;
  accessor: string;
  mod: string;
};

type TCell = {
  column: TColumn;
};

type IProps = {
  columns: Column<object>[];
  data: object[];
} & Partial<{
  className: string;
  colClassNames: string[];
  rowClassNames: string[];
  thClassNames: string[];
  headRef: RefObject<HTMLTableSectionElement>;
  bodyRef: RefObject<HTMLTableSectionElement>;
  initialState: TableState;
}>;

const cnTable = cn('Table');

const Table: FC<IProps> = (props) => {
  const {
    className,
    columns,
    data = [],
    colClassNames = [],
    rowClassNames = [],
    thClassNames = [],
    headRef,
    bodyRef,
    initialState,
  } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState,
    },
    useSortBy,
    useExpanded,
  );

  const additionalRowProps = { className: cnTable('Row', [...rowClassNames]) };

  const additionalThProps = () => ({
    className: cnTable('Th', [...thClassNames]),
  });

  const additionalCellProps = (cell: TCell) => ({
    className: cnTable(
      'Cell',
      {
        [cell.column?.mod]: cell.column?.mod,
      },
      [...colClassNames],
    ),
  });

  return (
    <table className={className} {...getTableProps()}>
      <thead ref={headRef}>
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps(additionalRowProps)}
            key={headerGroup.getHeaderGroupProps().key}
          >
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(additionalThProps())}
                key={column.id}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody ref={bodyRef} {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);

          return (
            <tr {...row.getRowProps(additionalRowProps)} key={row.id}>
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps(additionalCellProps(cell as any))}
                  key={`${row.id}-${cell.column.id}`}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
