



import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgWhiteLogoWithoutText: FC<ISvgProps> = (props: ISvgProps) => {
	return (
		<svg viewBox="0 0 48 48" {...props}>
			<path fillRule="evenodd" clipRule="evenodd" d="M24.8633 19.7142C26.2217 20.3933 26.2217 22.3318 24.8633 23.0109L16.0836 27.4008C14.8582 28.0135 13.4164 27.1224 13.4164 25.7524V16.9727C13.4164 15.6027 14.8582 14.7116 16.0836 15.3243L24.8633 19.7142Z" fill="white" />
			<path fillRule="evenodd" clipRule="evenodd" d="M15.976 10.3151C14.6177 10.9943 14.6177 12.9327 15.976 13.6119L32.1276 21.6877C33.353 22.3004 34.7947 21.4093 34.7947 20.0393V3.88773C34.7947 2.51771 33.353 1.62665 32.1276 2.23934L15.976 10.3151Z" fill="white" />
			<path fillRule="evenodd" clipRule="evenodd" d="M15.976 28.9289C14.6177 29.6081 14.6177 31.5465 15.976 32.2257L28.4417 38.4585C29.6671 39.0712 31.1088 38.1802 31.1088 36.8101V24.3445C31.1088 22.9745 29.6671 22.0834 28.4417 22.6961L15.976 28.9289Z" fill="white" />
			<path fillRule="evenodd" clipRule="evenodd" d="M24.8633 38.3279C26.2217 39.0071 26.2217 40.9455 24.8633 41.6247L16.0836 46.0146C14.8582 46.6273 13.4164 45.7362 13.4164 44.3662V35.5865C13.4164 34.2164 14.8582 33.3254 16.0836 33.9381L24.8633 38.3279Z" fill="white" />
		</svg>


	);
};

export default SvgWhiteLogoWithoutText;
