import { createRoutine } from 'redux-saga-routines';
import { reduxActions } from '@/constants';
import { TAuthStage, TAuthMode, IAccessTokenData } from '@/types';

const {
  AUTH_SET_ACTIVE_STAGE,
  AUTH_SET_ACTIVE_MODE,
  AUTH_SIGN_IN_WITH_PHONE_TOKEN,
  AUTH_SIGN_OUT,
  AUTH_SET_DATA,

} = reduxActions;

const signInWithPhoneToken = createRoutine(AUTH_SIGN_IN_WITH_PHONE_TOKEN);

const signOut = () => ({
  type: AUTH_SIGN_OUT,
});

const setAuthActiveMode = (mode: TAuthMode) => ({
  type: AUTH_SET_ACTIVE_MODE,
  payload: {
    mode,
  },
});

const setAuthActiveStage = (mode: TAuthMode, stage: TAuthStage) => ({
  type: AUTH_SET_ACTIVE_STAGE,
  payload: {
    mode,
    stage,
  },
});

const setAuthData = (data: IAccessTokenData) => ({
  type: AUTH_SET_DATA,
  payload: data,
});



export default {
  signInWithPhoneToken,
  signOut,
  setAuthData,
  setAuthActiveMode,
  setAuthActiveStage,

};
