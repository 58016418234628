import React, {
  useEffect,
  forwardRef,
  SyntheticEvent,
  useCallback,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { cn } from '@bem-react/classname';

import { Avatar } from '@/components/Avatar';

import useUpload from '@/hooks/useUpload';

import './UploadAvatar.less';

interface IProps {
  name: string;
  src?: string;
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void;
  onClear?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  className?: string;
}

const b = cn('UploadAvatar');

const UploadAvatar = forwardRef<any, IProps>((props, ref) => {
  const { onChange, onClear, className, name, src } = props;
  const { link, inputRef, handleUpload, handleUploadClear } = useUpload(src);

  useEffect(() => {
    if (inputRef.current && typeof ref === 'function') {
      ref(inputRef.current);
    }
  }, [ref, inputRef]);

  const handleFile = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      handleUpload(e);

      if (onChange) {
        onChange(e);
      }
    },
    [handleUpload, onChange],
  );

  const handleClear = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      handleUploadClear();

      if (onClear) {
        onClear(e);
      }
    },
    [handleUploadClear, onClear],
  );

  return (
    <div className={b({}, [className])}>
      <div className={b('Image-Wrapper')}>
        {link && (
          <button type="button" className={b('Clear')} onClick={handleClear}>
            Clear
          </button>
        )}

        <Avatar width="80px" imageSrc={link} />
      </div>

      <input
        hidden
        ref={inputRef}
        name={name}
        id="avatar-field"
        onChange={handleFile}
        type="file"
        accept="image/*"
      />

      <label className={b('Label')} htmlFor="avatar-field">
        <FormattedMessage
          id={`uploadAvatar.buttons.${link ? 'change' : 'add'}`}
        />
      </label>
    </div>
  );
});

export default UploadAvatar;
