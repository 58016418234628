import { AxiosError } from 'axios';
import { IError } from '@/types';

const createErrorObject = (error: AxiosError<any>) => {
  const result: IError = {
    statusCode: error.response?.status!,
    message: error.response?.data.title || '',
  };

  if (error.response?.data.errors) {
    result.errors = error.response?.data.errors;
  }

  return result;
};

export { createErrorObject };
