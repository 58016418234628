import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { authSelectors } from '@/store/selectors';
import AuthContext from '@/context/AuthContext';

interface IProps {
  children: JSX.Element;
}

const AuthProvider: FC<IProps> = ({ children }: IProps) => {
  const accessToken = useSelector(authSelectors.accessToken);
  const isAuth = !!accessToken;

  return <AuthContext.Provider value={isAuth}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
