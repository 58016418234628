import React, { FC } from 'react';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { Link } from 'react-router-dom';
import { PromotionCard } from '@/components/PromotionCard';
import { useMapState } from '@/hooks/useMapState';
import { IPromotion } from '@/types';
import { useFavoriteIds } from '@/data/useFavoriteIds';

interface IProps {
  promotion: IPromotion;
}

const Item = styled.div`
  width: 50%;
  padding: 15px;

  ${down('md')} {
    width: 50%;
  }

  ${down('sm')} {
    width: 100%;
  }
`;

const PromotionLink = styled(Link)`
  text-decoration: none;
`;

const MapPromotionsItem: FC<IProps> = ({ promotion }: IProps) => {
  const { dispatch } = useMapState();

  const image = promotion.images?.find((item) => item.type === 's665x378');
  const { favoriteIds, isIdsLoading } = useFavoriteIds();
  const isFavorite = favoriteIds?.includes(promotion.id);

  const handleMouseEnter = () => {
    dispatch({
      type: 'SET_ACTIVE_CARD_ID',
      payload: {
        id: promotion.id,
      },
    });
  };

  const handleMouseLeave = () => {
    dispatch({
      type: 'SET_ACTIVE_CARD_ID',
      payload: {
        id: '',
      },
    });
  };

  return (
    <Item>
      {!isIdsLoading && (
        <PromotionLink
          to={`/promotions/${promotion.id}`}
          target="_blank"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <PromotionCard
            isFavorite={isFavorite}
            title={promotion.title}
            startDateTime={promotion.startDateTime}
            endDateTime={promotion.endDateTime}
            startPromoCodeActivationDateTime={promotion.startPromocodeActivationDateTime}
            endPromoCodeActivationDateTime={
              promotion.endPromocodeActivationDateTime
            }
            company={promotion.business.name}
            rewards={promotion.ambassadorRewards}
            imageSrc={image?.media.path}
            id={promotion.id}
          />
        </PromotionLink>

      )}

    </Item>
  );
};

export default MapPromotionsItem;
