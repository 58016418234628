import React from 'react';

import { TTransferType } from '@/components/BillTransfer/BillTransfer';
import { SvgBillSuccess, SvgBillProcessing, SvgBillDeclined } from '@/components/Svg';
import { TOperationStatus } from '@/data/useOperations';

import { TBillFilter } from './types';

export const filters: TBillFilter[] = [
  {
    id: 'all',
    title: 'bill.filters.all',
    filterBy: {
      type: null,
      status: null,
    },
  },
  {
    id: 'income',
    title: 'bill.filters.inсome',
    filterBy: {
      type: 'Incoming',
    },
  },
  {
    id: 'withdraw',
    title: 'bill.filters.withdraw',
    filterBy: {
      type: 'Withdrawal',
    },
  },
  {
    id: 'success',
    title: 'bill.filters.success',
    filterBy: {
      status: 'Success',
    },
  },
  {
    id: 'progress',
    title: 'bill.filters.progress',
    filterBy: {
      status: 'Processing',
    },
  },
  {
    id: 'cancel',
    title: 'bill.filters.cancel',
    filterBy: {
      status: 'Declined',
    },
  },
];

export const columns = [
  {
    Header: 'Дата/Время',
    accessor: 'datetime',
  },
  {
    Header: 'Подробности',
    accessor: 'info',
  },
  {
    Header: 'Сумма',
    accessor: 'sum',
  },
  {
    Header: 'Операция',
    accessor: 'operation',
  },
  {
    Header: 'Статус',
    accessor: 'status',
  },
];

type TData = {
  datetime: string;
  info: string;
  sum: {
    type: TTransferType;
    value: string;
  };
  operation: string;
  status: string;
};

export const data: TData[] = [
  {
    datetime: '25.04.2020, 12:38',
    info: 'Сканирование QR промокода',
    sum: {
      type: 'income',
      value: '10000.00',
    },
    operation: 'Начисление',
    status: 'success',
  },
  {
    datetime: '24.04.2020, 12:38',
    info: 'Visa / MasterCard ****9999',
    sum: {
      type: 'withdraw',
      value: '680.00',
    },
    operation: 'Вывод',
    status: 'progress',
  },
  {
    datetime: '24.04.2020, 12:38',
    info: 'Visa / MasterCard ****9999',
    sum: {
      type: 'withdraw',
      value: '2700.00',
    },
    operation: 'Вывод',
    status: 'cancel',
  },
];

export const getIcon = (status: TOperationStatus) => {
  switch (status) {
    case 'Success':
      return <SvgBillSuccess />;
    case 'Processing':
      return <SvgBillProcessing />;
    case 'Pending':
      return <SvgBillProcessing />;
    case 'Declined':
      return <SvgBillDeclined />;
  }
};