import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { FilterDropdown } from '@/components/FilterDropdown';
import { SingleSelection, MultiSelection } from '@/components/Selections';
import { useSearchFilters } from '@/hooks/useSearchFilters';
import { useSearchFilterOptions } from '@/hooks/useSearchFilterOptions';
import { TSearchFilterControl, IUrlParamWithValue } from '@/types';

const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Control = styled.div`
  margin-right: 12px;
`;

const Menu = styled.div`
  position: relative;
  width: 300px;
`;

const SearchFilters = () => {
  const {
    currentCategoryId,
    currentSubcategoryIds,
    currentBrandIds,
    setFiltersInUrl,
  } = useSearchFilters();

  const {
    brandsOptions,
    categoriesOptions,
    subcategoriesOptions,
  } = useSearchFilterOptions(currentCategoryId);

  const [categoryId, setCategoryId] = useState<string>(currentCategoryId);
  const [subcategoryIds, setSubcategoryIds] = useState<string[]>(
    currentSubcategoryIds,
  );
  const [brandIds, setBrandIds] = useState<string[]>(currentBrandIds);

  const [isChangedCategories, setChangedCategories] = useState(false);
  const [isChangedSubcategories, setChangedSubcategories] = useState(false);
  const [isChangedBrands, setChangedBrands] = useState(false);

  const [openedFilter, setOpenedFilter] = useState<TSearchFilterControl | null>(
    null,
  );

  const intl = useIntl();

  const tLabelCategories = intl.formatMessage({
    id: 'promotions.search.filter.categories',
  });
  const tLabelSubcategories = intl.formatMessage({
    id: 'promotions.search.filter.subcategories',
  });
  const tLabelBrands = intl.formatMessage({
    id: 'promotions.search.filter.brands',
  });

  const resetChangedStatus = () => {
    setChangedCategories(false);
    setChangedSubcategories(false);
    setChangedBrands(false);
  };

  const handleCategoryChange = (value: string) => {
    setCategoryId(value);
    setSubcategoryIds([]);
    setBrandIds([]);
    setChangedCategories(true);
    setChangedSubcategories(true);
    setChangedBrands(true);
  };

  const handleSubcategoryChange = (value: string[]) => {
    setSubcategoryIds(value);
    setBrandIds([]);
    setChangedSubcategories(true);
    setChangedBrands(true);
  };

  const handleBrandsChange = (value: string[]) => {
    setBrandIds(value);
    setChangedBrands(true);
  };

  const handleCategoriesFilterDropdownChange = (status: boolean) => {
    if (!status && isChangedCategories) {
      setFiltersInUrl(
        [
          {
            param: 'categoryIds',
            value: categoryId,
          },
          {
            param: 'brandIds',
            value: '',
          },
        ],
        { isResetPage: true },
      );
      resetChangedStatus();
    }
    setOpenedFilter(status ? 'categories' : null);
  };

  const handleSubcategoriesFilterDropdownChange = (status: boolean) => {
    if (!status && isChangedSubcategories) {
      const value = subcategoryIds.length ? subcategoryIds : categoryId;
      setFiltersInUrl(
        [
          {
            param: 'categoryIds',
            value,
          },
          {
            param: 'brandIds',
            value: '',
          },
        ],
        { isResetPage: true },
      );
      resetChangedStatus();
    }
    setOpenedFilter(status ? 'subcategories' : null);
  };

  const handleBrandsFilterDropdownChange = (status: boolean) => {
    if (!status && isChangedBrands) {
      setFiltersInUrl(
        [
          {
            param: 'brandIds',
            value: brandIds,
          },
        ],
        { isResetPage: true },
      );
      resetChangedStatus();
    }
    setOpenedFilter(status ? 'brands' : null);
  };

  const closeAllFilters = () => {
    const params: IUrlParamWithValue[] = [];

    if (openedFilter === 'categories' || openedFilter === 'subcategories') {
      if (openedFilter === 'categories') {
        params.push({
          param: 'categoryIds',
          value: categoryId,
        });
      }

      if (openedFilter === 'subcategories') {
        const value = subcategoryIds.length ? subcategoryIds : categoryId;
        params.push({
          param: 'categoryIds',
          value,
        });
      }
    }

    if (openedFilter === 'brands' && isChangedBrands) {
      params.push({
        param: 'brandIds',
        value: brandIds,
      });
    } else {
      params.push({
        param: 'brandIds',
        value: [],
      });
    }

    setFiltersInUrl(params, { isResetPage: true });
    setOpenedFilter(null);
  };

  return (
    <Toolbar>
      <Control>
        <FilterDropdown
          label={tLabelCategories}
          opened={openedFilter === 'categories'}
          isActiveFilter={!!currentCategoryId}
          onDropdownChange={handleCategoriesFilterDropdownChange}
        >
          <Menu>
            <SingleSelection
              defaultValue={currentCategoryId}
              fieldsName="category"
              items={categoriesOptions}
              onChange={handleCategoryChange}
              onApply={closeAllFilters}
            />
          </Menu>
        </FilterDropdown>
      </Control>
      <Control>
        <FilterDropdown
          label={tLabelSubcategories}
          opened={openedFilter === 'subcategories'}
          disabled={!currentCategoryId || !subcategoriesOptions.length}
          isActiveFilter={
            !!(
              subcategoriesOptions.length &&
              currentSubcategoryIds.length &&
              currentCategoryId
            )
          }
          onDropdownChange={handleSubcategoriesFilterDropdownChange}
        >
          <Menu>
            <MultiSelection
              defaultValue={currentSubcategoryIds}
              fieldsName="subcategory"
              items={subcategoriesOptions}
              onChange={handleSubcategoryChange}
              onApply={closeAllFilters}
            />
          </Menu>
        </FilterDropdown>
      </Control>
      <Control>
        <FilterDropdown
          label={tLabelBrands}
          opened={openedFilter === 'brands'}
          isActiveFilter={!!currentBrandIds.length}
          onDropdownChange={handleBrandsFilterDropdownChange}
        >
          <Menu>
            <MultiSelection
              defaultValue={currentBrandIds}
              fieldsName="brand"
              items={brandsOptions}
              withSearch
              onChange={handleBrandsChange}
              onApply={closeAllFilters}
            />
          </Menu>
        </FilterDropdown>
      </Control>
    </Toolbar>
  );
};

export default SearchFilters;
