import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgNotification: FC<ISvgProps> = (props: ISvgProps) => {
	return (
		<svg viewBox="0 0 24 24" {...props}>
			<path d="M15.7499 21C15.7499 21.1989 15.6709 21.3897 15.5303 21.5303C15.3896 21.671 15.1988 21.75 14.9999 21.75H8.99993C8.80102 21.75 8.61025 21.671 8.4696 21.5303C8.32895 21.3897 8.24993 21.1989 8.24993 21C8.24993 20.8011 8.32895 20.6103 8.4696 20.4697C8.61025 20.329 8.80102 20.25 8.99993 20.25H14.9999C15.1988 20.25 15.3896 20.329 15.5303 20.4697C15.6709 20.6103 15.7499 20.8011 15.7499 21ZM20.7984 18C20.6682 18.2292 20.4793 18.4196 20.2511 18.5514C20.0228 18.6833 19.7635 18.7518 19.4999 18.75H4.49993C4.23625 18.7496 3.97731 18.6798 3.74923 18.5475C3.52115 18.4151 3.33198 18.225 3.20079 17.9963C3.0696 17.7676 3.00102 17.5083 3.00196 17.2446C3.00291 16.9809 3.07335 16.7222 3.20618 16.4944C3.72649 15.5981 4.49993 13.0631 4.49993 9.75C4.49993 7.76088 5.29011 5.85322 6.69663 4.4467C8.10315 3.04018 10.0108 2.25 11.9999 2.25C13.9891 2.25 15.8967 3.04018 17.3032 4.4467C18.7098 5.85322 19.4999 7.76088 19.4999 9.75C19.4999 13.0622 20.2743 15.5981 20.7946 16.4944C20.9288 16.7225 20.9997 16.9822 21.0002 17.2468C21.0007 17.5114 20.9307 17.7714 20.7974 18H20.7984ZM19.4999 17.25C18.7752 16.0059 17.9999 13.1297 17.9999 9.75C17.9999 8.1587 17.3678 6.63258 16.2426 5.50736C15.1174 4.38214 13.5912 3.75 11.9999 3.75C10.4086 3.75 8.88251 4.38214 7.75729 5.50736C6.63207 6.63258 5.99993 8.1587 5.99993 9.75C5.99993 13.1306 5.22368 16.0069 4.49993 17.25H19.4999Z" />
		</svg>
	);
};

export default SvgNotification;

