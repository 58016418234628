import { confirmEmail } from '@/data/useUser';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const useEmailConfirmation = () => {
  const { code } = useParams<{ code: string }>();
  const [response, setResponse] = useState<{
    ok: boolean;
    statusCode?: number;
  }>({ ok: false });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    confirmEmail(code)
      .then(() => setResponse({ ok: true, statusCode: 200 }))
      .catch((e) => {
        if (e.message.includes('404')) {
          setResponse({ ok: false, statusCode: 404 });
        } else {
          setResponse({ ok: false });
        }
      })
      .finally(() => setLoading(false));
  }, [code]);

  return { response, loading };
};
