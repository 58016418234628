import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { MainTitle } from '@/components/MainTitle';
import { colors } from '@/constants';

interface IProps {
  statusCode: number | string;
  title: string;
}

const Wrapper = styled.div`
  padding: 30px 0;
`;

const Message = styled.p`
  padding: 16px 0;
`;

const HomePageLink = styled(Link)`
  color: ${colors.BLUE};
  text-decoration: none;
  transition: all 0.3s;

  &:hover {
    color: ${colors.AZUL};
  }
`;

const PageErrorMessage: FC<IProps> = ({ statusCode, title }: IProps) => (
  <Wrapper>
    <MainTitle text={`${statusCode} ${title}`} fontWeight={900} hLevel={1} />
    <Message>
      <FormattedMessage id="errorPage.headOnOverTo" />{' '}
      <HomePageLink to="/">
        <FormattedMessage id="errorPage.homePage" />
      </HomePageLink>
    </Message>
  </Wrapper>
);

export default PageErrorMessage;
