export const removeNullKeys = (obj: Record<string, unknown>) => {
  const newObj = { ...obj };

  for (const key in newObj) {
    if (newObj[key] === null) {
      delete newObj[key];
    }
  }

  return newObj;
};
