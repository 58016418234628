import React, { FC, memo, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { Container, Row } from 'styled-bootstrap-grid';
import { down } from 'styled-breakpoints';
import { motion, useAnimation } from 'framer-motion';
import { SvgBalanceRouble, SvgBillDeclined, SvgBillProcessing, SvgBillSuccess } from '@/components/Svg';
import { HeaderProfile } from '@/components/HeaderProfile';
import { AuthCenter } from '@/components/AuthCenter';
import { Modal } from '@/components/Modal';
import {
  SearchPromotionsForm,
  SearchPromotionsFormSmall,
} from '@/components/Forms';
import { useAuth } from '@/data/useAuth';
import { useUser } from '@/data/useUser';
import { authActions } from '@/store/actions';
import { colors } from '@/constants';
import { TAuthMode } from '@/types';
import { NotificationDropdow } from '../Notification/NotificationDropdown';
import { useStatus } from '@/data/useStatus';

interface IProps {
  fluid?: boolean;
  mapPageMode?: boolean;
  mobileDeviceMode?: boolean;
}

interface IHeaderProps {
  mapPageMode: boolean;
  mobileDeviceMode: boolean;
}

interface IColsProps {
  withoutNegativeMargins: boolean;
}

interface ISearchFormWrapperProps {
  isFluidHeader: boolean;
}

const StyledHeader = styled(motion.header) <IHeaderProps>`
  padding-top: 12px;
  padding-bottom: 12px;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 62px;
  background-color: ${colors.WHITE};
  border-bottom: 1px solid ${colors.LILAC_WHITE};

  ${({ mobileDeviceMode }: IHeaderProps) => {
    if (mobileDeviceMode) {
      return css`
        ${down('lg')} {
          position: relative;
        }
      `;
    }
    return '';
  }}

  ${({ mapPageMode }: IHeaderProps) => {
    if (!mapPageMode) {
      return css`
        ${down('sm')} {
          height: 120px;
        }
      `;
    }
    return '';
  }}
`;

const TopBar = styled(Container)`
  margin-bottom: 18px;
`;

const SvgStatusText = styled.div`
  background-color: ${colors.LILAC_WHITE};
  color: ${colors.BLACK};
  font-size: 14px; 
  line-height: 20px; 
  font-weight: 400;
  padding: 10px 9px 9px 11px; 
  border-radius: 10px;
  width: 250px; 
  position: absolute; 
  top: 100%; 
  left: 50%; 
  z-index: 100; 
  transform: translateX(-50%); 
  display: block; 
`;

const SvgStatusWrapper = styled.div`
margin-right: 15px;
position: relative; 
`;



const BottomBar = styled(Container)`
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 38px;

  ${down('sm')} {
    display: flex;
  }
`;

const MapSearchWrapper = styled(BottomBar)``;

const LogoWrapper = styled.div`
  width: 100px;
  height: 34px;
`;

const LogoImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;
const DocumentsLink = styled(Link)`
  text-decoration: none;
  color: ${colors.BLUE};
`;

const Cols = styled(Row) <IColsProps>`
  padding: 0 15px;
  height: 100%;
  ${({ withoutNegativeMargins }: IColsProps) => {
    if (withoutNegativeMargins) {
      return css`
        margin: 0;
      `;
    }
    return '';
  }}
`;

const SearchFormWrapper = styled.div<ISearchFormWrapperProps>`
  padding: 0 60px;
  flex: 1;

  ${down('sm')} {
    display: none;
  }

  ${({ isFluidHeader }: ISearchFormWrapperProps) => {
    if (isFluidHeader) {
      return css`
        max-width: 800px;
      `;
    }
    return '';
  }}
`;

const Tools = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Balance = styled.div`
  margin-right: 30px;
  display: flex;
  flex-direction: row;

  ${down('sm')} {
    display: none;
  }
`;

const BalanceValue = styled.div`
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
  color: ${colors.BLACK};
`;

// const SearchButtonWrapper = styled.div`
//   padding: 0 12px;
//   height: 34px;
// `;

// const SearchButton = styled.button`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 46px;
//   height: 34px;
//   border: 0;
//   background-color: transparent;
// `;

const headerVariants = {
  inactive: {
    y: 0,
    transition: {
      duration: 0,
    },
  },
  active: {
    y: -58,
    transition: {
      duration: 0,
    },
  },
};

const Header: FC<IProps> = ({
  fluid = false,
  mapPageMode = false,
  mobileDeviceMode = false,
}: IProps) => {
  const [isOpenedModal, setOpenedModal] = useState(false);
  const [activeMapSearch, setActiveMapSearch] = useState(false);
  const [isHovered, setIsHovered] = useState(false);


  const dispatch = useDispatch();

  const controls = useAnimation();

  const matchSignInRoute = useRouteMatch({
    path: '/signin',
  });
  const matchSignUpRoute = useRouteMatch({
    path: '/signup',
  });
  const matchSharedPromotionRoute = useRouteMatch({
    path: '/code/token/:token',
  });

  const isAllNotMatch =
    !matchSignInRoute && !matchSignUpRoute && !matchSharedPromotionRoute;

  const { isAuth } = useAuth();
  const { user } = useUser();
  const { status, isLoading } = useStatus();

  const handleOpenModal = (mode: TAuthMode) => {
    dispatch(authActions.setAuthActiveMode(mode));
    setOpenedModal(true);
  };

  const handleCloseModal = () => {
    setOpenedModal(false);
  };

  const handleSearchFormSmallFocus = () => {
    if (!mapPageMode) {
      controls.start('active');
    }
  };

  const handleCancelSearchClick = () => {
    controls.start('inactive');
    setActiveMapSearch(false);
  };

  // const handleShowSearchClick = () => {
  //   setActiveMapSearch(true);
  // };

  const renderTopBar = () => (
    <TopBar fluid={fluid}>
      <Cols
        withoutNegativeMargins={fluid}
        justifyContent="between"
        alignItems="center"
      >
        <div>
          <Link to="/">
            <LogoWrapper>
              <LogoImage src="/assets/images/logo.svg" alt="Fartsa" />
            </LogoWrapper>
          </Link>
        </div>

        {isAuth ? <SearchFormWrapper isFluidHeader={fluid}>
          <SearchPromotionsForm />
        </SearchFormWrapper> : null}


        {isAllNotMatch && (
          <div>
            <Tools>
              {isAuth && user?.balance ? (
                <>
                  <Balance>
                    <SvgBalanceRouble
                      width={18}
                      height={18}
                      fill={colors.BLACK}
                    />

                    <BalanceValue>{user.balance.availableBalance} {user.balance.frozenBalance ? `(${user.balance.frozenBalance})` : null}</BalanceValue>
                  </Balance>

                </>

              ) : null}
              {/* {mapPageMode && (
                <SearchButtonWrapper>
                  <SearchButton onClick={handleShowSearchClick}>
                    <SvgSearch width={28} fill={colors.BLUE} />
                  </SearchButton>
                </SearchButtonWrapper>
              )} */}
              {!isLoading && (
                <SvgStatusWrapper onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                  {
                    status?.status === 'Empty' ?
                      <>
                        <SvgBillDeclined height={18} width={18} />
                        {isHovered && (<SvgStatusText>Не верифицирован, невозможно принять участие в промоакции. Для верификации заполните <DocumentsLink to='/account/status'>профиль</DocumentsLink>. </SvgStatusText>)}
                      </>
                      :
                      (status?.status === 'PartFilled' ?
                        <>
                          <SvgBillProcessing height={18} width={18} />
                          {isHovered && (<SvgStatusText>Частично верифицирован, невозможно вывести деньги. Для полной верификации заполните <DocumentsLink to='/documents'>документы</DocumentsLink>. </SvgStatusText>)}
                        </>
                        :
                        <>
                          <SvgBillSuccess height={18} width={18} />
                          {isHovered && (<SvgStatusText>Полностью верифицирован.</SvgStatusText>)}
                        </>)
                  }
                </SvgStatusWrapper>
              )}

              <HeaderProfile showAuthModal={handleOpenModal} />
            </Tools>
          </div>
        )}
        {isAuth && (
          <NotificationDropdow />
        )}

      </Cols>


    </TopBar>
  );

  const renderTopBarWithSearch = () => (
    <MapSearchWrapper>
      <SearchPromotionsFormSmall
        mapPageMode
        onFocus={handleSearchFormSmallFocus}
        onCancelClick={handleCancelSearchClick}
      />
    </MapSearchWrapper>
  );

  return (
    <>
      <StyledHeader
        mobileDeviceMode={mobileDeviceMode}
        mapPageMode={mapPageMode}
        initial="inactive"
        variants={headerVariants}
        animate={controls}
      >
        {!activeMapSearch ? renderTopBar() : renderTopBarWithSearch()}

        {!mapPageMode && (
          <BottomBar>
            <SearchPromotionsFormSmall
              onFocus={handleSearchFormSmallFocus}
              onCancelClick={handleCancelSearchClick}
            />
          </BottomBar>
        )}
      </StyledHeader>
      <Modal visible={isOpenedModal} onCancel={handleCloseModal}>
        <AuthCenter
          onSuccessSignUp={handleCloseModal}
          onSuccessSignIn={handleCloseModal}
        />
      </Modal>
    </>
  );
};

export default memo(Header);
