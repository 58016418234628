import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '@/constants';

interface IProps {
  forAttr?: string;
  children?: ReactNode;
}

const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${colors.MONSOON};
`;

const FieldLabel: FC<IProps> = ({ forAttr = '', children = null }: IProps) => {
  const labelProps = forAttr ? { htmlFor: forAttr } : {};

  return <Label {...labelProps}>{children}</Label>;
};

export default FieldLabel;
