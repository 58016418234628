import React, { FC } from 'react';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { colors } from '@/constants';
import { IPropertyListItem } from '@/types';

interface IProps {
  items: IPropertyListItem[];
}

const Wrapper = styled.div`
  width: 100%;
`;

const List = styled.ul`
  list-style: none;
  border-bottom: solid 1px ${colors.LILAC_WHITE};
`;

const ListItem = styled.li`
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${down('xs')} {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const Label = styled.span`
  font-size: 14px;
  color: ${colors.MONSOON};

  ${down('xs')} {
    margin-bottom: 6px;
  }
`;

const Value = styled.span`
  font-size: 16px;
  text-align: right;
  color: ${colors.BLACK};
`;

const PropertyList: FC<IProps> = ({ items }: IProps) => {
  return (
    <Wrapper>
      <List>
        {items.map((i) => (
          <ListItem key={i.label}>
            <Label>{i.label}</Label>
            <Value>{i.value}</Value>
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
};

export default PropertyList;
