
import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgFirstAid: FC<ISvgProps> = (props: ISvgProps) => {


	return (
		<svg viewBox="0 0 32 32" {...props}>
			<g id="FirstAid">
				<path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M11 27V21H5C5 21 4.17157 21 3.58579 20.4142C3.58579 20.4142 3 19.8284 3 19V13C3 13 3 12.1716 3.58579 11.5858C3.58579 11.5858 4.17157 11 5 11H11V5C11 5 11 4.17157 11.5858 3.58579C11.5858 3.58579 12.1716 3 13 3H19C19 3 19.8284 3 20.4142 3.58579C20.4142 3.58579 21 4.17157 21 5V11H27C27 11 27.8284 11 28.4142 11.5858C28.4142 11.5858 29 12.1716 29 13V19C29 19 29 19.8284 28.4142 20.4142C28.4142 20.4142 27.8284 21 27 21H21V27C21 27 21 27.8284 20.4142 28.4142C20.4142 28.4142 19.8284 29 19 29H13C13 29 12.1716 29 11.5858 28.4142C11.5858 28.4142 11 27.8284 11 27ZM13 27H19V20C19 19.4477 19.4477 19 20 19H27V13H20C19.4477 13 19 12.5523 19 12V5H13V12C13 12.5523 12.5523 13 12 13H5V19H12C12.5523 19 13 19.4477 13 20V27Z" fill="#1C1C1C" />
			</g>
		</svg>
	);
};

export default SvgFirstAid;
