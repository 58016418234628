import { useIntl } from 'react-intl';

const useDateRange = (start: string | null, end: string | null) => {
  const intl = useIntl();

  const tFrom = intl.formatMessage({ id: 'date.from' });
  const tUntil = intl.formatMessage({ id: 'date.until' });
  const tIndefinitely = intl.formatMessage({ id: 'date.indefinitely' });

  let result = '';

  if (!start && !end) {
    result = tIndefinitely;
  } else if (!start) {
    result = `${tUntil} ${end}`;
  } else if (!end) {
    result = `${tFrom} ${start}`;
  } else {
    result = `${start} - ${end}`;
  }

  return result;
};

export { useDateRange };
