import React, { useState } from 'react';
import { Container } from 'styled-bootstrap-grid';
import { useIntl } from 'react-intl';
import { cn } from '@bem-react/classname';

import { getName } from '@/helpers/getName';

import { useAuth } from '@/data/useAuth';
import { useUser } from '@/data/useUser';

import { Layout } from '@/components/Layout';
import { RectangleLink } from '@/components/RectangleLink';
import { SvgAccountSettings, SvgAccountStatus, SvgAccountDocuments } from '@/components/Svg';
import { Modal } from '@/components/Modal';
import { DeleteAccount } from '@/components/DeleteAccount';

import './AccountPage.less';

const cnAccount = cn('AccountPage');

const AccountPage = () => {
  const intl = useIntl();
  const { isAuth } = useAuth();
  const { user } = useUser();

  const [isDeleteModalShown, setDeleteModalShown] = useState(false);

  const handleCloseModal = () => setDeleteModalShown(false);
  const handleOpenModal = () => setDeleteModalShown(true);

  const name = getName(user?.profile, 'full');

  const links = [
    {
      to: '/account/settings',
      icon: SvgAccountSettings,
      title: intl.formatMessage({
        id: 'account.links.settings.title',
      }),
      text: intl.formatMessage({
        id: 'account.links.settings.text',
      }),
    },
    {
      to: '/account/status',
      icon: SvgAccountStatus,
      title: intl.formatMessage({
        id: 'account.links.status.title',
      }),
      text: intl.formatMessage({
        id: 'account.links.status.text',
      }),
    },
    {
      to: '/documents',
      icon: SvgAccountDocuments,
      title: intl.formatMessage({
        id: 'account.links.documents.title',
      }),
      text: intl.formatMessage({
        id: 'account.links.documents.text',
      }),
    },
  ];

  return (
    <Layout
      isAuth={isAuth}
      i18nSeoTitle="seo.title.account"
      i18nTitle="common.account"
    >
      <Container>
        <p className={cnAccount('Data')}>
          {name && `${name}, `}

          <span className={cnAccount('Phone')}>{user?.profile.phone}</span>
        </p>
      </Container>

      <Container>
        <div className={cnAccount('Links')}>
          {links.map((link) => (
            <RectangleLink
              className={cnAccount('Link')}
              key={link.to}
              to={link.to}
              Icon={link.icon}
              title={link.title}
              text={link.text}
            />
          ))}
        </div>

        <div className={cnAccount('Cancel')}>
          <p className={cnAccount('Question')}>
            {intl.formatMessage({
              id: 'account.cancel.question',
            })}
          </p>

          <button
            className={cnAccount('Cancel-Link')}
            onClick={handleOpenModal}
            type="button"
          >
            {intl.formatMessage({
              id: 'account.cancel.button',
            })}
          </button>
        </div>
      </Container>

      <Modal visible={isDeleteModalShown} onCancel={handleCloseModal}>
        <DeleteAccount onCancel={handleCloseModal} />
      </Modal>
    </Layout>
  );
};

export default AccountPage;
