import useSWR from 'swr';
import axios, { AxiosRequestConfig } from 'axios';
import { commonAPI } from '@/api';
import { NewLegalEntity, BankAccount, LegalEntityStatus } from '@/types';
import { createErrorObject } from '@/helpers/createErrorObject';

const useLegal = () => {
  const {
    data,
    mutate,
    error,
  }: { data?: LegalEntityStatus; mutate: any; error?: any } = useSWR(
    'ambassador/legalstatus',
    commonAPI.fetch,
    {
      revalidateOnFocus: false,
    },
  );

  const isLoading = !data && (!error || axios.isCancel(error));
  const isError = !!error;

  return {
    mutate,
    isLoading,
    isError,
    bankAccount: data?.bankAccount,
    legalEntity: data?.legalEntity,
    statusType: data?.statusType,
    error: error ? createErrorObject(error) : null,
  };
};

const updateLegalEntity = async (data: NewLegalEntity) => {
  const response = await commonAPI.fetch('/ambassador/legalentity/save', data, {
    requestConfig: { method: 'post' } as AxiosRequestConfig,
  });
  return response;
};

const updateBankAccount = async (data: BankAccount) => {
  const response = await commonAPI.fetch('/ambassador/bankaccount/save', data, {
    requestConfig: { method: 'post' } as AxiosRequestConfig,
  });

  return response;
};

const uploadPassport = async (data: FormData, headers: any) => {
  const response = await axios.post(`${process.env.REACT_APP_DOCUMENTS_BASE_URL}/upload`, data, headers);
  return response;

};

export { useLegal, updateLegalEntity, updateBankAccount, uploadPassport };
