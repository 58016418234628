


import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgVK: FC<ISvgProps> = (props: ISvgProps) => {

	return (
		<svg viewBox="0 0 30 30" {...props}>
			<path fillRule="evenodd" clipRule="evenodd" d="M15 30C23.284 30 30 23.283 30 15C30 6.715 23.284 0 15 0C6.716 0 0 6.715 0 15C0 23.283 6.716 30 15 30ZM21.0075 16.8128C21.046 17.1778 21.4103 17.5084 22.108 18.156C23.558 19.5014 23.9527 20.2126 24.0504 20.3885C24.0591 20.4043 24.0655 20.4158 24.07 20.423C24.725 21.51 23.345 21.596 23.345 21.596L20.706 21.632C20.706 21.632 20.139 21.744 19.393 21.232C19.0021 20.9647 18.62 20.5271 18.2559 20.1103C17.7007 19.4746 17.1875 18.8869 16.749 19.024C16.015 19.258 16.037 20.844 16.037 20.844C16.037 20.844 16.042 21.071 15.875 21.251C15.693 21.443 15.336 21.371 15.336 21.371H14.157C14.157 21.371 11.553 21.639 9.255 19.25C6.754 16.645 4.545 11.529 4.545 11.529C4.545 11.529 4.418 11.218 4.556 11.054C4.711 10.87 5.137 10.872 5.137 10.872L7.957 10.86C7.957 10.86 8.224 10.909 8.413 11.049C8.572 11.164 8.661 11.381 8.661 11.381C8.661 11.381 9.116 12.536 9.721 13.579C10.899 15.617 11.449 16.063 11.849 15.845C12.434 15.525 12.256 12.965 12.256 12.965C12.256 12.965 12.269 12.033 11.964 11.619C11.729 11.299 11.283 11.204 11.087 11.178C10.928 11.157 11.189 10.789 11.527 10.622C12.034 10.376 12.932 10.361 13.992 10.371C14.818 10.38 15.055 10.431 15.378 10.51C16.1272 10.6902 16.1039 11.27 16.0535 12.5215C16.0385 12.8953 16.021 13.3291 16.021 13.83C16.021 13.9424 16.0177 14.0621 16.0144 14.1853C15.997 14.8253 15.977 15.5585 16.398 15.831C16.614 15.972 17.145 15.854 18.467 13.607C19.093 12.542 19.562 11.29 19.562 11.29C19.562 11.29 19.666 11.067 19.823 10.972C19.986 10.875 20.207 10.905 20.207 10.905L23.181 10.886C23.181 10.886 24.073 10.779 24.216 11.182C24.371 11.605 23.883 12.593 22.67 14.21C21.5155 15.7468 20.9548 16.3121 21.0075 16.8128Z" fill="white" />
		</svg>




	);
};

export default SvgVK;