import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { SvgClose } from '@/components/Svg';
import { FilterSelector } from '@/components/FilterSelector';
import { CustomScrollbars } from '@/components/CustomScrollbars';
import { Button } from '@/components/Buttons';
import { HeaderMenu } from '@/components/HeaderMenu';
import { SingleSelection, MultiSelection } from '@/components/Selections';
import { useSearchFiltersState } from '@/hooks/useSearchFiltersState';
import { useSearchFilters } from '@/hooks/useSearchFilters';
import { useSearchFilterOptions } from '@/hooks/useSearchFilterOptions';
import { useWindowSize } from '@/hooks/useWindowSize';
import { getSelectedLabels } from '@/helpers/getSelectedLabels';
import { colors } from '@/constants';
import { IUrlParamWithValue } from '@/types';

interface IMenuBodyProps {
  height: number;
}

const MENU_HEADER_HEIGHT = 62;
const MENU_HEADER_SEARCH_HEIGHT = 86;
const MENU_FOOTER_HEIGHT = 62;

const FiltersMenu = styled(motion.div)`
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: ${colors.WHITE};
  overflow: hidden;
`;

const MenuBody = styled.div<IMenuBodyProps>`
  padding: 0 16px;
  ${({ height }: IMenuBodyProps) => {
    return css`
      height: ${height}px;
    `;
  }}
`;

const MenuBodyInner = styled.div`
  height: auto;
`;

const MenuFooter = styled.div`
  padding: 12px 16px;
  display: flex;
  max-height: 67px;
  justify-content: space-between;
  border-top: 1px solid ${colors.GHOST_WHITE};
`;

const menuVariants = {
  show: {
    y: 0,
    transition: {
      duration: 0.2,
    },
  },
  hidden: {
    y: '100%',
    transition: {
      duration: 0.2,
    },
  },
};

const SearchFiltersMenu = () => {
  const {
    state: { activeFiltersMenu },
    dispatch: filterStateDispatch,
  } = useSearchFiltersState();

  const {
    isFiltersInit,
    currentCategoryId,
    currentSubcategoryIds,
    currentBrandIds,
    setFiltersInUrl,
  } = useSearchFilters();

  const [categoryId, setCategoryId] = useState<string>('');
  const [subcategoryIds, setSubcategoryIds] = useState<string[]>([]);
  const [brandIds, setBrandIds] = useState<string[]>([]);

  const [isChangedCategories, setChangedCategories] = useState(false);
  const [isChangedSubcategories, setChangedSubcategories] = useState(false);
  const [isChangedBrands, setChangedBrands] = useState(false);

  const [categoriesLabel, setCategoriesLabel] = useState<string>('');
  const [subcategoriesLabel, setSubcategoriesLabel] = useState<string>('');
  const [brandsLabel, setBrandsLabel] = useState<string>('');

  const controls = useAnimation();
  const { width, height } = useWindowSize();

  const {
    brandsOptions,
    categoriesOptions,
    subcategoriesOptions,
  } = useSearchFilterOptions(categoryId);

  const intl = useIntl();

  const tHeaderTitle = intl.formatMessage({ id: 'common.filters' });
  const tClear = intl.formatMessage({ id: 'common.clear' });
  const tShowPromotions = intl.formatMessage({
    id: 'promotions.search.showResults',
  });
  const tLabelCategories = intl.formatMessage({
    id: 'promotions.search.filter.categories',
  });
  const tLabelSubcategories = intl.formatMessage({
    id: 'promotions.search.filter.subcategories',
  });
  const tLabelBrands = intl.formatMessage({
    id: 'promotions.search.filter.brands',
  });

  const bodyEl = document.querySelector('body');

  const maxHeight = height
    ? height - MENU_HEADER_HEIGHT - MENU_FOOTER_HEIGHT
    : 0;

  const maxHeightWithSearch = height
    ? height -
      MENU_HEADER_HEIGHT -
      MENU_FOOTER_HEIGHT -
      MENU_HEADER_SEARCH_HEIGHT
    : 0;

  const selectionSize = {
    width: '100%',
    height: maxHeight,
  };

  const selectionSizeWithSearch = {
    width: '100%',
    height: maxHeightWithSearch,
  };

  const showMenu = () => {
    controls.start('show');
    bodyEl?.setAttribute('style', 'overflow: hidden');
  };

  const hideMenu = () => {
    controls.start('hidden');
    bodyEl?.removeAttribute('style');
  };

  useEffect(() => {
    if (isFiltersInit) {
      setCategoryId(currentCategoryId);
      setSubcategoryIds(currentSubcategoryIds);
      setBrandIds(currentBrandIds);
    }
  }, [
    isFiltersInit,
    currentCategoryId,
    currentSubcategoryIds,
    currentBrandIds,
  ]);

  useEffect(() => {
    const selectionCategories = getSelectedLabels(
      categoriesOptions,
      categoryId,
    );
    const selectionSubCategories = getSelectedLabels(
      subcategoriesOptions,
      subcategoryIds,
    );
    const selectionBrands = getSelectedLabels(brandsOptions, brandIds);

    setCategoriesLabel(selectionCategories);
    setSubcategoriesLabel(selectionSubCategories);
    setBrandsLabel(selectionBrands);
  }, [categoryId, subcategoryIds, brandIds]); // eslint-disable-line

  useEffect(() => {
    if (width && width >= 1200) {
      hideMenu();
    }
  }, [width]); // eslint-disable-line

  useEffect(() => {
    if (activeFiltersMenu) {
      showMenu();
    } else {
      hideMenu();
    }
  }, [activeFiltersMenu]); // eslint-disable-line

  const resetChangedStatus = () => {
    setChangedCategories(false);
    setChangedSubcategories(false);
    setChangedBrands(false);
  };

  const handleCategoryChange = (value: string) => {
    setCategoryId(value);
    setSubcategoryIds([]);
    setBrandIds([]);
    setChangedCategories(true);
    setChangedSubcategories(true);
    setChangedBrands(true);
  };

  const handleSubcategoryChange = (value: string[]) => {
    setSubcategoryIds(value);
    setBrandIds([]);
    setChangedSubcategories(true);
    setChangedBrands(true);
  };

  const handleBrandsChange = (value: string[]) => {
    setBrandIds(value);
    setChangedBrands(true);
  };

  const handleCloseButtonClick = () => {
    filterStateDispatch({
      type: 'SET_ACTIVE_MENU_STATUS',
      payload: {
        status: false,
      },
    });
  };

  const handleResetButtonClick = () => {
    setCategoryId('');
    setSubcategoryIds([]);
    setBrandIds([]);
    setChangedCategories(true);
    setChangedSubcategories(true);
    setChangedBrands(true);
  };

  const handleSearchButtonClick = () => {
    const params: IUrlParamWithValue[] = [];

    if (isChangedCategories && !subcategoryIds.length) {
      params.push({
        param: 'categoryIds',
        value: categoryId,
      });
    }

    if (isChangedSubcategories && subcategoryIds.length) {
      params.push({
        param: 'categoryIds',
        value: subcategoryIds,
      });
    }

    if (isChangedBrands) {
      params.push({
        param: 'brandIds',
        value: brandIds,
      });
    }

    setFiltersInUrl(params);

    resetChangedStatus();

    filterStateDispatch({
      type: 'SET_ACTIVE_MENU_STATUS',
      payload: { status: false },
    });
  };

  return (
    <FiltersMenu initial="hidden" variants={menuVariants} animate={controls}>
      <HeaderMenu
        title={tHeaderTitle}
        leftButtonLabel={
          <SvgClose width={12} height={12} fill={colors.BLACK} />
        }
        rightButtonLabel={tClear}
        onClickLeftButton={handleCloseButtonClick}
        onClickRightButton={handleResetButtonClick}
      />
      <MenuBody height={maxHeight}>
        {maxHeight && (
          <CustomScrollbars autoHeightMax={maxHeight}>
            <MenuBodyInner>
              <FilterSelector
                title={tLabelCategories}
                selection={categoriesLabel}
              >
                <SingleSelection
                  defaultValue={categoryId}
                  fieldsName="category"
                  items={categoriesOptions}
                  listSize={selectionSize}
                  resetOnly
                  onChange={handleCategoryChange}
                />
              </FilterSelector>
              <FilterSelector
                title={tLabelSubcategories}
                selection={subcategoriesLabel}
                disabled={!categoryId || !subcategoriesOptions.length}
              >
                <MultiSelection
                  defaultValue={subcategoryIds}
                  fieldsName="subcategory"
                  items={subcategoriesOptions}
                  listSize={selectionSize}
                  resetOnly
                  onChange={handleSubcategoryChange}
                />
              </FilterSelector>
              <FilterSelector title={tLabelBrands} selection={brandsLabel}>
                <MultiSelection
                  defaultValue={brandIds}
                  fieldsName="brand"
                  items={brandsOptions}
                  listSize={selectionSizeWithSearch}
                  resetOnly
                  withSearch
                  onChange={handleBrandsChange}
                />
              </FilterSelector>
            </MenuBodyInner>
          </CustomScrollbars>
        )}
      </MenuBody>
      <MenuFooter>
        <Button
          fluid
          type="button"
          displayType="primary"
          onClick={handleSearchButtonClick}
        >
          {tShowPromotions}
        </Button>
      </MenuFooter>
    </FiltersMenu>
  );
};

export default SearchFiltersMenu;
