import { put, call, takeLatest } from 'redux-saga/effects';
import { authActions, phoneAuthActions } from '@/store/actions';
import { authAPI } from '@/api';
import { IReduxAction, IAccessTokenData } from '@/types';
import handleError from './utils/handleError';


interface IActionSignInWithPhoneToken extends IReduxAction {
  payload: {
    token: string;
    grantType: string;

  };
}



function* signInWithPhoneToken(action: IActionSignInWithPhoneToken) {
  const { token
    , grantType } = action.payload;
  const signInWithPhoneTokenActions = authActions.signInWithPhoneToken;

  try {
    const res: { data: IAccessTokenData } = yield call(
      authAPI.signInWithPhoneToken,
      {
        token,
        grantType
      },
    );

    const { data } = res;

    yield put(signInWithPhoneTokenActions.success(data));


    yield put(phoneAuthActions.clearData());

  } catch (error) {
    yield call(handleError, {
      error,
      action,
      errorAction: signInWithPhoneTokenActions.failure,
    });
  } finally {
    yield put(signInWithPhoneTokenActions.fulfill());
  }
}

export default function* watcherSaga() {
  yield takeLatest(
    authActions.signInWithPhoneToken.TRIGGER,
    signInWithPhoneToken,
  );
}
