import { useContext } from 'react';
import SearchFiltersContext from '@/context/SearchFiltersContext';

const useSearchFiltersState = () => {
  const ctx = useContext(SearchFiltersContext);

  return {
    state: ctx!.state,
    dispatch: ctx!.dispatch,
  };
};

export { useSearchFiltersState };
