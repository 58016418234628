import { useRef, useState, SyntheticEvent, useCallback } from 'react';

const useUpload = (src?: string) => {
  const [link, setLink] = useState(src);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleUpload = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0];

    setLink(file ? URL.createObjectURL(file) : '');
  }, []);

  const handleUploadClear = useCallback(() => {
    setLink('');

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  }, []);

  return { link, inputRef, handleUpload, handleUploadClear };
};

export default useUpload;
