import useSWR from 'swr';
import axios from 'axios';
import { promotionsAPI } from '@/api';
import { createErrorObject } from '@/helpers/createErrorObject';
import { IPromotion } from '@/types';

interface IParams {
  actualNow?: boolean;
  archived?: boolean;
}

const useMyPromotions = ({ actualNow, archived }: IParams) => {
  const {
    data,
    mutate,
    error,
  }: { data?: IPromotion[]; mutate: any; error?: any } = useSWR(
    ['me/promoactions/participated', actualNow, archived],
    promotionsAPI.getMyPromotions,
    {
      revalidateOnFocus: false,
    },
  );

  const isLoading = !data && (!error || axios.isCancel(error));
  const isError = !!error;

  return {
    mutate,
    isLoading,
    isError,
    promoactions: data || [],
    error: error ? createErrorObject(error) : null,
  };
};

export { useMyPromotions };
