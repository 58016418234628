import { AxiosRequestConfig } from 'axios';
import apiClient from './utils/apiClient';

interface CheckAccountMatch {
	token: string;
	grantType: string;


}





export default {
	async MatchAccount({
		token, grantType
	}: CheckAccountMatch) {
		const api = apiClient({
			withAccessToken: true,
		});

		try {

			const response = await api.get(`${process.env.REACT_APP_API_URL}me/profile/match`, {
				params: {
					token: token, grantType: grantType
				}
			} as AxiosRequestConfig);

			const { data } = response;

			return {
				data
			};
		} catch (error) {
			throw error;
		}
	},

	async MatchAccountConfirm({
		token, grantType
	}: CheckAccountMatch) {
		const api = apiClient({
			withAccessToken: true,
		});

		try {


			const response = await api.post(`${process.env.REACT_APP_API_URL}me/profile/match`, {}, {
				params: {
					token: token, grantType: grantType
				}
			} as AxiosRequestConfig);

			const { data } = response;






			return {
				data
			};
		} catch (error) {
			throw error;
		}
	},
};