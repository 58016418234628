import React, { FC, ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '@/constants';

interface IProps {
  title: string;
  leftButtonLabel?: string | ReactElement | ReactElement[];
  rightButtonLabel?: string | ReactElement | ReactElement[];
  onClickLeftButton?: () => void;
  onClickRightButton?: () => void;
}

interface IWrapperButtonProps {
  position: 'left' | 'right';
}

const MenuHeader = styled.div`
  position: relative;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 62px;
  border-bottom: solid 1px ${colors.LILAC_WHITE};
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${colors.BLACK};
`;

const Button = styled.button`
  padding: 0 16px;
  min-height: 62px;
  font-size: 16px;
  background-color: transparent;
  border: 0;
`;

const WrapperButton = styled.div<IWrapperButtonProps>`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  width: auto;
  height: 100%;

  ${({ position }: IWrapperButtonProps) => {
    if (position === 'left') {
      return css`
        left: 0;
      `;
    }
    return css`
      right: 0;
    `;
  }}
`;

const HeaderMenu: FC<IProps> = ({
  title,
  leftButtonLabel = '',
  rightButtonLabel = '',
  onClickLeftButton = () => {},
  onClickRightButton = () => {},
}: IProps) => {
  return (
    <MenuHeader>
      {leftButtonLabel && (
        <WrapperButton position="left">
          <Button type="button" onClick={onClickLeftButton}>
            {leftButtonLabel}
          </Button>
        </WrapperButton>
      )}
      <Title>{title}</Title>
      {rightButtonLabel && (
        <WrapperButton position="right">
          <Button type="button" onClick={onClickRightButton}>
            {rightButtonLabel}
          </Button>
        </WrapperButton>
      )}
    </MenuHeader>
  );
};

export default HeaderMenu;
