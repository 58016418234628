import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from '@/constants';

interface IProps {
  value: string;
}

const Wrapper = styled.div`
  padding: 22px;
  border-radius: 8px;
  border: 1px solid ${colors.LILAC_WHITE};
  overflow: auto;
`;

const PromoCode = styled.div`
  width: 100%;
  text-align: center;
  line-height: 1.5;
`;

const PromoCodeHolder: FC<IProps> = ({ value }: IProps) => {
  return (
    <Wrapper>
      <PromoCode>{value}</PromoCode>
    </Wrapper>
  );
};

export default PromoCodeHolder;
