import basicFetcher from './utils/basicFetcher';

interface IGetPromotionsParams {
  categoryIds?: string | string[];
}

export default {
  async getBrands(url: string, categoryIds?: string | string[]) {
    let params: IGetPromotionsParams = {};

    if (categoryIds) {
      params = { ...params, categoryIds };
    }

    try {
      const response = await basicFetcher(url, params);
      return response;
    } catch (error) {
      throw error;
    }
  },
};
