import React, { FC } from 'react';
import { connect } from 'react-redux';
import { SignUpScene, IPropsSignUpScene } from '@/scenes/SignUpScene';
import { phoneAuthActions, authActions } from '@/store/actions';
import { phoneAuthSelectors, authSelectors } from '@/store/selectors';
import { IReduxState } from '@/types';

interface IProps extends IPropsSignUpScene {
  insideModal?: boolean;
  onSuccessAuth?: () => void;
}

const {
  setPhoneNumber,
  signInWithPhoneNumber,
  verifySmsCode,
  signInWithTinkoffToken
} = phoneAuthActions;

const { setAuthActiveStage, setAuthActiveMode } = authActions;

const SignUpContainer: FC<IProps> = (props) => <SignUpScene {...props} />;

const mapStateToProps = (state: IReduxState) => ({
  idToken: phoneAuthSelectors.idToken(state),
  phoneNumber: phoneAuthSelectors.phoneNumber(state),
  confirmationResult: phoneAuthSelectors.confirmationResult(state),
  loadingSignIn: phoneAuthSelectors.loadingSignIn(state),
  loadingCodeVerification: phoneAuthSelectors.loadingCodeVerification(state),
  errorSignIn: phoneAuthSelectors.errorSignIn(state),
  errorCodeVerification: phoneAuthSelectors.errorCodeVerification(state),
  activeStage: authSelectors.activeSignUpStage(state),
});

const mapDispatchToProps = {
  signInWithPhoneNumber,
  signInWithTinkoffToken,
  verifySmsCode,
  setPhoneNumber,
  setAuthActiveMode,
  setAuthActiveStage,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpContainer);
