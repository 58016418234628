import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgEmail: FC<ISvgProps> = (props: ISvgProps) => {
  return (
    <svg viewBox="0 0 18 14" {...props}>
      <path d="M18 2.223V1.94C18 .87 17.13 0 16.06 0H1.94C.87 0 0 .87 0 1.94v9.602c0 1.07.87 1.94 1.94 1.94h14.12c1.07 0 1.94-.87 1.94-1.94V2.223zM1.94 1.055h14.12c.488 0 .885.397.885.884L9.882 6.65c-.47.313-1.294.313-1.764 0L1.055 1.94c0-.487.397-.884.884-.884zm14.12 11.371H1.94a.885.885 0 01-.885-.884V3.207l6.478 4.319c.411.274.939.411 1.467.411.528 0 1.056-.137 1.467-.411l6.478-4.319v8.335a.885.885 0 01-.884.884z" />
    </svg>
  );
};

export default SvgEmail;
