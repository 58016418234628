import React, { FC } from 'react';
import { colors } from '@/constants';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface IProps {
  to: string;
  Icon: any;
  title: string;
  text: string;
  className?: string;
}

const Rectangle = styled(Link)`
  display: block;
  width: 348px;
  min-height: 182px;
  padding: 24px 24px 30px 24px;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.06);
  background: ${colors.WHITE};
  border: 2px solid transparent;
  border-radius: 16px;
  text-decoration: none;
  color: ${colors.BLACK};
  transition: 0.2s;

  &:hover {
    border: 2px solid ${colors.BLUE};
  }
`;

const Title = styled.p`
  margin: 10px 0;
  font-size: 18px;
  font-weight: bold;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
`;

const RectangleLink: FC<IProps> = (props) => {
  const { className, to, Icon, title, text } = props;

  return (
    <Rectangle className={className} to={to}>
      <Icon width={32} height={32} fill={colors.BLUE} />

      <Title>{title}</Title>

      <Text>{text}</Text>
    </Rectangle>
  );
};

export default RectangleLink;
