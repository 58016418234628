
import SvgWhiteLogo from '@/components/Svg/SvgWhiteLogo';
import { colors } from '@/constants';
import { cn } from '@bem-react/classname';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import './LandingAmbassadorPage.less';
import { Button } from '@/components/Buttons';
import SvgLandingHeader from '@/components/Svg/SvgLandingHeader';
import AmbassadorChiniseWoman from '../../images/AmbassadorChineseWoman.png';
import Cheese from '../../images/Cheese.png';
import Case from '../../images/Case.png';
import AmbassadorManWithHands from '../../images/AmbassadorManWithHands.png';

import salesBackground from '../../images/salesBackground.png';
import AmbassadorHeaderGirl from '../../images/AmbassadorHeaderGirl.png';
import { useIntl } from 'react-intl';
import { Accordion, AccordionItem } from '@/components/Accordion';
import { Article } from '@/components/Article';

import AmbassadorGreenLike from '../../images/AmbassadorGreenLike.png';
import AmbassadorManWithGlasses from '../../images/AbassadorManWithGlasses.png';
import SvgGreenTriangle from '@/components/Svg/SvgGreenTriangle';

import SvgWhiteLogoWithoutText from '@/components/Svg/SvgWhiteLogoWithoutText';
import SvgWhiteTriangle from '@/components/Svg/SvgWhiteTriangle';
import SvgLittleBlueTriangle from '@/components/Svg/SvgLittleBlueTriangle';
import SvgLittleGreenTriangle from '@/components/Svg/SvgLittleGreenTriangle';
import SvgLittleRedTriangle from '@/components/Svg/SvgLittleRedTriangle';
import SvgLittleYellowTriangle from '@/components/Svg/SvgLittleYellowTriangle';
import getFaqs from '../../api/faqs';
import IFaq from 'src/types/faq';
import LandingFooter from '@/components/LandingFooter/LandingFooter';

const PageHead = styled.div`
  margin-left: 10%;
margin-right: 10%;
  align-items: center;
justify-content: space-between;
display: flex;
flex-direction: row;
height: 88px;
@media (max-width: 768px) {
	margin-left: 5%;
	margin-right: 5%;
  }
  
`;

const HeadContainer = styled.div`
background-color: ${colors.BLUE}

`;
const HeaderContainer = styled.div`
margin-left: 10%;
margin-right: 10%;
display:flex;
flex-direction: row;
align-items: center;
@media (max-width: 768px) {
	margin-left: 5%;
	margin-right: 5%;
	flex-direction: column;
	margin-top: 90px;
  }



`;

const HeaderText = styled.div`
display: flex;
flex-direction: column;
margin-left: 125px;
@media (max-width: 768px) {
	margin-left: 0px;
	margin-top: 10px
  }
`;

const HeaderTextTitle = styled.div`
color: #FFFFFF;
font-weight: 900;
font-size: 52px;
line-height: 62px
`;

const HeaderSvgContainer = styled.div`
width: 540px;
margin-bottom: 60px;
display: flex;
position: relative;
@media (max-width: 768px) {
	margin-left: 5%;
	margin-right: 5%;
	width:100%
  }



`;

const HeaderTextMessage = styled.div`
color: #FFFFFF;
font-size: 18px;
white-space: pre-line;
line-height: 28px;
margin-top: 40px;
`;

const HeaderButtonsContainer = styled.div`
display: flex;
flex-direction: row;
margin-top: 60px;
@media (max-width: 768px) {
	margin-bottom: 60px;
	
	flex-direction: column;
	width: 100%
  }
  


`;


const AdvantagesContainer = styled.div`
background-color: #F8F9FF;
padding-top: 50px;

`;
const AdvantagesContentContainer = styled.div`
margin-left: 10%;
margin-right: 10%;
 gap: 20px;
display: flex;

flex-direction: row;
@media (max-width: 768px) {
    flex-direction: column;
	width: 90vw;
	gap: 0;
	margin-left: 5%;
    margin-right: 5%;
  }
`;

const GuideCardContainer = styled.div`
display: flex;
flex-direction: column;
width: 25%;
padding-left: 24px;
padding-top: 32px;
padding-right: 24px;
padding-bottom: 32px;
border-radius: 32px;
background: #FFFFFF;
@media (max-width: 768px) {
	width: 100%;
	margin-top: 30px;
  }
`;

const GuideCardCounterContainer = styled.div<{ count: number }>`
width: 36px;
height: 36px;
border-radius: 50%;
display: flex;
justify-content: center;

color: white;
font-size: 22px;
line-height: 26.4px;
align-items: center;
font-weight: 700;
background-color: ${props => {
		if (props.count === 1) {
			return '#00E676';
		} if (props.count === 2) {
			return '#FFC107';
		} if (props.count === 3) {
			return '#304FFE';
		} else {
			return '#FF1744';

		}
	}}
`;

const GuideCardTextContainer = styled.div`
margin-top: 20px;
font-weight: 400;
font-size: 18px;
color: #76767A;
line-height: 28px;
`;
const GuideCardArrow = styled.span`
width: 20px;
height: 1px;
position: relative;
align-self: center;
@media (max-width: 768px) {
	transform: rotate(90deg);
	margin-top: 25px;
  }


& > div {
  position: relative;
  width: 20px;
  height: 2px;
  background-color: #C1CBFF;
  left: 0;
  display: block;

  &::after {
	content: '';
	position: absolute;
	width: 10px;
	height: 2px;
	top: -3.9px;
	z-index: 5;
	right: -1.1px;
	background-color: #C1CBFF;
	transform: rotate(45deg);
	border-radius: 30%;
  }

  &::before {
	content: '';
	position: absolute;
	width: 10px;
	z-index: 5;
	height: 2px;
	top: 3.9px;
	right: -1.1px;
	border-radius: 30%;
	background-color: #C1CBFF;
	transform: rotate(-45deg);
  }
}
`;





const PossibilitiesBlockContainer = styled.div`
background-color: #F8F9FF;
padding-top: 60px;


`;

const PossibilitiesContainer = styled.div`
margin-left: 10%;
margin-right: 10%;
display: flex;
flex-direction: column;
align-items: center;
@media (max-width: 768px) {
    flex-direction: column;
		margin-left: 5%;
		margin-right: 5%;
  }

`;
const PossibilitiesCardsContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 30px;
width: 50%;
@media (max-width: 768px) {
    flex-direction: column;
	width: 100%;
  }

`;

const PossibilitiesTitle = styled.div`
font-weight: 900;
font-size: 44px;
line-height: 60px;
@media (max-width: 768px) {
	font-size: 32px;
	line-height: 44px;
	  
	
  }
`;





const PossibilitiesCardContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;

width: 100%;
margin-top: 75px;
border-radius: 40px;


padding-bottom: 5%;
padding-top: 5%;
@media (max-width: 768px) {
    flex-direction: column;
  }
`;



const PossibilitiesCardTextContainer = styled.div`
display: flex;
flex-direction: column;
width: 50%;
@media (max-width: 768px) {
   width: 100%
  }


`;
const PossibilitiesCardText = styled.div`
margin-top: 45px;
font-weight: 400;
font-size: 18px;
color: #76767A;
line-height: 28px;

`;





const StartNowBlockContainer = styled.div`
background-color: #F8F9FF;
padding-top: 130px;

`;

const StartNowContainer = styled.div`
margin-left: 10%;
margin-right: 10%;
width: 80%;
border-radius: 40px;
height: 537px;
background-image: url(${salesBackground});
background-repeat: no-repeat;
background-size: cover;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding-left: 20%;
padding-right: 20%;
@media (max-width: 768px) {
	margin-left: 5%;
	margin-right: 5%;
	width: 90%;
	padding-left: 10%;
	padding-top: 10%;
padding-right: 10%;
  }

`;

const FaqBlockContainer = styled.div`
background-color: #F8F9FF;
padding-top: 120px;
`;


const FaqContainer = styled.div`
margin-left: 10%;
margin-right: 10%;
display: flex;
flex-direction: column;

@media (max-width: 768px) {
	margin-left: 5%;
	margin-right: 5%;
  }

`;




const FaqTitle = styled.div`
font-size: 44px;
line-height: 60px;
font-weight: 900;
`;

const FaqsContainer = styled.div`
width: 80%;
background-color: #FFFFFF;
border-radius: 40px;
padding-left: 5%;
padding-right: 5%;
margin-left: 10%;
margin-top: 65px;
@media (max-width: 768px) {
	margin-left: 5%;
	margin-right: 5%;
	width:90%;
  }
`;


const KoreanGirlImgContainer = styled.img`
margin-left: auto;
margin-right: auto;
left: 5%;
text-align: center;
border: none;
  top: 4.7%;
z-index: 4;
position: absolute;
width: 470px;
height:  687px;
@media (max-width: 768px) {
	transform: rotate(-26.6deg);
	width: 310px;
	height:  475px;
	left: -5%;
	right: 0;
	top: 10%;
  }

		
`;
const KoreanGirlContainer = styled.div`
width: 400px;
height: 380px;
overflow: hidden;
transform: rotate(26.6deg);
position: absolute;
left: 10%;
top: -23.3%;


		
`;

const RedTriangleSvgContainer = styled.div`

position: relative;
display: flex;
justify-content: flex-end;
width: 50%;
z-index: 2;
@media (max-width: 768px) {
	margin-top: 100px;
	width: 90vw;
	justify-content: '';
	display: block;
	margin-left: 0;
	

	
  }
`;

const ManWithPhoneImgContainer = styled.img`
		left: -2%;
		top: -8%;
		transform: rotate(-26.6deg);
		z-index: 100;
		position: absolute;
		width: 380px;
		height:  592px;	
		@media (max-width: 768px) {
			margin-left: 0;
			margin-top: 100px;
			width: 320px;
			height:  500px;	
			left: -2%;
		top: -19%;
		
			
		  }
		
`;



const RedWalletImgContainer = styled.img`
        margin-left: auto;
		margin-right: auto;
		left: -20%;
		text-align: center;
		border: none;
  	    top: -5%;
		z-index: 0;
		position: absolute;
		width: 170px;
		height:  170px;	
		@media (max-width: 768px) {
			width: 130px;
		height:  130px;	
		left: -5%;

		  }
`;

const ManWithPhoneContainer = styled.div`
width: 400px;
height: 380px;
overflow: hidden;
transform: rotate(26.6deg);
position: absolute;


left: 20%;
top: -1.6%;


@media (max-width: 768px) {
	left: 20%;
top: -15.7%;
  }

`;




const PossibilitiesCard = styled.div<{ column: number }>`
display: flex;
flex-direction: column;
width: 100%;
padding-left: 24px;
padding-top: 32px;
padding-bottom: ${props => {
		if (props.column === 1) {
			return '0px';
		} else {
			return '32px';
		}
	}};
padding-right: 24px;
border-radius: 32px;
justify-content: ${props => {
		if (props.column === 1) {
			return 'space-between';
		} else {
			return '';
		}
	}};
background-color: #FFFFFF;
position: relative;
border-radius: 40px;
overflow: hidden;


@media (max-width: 768px) {
   width: 100%;
  }
`;

const SvgLittleBlueTriangleContainer = styled.div`

position: absolute;
left: 63%;
top: -25%;



`;
const SvgLittleGreenTriangleContainer = styled.div`

position: absolute;
left: 80%;
top: 31%;



`;

const SvgLittleRedTriangleContainer = styled.div`

position: absolute;
left: 0;
top: 0;
`;

const SvgLittleYellowTriangleContainer = styled.div`

position: absolute;
left: 0;
top: 40%;
`;
const PossibilitiesTitleSpan = styled.span`
color: #304FFE;
`;
const PossibilitiesCardTitle = styled.div`
font-size: 22px;
line-height: 26.4px;
color: #1A1A21;
font-weight: 700;
margin-bottom: 0px;
margin-top: 20px;
`;
const PossibilitiesCardImg = styled.img<{ count: number }>`
display: block;
width: ${props => {
		if (props.count === 1) {
			return '400px';
		} else {
			return '600px';
		}
	}};
scale: crop;
height: 323px;
 z-index: 5;
`;
const PossibilitiesCardImgContainer = styled.div`
width: 100%;
margin-top: 30px;
position: relative;
display: flex;
border-radius: 40%;
align-items: center;
justify-content: center;
z-index: 1;
`;

const PossibilitiesCard3dImg = styled.img`
display: block;
width: 158px;
height: 158px;
`;

const PossibilitiesWrapper = styled.div`
display: flex;
flex-direction: row;
gap: 32px;
justify-content: space-between;
align-items: center;
margin-top: 75px;
@media (max-width: 768px) {
	width: 100%;
	flex-direction: column;
   }

`;


export const cnLandingPage = cn('LandingPage');


export default function LandingAmbassadorPage() {
	const intl = useIntl();
	const [faqs, setFaqs] = useState<IFaq[]>([]);
	useEffect(() => {
		async function getAsyncData() {
			setFaqs(await getFaqs.getMyFaqs());
		}
		getAsyncData();
	}, []);

	const tRegistrateButton = intl.formatMessage({
		id: 'landingPage.buttonRegistrate',
	});


	const tLogInButton = intl.formatMessage({
		id: 'landingPage.buttonLogIn',
	});



	const tStartNowButton = intl.formatMessage({
		id: 'landingPage.buttonStartNow',
	});
	const tHeaderTextTitle = intl.formatMessage({
		id: 'landingPage.HeaderAmbassadorTextTitle',
	});
	const tHeaderText = intl.formatMessage({
		id: 'landingPage.HeaderAmbassadorText',
	});



	const tPossibilitiesFirstTitle = intl.formatMessage({
		id: 'landingPage.PossibilitiesFirstTitle',
	});

	const tPossibilitiesFirstCardTitle = intl.formatMessage({
		id: 'landingPage.PossibilitiesFirstCardTitle',
	});
	const tPossibilitiesFirstCardTextp1 = intl.formatMessage({
		id: 'landingPage.PossibilitiesFirstCardTextp1',
	});
	const tPossibilitiesFirstCardTextp2 = intl.formatMessage({
		id: 'landingPage.PossibilitiesFirstCardTextp2',
	});

	const tStartNowTitleP1 = intl.formatMessage({
		id: 'landingPage.StartNowTitleP1',
	});
	const tStartNowTitleP2 = intl.formatMessage({
		id: 'landingPage.StartNowTitleP2',
	});
	const tStartNowText = intl.formatMessage({
		id: 'landingPage.StartNowText',
	});
	const tFaqTitle = intl.formatMessage({
		id: 'landingPage.FaqTitle',
	});
	const tFartsa = intl.formatMessage({
		id: 'seo.title.unAuthHome',
	});



	const [windowWidth, setWindowWidth] = useState(window.innerWidth);



	const svgTriangleSize = (windowWidth < 769) ? 373 : 450;

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};
		window.scrollTo(0, 0);

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;

	const [activeAccordionKey, setActiveAccordionKey] = useState<
		number | string | null
	>(null);

	const handleAccordionChange = (key: number | string) => {
		if (key === activeAccordionKey) {
			setActiveAccordionKey(null);
		} else {
			setActiveAccordionKey(key);
		}
	};


	const renderFaq = () =>
		faqs?.length ? (
			<Accordion
				activeKey={activeAccordionKey}
				onChange={handleAccordionChange}
			>
				{faqs.map((faq) => (
					<AccordionItem
						key={faq.question}
						title={faq.question.replace(htmlRegexG, '')}
						itemKey={faq.question}

					>
						<Article
							markup={faq.answer.replace(htmlRegexG, '')}
						/>
					</AccordionItem>
				))}
			</Accordion>
		) : null;



	return (
		<div style={{ overflow: 'hidden' }}>
			<HeadContainer>
				<PageHead >
					{windowWidth < 769 ? <SvgWhiteLogoWithoutText width={48} height={48} /> : <SvgWhiteLogo height={45} width={125} />}
					<div>
						<Button to='/signIn' className={cnLandingPage('LogInButton')}> {tLogInButton} </Button>
						<Button to='/signUp' className={cnLandingPage('RegistrationButton')}>{tRegistrateButton}</Button>
					</div>
				</PageHead>
				<HeaderContainer>
					<HeaderSvgContainer>
						{windowWidth < 769 ?
							<div style={{ width: 340, height: 340, position: 'relative' }}>
								<SvgWhiteTriangle width={373} height={373} />
								<KoreanGirlContainer >
									<KoreanGirlImgContainer src={AmbassadorHeaderGirl} />
								</KoreanGirlContainer>
								<SvgLittleBlueTriangleContainer>
									<SvgLittleBlueTriangle width={106} height={90} />
								</SvgLittleBlueTriangleContainer>
								<SvgLittleGreenTriangleContainer>
									<SvgLittleGreenTriangle width={74} height={73} />
								</SvgLittleGreenTriangleContainer>
								<SvgLittleRedTriangleContainer>
									<SvgLittleRedTriangle width={96} height={97} />
								</SvgLittleRedTriangleContainer>
								<SvgLittleYellowTriangleContainer>
									<SvgLittleYellowTriangle width={66} height={77} />
								</SvgLittleYellowTriangleContainer>


							</div>
							:
							<>
								<SvgLandingHeader width={540} height={658} />
								<KoreanGirlImgContainer src={AmbassadorHeaderGirl} />
							</>}


					</HeaderSvgContainer>


					<HeaderText>
						<HeaderTextTitle>
							{tHeaderTextTitle}
						</HeaderTextTitle>
						<HeaderTextMessage>
							{tHeaderText}
						</HeaderTextMessage>
						<HeaderButtonsContainer>
							<Button to='/signUp' className={cnLandingPage('StartNowButton')}>{tStartNowButton}</Button>
							<Button to='/signIn' className={cnLandingPage('HeaderLogInButton')}> {tLogInButton} </Button>
						</HeaderButtonsContainer>
					</HeaderText>
				</HeaderContainer>
			</HeadContainer>
			<PossibilitiesBlockContainer>
				<PossibilitiesContainer>
					<PossibilitiesCardContainer>
						<PossibilitiesCardTextContainer>
							<PossibilitiesTitle>{tPossibilitiesFirstCardTitle}</PossibilitiesTitle>
							<PossibilitiesCardText>
								{tPossibilitiesFirstCardTextp1}
								<PossibilitiesCardText>{tPossibilitiesFirstCardTextp2}</PossibilitiesCardText>
							</PossibilitiesCardText>
						</PossibilitiesCardTextContainer>

						<RedTriangleSvgContainer >
							<div style={{ width: svgTriangleSize, height: svgTriangleSize, position: 'relative' }}>
								<SvgGreenTriangle width={svgTriangleSize} height={svgTriangleSize} />
								<ManWithPhoneContainer >
									<ManWithPhoneImgContainer src={AmbassadorManWithGlasses} />
								</ManWithPhoneContainer>
								<RedWalletImgContainer src={AmbassadorGreenLike} />
							</div>

						</RedTriangleSvgContainer>
					</PossibilitiesCardContainer>
				</PossibilitiesContainer>
			</PossibilitiesBlockContainer>
			<AdvantagesContainer>
				<AdvantagesContentContainer>
					<GuideCardContainer>
						<GuideCardCounterContainer count={1}>1</GuideCardCounterContainer>
						<GuideCardTextContainer>Бренды размещают промоакции на сервисе Fartca.</GuideCardTextContainer>
					</GuideCardContainer>
					<GuideCardArrow ><div></div></GuideCardArrow>
					<GuideCardContainer>
						<GuideCardCounterContainer count={2}>2</GuideCardCounterContainer>
						<GuideCardTextContainer>Амбассадоры выбирает акцию и получает уникальный QR-код с промопредложением.</GuideCardTextContainer>
					</GuideCardContainer>
					<GuideCardArrow ><div></div></GuideCardArrow>
					<GuideCardContainer>
						<GuideCardCounterContainer count={3}>3</GuideCardCounterContainer>
						<GuideCardTextContainer>Амбассадор ищет клиентов под промоакцию и предоставляет QR-код.</GuideCardTextContainer>
					</GuideCardContainer>
					<GuideCardArrow ><div></div></GuideCardArrow>
					<GuideCardContainer>
						<GuideCardCounterContainer count={4}>4</GuideCardCounterContainer>
						<GuideCardTextContainer>Клиент использует QR-код амбассадор получает комиссию.</GuideCardTextContainer>
					</GuideCardContainer>

				</AdvantagesContentContainer>
			</AdvantagesContainer>
			<PossibilitiesBlockContainer>
				<PossibilitiesContainer>
					<PossibilitiesTitle>
						{tPossibilitiesFirstTitle} <PossibilitiesTitleSpan>{tFartsa}</PossibilitiesTitleSpan>
					</PossibilitiesTitle>
					<PossibilitiesWrapper >


						<PossibilitiesCardsContainer>
							<PossibilitiesCard column={1}>
								<PossibilitiesCardTitle>
									Проверенные компании и сервисы
								</PossibilitiesCardTitle>
								<GuideCardTextContainer>
									Все бренды и акции – реальные. Мы верифицируем всё, что размещается в Fartsa.
								</GuideCardTextContainer>
								<PossibilitiesCardImgContainer>
									<PossibilitiesCardImg count={1} src={AmbassadorChiniseWoman} />
								</PossibilitiesCardImgContainer>
							</PossibilitiesCard>
							<PossibilitiesCard column={2}>
								<PossibilitiesCard3dImg src={Cheese} />
								<PossibilitiesCardTitle>
									Финансовая прозрачность
								</PossibilitiesCardTitle>
								<GuideCardTextContainer>
									В акции чётко прописаны условия вознаграждения и суммы. Все QR-коды уникальны – благодаря этому вы всегда понимаете, сколько клиентов привели и за что заработали.
								</GuideCardTextContainer>
							</PossibilitiesCard>
						</PossibilitiesCardsContainer>
						<PossibilitiesCardsContainer>
							<PossibilitiesCard column={2}>
								<PossibilitiesCard3dImg src={Case} />
								<PossibilitiesCardTitle>
									Гарантия оплаты
								</PossibilitiesCardTitle>
								<GuideCardTextContainer>
									Когда бренд добавляет акцию, он пополняет баланс. Из него оплачивается вознаграждение амбассадоров. Чтобы вывести средства на личный счёт, при регистрации вы проходите верификацию.								</GuideCardTextContainer>

							</PossibilitiesCard>
							<PossibilitiesCard column={1}>
								<PossibilitiesCardTitle>
									Управление доходом
								</PossibilitiesCardTitle>
								<GuideCardTextContainer>
									Вы можете продвигать любое количество брендов и промоакций одновременно.
								</GuideCardTextContainer>
								<PossibilitiesCardImgContainer>
									<PossibilitiesCardImg count={2} src={AmbassadorManWithHands} />
								</PossibilitiesCardImgContainer>
							</PossibilitiesCard>
						</PossibilitiesCardsContainer>
					</PossibilitiesWrapper>
				</PossibilitiesContainer>
			</PossibilitiesBlockContainer>
			<StartNowBlockContainer>
				<StartNowContainer>
					<PossibilitiesTitle>{tStartNowTitleP1}</PossibilitiesTitle>

					<PossibilitiesTitle>{tStartNowTitleP2}</PossibilitiesTitle>

					<PossibilitiesCardText>	{tStartNowText}</PossibilitiesCardText>
					<HeaderButtonsContainer>
						<Button to='/signUp' className={cnLandingPage('StartNowButtonStart')}>{tStartNowButton}</Button>
						<Button to='/signIn' className={cnLandingPage('StartNowLogInButton')}> {tLogInButton} </Button>
					</HeaderButtonsContainer>
				</StartNowContainer>

			</StartNowBlockContainer>
			{faqs?.length ? <FaqBlockContainer>
				<FaqContainer>
					<FaqTitle>{tFaqTitle}</FaqTitle>
				</FaqContainer>
				<FaqsContainer>
					{renderFaq()}
				</FaqsContainer>
			</FaqBlockContainer> : null}
			<div style={{ height: 50, backgroundColor: '#F8F9FF' }} />
			<LandingFooter />

		</div>
	);
}
