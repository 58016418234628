import React, { FC, useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import qs from 'query-string';
import { Chip } from '@/components/Chip';
import {
  SearchResultCategoryItem,
  SearchResultPromotionItem,
} from '@/components/SearchResultItem';
import { SearchResultSection } from '@/components/SearchResultSection';
import { CustomScrollbars } from '@/components/CustomScrollbars';
import { WrapperChipItems } from '@/components/shared';
import { useQuickSearch } from '@/data/useQuickSearch';
import { useDebounce } from '@/hooks/useDebounce';
import { useWindowSize } from '@/hooks/useWindowSize';
import { colors } from '@/constants';

interface IProps {
  searchString: string;
  smallScreenMode?: boolean;
  onStartSearch: () => void;
  onItemClick?: () => void;
}

const SEARCH_PANEL_HEIGHT = 120;

const ChipButton = styled.button`
  background-color: transparent;
  border: 0;
`;

const ItemButton = styled(ChipButton)`
  display: block;
  width: 100%;
`;

const ShowAllPromotionsButton = styled.button`
  padding: 10px;
  width: 100%;
  font-size: 14px;
  text-align: center;
  color: ${colors.BLUE};
  background-color: transparent;
  border: 0;
  transition: all 0.2s;

  &:hover {
    background-color: ${colors.GHOST_WHITE};
  }
`;

const QuickSearchResults: FC<IProps> = ({
  searchString,
  smallScreenMode = false,
  onStartSearch,
  onItemClick = () => {},
}: IProps) => {
  const [tempSearchTerm, setTempSearchTerm] = useState(searchString);
  const [searchTerm, setSearchTerm] = useState(searchString);

  const debouncedSearchTerm = useDebounce(tempSearchTerm, 1000);
  const { quickSearchResult } = useQuickSearch(searchTerm);

  const scrollbarsInnerRef = useRef<HTMLDivElement>(null);

  const history = useHistory();
  const location = useLocation();

  const intl = useIntl();
  const { height } = useWindowSize();

  const tPromotions = intl.formatMessage({ id: 'common.promotions' });
  const tCategories = intl.formatMessage({
    id: 'promotions.search.filter.categories',
  });
  const tBrands = intl.formatMessage({ id: 'promotions.search.filter.brands' });

  const maxHeight = height ? height - SEARCH_PANEL_HEIGHT : 0;
  const autoHeightMax = smallScreenMode ? maxHeight : 400;

  useEffect(() => {
    if (searchString !== tempSearchTerm) {
      setTempSearchTerm(searchString);
    }
  }, [searchString]); // eslint-disable-line

  useEffect(() => {
    if (searchString !== searchTerm) {
      setSearchTerm(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]); // eslint-disable-line

  const pushHistorySearch = (search: string) => {
    if (location.pathname !== '/search') {
      history.push({
        pathname: '/search',
        search,
      });
    } else {
      history.push({
        search,
      });
    }
  };

  const handlePromotionClick = useCallback((id: number | string) => {
    window.open(`/promotions/${id}`, '_blank');
    onItemClick();
  }, []); // eslint-disable-line

  const handleCategoryClick = useCallback((id: number | string) => {
    const search = qs.stringify({
      categoryIds: id,
    });
    pushHistorySearch(search);
    onItemClick();
  }, []); // eslint-disable-line

  const handleBrandClick = useCallback((id: number | string) => {
    const search = qs.stringify({
      brandIds: id,
    });
    pushHistorySearch(search);
    onItemClick();
  }, []); // eslint-disable-line

  const renderPromotions = () => {
    if (
      !quickSearchResult?.promoactions ||
      !quickSearchResult?.promoactions.length
    ) {
      return null;
    }

    return (
      <SearchResultSection title={tPromotions}>
        {quickSearchResult.promoactions.slice(0, 3).map((i) => {
          return (
            <ItemButton
              key={i.id}
              type="button"
              onClick={() => handlePromotionClick(i.id)}
            >
              <SearchResultPromotionItem
                imageSrc={i.media?.path}
                title={i.title}
              />
            </ItemButton>
          );
        })}
        {quickSearchResult.promoactions.length > 3 ? (
          <ShowAllPromotionsButton type="button" onClick={onStartSearch}>
            <FormattedMessage id="promotions.search.showAll" />
          </ShowAllPromotionsButton>
        ) : null}
      </SearchResultSection>
    );
  };

  const renderCategories = () => {
    if (
      !quickSearchResult?.categories ||
      !quickSearchResult?.categories.length
    ) {
      return null;
    }

    return (
      <SearchResultSection title={tCategories}>
        {quickSearchResult.categories.map((i) => {
          return (
            <ItemButton
              key={i.id}
              type="button"
              onClick={() => handleCategoryClick(i.id)}
            >
              <SearchResultCategoryItem categoryType={i.slug} text={i.title} />
            </ItemButton>
          );
        })}
      </SearchResultSection>
    );
  };

  const renderBrands = () => {
    if (!quickSearchResult?.brands || !quickSearchResult?.brands.length) {
      return null;
    }

    return (
      <SearchResultSection title={tBrands}>
        <WrapperChipItems>
          {quickSearchResult.brands.map((i) => {
            return (
              <ChipButton
                key={i.id}
                type="button"
                onClick={() => handleBrandClick(i.id)}
              >
                <Chip logoSrc={i.media?.path} text={i.title} />
              </ChipButton>
            );
          })}
        </WrapperChipItems>
      </SearchResultSection>
    );
  };

  return (
    <CustomScrollbars autoHeight autoHeightMax={autoHeightMax}>
      <div ref={scrollbarsInnerRef}>
        {renderPromotions()}
        {renderCategories()}
        {renderBrands()}
      </div>
    </CustomScrollbars>
  );
};

export default QuickSearchResults;
