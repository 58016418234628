import { produce } from 'immer';
import { phoneAuthActions } from '@/store/actions';
import { common, reduxActions } from '@/constants';
import { TphoneAuthState, IError, TConfirmationResult } from '@/types';

interface IAction {
  type: string;
  payload?: IActionPayload;
}

interface IActionPayload {
  idToken: string;
  phoneNumber: string;
  status: boolean;
  confirmationResult: TConfirmationResult;
  error: IError;
}

const {
  signInWithPhoneNumber,
  verifySmsCode,
  signInWithTinkoffToken,

} = phoneAuthActions;

const { SET_PHONE_NUMBER, CLEAR_DATA } = reduxActions;

const { DEFAULT_ERROR } = common;

const initialLoading = {
  signIn: false,
  codeVerification: false,
};

const initialErrors = {
  signIn: DEFAULT_ERROR,
  codeVerification: DEFAULT_ERROR,
};

const INITIAL_STATE: TphoneAuthState = {
  idToken: '',
  phoneNumber: '',
  confirmationResult: null,
  loading: initialLoading,
  error: initialErrors,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = INITIAL_STATE, action: IAction) =>
  produce(state, (draft) => {
    const { type, payload } = action;

    switch (type) {



      case signInWithPhoneNumber.TRIGGER: {
        const draftState = draft;
        draftState.loading = initialLoading;
        draftState.error = initialErrors;
        return draftState;
      }

      case signInWithPhoneNumber.REQUEST: {
        const draftState = draft;
        draftState.loading.signIn = true;
        return draftState;
      }

      case signInWithPhoneNumber.SUCCESS: {
        const draftState = draft;
        draftState.confirmationResult = payload!.confirmationResult;
        return draftState;
      }

      case signInWithPhoneNumber.FAILURE: {

        const draftState = draft;
        draftState.error.signIn = { ...DEFAULT_ERROR, ...payload?.error! };

        return draftState;
      }

      case signInWithPhoneNumber.FULFILL: {
        const draftState = draft;
        draftState.loading.signIn = false;
        return draftState;
      }

      case verifySmsCode.TRIGGER: {
        const draftState = draft;
        draftState.error = initialErrors;
        return draftState;
      }

      case verifySmsCode.REQUEST: {
        const draftState = draft;
        draftState.loading.codeVerification = true;
        return draftState;
      }

      case verifySmsCode.SUCCESS: {
        const draftState = draft;
        draftState.confirmationResult = null;
        draftState.idToken = payload!.idToken;
        return draftState;
      }

      case verifySmsCode.FAILURE: {
        const draftState = draft;
        draftState.error.codeVerification = {
          ...DEFAULT_ERROR,
          ...payload?.error!,
        };
        return draftState;
      }

      case verifySmsCode.FULFILL: {
        const draftState = draft;
        draftState.loading.codeVerification = false;
        return draftState;
      }
      case signInWithTinkoffToken.TRIGGER: {
        const draftState = draft;
        draftState.error = initialErrors;
        return draftState;
      }

      case signInWithTinkoffToken.REQUEST: {
        const draftState = draft;
        draftState.loading.codeVerification = true;
        return draftState;
      }

      case signInWithTinkoffToken.SUCCESS: {
        const draftState = draft;
        draftState.confirmationResult = null;
        draftState.idToken = payload!.idToken;
        return draftState;
      }

      case signInWithTinkoffToken.FAILURE: {
        const draftState = draft;
        draftState.error.codeVerification = {
          ...DEFAULT_ERROR,
          ...payload?.error!,
        };
        return draftState;
      }

      case signInWithTinkoffToken.FULFILL: {
        const draftState = draft;
        draftState.loading.codeVerification = false;
        return draftState;
      }


      case SET_PHONE_NUMBER: {
        const draftState = draft;
        draftState.phoneNumber = payload!.phoneNumber;
        return draftState;
      }


      case CLEAR_DATA: {
        return INITIAL_STATE;
      }

      default:
        return state;
    }
  });
