import qs from 'query-string';
import { IAccessTokenDataFromAPI } from '@/types';
import apiClient from './utils/apiClient';
import { AxiosRequestConfig } from 'axios';

interface ISignInWithPhoneTokenParams {
  token: string;
  grantType: string;
}

export default {
  async signInWithPhoneToken({ token, grantType }: ISignInWithPhoneTokenParams) {
    const api = apiClient({
      withAccessToken: false,
      requestConfig: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      } as AxiosRequestConfig,
    });

    try {
      const postBody = {
        token,
        grant_type: grantType,
        client_id: process.env.REACT_APP_AUTH_CLIENT_ID
      };

      const response = await api.post('connect/token', qs.stringify(postBody));

      const { data }: { data: IAccessTokenDataFromAPI } = response;

      const result = {
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
        expiresIn: data.expires_in,
      };
      //@ts-ignore
      if (window.ReactNativeWebView) {
        //@ts-ignore
        window.ReactNativeWebView.postMessage(JSON.stringify(result));
      }



      return {
        data: result,
      };
    } catch (error) {
      throw error;
    }
  },
};
