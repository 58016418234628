import useSWR from 'swr';
import axios from 'axios';
import { promotionsAPI } from '@/api';
import { createErrorObject } from '@/helpers/createErrorObject';
import { IStatPromotion } from '@/types';

interface IParams {
	formed?: boolean;
	expired?: boolean;
	extinguished?: boolean;
	all?: boolean;
}

const useMyStatPromotions = ({ formed, expired, extinguished, all }: IParams) => {
	const {
		data,
		mutate,
		error,
	}: { data?: IStatPromotion[]; mutate: any; error?: any } = useSWR(

		['me/promocodes/statistic', 1, formed, expired, extinguished, all],
		promotionsAPI.getMyStatPromotions,
		{
			revalidateOnFocus: false,
		},
	);

	const isLoading = !data && (!error || axios.isCancel(error));
	const isError = !!error;

	return {
		mutate,
		isLoading,
		isError,
		promoactions: data || [],
		error: error ? createErrorObject(error) : null,
	};
};

export { useMyStatPromotions };
