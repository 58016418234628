export default {
  SIGN_IN_WITH_PHONE_NUMBER: 'phone/SIGN_IN_WITH_PHONE_NUMBER',
  VERIFY_SMS_CODE: 'phone/VERIFY_SMS_CODE',
  SET_PHONE_NUMBER: 'phone/SET_PHONE_NUMBER',
  SIGN_IN_WITH_TINKOFF_TOKEN: 'phone/SIGN_IN_WITH_TINKOFF_TOKEN',
  CLEAR_DATA: 'phone/CLEAR_DATA',


  AUTH_SET_ACTIVE_MODE: 'auth/SET_ACTIVE_MODE',
  AUTH_SET_ACTIVE_STAGE: 'auth/SET_ACTIVE_STAGE',
  AUTH_SIGN_IN_WITH_PHONE_TOKEN: 'auth/SIGN_IN_WITH_PHONE_TOKEN',
  AUTH_SIGN_OUT: 'auth/AUTH_SIGN_OUT',
  AUTH_SET_DATA: 'auth/SET_DATA',

};
