import qs from 'query-string';
import { IApiClientConfig } from '@/types';
import apiClient from './apiClient';
import { AxiosRequestConfig } from 'axios';

const basicFetcher = async (
  url: string,
  params: any = {},
  config: IApiClientConfig = {},
) => {
  const { withAccessToken, requestConfig } = config;
  const api = apiClient({
    withAccessToken,
    requestConfig,
  });

  const method = requestConfig?.method?.toLowerCase() || 'get';

  try {
    let response;

    if (!method || method === 'get') {
      response = await api.get(url, {
        params,
        paramsSerializer: (p) => {
          return qs.stringify(p);
        },
      } as AxiosRequestConfig);
    } else if (method === 'put') {
      response = await api.put(url, params);
    } else {
      response = await api.post(url, params);
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default basicFetcher;
