import { colors, datetime } from '@/constants';
import React, { SyntheticEvent, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Modal from './Modal';
import moment from 'moment';
import { uploadMedia } from '@/data/useMedia';
import { BaseInput } from '../FormControls';
import { IMe, TSex } from '@/types';
import FormFieldError from '../FormControls/FormFieldError';
import { useForm } from 'react-hook-form';
import { cn } from '@bem-react/classname';
import './NewAccountHelpModal.less';
import { RadioButton } from '../RadioButton';
import UploadAvatar from '../UploadAvatar/UploadAvatar';
import { updateProfile, useUser } from '@/data/useUser';
import { commonAPI } from '@/api';
import { useHistory } from 'react-router-dom';

// import matchAccountApi from 'src/api/matchAccount';

// interface IProps {
// }
const b = cn('NewAccountHeplModal');

const Wrapper = styled.div`
  width: 330px;
  display: flex;
  flex-direction: column;
`;
const Question = styled.div`
  margin-top: 20px;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  color: ${colors.MONSOON};
`;

const UserPhotoNameWrapper = styled.div`
display: flex;
flex-direction: row;
margin-top: 25px;


`;

const UserNameWrapper = styled.div`
display: flex;
flex-direction: column;
margin-left: 8px;


`;

const TinkoffText = styled.div`
color: ${colors.MONSOON};
font-size: 14px;
  font-weight: normal;
  line-height: normal;



`;


const TitleWrapper = styled.div`
 
  text-align: center;
  font-size:20px;
`;
const ConfirmMatchButtonCss = css`
  font-size: 14px;
  font-weight: normal;
  line-height: 40px;
  color: ${colors.WHITE};
  background-color: ${colors.BLUE};
  border: 0;
  cursor: pointer;
  margin-top 15px;
  border-radius: 6px;
  width: 65%;
  transition: all 0.3s;



  &:hover {
    background-color: ${colors.AZUL};
  }
`;


const FormWrapper = styled.div`
margin-top: 10px;

`;

const AvatarWrapper = styled.div`
display: flex;
align-items: center;
flex-direction: column;
justify-content: center;

`;


const ConfirmMatchButton = styled.button`
  ${() => ConfirmMatchButtonCss}
`;


const SkipMatchButtonCss = css`
  font-size: 14px;
  font-weight: normal;
  line-height: 40px;
  color: ${colors.BLACK};
  background-color: ${colors.TINKOFF_GRAY};
  border: 0;
  cursor: pointer;
  margin-top 15px;
  border-radius: 6px;
  width: 30%;
  transition: all 0.3s;



  &:hover {
    background-color: #EBEBEB;
  }
`;


const StepContainer = styled.div<{ width: any }>`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 10px;
  position: relative;
  :before {
    content: '';
    position: absolute;
    background: ${colors.TINKOFF_GRAY};
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: '';
    position: absolute;
    background: ${colors.BLUE};
    height: 4px;
    width: ${props => props.width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`;

const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const StepStyle = styled.div<{ step: any }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 3px solid ${(props) =>
		props.step === 'completed' ? colors.BLUE : colors.TINKOFF_GRAY};
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StepCount = styled.span<{ step: any, activeStep: any }>`
  font-size: 19px;
  color: ${(props) =>
		props.step === props.activeStep ? colors.BLUE : colors.TINKOFF_GRAY} ;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
  margin-top: 10px;
`;



const CheckMark = styled.div`
  font-size: 26px;
  font-weight: 600;
  color: ${colors.BLUE};
  -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
  -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
  transform: scaleX(-1) rotate(-46deg);
`;

const steps = [
	{
		label: '1',
		step: 1,
	},
	{
		label: '2',
		step: 2,
	},
	{
		label: '3',
		step: 3,
	},
	{
		label: '4',
		step: 4,
	},
];



const SkipMatchButton = styled.button`
  ${() => SkipMatchButtonCss}
`;




export default function NewAccountHelpModal() {

	const [isVisible, setVisible] = useState(true);
	const [activeStep, setActiveStep] = useState(1);
	const [error, setError] = useState('');
	const [user, setUser] = useState<IMe>();
	const { mutate } = useUser();
	const [isDeleteMedia, setDeleteMedia] = useState(false);
	const [sex, setSex] = useState('');
	const { register, handleSubmit } = useForm();
	const history = useHistory();




	useEffect(() => {
		commonAPI.fetch('me').then(response => {
			setUser(response);
		}
		);
	}, []);
	useEffect(() => {
		if (user?.profile?.sex) {
			setSex(user.profile.sex);
		}
	}, [user]);
	const stepIncrease = () => {
		setActiveStep(prev => prev + 1);
	};
	const totalSteps = steps.length;


	const onSubmit = async (data: any) => {
		let pictureId = null;
		const { media, ...restData } = data;

		if (media?.length) {
			const mediaData = new FormData();
			mediaData.append('file', media[0]);


			const { id } = await uploadMedia(mediaData).catch((e: Error) => { setError(`Не удалось сохранить данные: ${e.message}`); });
			pictureId = id;
		}

		const birthDate = moment(data.birthDate).format(
			datetime.BACKEND_DATE_FORMAT,
		);

		const backendData = { ...restData };

		if (moment(birthDate).isValid()) {
			backendData.birthDate = birthDate;
		}

		if (!isDeleteMedia && pictureId) {
			backendData.pictureId = pictureId;
		}

		if (isDeleteMedia) {
			backendData.pictureId = '';
		}

		const newProfile = await updateProfile(backendData).catch((e: Error) => { setError(`Не удалось сохранить данные: ${e.message}`); });
		if (newProfile) {
			stepIncrease();
			setError('');
		}
		if (
			data.media?.length ||
			newProfile.firstName !== user?.profile?.firstName ||
			newProfile.lastName !== user?.profile?.lastName
		) {
			mutate('/me', (me: IMe) => ({ ...me, profile: newProfile }));
		}

	};

	const handleMediaClear = () => setDeleteMedia(true);
	const stepDecrease = () => {
		setActiveStep(prev => prev - 1);

	};
	const handleSexChange = (e: SyntheticEvent<HTMLInputElement>) => {
		setSex(e.currentTarget.value as TSex);
	};

	const handleMediaChange = () => setDeleteMedia(false);
	const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

	const handleClose = () => {
		setVisible(false);
		history.push('/');
		history.go(0);
	};
	const handleGoToDocuments = () => {
		setVisible(false);
		history.push('/documents');
		history.go(0);
	};

	//   const handleConfirm = () => {
	//     matchAccountApi.MatchAccountConfirm({
	//       token,
	//       grantType: 'TinkoffId'
	//     });
	//     localStorage.removeItem('tinkoffMatchData');
	//     setVisible(false);
	//   };

	return (
		<Modal visible={isVisible} onCancel={handleClose}>
			<Wrapper>
				<StepContainer width={width}>
					{steps.map(({ step }) => (
						<StepWrapper key={step}>
							<StepStyle step={activeStep >= step ? 'completed' : 'incomplete'}>
								{activeStep > step ? (
									<CheckMark>L</CheckMark>
								) : (
									<StepCount step={step} activeStep={activeStep}>{step}</StepCount>
								)}
							</StepStyle>
						</StepWrapper>
					))}
				</StepContainer>
				{(activeStep === 1) ?
					<>
						<TitleWrapper>
							Заголовок 1 (расскажите о себе)
						</TitleWrapper>
						<Question>Укажите свои личные данные. На все уйдет примерно  3 минуты. </Question>
						<TitleWrapper>	<ConfirmMatchButton onClick={stepIncrease}>Начать
						</ConfirmMatchButton></TitleWrapper>

						<UserPhotoNameWrapper>


							<UserNameWrapper>

								<TinkoffText>Картинка</TinkoffText>
							</UserNameWrapper>
						</UserPhotoNameWrapper>
					</>
					: null}
				{(activeStep === 2 || activeStep === 3) ?
					<>
						<TitleWrapper>
							{activeStep === 2 ? 'Заголовок 2 (Как вас предоставлять заказчикам)' : null}
						</TitleWrapper>
						<Question> {activeStep === 2 ? 'Эти данные нужны для работы на сервисе и связи с рекламодателями. При этом рекламодатель увидит только ваше ФИО' : null}   </Question>
						<FormWrapper>
							<form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
								<div className={b('Row')}>
									<div className={b('Main-Col')}>


										{activeStep === 2 ? <>

											<div className={b('Block')}>
												<label className={b('Label')} htmlFor="name">
													Имя
												</label>

												<BaseInput
													ref={register}
													className={b('Field')}
													defaultValue={user?.profile?.firstName}
													id="name"
													name="firstName"
													placeholder={`${'Имя'}`}
												/>
											</div>


											<div className={b('Block')}>
												<label className={b('Label')} htmlFor="middleName">
													Отчество
												</label>

												<BaseInput
													ref={register}
													className={b('Field')}
													defaultValue={user?.profile?.middleName}
													id="middleName"
													name="middleName"
													placeholder={`${'Отчество'}`}
												/>
											</div>

											<div className={b('Block')}>
												<label className={b('Label')} htmlFor="surname">
													Фамилия
												</label>

												<BaseInput
													ref={register}
													className={b('Field')}
													defaultValue={user?.profile?.lastName}
													name="lastName"
													id="surname"
													placeholder={`${'Фамилия'}`}
												/>
											</div>

											<div className={b('Block')}>
												<label className={b('Label')} htmlFor="birthDate">
													Дата рождения
												</label>

												<BaseInput
													ref={register}
													className={b('Field')}
													defaultValue={
														user?.profile?.birthDate &&
														moment(user?.profile?.birthDate).format(
															datetime.BACKEND_DATE_FORMAT,
														)
													}
													type="date"
													name="birthDate"
													id="birthDate"
												/>
											</div>

											<div className={b('Block')}>
												<span className={b('Label')}>Пол</span>

												<div className={b('Radio-Group')}>
													<RadioButton
														ref={register}
														id="female"
														name="sex"
														currentValue={sex}
														value="Female"
														className={b('Radio')}
														onChange={handleSexChange}
													>
														Женский
													</RadioButton>

													<RadioButton
														ref={register}
														id="male"
														name="sex"
														currentValue={sex}
														value="Male"
														className={b('Radio')}
														onChange={handleSexChange}
													>
														Мужской
													</RadioButton>
												</div>
											</div>

										</> : null}

										{activeStep === 3 ?
											<AvatarWrapper>
												<UploadAvatar
													ref={register}
													src={user?.profile?.media?.path}
													onClear={handleMediaClear}
													onChange={handleMediaChange}
													name="media"
												/>
												<Question>Пользователям с хорошей фотографией больше доверяют.
													Фото можно добавить потом.
												</Question>
											</AvatarWrapper> : null}
									</div>
								</div>
								<ButtonsContainer>

									<SkipMatchButton onClick={stepDecrease} type='button'>назад</SkipMatchButton>
									<ConfirmMatchButton type='submit' >далее</ConfirmMatchButton>
								</ButtonsContainer>
								{error && <FormFieldError message={error} />}
							</form>
						</FormWrapper>

					</>
					: null}

				{(activeStep === 4) ?
					<>
						<TitleWrapper>
							Заголовок 3(Все успешно завершено)
						</TitleWrapper>
						<Question>Для начала работы с рекламодателями перейдите к заполнениию документов.</Question>
						<ButtonsContainer>
							<SkipMatchButton onClick={stepDecrease} type='button'>назад</SkipMatchButton>
							<ConfirmMatchButton onClick={handleGoToDocuments} >Заполнить документы</ConfirmMatchButton>
						</ButtonsContainer>
					</>
					: null}
			</Wrapper>
		</Modal>

	);
}