import useSWR from 'swr';
import axios from 'axios';
import { promotionsAPI } from '@/api';
import { createErrorObject } from '@/helpers/createErrorObject';



const useFavoriteIds = () => {
	const {
		data,
		mutate,
		error,
	}: { data?: (number | string)[]; mutate: any; error?: any } = useSWR(
		['favorites/promoactions/ids'],
		promotionsAPI.getFavouriteIds,
		{
			revalidateOnFocus: false,
		},
	);

	const isIdsLoading = !data && (!error || axios.isCancel(error));
	const isError = !!error;
	const favoriteIds = data;

	return {
		mutate,
		isIdsLoading,
		isError,
		error: error ? createErrorObject(error) : null,
		favoriteIds

	};
};

export { useFavoriteIds };