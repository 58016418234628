import {
  validateNaturalPersonInn,
  validateOgrnip,
} from '@/helpers/validationHeplers';
import { LegalEntityType } from '@/types';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

export const useConfig = () => {
  const { formatMessage } = useIntl();

  const translations = {
    tErrorRequiredCode: formatMessage({ id: 'form.errors.required' }),
    tMarkMiddleName: formatMessage({ id: 'legalStatus.marks.middleName' }),
    tMarkByWhom: formatMessage({ id: 'legalStatus.marks.byWhom' }),
    tLabelInn: formatMessage({ id: 'legalStatus.labels.inn' }),
    tLabelOgrnip: formatMessage({ id: 'legalStatus.labels.ogrnip' }),
    tLabelLegalAddress: formatMessage({
      id: 'legalStatus.labels.legalAddress',
    }),
    tLabelRegisterCertificate: formatMessage({
      id: 'legalStatus.labels.registerCertificate',
    }),
    tLabelRegisterDate: formatMessage({
      id: 'legalStatus.labels.registerDate',
    }),
    tLabelSurname: formatMessage({ id: 'legalStatus.labels.surname' }),
    tLabelName: formatMessage({ id: 'legalStatus.labels.name' }),
    tLabelPassportNumber: formatMessage({
      id: 'legalStatus.labels.passportNumber',
    }),
    tLabelByWhom: formatMessage({ id: 'legalStatus.labels.byWhom' }),
    tLabelPlaceCode: formatMessage({ id: 'legalStatus.labels.placeCode' }),
    tLabelIssueDate: formatMessage({ id: 'legalStatus.labels.issueDate' }),
    tLabelRegistration: formatMessage({
      id: 'legalStatus.labels.registration',
    }),
    tLabelMiddleName: formatMessage({
      id: 'legalStatus.labels.middleName',
    }),
    tLabelHasMiddleName: formatMessage({
      id: 'legalStatus.labels.hasMiddleName',
    }),
    tLabelBirthday: formatMessage({
      id: 'legalStatus.labels.birthday',
    }),
    tLabelBirthplace: formatMessage({
      id: 'legalStatus.labels.birthplace',
    }),
    tLabelPassportPhoto: formatMessage({
      id: 'legalStatus.labels.passportPhoto',
    }),
    tLabelPassportMain: formatMessage({
      id: 'legalStatus.labels.passportMain',
    }),
    tLabelPassportAddress: formatMessage({
      id: 'legalStatus.labels.passportAddress',
    }),
    tPlaceholderInn: formatMessage({ id: 'legalStatus.placeholders.inn' }),
    tPlaceholderOgrnip: formatMessage({
      id: 'legalStatus.placeholders.ogrnip',
    }),
    tPlaceholderLegalAddress: formatMessage({
      id: 'legalStatus.placeholders.legalAddress',
    }),
    tPlaceholderRegisterCertificate: formatMessage({
      id: 'legalStatus.placeholders.registerCertificate',
    }),
    tPlaceholderRegisterDate: formatMessage({
      id: 'legalStatus.placeholders.registerDate',
    }),
    tPlaceholderSurname: formatMessage({
      id: 'legalStatus.placeholders.surname',
    }),
    tPlaceholderName: formatMessage({
      id: 'legalStatus.placeholders.name',
    }),
    tPlaceholderMiddleName: formatMessage({
      id: 'legalStatus.placeholders.middleName',
    }),
    tPlaceholderBirthday: formatMessage({
      id: 'legalStatus.placeholders.birthday',
    }),
    tPlaceholderBirthplace: formatMessage({
      id: 'legalStatus.placeholders.birthplace',
    }),
    tPlaceholderPassportNumber: formatMessage({
      id: 'legalStatus.placeholders.passportNumber',
    }),
    tPlaceholderByWhom: formatMessage({
      id: 'legalStatus.placeholders.byWhom',
    }),
    tPlaceholderPlaceCode: formatMessage({
      id: 'legalStatus.placeholders.placeCode',
    }),
    tPlaceholderIssueDate: formatMessage({
      id: 'legalStatus.placeholders.issueDate',
    }),
    tPlaceholderRegistration: formatMessage({
      id: 'legalStatus.placeholders.registration',
    }),
    tPlaceholderPassportPhoto: formatMessage({
      id: 'legalStatus.placeholders.passportPhoto',
    }),
    tPlaceholderPassportMain: formatMessage({
      id: 'legalStatus.placeholders.passportMain',
    }),
    tPlaceholderPassportAddress: formatMessage({
      id: 'legalStatus.placeholders.passportAddress',
    }),
    tOptionSelfWorking: formatMessage({
      id: 'legalStatus.legal.options.selfWorking',
    }),
    tOptionEnterpreneur: formatMessage({
      id: 'legalStatus.legal.options.entrepreneur',
    }),
    tOptionIndividual: formatMessage({
      id: 'legalStatus.legal.options.individual',
    }),

    tTitleLegal: formatMessage({ id: 'legalStatus.titles.legal' }),
    tTitleEnterpreneur: formatMessage({
      id: 'legalStatus.titles.enterpreneur',
    }),
    tTitleData: formatMessage({ id: 'legalStatus.titles.data' }),
    tTitlePhoto: formatMessage({ id: 'legalStatus.titles.photo' }),
    tPhotoInfo: formatMessage({ id: 'legalStatus.photo.info' }),
    tSave: formatMessage({ id: 'legalStatus.save' }),
  };

  const validationSchema = yup.object().shape({
    legalEntityType: yup.string().required(translations.tErrorRequiredCode),
    inn: validateNaturalPersonInn({
      errorLimitNumbers: formatMessage(
        { id: 'form.errors.limitNumbers' },
        { count: 12 },
      ),
      errorRequiredText: translations.tErrorRequiredCode,
      errorText: formatMessage(
        { id: 'form.errors.controlSum' },
        { name: 'ИНН' },
      ),
    }),
    ogrn: yup.string().when('legalEntityType', {
      is: LegalEntityType.INDIVIDUAL_ENTERPRENEUR,
      then: validateOgrnip({
        errorLimitNumbers: formatMessage(
          { id: 'form.errors.limitNumbers' },
          { count: 15 },
        ),
        errorRequiredText: translations.tErrorRequiredCode,
        errorText: formatMessage(
          { id: 'form.errors.controlSum' },
          { name: 'ОГРНИП' },
        ),
      }),
    }),
    address: yup.string().when('legalEntityType', {
      is: LegalEntityType.INDIVIDUAL_ENTERPRENEUR,
      then: yup.string().required(translations.tErrorRequiredCode),
    }),
    registrationDocumentNumber: yup.string().when('legalEntityType', {
      is: LegalEntityType.INDIVIDUAL_ENTERPRENEUR,
      then: yup.string().required(translations.tErrorRequiredCode),
    }),
    registrationDate: yup.string().when('legalEntityType', {
      is: LegalEntityType.INDIVIDUAL_ENTERPRENEUR,
      then: yup.string().required(translations.tErrorRequiredCode),
    }).nullable(),
    firstName: yup.string().required(translations.tErrorRequiredCode),
    lastName: yup.string().required(translations.tErrorRequiredCode),
    dateOfBirth: yup
      .string()
      .required(translations.tErrorRequiredCode)
      .nullable(),
    placeOfBirth: yup.string().required(translations.tErrorRequiredCode),
    documentNumber: yup
      .string()
      .required(translations.tErrorRequiredCode)
      .matches(/\d{4} \d{6}/, formatMessage({ id: 'form.errors.format' })),
    documentIssuedBy: yup.string().required(translations.tErrorRequiredCode),
    documentIssuedByCode: yup
      .string()
      .required(translations.tErrorRequiredCode)
      .matches(/\d{3}-\d{3}/, formatMessage({ id: 'form.errors.format' })),
    documentIssuedDateAt: yup
      .string()
      .required(translations.tErrorRequiredCode)
      .nullable(),
    addressRegistration: yup.string().required(translations.tErrorRequiredCode),
    passportPhoto: yup
      .mixed()
      .test('files', translations.tErrorRequiredCode, val => val === undefined),
    passportMain: yup
      .mixed()
      .test('files', translations.tErrorRequiredCode, val => val === undefined),
    passportAddress: yup
      .mixed()
      .test('files', translations.tErrorRequiredCode, val => val === undefined),
  });

  return { translations, validationSchema };
};
