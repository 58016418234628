import { useEffect, RefObject } from 'react';

const useOutsideClick = (
  ref: RefObject<any>,
  callback: () => void,
  preventClass?: string,
) => {
  const handleClick = (e: MouseEvent) => {
    const isPrevent =
      preventClass &&
      e.target &&
      // @ts-ignore
      e.target.classList.contains(preventClass);

    if (ref.current && !ref.current.contains(e.target) && !isPrevent) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export { useOutsideClick };
