import useSWR from 'swr';
import axios from 'axios';
import { commonAPI } from '@/api';
import { createErrorObject } from '@/helpers/createErrorObject';
import { IPromotionWithPromoCode } from '@/types';

const usePromotionByShareToken = (shareToken: string) => {
  const {
    data,
    mutate,
    error,
  }: { data?: IPromotionWithPromoCode; mutate: any; error?: any } = useSWR(
    `promoaction/${shareToken}`,
    (url) =>
      commonAPI.fetch(
        url,
        {},
        {
          withAccessToken: false,
        },
      ),
    {
      revalidateOnFocus: false,
    },
  );

  const isLoading = !data && (!error || axios.isCancel(error));
  const isError = !!error;

  return {
    mutate,
    isLoading,
    isError,
    data: {
      promotion: data?.promoaction,
      promoCode: data?.promocode,
    },
    error: error ? createErrorObject(error) : null,
  };
};

export { usePromotionByShareToken };
