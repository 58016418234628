import React, { forwardRef } from 'react';
import BaseInput from '../BaseInput';
import { useAutocomplete } from './hooks';

interface IProps {
  placeholder?: string;
  isError?: boolean;
  onInputChange?: any;
  id?: string;
  name: string;
  type?: string;
}

const Search = forwardRef<HTMLInputElement, IProps>((props, ref) => {
  const { id, placeholder, name, type, isError, onInputChange } = props;
  const {
    value,
    setValue,
    setOptions,
    fetchOptions,
    getOptionsScheme,
  } = useAutocomplete();

  const handleChange = async (val: string) => {
    setValue(val);

    if (onInputChange) {
      onInputChange(val);
    }

    const newOptions = await fetchOptions(val);

    setOptions(newOptions.map(getOptionsScheme));
  };

  return (
    <BaseInput
      type={type}
      ref={ref}
      id={id}
      name={name}
      placeholder={placeholder}
      value={value}
      onInputChange={handleChange}
      autocomplete="off"
      isError={isError}
    />
  );
});

export default Search;
