import React, { FC } from 'react';
import { IPromotion } from '@/types';
import { AboutCompany } from '@/components/AboutCompany';

interface IProps {
  promotion: IPromotion;
}

const getContactData = (
  data: string | Array<string> | undefined,
): Array<string> => {
  let result: Array<string> = [];

  if (!data) {
    return result;
  }

  if (data && Array.isArray(data)) {
    result = result.concat(data);
  } else {
    result.push(data);
  }

  return result;
};

const AboutCompanySidebar: FC<IProps> = ({ promotion }: IProps) => {
  const aboutCompanyAddresses: Array<string> = getContactData(
    promotion?.legalEntity?.address,
  );
  const aboutCompanyEmails: Array<string> = getContactData(
    promotion?.business?.email,
  );
  const aboutCompanyPhone: Array<string> = getContactData(
    promotion?.business?.phone,
  );

  return (
    <AboutCompany
      companyName={promotion.legalEntity.name}
      addressItems={aboutCompanyAddresses}
      emailItems={aboutCompanyEmails}
      phoneItems={aboutCompanyPhone}
      asCard
    />
  );
};

export default AboutCompanySidebar;
