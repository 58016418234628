import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '@/constants';

interface IProps {
  text: string;
  icon: ReactNode;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
`;

const TextWrapper = styled.div`
  margin: 0 0 0 10px;
  font-size: 14px;
  color: ${colors.BLACK};
`;

const LabelWithIcon: FC<IProps> = ({ text, icon }: IProps) => {
  return (
    <Wrapper>
      {icon}
      <TextWrapper>{text}</TextWrapper>
    </Wrapper>
  );
};

export default LabelWithIcon;
