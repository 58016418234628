import React, { ChangeEvent, forwardRef, useState } from 'react';
import { cn } from '@bem-react/classname';

import FormFieldError from './FormFieldError';
import './Textarea.less';

interface IProps {
  value: string;
  onChange: (value: string) => void;
  mark?: string;
  placeholder?: string;
  defaultValue?: string;
  id?: string;
  name?: string;
  rows?: number;
  className?: string;
  isError?: boolean;
  errorMessage?: string;
}

const b = cn('Textarea');

const Textarea = forwardRef<HTMLTextAreaElement, IProps>((props, ref) => {
  const {
    id,
    mark,
    name,
    value = null,
    defaultValue = '',
    onChange,
    rows,
    isError,
    errorMessage,
    className,
    placeholder,
  } = props;

  const [textareaValue, setTextareaValue] = useState(defaultValue);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const currentValue = e.currentTarget.value;
    if (value === null) {
      setTextareaValue(currentValue);
    }

    if (onChange) {
      onChange(currentValue);
    }
  };

  const finalValue = value !== null ? value : textareaValue;

  return (
    <>
      <textarea
        id={id}
        name={name}
        className={b({ error: Boolean(isError) }, [className])}
        ref={ref}
        rows={rows}
        value={finalValue}
        onChange={handleChange}
        placeholder={placeholder}
      />

      {mark && <p className={b('Mark')}>{mark}</p>}

      {isError && errorMessage ? (
        <FormFieldError withIcon message={errorMessage} />
      ) : null}
    </>
  );
});

export default Textarea;
