import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { MainTitle } from '@/components/MainTitle';
import { BaseInput } from '@/components/FormControls';
import { Button } from '@/components/Buttons';
import { Loader } from '@/components/Loader';
import { colors } from '@/constants';
import { IError, TFormType } from '@/types';

interface IProps {
  defaultVerificationType: TFormType;
  phoneNumber: string;
  isSingleVerificationType?: boolean;
  loading: boolean;
  error: IError;
  verifyCode: (data: TFormData) => void;
  resendCode: () => void;
}

type TFormData = {
  code: string;
};

const Wrapper = styled.div`
  width: 330px;
`;

const TitleWrapper = styled.div`
  margin: 0 0 34px 0;
  text-align: center;
`;

const PhoneMessage = styled.div`
  margin: 0 0 24px 0;
  width: 190px;
  font-size: 14px;
  font-weight: 600;
  font-weight: normal;
  line-height: 20px;
  color: ${colors.MONSOON};
`;

const RepeatMessage = styled.div`
  padding: 34px 0 24px 0;
  font-size: 14px;
  font-weight: 600;
  font-weight: normal;
  color: ${colors.MONSOON};
`;

const RepeatButtonWrapper = styled.span`
  padding: 0 6px;
`;

const ButtonForm = styled.button`
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  color: ${colors.BLUE};
  background-color: transparent;
  border: 0;

  &:hover {
    color: ${colors.AZUL};
  }
`;

const SubmitButtonWrapper = styled.div`
  padding: 16px 0 0 0;
  width: 100%;
`;

const PhoneVerificationForm: FC<IProps> = ({
  defaultVerificationType,
  phoneNumber,
  loading,
  error,
  isSingleVerificationType = false,
  verifyCode,
  resendCode,
}: IProps) => {
  const [verificationTypeState, setVerificationTypeState] = useState(
    defaultVerificationType,
  );

  const intl = useIntl();

  const tMainTitle = intl.formatMessage({
    id: 'phoneVerification.confirmNumber',
  });
  const tCodePlaceholder = intl.formatMessage({
    id: 'form.placeholders.typeCode',
  });
  const tErrorRequiredCode = intl.formatMessage({
    id: 'form.errors.smsCode.required',
  });
  const tErrorCode = intl.formatMessage({
    id: 'form.errors.smsCode',
  });

  const validationSchema = yup.object().shape({
    code: yup.string().required(tErrorRequiredCode),
  });

  const { register, setValue, handleSubmit, errors, clearErrors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  let codeErrorText = '';

  if (errors.code && !error.statusCode) {
    codeErrorText = errors.code.message;
  } else if (error.statusCode) {
    codeErrorText = tErrorCode;
  }

  const messageId =
    verificationTypeState === 'sms'
      ? 'phoneVerification.typeSMSCode'
      : 'phoneVerification.weWillCall';

  const messageValues = {
    phoneNumber: phoneNumber != '' ? phoneNumber : localStorage.getItem('phone')
  };

  const toggleButtonMessageId =
    verificationTypeState === 'sms'
      ? 'phoneVerification.callMe'
      : 'phoneVerification.sendMeSMS';

  const repeatMessageId =
    verificationTypeState === 'sms'
      ? 'phoneVerification.notReceiveSMS'
      : 'phoneVerification.notCallYou';

  const repeatButtonMessageId =
    verificationTypeState === 'sms'
      ? 'phoneVerification.tryAgain'
      : 'phoneVerification.retry';

  useEffect(() => {
    register('code');
  }, [register]);

  const onSubmit = (data: TFormData) => {
    verifyCode(
      data);
  };

  const handleInputChange = (v: string) => {
    setValue('code', v);
  };

  const handleInputFocus = () => {
    clearErrors('code');
  };

  const handleRepeatButtonClick = () => {
    resendCode();
  };

  const handleToggleFormTypeButtonClick = () => {
    const currentType = verificationTypeState === 'sms' ? 'phoneCall' : 'sms';
    setVerificationTypeState(currentType);
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <MainTitle text={tMainTitle} />
      </TitleWrapper>
      <PhoneMessage>
        <FormattedMessage id={messageId} values={messageValues} />
        {verificationTypeState === 'phoneCall' ? (
          <>
            <br />
            <FormattedMessage id="phoneVerification.typeCode" />:
          </>
        ) : null}
      </PhoneMessage>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BaseInput
          type="number"
          name="code"
          placeholder={tCodePlaceholder}
          onInputChange={handleInputChange}
          onInputFocus={handleInputFocus}
          disabled={loading}
          isError={errors.code || error.statusCode}
          errorMessage={codeErrorText}
        />
        <SubmitButtonWrapper>
          <Button fluid type="submit" displayType="primary" disabled={loading}>
            {loading ? (
              <Loader size={22} thickness={3} color={colors.WHITE} />
            ) : (
              <FormattedMessage id="common.verify" />
            )}
          </Button>
        </SubmitButtonWrapper>
      </form>
      <RepeatMessage>
        <FormattedMessage id={repeatMessageId} />
        <RepeatButtonWrapper>
          <ButtonForm onClick={handleRepeatButtonClick}>
            <FormattedMessage id={repeatButtonMessageId} />
          </ButtonForm>
        </RepeatButtonWrapper>
      </RepeatMessage>
      {isSingleVerificationType ? null : (
        <ButtonForm onClick={handleToggleFormTypeButtonClick}>
          <FormattedMessage id={toggleButtonMessageId} />
        </ButtonForm>
      )}
    </Wrapper>
  );
};

export default PhoneVerificationForm;
