import { combineReducers } from 'redux';
import phoneAuth from './phoneAuth';
import auth from './auth';
import settings from './settings';

export default combineReducers({
  phoneAuth,
  auth,
  settings,
});


