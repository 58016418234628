export const filters = [
  {
    id: 'actions',
    title: 'favorites.filters.actions',
    filterBy: {
      actions: false,
      companies: true,
    },
  },
  {
    id: 'companies',
    title: 'favorites.filters.companies',
    filterBy: {
      actions: true,
      companies: false,
    },
  },
];
