import { useState } from 'react';
import { useSWRInfinite, SWRInfiniteResponseInterface } from 'swr';
import { promotionsAPI } from '@/api';
import { IPromotion, IPromotionsFilterParams } from '@/types';

interface IParams {
  pageSize: number;
  take?: number;
  skip?: number;
  searchString?: string;
  filters?: IPromotionsFilterParams;
  actualNow?: boolean
}

const usePromotionsInfinite = ({
  pageSize,
  searchString,
  filters,
  actualNow,
}: IParams) => {
  const [pageNumber, setPageNumber] = useState<number | null>(null);

  const {
    data,
    error,
    size,
    setSize,
    mutate,
  }: SWRInfiniteResponseInterface<IPromotion[], any> = useSWRInfinite(
    (index: number) => {
      if (pageNumber !== null) {
        const offset = pageNumber <= 0 ? 0 : (pageNumber - 1) * pageSize;
        return [
          'catalog/promoactions',
          pageSize,
          index * pageSize + offset,
          undefined,
          searchString,
          filters,
          actualNow
        ];
      }
      return null;
    },
    promotionsAPI.getPromotions,
    {
      revalidateOnFocus: false,
    },
  );

  const emptyItems: Array<IPromotion> = [];

  const items: Array<IPromotion> =
    data && Array.isArray(data) ? emptyItems.concat(...data) : emptyItems;

  const isLoadingInitialData = !data && !error;

  const isLoadingMore =
    isLoadingInitialData ||
    (size! > 0 && data && typeof data[size! - 1] === 'undefined');

  const isEmpty = !data || data?.[0]?.length === 0;

  const isReachingEnd =
    isEmpty || (data && data[data.length - 1]?.length < pageSize);

  return {
    data,
    items,
    mutate,
    size,
    setSize,
    pageNumber,
    setPageNumber,
    isLoadingInitialData,
    isLoadingMore,
    isEmpty,
    isReachingEnd,
    error,
  };
};

export { usePromotionsInfinite };
