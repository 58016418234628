export const filters = [
  {
    id: 'active',
    title: 'myPromo.filters.active',
    filterBy: {
      archived: false,
      actualNow: true,
    },
  },
  {
    id: 'archived',
    title: 'myPromo.filters.archived',
    filterBy: {
      archived: true,
      actualNow: false,
    },
  },
];
