import React from 'react';
import styled from 'styled-components';
import { Container, Row } from 'styled-bootstrap-grid';
import { Link, Redirect } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Header } from '@/components/Header';
import { Footer } from '@/components/Footer';
import { PromotionsGrid } from '@/components/PromotionsGrid';
import { MainTitle } from '@/components/MainTitle';
import { PromotionsToolbar } from '@/components/PromotionsToolbar';
import { SearchFiltersMenu } from '@/components/SearchFilters';
import { Page, PageContent } from '@/components/shared';
import { useAuth } from '@/data/useAuth';
import { colors } from '@/constants';
import { useStatus } from '@/data/useStatus';





const PageHead = styled.div`
  margin-bottom: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid ${colors.LILAC_WHITE};
`;

const TitleWrapper = styled.div`
  padding: 45px 0 0 0;
`;

const Message = styled.p`
  margin-bottom: 20px;
`;

const AuthLink = styled(Link)`
  color: ${colors.BLUE};
  text-decoration: none;

  &:hover {
    color: ${colors.AZUL};
  }
`;

const HomePage = () => {
  const intl = useIntl();
  const { isAuth } = useAuth();

  const { status } = useStatus();

  const urlParams = new URLSearchParams(window.location.search);
  const profileHelper = urlParams.get('profileHelper');

  const tMainTitle = intl.formatMessage({
    id: isAuth ? 'common.promotions' : 'auth.authPls',
  });



  const tSeoTitle = intl.formatMessage({
    id: isAuth ? 'seo.title.home' : 'seo.title.unAuthHome',
  });

  return !isAuth ? (
    <Redirect to={'/option'} />
  ) : ((status?.status === 'Empty' && profileHelper) ? <Redirect to={'/profileHelper'} /> : (
    <Page>
      <Helmet>
        <title>{tSeoTitle}</title>
      </Helmet>

      <>
        <SearchFiltersMenu />
        <Header />
        <PageContent>
          <PageHead>
            <Container>
              <TitleWrapper>
                <MainTitle text={tMainTitle} hLevel={1} fontWeight={900} />
              </TitleWrapper>
              {isAuth ? <PromotionsToolbar /> : null}
            </Container>
          </PageHead>
          <Container>
            {isAuth ? (
              <Row>
                <PromotionsGrid />
              </Row>
            ) : (
              <>
                <Message>
                  <FormattedMessage id="promotions.auth" />
                </Message>
                <AuthLink to="/signin">
                  <FormattedMessage id="auth.logIn" />
                </AuthLink>
              </>
            )}
          </Container>
        </PageContent>
        <Footer />
      </>
    </Page>
  ));
};

export default HomePage;
