import React, { useState, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { SvgArrowDown } from '@/components/Svg';
import { colors } from '@/constants';
import { ISelectOption } from '@/types';
import FormFieldError from './FormFieldError';

interface IProps {
  options: Array<ISelectOption>;
  placeholder?: string;
  id?: string;
  name?: string;
  defaultValue?: string | number;
  disabled?: boolean;
  isError?: boolean;
  errorMessage?: string;
  withErrorIcon?: boolean;
  className?: string;
  onChange: (value: string | number) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

interface ISelectProps {
  isEmpty: boolean;
  isError: boolean;
}

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
  max-height: 54px;
  overflow: hidden;
  border-radius: 8px;
`;

const Select = styled.select<ISelectProps>`
  position: relative;
  width: 100%;
  max-height: 54px;
  padding: 17px 36px 18px 12px;
  font-size: 16px;
  color: ${({ isEmpty }: ISelectProps) =>
    isEmpty ? colors.MISCHKA : colors.BLACK};
  font-weight: ${({ isEmpty }: ISelectProps) => (isEmpty ? 400 : 700)};
  border: 0;
  border-radius: 8px;

  ${({ isError }: ISelectProps) => {
    if (isError) {
      return css`
        box-shadow: inset 0 0 0 2px ${colors.RED};
      `;
    }
    return css`
      box-shadow: inset 0 0 0 1px ${colors.LILAC_WHITE};
    `;
  }}

  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';

  &:focus {
    box-shadow: inset 0 0 0 2px ${colors.BLUE};
  }
`;

const ArrowWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 11px;
  height: 100%;
  max-height: 54px;
  pointer-events: none;
`;

const BaseSelect = forwardRef<any, IProps>((props, ref) => {
  const {
    options,
    placeholder = '',
    id = '',
    name = '',
    defaultValue = '',
    disabled = false,
    isError = false,
    errorMessage = '',
    withErrorIcon = true,
    className,
    onChange,
    onFocus = () => {},
    onBlur = () => {},
  } = props;

  const [isEmptyState, setIsEmptyState] = useState(!defaultValue);
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    onChange(value);
    setIsEmptyState(!value);
  };

  const handleFocus = () => {
    onFocus();
  };

  const handleBlur = () => {
    onBlur();
  };

  const otherProps: { id?: string; name?: string } = {};

  if (id) {
    otherProps.id = id;
  }

  if (name) {
    otherProps.name = name;
  }

  return (
    <>
      <SelectWrapper className={className}>
        <Select
          {...otherProps}
          ref={ref}
          defaultValue={defaultValue}
          disabled={disabled}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          isEmpty={isEmptyState}
          isError={isError}
        >
          {placeholder ? (
            <option key="empty" disabled value="">
              {placeholder}
            </option>
          ) : null}
          {options.map((i: ISelectOption) => (
            <option key={i.value} value={i.value}>
              {i.label}
            </option>
          ))}
        </Select>
        <ArrowWrapper>
          <SvgArrowDown width={11} height={6} fill={colors.BLACK} />
        </ArrowWrapper>
      </SelectWrapper>
      {isError && errorMessage ? (
        <FormFieldError withIcon={withErrorIcon} message={errorMessage} />
      ) : null}
    </>
  );
});

export default BaseSelect;
