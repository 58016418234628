import React from 'react';
import styled from 'styled-components';
import { colors, datetime } from '@/constants';
import { SvgLogoWithoutText } from '../Svg';
import { MenuDivider } from '../shared';
import moment from 'moment';
import Dictionary from './Dictionary';
import { useHistory } from 'react-router-dom';
import INotification, { TAction } from 'src/types/Notification';
import basicFetcher from 'src/api/utils/basicFetcher';
import { AxiosRequestConfig } from 'axios';
import { useNotifications } from '@/data/useNotifications';
import notificationsApi from '../../api/notifications';


const NotificationWrapper = styled.div`
display: flex;
width: 100%;
flex-direction: row;
padding: 10px 10px 10px 10px;
min-height: 50px;
border-radius: 5px;
&: hover {
	background-color: rgba(0,16,61,.04);
  }
`;
const ImageWrapper = styled.img`

border-radius: 50%;
height: 40px;
object-fit: cover;
width: 40px;`;

const SvgWrapper = styled.div`
display: inline-block;
border-radius: 50%;
height: 40px;
width: 40px;`;

const ContentWrapper = styled.div`
display: flex;
flex-direction: column;
margin-left:10px;
width: 100%;`;



const TextWrapper = styled.div`
display: flex;
flex-direction: column;
`;

const ButtonsWrapper = styled.div`
display: flex;
flex-direction: row;
margin-top 10px;
`;

const Button = styled.div`
 margin-left: 40px;
 max-width: 185px;
 &: first-child {
    margin-left: 0;
  }
  &: hover {
	transform: scale(1.02);
	transition: transform 0.11s;
  }
  cursor: pointer
`;



const actionClassNames: Dictionary<string> = {
	'Ok': colors.FRUIT_SALAD,
	'Danger': '#262626',
	'Primay': colors.BLUE
};


const TitleWrapper = styled.div`
display: flex;
position: relative;
flex-directin: row;
justify-content: space-between;
margin-right: 10px;
align-items: center;
`;

const DeleteButtonWrapper = styled.div`
position: absolute;
color: #a0a0a0;
font-size: 14px;
left: 103%;
top: -50%;
font-weight: 600;
`;


export default function Notification(props: INotification) {

	const history = useHistory();
	const { mutate } = useNotifications();

	const isPointer = (url: string | null) => {
		if (url) {
			return { cursor: 'pointer' };
		}
		return {};
	};

	const navigateTo = (url: string | null | undefined) => {
		if (url) {
			history.push(url);
			history.go(0);
		}
	};
	const onClickHandler = async (action: TAction) => {
		if (action.apiAction) {
			await basicFetcher(action.apiAction.apiUrl, {}, { requestConfig: { method: action.apiAction.method.method } as AxiosRequestConfig });
			mutate();

			if (action.afterNavigationAction) {
				navigateTo(action.afterNavigationAction);

			}
		}
	};

	const getDateTime = (time: string | null) => {

		if (!time) {
			return '';
		}
		const givenDate = new Date(time);
		const today = new Date();
		if (givenDate.getDate() === today.getDate()) {
			return `Сегодня в ${moment(time).format(
				datetime.HOUR_MINUTE_FORMAT)}`;

		}
		return moment(props.createdAtUtc).format(
			datetime.DAY_MONTH_TIME_FORMAT);
	};
	const onNotificationDelete = () => {
		notificationsApi.deleteNotification(props.id);
		mutate();
	};

	return (
		<>
			<MenuDivider />
			<NotificationWrapper >
				{props.imageAction?.imagePath ? <ImageWrapper src={props.imageAction.imagePath} onClick={() => navigateTo(props.imageAction?.navigationAction)} style={isPointer(props.imageAction?.navigationAction)} alt="" /> :
					<SvgWrapper>
						<SvgLogoWithoutText
							width={40}
							height={40}
							fill={colors.BLUE}
						/>
					</SvgWrapper>
				}
				<ContentWrapper  >
					<TextWrapper style={isPointer(props.navigationAction)}>
						<TitleWrapper>
							<span onClick={() => navigateTo(props.navigationAction)} style={{ fontSize: 16, fontWeight: 700 }}>{props.title}</span>
							<span onClick={() => navigateTo(props.navigationAction)} style={{ fontSize: 12, color: ' #a0a0a0', alignSelf: 'center' }}>{getDateTime(props.createdAtUtc)}</span>
							<DeleteButtonWrapper onClick={onNotificationDelete}>x</DeleteButtonWrapper>
						</TitleWrapper>
						<span onClick={() => navigateTo(props.navigationAction)} style={{ fontSize: 14, marginTop: 5 }}>{props.message}</span>
					</TextWrapper>

					<ButtonsWrapper >
						{
							props.actions?.map((action, index) => {
								return (
									<Button onClick={() => { onClickHandler(action); }} key={`notification ${index}`} style={{ color: actionClassNames[action.type] }}>
										{action.text}
									</Button>
								);
							})
						}
					</ButtonsWrapper>
				</ContentWrapper>
			</NotificationWrapper>
		</>

	);
}
