import useSWR from 'swr';
import axios from 'axios';
import { commonAPI } from '@/api';
import { createErrorObject } from '@/helpers/createErrorObject';
import { IPromotion } from '@/types';

const usePromotion = (id: number) => {
  const {
    data,
    mutate,
    error,
  }: { data?: IPromotion; mutate: any; error?: any } = useSWR(
    `catalog/promoactions/${id}`,
    commonAPI.fetch,
    {
      revalidateOnFocus: false,
    },
  );

  const isLoading = !data && (!error || axios.isCancel(error));
  const isError = !!error;

  return {
    mutate,
    isLoading,
    isError,
    promotion: data,
    error: error ? createErrorObject(error) : null,
  };
};

export { usePromotion };
