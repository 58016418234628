import React, { FC } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { MainTitle } from '@/components/MainTitle';
import { Article } from '@/components/Article';
import { PropertyList } from '@/components/PropertyList';
import { PosterImage } from '@/components/shared';
import { IPromotion, IPropertyListItem } from '@/types';

interface IProps {
  promotion: IPromotion;
}

const ListWrapper = styled.div`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ListTitleWrapper = styled.div`
  margin-bottom: 16px;
`;

const PosterWrapper = styled.div`
  margin-bottom: 24px;
`;

const Description = styled.div`
  margin-bottom: 40px;
`;

const TitleWrapper = styled.div`
  margin-bottom: 16px;
`;

const PromotionMainContent: FC<IProps> = ({ promotion }: IProps) => {
  const intl = useIntl();

  const tPromotionsDurationRange = intl.formatMessage({
    id: 'promotions.durationRange',
  });
  const tPromoCodesDurationRange = intl.formatMessage({
    id: 'promoCodes.durationRange',
  });
  const tStart = intl.formatMessage({ id: 'common.dateRangeStart' });
  const tEnd = intl.formatMessage({ id: 'common.dateRangeEnd' });
  const tDescription = intl.formatMessage({ id: 'common.description' });

  const pageTitle = promotion?.title || '';
  const image = promotion?.images?.find((i) => i.type === 's690x378');

  const promotionDuration: Array<IPropertyListItem> = [];
  const promoCodeDuration: Array<IPropertyListItem> = [];

  if (promotion?.startDateTime) {
    promotionDuration.push({
      label: tStart,
      value: moment(promotion.startDateTime).format('lll'),
    });
  }

  if (promotion?.endDateTime) {
    promotionDuration.push({
      label: tEnd,
      value: moment(promotion.endDateTime).format('lll'),
    });
  }

  if (promotion?.startPromocodeActivationDateTime) {
    promoCodeDuration.push({
      label: tStart,
      value: moment(promotion.startPromocodeActivationDateTime).format('lll'),
    });
  }

  if (promotion?.endPromocodeActivationDateTime) {
    promoCodeDuration.push({
      label: tEnd,
      value: moment(promotion.endPromocodeActivationDateTime).format('lll'),
    });
  }

  return (
    <>
      {image ? (
        <PosterWrapper>
          <PosterImage fluid={false} src={image?.media.path} alt={pageTitle} />
        </PosterWrapper>
      ) : null}

      {promotionDuration.length ? (
        <ListWrapper>
          <ListTitleWrapper>
            <MainTitle
              text={tPromotionsDurationRange}
              fontSize={16}
              hLevel={6}
            />
          </ListTitleWrapper>
          <PropertyList items={promotionDuration} />
        </ListWrapper>
      ) : null}

      {promoCodeDuration.length ? (
        <ListWrapper>
          <ListTitleWrapper>
            <MainTitle
              text={tPromoCodesDurationRange}
              fontSize={16}
              hLevel={6}
            />
          </ListTitleWrapper>
          <PropertyList items={promoCodeDuration} />
        </ListWrapper>
      ) : null}

      {promotion?.description ? (
        <Description>
          <TitleWrapper>
            <MainTitle text={tDescription} fontSize={24} fontWeight={900} />
          </TitleWrapper>
          <Article markup={promotion.description} />
        </Description>
      ) : null}
    </>
  );
};

export default PromotionMainContent;
