import React, { ChangeEvent, forwardRef } from 'react';
import { cn } from '@bem-react/classname';

import './Checkbox.less';

interface IProps {
  name: string;
  label: string;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
  reversed?: boolean;
}

const cnCheckbox = cn('Checkbox');

const Checkbox = forwardRef<HTMLInputElement, IProps>((props, ref) => {
  const {
    reversed,
    name,
    label,
    checked,
    onChange,
    className,
    disabled,
  } = props;

  return (
    <div className={cnCheckbox()}>
      <input
        ref={ref}
        disabled={disabled}
        className={cnCheckbox('Input')}
        id={name}
        checked={checked}
        onChange={onChange}
        type="checkbox"
        name={name}
      />

      <label
        className={cnCheckbox('Label', { reversed }, [className])}
        htmlFor={name}
      >
        {label}
      </label>
    </div>
  );
});

export default Checkbox;
