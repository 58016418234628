import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgMoneyTransfer: FC<ISvgProps> = (props: ISvgProps) => {
  return (
    <svg viewBox="0 0 42 42" {...props}>
      <defs>
        <clipPath id="b">
          <use xlinkHref="#a"/>
        </clipPath>
        <path d="M2 0h18l.2.01q.1 0 .2.03l.18.05q.1.02.19.06l.17.09q.09.04.17.1l.16.11.14.14.14.14.11.16q.06.08.1.17l.09.17.06.19.05.19.03.2L22 2v12l-.01.2q0 .1-.03.2l-.05.18q-.02.1-.06.19l-.09.17q-.04.09-.1.17l-.11.16-.14.14-.14.14-.16.11q-.08.06-.17.1l-.17.09-.19.06-.19.05-.2.03L20 16H2l-.2-.01q-.1 0-.2-.03l-.18-.05q-.1-.02-.19-.06l-.17-.09q-.09-.04-.17-.1l-.16-.11-.14-.14-.14-.14-.11-.16q-.06-.08-.1-.17l-.09-.17-.06-.19-.05-.19-.03-.2L0 14V2l.01-.2q0-.1.03-.2l.05-.18q.02-.1.06-.19l.09-.17Q.28.97.34.89L.45.73.6.6.73.45.9.34q.08-.06.17-.1l.17-.09.19-.06.19-.05.2-.03L2 0z" id="a"/>
      </defs>
      <path d="M41 21a20 20 0 10-40 0 20 20 0 0040 0" fill="#00E676"/>
      <path d="M12 13h18l.2.01q.1 0 .2.03l.18.05q.1.02.19.06l.17.09q.09.04.17.1l.16.11.14.14.14.14.11.16q.06.08.1.17l.09.17.06.19.05.19.03.2.01.19v12l-.01.2q0 .1-.03.2l-.05.18q-.02.1-.06.19l-.09.17q-.04.09-.1.17l-.11.16-.14.14-.14.14-.16.11q-.08.06-.17.1l-.17.09-.19.06-.19.05-.2.03L30 29H12l-.2-.01q-.1 0-.2-.03l-.18-.05q-.1-.02-.19-.06l-.17-.09q-.09-.04-.17-.1l-.16-.11-.14-.14-.14-.14-.11-.16q-.06-.08-.1-.17l-.09-.17-.06-.19-.05-.19-.03-.2L10 27V15l.01-.2q0-.1.03-.2l.05-.18q.02-.1.06-.19l.09-.17q.04-.09.1-.17l.11-.16.14-.14.14-.14.16-.11q.08-.06.17-.1l.17-.09.19-.06.19-.05.2-.03L12 13z" fill="none"/>
      <g clip-path="url(#b)" transform="translate(10 13)">
        <path d="M2 0h18l.2.01q.1 0 .2.03l.18.05q.1.02.19.06l.17.09q.09.04.17.1l.16.11.14.14.14.14.11.16q.06.08.1.17l.09.17.06.19.05.19.03.2L22 2v12l-.01.2q0 .1-.03.2l-.05.18q-.02.1-.06.19l-.09.17q-.04.09-.1.17l-.11.16-.14.14-.14.14-.16.11q-.08.06-.17.1l-.17.09-.19.06-.19.05-.2.03L20 16H2l-.2-.01q-.1 0-.2-.03l-.18-.05q-.1-.02-.19-.06l-.17-.09q-.09-.04-.17-.1l-.16-.11-.14-.14-.14-.14-.11-.16q-.06-.08-.1-.17l-.09-.17-.06-.19-.05-.19-.03-.2L0 14V2l.01-.2q0-.1.03-.2l.05-.18q.02-.1.06-.19l.09-.17Q.28.97.34.89L.45.73.6.6.73.45.9.34q.08-.06.17-.1l.17-.09.19-.06.19-.05.2-.03L2 0h0z" fill="none" stroke="#FFF" stroke-width="2"/>
      </g>
      <g fill="none" stroke="#FFF" stroke-linecap="round">
        <path d="M26 23.5H13.5l2-2" stroke-linejoin="round"/>
        <path d="M15.5 25.5l-2-2"/>
      </g>
      <g fill="none" stroke="#FFF" stroke-linecap="round">
        <path d="M16 18.5h12.5l-2-2" stroke-linejoin="round"/>
        <path d="M26.5 20.5l2-2"/>
      </g>
    </svg>
  );
};

export default SvgMoneyTransfer;
