import { useCategories } from '@/data/useCategories';
import { useBrands } from '@/data/useBrands';
import {
  getFilterControlOptions,
  getSubcategoriesFilterControlOptions,
} from '@/helpers/getFilterControlOptions';

const useSearchFilterOptions = (categoryId: string) => {
  const { brands } = useBrands(categoryId);
  const { categories } = useCategories();

  const brandsOptions = getFilterControlOptions(brands);
  const categoriesOptions = getFilterControlOptions(categories);
  const subcategoriesOptions = getSubcategoriesFilterControlOptions(
    categories,
    categoryId,
  );

  return {
    brandsOptions,
    categoriesOptions,
    subcategoriesOptions,
  };
};

export { useSearchFilterOptions };
