import React, { FC } from 'react';
import { connect } from 'react-redux';
import { SignInScene, IPropsSignInScene } from '@/scenes/SignInScene';
import { phoneAuthActions, authActions } from '@/store/actions';
import { phoneAuthSelectors, authSelectors } from '@/store/selectors';
import { IReduxState } from '@/types';

interface IProps extends IPropsSignInScene {
  insideModal?: boolean;
  onSuccessAuth?: () => void;
}

const {
  setPhoneNumber,
  signInWithPhoneNumber,
  verifySmsCode,
  signInWithTinkoffToken,
} = phoneAuthActions;

const { setAuthActiveStage, setAuthActiveMode } = authActions;

const SignUpContainer: FC<IProps> = (props) => <SignInScene {...props} />;

const mapStateToProps = (state: IReduxState) => ({
  phoneNumber: phoneAuthSelectors.phoneNumber(state),
  confirmationResult: phoneAuthSelectors.confirmationResult(state),
  loadingSignIn: phoneAuthSelectors.loadingSignIn(state),
  loadingCodeVerification: phoneAuthSelectors.loadingCodeVerification(state),
  errorSignIn: phoneAuthSelectors.errorSignIn(state),
  errorCodeVerification: phoneAuthSelectors.errorCodeVerification(state),
  activeStage: authSelectors.activeSignInStage(state),
});

const mapDispatchToProps = {
  signInWithPhoneNumber,
  verifySmsCode,
  signInWithTinkoffToken,
  setPhoneNumber,
  setAuthActiveStage,
  setAuthActiveMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpContainer);
