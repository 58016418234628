import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BaseCSS } from 'styled-bootstrap-grid';
import { ThemeProvider } from 'styled-components';
import LocaleProvider from '@/containers/LocaleProvider';
import AuthProvider from '@/containers/AuthProvider';
import SearchFiltersProvider from '@/containers/SearchFiltersProvider';
import { App } from '@/components/App';
import { store, persistor } from '@/store/index';
import { breakpoints } from '@/constants';
import * as serviceWorker from './serviceWorker';
import '@/styles/global.css';
import 'moment/locale/ru';
import 'moment/locale/en-gb';
import * as Sentry from '@sentry/react';

const theme = {
  breakpoints,
};



Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


ReactDOM.render(
  <React.StrictMode>
    <BaseCSS />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LocaleProvider>
          <AuthProvider>
            <SearchFiltersProvider>
              <ThemeProvider theme={theme}>
                <App />
              </ThemeProvider>
            </SearchFiltersProvider>
          </AuthProvider>
        </LocaleProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
