
import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgTelegramBlack: FC<ISvgProps> = (props: ISvgProps) => {
	return (
		<svg viewBox="0 0 256 256" {...props}>
			<path d="M229.95117,33.252a13.96536,13.96536,0,0,0-14.2539-2.42187L31.14014,103.335a14.00006,14.00006,0,0,0,2.37353,26.75879L82,139.79055V199.9844a13.99436,13.99436,0,0,0,23.89941,9.89843L133.169,182.61355,173.9541,218.5049a13.88378,13.88378,0,0,0,9.20215,3.502,14.14473,14.14473,0,0,0,4.34277-.68848,13.88364,13.88364,0,0,0,9.34961-10.19726L234.46191,46.9883A13.967,13.967,0,0,0,229.95117,33.252ZM34.26709,116.542a1.85417,1.85417,0,0,1,1.26074-2.03711L189.95557,53.83645,86.60938,128.47487,35.8667,118.32619A1.85412,1.85412,0,0,1,34.26709,116.542Zm63.147,84.85645A1.99983,1.99983,0,0,1,94,199.9844V148.14431l30.14258,26.52588Zm87.7378,7.042a2.00059,2.00059,0,0,1-3.271,1.05567L97.60742,135.33474l124.9834-90.26538Z" />
		</svg>



	);
};

export default SvgTelegramBlack;
