







import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgLittleGreenTriangle: FC<ISvgProps> = (props: ISvgProps) => {
	return (
		<svg viewBox="0 0 74 73" {...props}>
			<g filter="url(#filter0_d_4388_15430)">
				<path fillRule="evenodd" clipRule="evenodd" d="M47.0571 51.1651C46.0946 56.6674 39.2635 58.7094 35.4391 54.638L10.7203 28.3222C7.27025 24.6493 8.8916 18.6298 13.7196 17.1865L44.6598 7.93779C49.4878 6.49457 54.1468 10.6368 53.2785 15.6005L47.0571 51.1651Z" fill="url(#paint0_linear_4388_15430)" />
			</g>
			<path fillRule="evenodd" clipRule="evenodd" d="M49.6549 50.3887C48.6923 55.891 41.8612 57.933 38.0368 53.8616L13.318 27.5458C9.868 23.8729 11.4893 17.8534 16.3174 16.4102L47.2576 7.16142C52.0856 5.71821 56.7445 9.86041 55.8762 14.8241L49.6549 50.3887Z" fill="#00E676" />
			<defs>
				<filter id="filter0_d_4388_15430" x="0.337644" y="0.244704" width="67.281" height="71.9112" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
					<feOffset dx="2.84703" dy="3.98584" />
					<feGaussianBlur stdDeviation="5.69405" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 1 0 0 0 0 0.803922 0 0 0 0.29693 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4388_15430" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4388_15430" result="shape" />
				</filter>
				<linearGradient id="paint0_linear_4388_15430" x1="70.6997" y1="56.7539" x2="55.1684" y2="4.79652" gradientUnits="userSpaceOnUse">
					<stop stopColor="#4AFFDC" />
					<stop offset="1" stopColor="#2EBEFF" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default SvgLittleGreenTriangle;


