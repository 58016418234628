import React, { FC } from 'react';
import { ISvgColorfulProps } from '@/types';

const SvgGooglePay: FC<ISvgColorfulProps> = (props: ISvgColorfulProps) => {
  return (
    <svg viewBox="0 0 25 25" {...props}>
      <g fill="none">
        <path
          fill="#4285F4"
          d="M24.005 12.505c0-.833-.093-1.667-.186-2.5H12.245v4.722h6.574c-.277 1.482-1.11 2.87-2.407 3.704v3.055h3.982c2.314-2.13 3.61-5.277 3.61-8.981z"
        />
        <path
          fill="#34A853"
          d="M12.245 24.45c3.334 0 6.111-1.112 8.149-2.964l-3.982-3.055c-1.111.74-2.5 1.204-4.167 1.204-3.148 0-5.926-2.13-6.851-5.093H1.319v3.148c2.13 4.167 6.297 6.76 10.926 6.76z"
        />
        <path
          fill="#FBBC04"
          d="M5.394 14.542c-.556-1.481-.556-3.148 0-4.722V6.672H1.319c-1.759 3.426-1.759 7.5 0 11.018l4.075-3.148z"
        />
        <path
          fill="#EA4335"
          d="M12.245 4.82c1.76 0 3.426.648 4.723 1.852l3.518-3.519c-2.222-2.037-5.185-3.24-8.148-3.148-4.63 0-8.889 2.593-10.926 6.76l4.074 3.147C6.32 6.95 9.097 4.82 12.246 4.82z"
        />
      </g>
    </svg>
  );
};

export default SvgGooglePay;
