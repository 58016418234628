import React from 'react';
import { useIntl } from 'react-intl';

import { cn } from '@bem-react/classname';

import { Button } from '@/components/Buttons';

import './BillWithdraw.less';

const cnBillWithdraw = cn('BillWithdraw');

const BillWithdraw = () => {
  const intl = useIntl();

  const tTitle = intl.formatMessage({
    id: 'bill.withdraw.title',
  });

  const tText = intl.formatMessage({
    id: 'bill.withdraw.text',
  });

  const tGoToProfile = intl.formatMessage({
    id: 'bill.withdraw.goToProfile',
  });

  return (
    <div className={cnBillWithdraw()}>
      <h2 className={cnBillWithdraw('Title')}>{tTitle}</h2>

      <p className={cnBillWithdraw('Text')}>{tText}</p>

      <div>
        <Button
          to="/account"
          className={cnBillWithdraw('Button')}
          displayType="primary"
        >
          {tGoToProfile}
        </Button>
      </div>
    </div>
  );
};

export default BillWithdraw;
