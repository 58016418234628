import React, { FC, useEffect, useMemo, useState } from 'react';

import { Context, AutocompleteContext } from './context';

interface IProps {
  onChange: (val: any) => any;
  getOptionsScheme: any;
  fetchOptions: any;
  controlledValue: any;
}

const AutocompleteProvider: FC<IProps> = (props) => {
  const {
    controlledValue,
    children,
    onChange,
    getOptionsScheme,
    fetchOptions,
  } = props;
  const [value, setValue] = useState(controlledValue);
  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => setValue(controlledValue), [controlledValue]);

  const providerValue: Context = useMemo(
    () => ({
      value,
      setValue,
      options,
      setOptions,
      onChange,
      fetchOptions,
      getOptionsScheme,
    }),
    [fetchOptions, getOptionsScheme, onChange, options, value],
  );

  return (
    <AutocompleteContext.Provider value={providerValue}>
      {children}
    </AutocompleteContext.Provider>
  );
};

export default AutocompleteProvider;
