import { IPromotionCategory, ISelectOption } from '@/types';

interface IItem {
  id: string | number;
  title: string;
}

const getFilterControlOptions = (items: IItem[]): ISelectOption[] =>
  items.map((i) => {
    return {
      value: i.id.toString(),
      label: i.title,
    };
  });

const getSubcategoriesFilterControlOptions = (
  items: IPromotionCategory[],
  categoryId?: string,
): ISelectOption[] => {
  if (categoryId) {
    const category = items.find((i) => i.id.toString() === categoryId);

    return category
      ? category.subcategories.map((i) => {
        return {
          value: i.id.toString(),
          label: i.title,
        };
      })
      : [];
  }

  return [];
};

export { getFilterControlOptions, getSubcategoriesFilterControlOptions };
