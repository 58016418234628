import React, { useState, useEffect, SyntheticEvent } from 'react';
import { cn } from '@bem-react/classname';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Container } from 'styled-bootstrap-grid';
import moment from 'moment';

import { IMe, TSex } from '@/types';

import { datetime } from '@/constants';

import { useAuth } from '@/data/useAuth';
import { updateProfile, useUser } from '@/data/useUser';
import { uploadMedia } from '@/data/useMedia';

import { Layout } from '@/components/Layout';
import { UploadAvatar } from '@/components/UploadAvatar';

import { Button } from '@/components/Buttons';
import { BaseInput } from '@/components/FormControls';
import { RadioButton } from '@/components/RadioButton';
import { Loader } from '@/components/Loader';
import { Modal } from '@/components/Modal';
import { SuccessChanges } from '@/components/SuccessChanges';
import { tinkoffButton } from '@/components/Buttons/TinkoffButton';
import matchAccountApi from '../../api/matchAccount';
import './AccountStatusPage.less';
import smsAuth from 'src/api/smsAuth';
import { commonAPI } from '@/api';
import FormFieldError from '@/components/FormControls/FormFieldError';

const b = cn('AccountStatusPage');

const AccountStatusPage = () => {
  const { isAuth } = useAuth();
  const { formatMessage } = useIntl();
  const { mutate } = useUser();
  const { register, handleSubmit, setValue } = useForm();
  const [user, setUser] = useState<IMe>();

  const [sex, setSex] = useState(user?.profile?.sex || '');
  const [isDeleteMedia, setDeleteMedia] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user?.profile?.sex) {
      setSex(user.profile.sex);
    }
  }, [user]);

  const handleSexChange = (e: SyntheticEvent<HTMLInputElement>) => {
    setSex(e.currentTarget.value as TSex);
  };

  const setTinkoffValue = (option: IMe) => {

    setValue('firstName', option.name);
    setValue('middleName', option.profile.middleName);
    setValue('lastName', option.profile.lastName);
    setValue('birthDate', option.profile.birthDate);
    setSex(option.profile.sex!);
  };

  const messageHandler = async (event: MessageEvent) => {
    if (event.origin === process.env.REACT_APP_TINKOFF_AUTH_ORIGIN) {
      const eventData = JSON.parse(event.data);

      const response = await smsAuth.verifyTinkoffCode({ code: eventData.code });

      const token = await response.data.accessToken;
      matchAccountApi.MatchAccountConfirm({
        token,
        grantType: 'TinkoffId'
      }).then(() => commonAPI.fetch('me').then(data => {
        setIsLoading(true);
        setUser(data);
        setTinkoffValue(data);


      }
      ).finally(() => setIsLoading(false)));

      setOpenSuccessModal(true);

    }
  };



  useEffect(() => {
    tinkoffButton({ size: 'm', text: 'Заполнить с Tinkoff' });

    setIsLoading(true);

    commonAPI.fetch('me').then(response => {
      setUser(response);
    }
    ).finally(() => setIsLoading(false));
  }, []);



  useEffect(() => {
    window.addEventListener('message', messageHandler);

    return function () {
      window.removeEventListener('message', messageHandler);
    };

  }, []);

  const onSubmit = async (data: any) => {
    let pictureId = null;
    const { media, ...restData } = data;

    if (media.length) {
      const mediaData = new FormData();
      mediaData.append('file', media[0]);


      const { id } = await uploadMedia(mediaData);
      pictureId = id;
    }

    const birthDate = moment(data.birthDate).format(
      datetime.BACKEND_DATE_FORMAT,
    );

    const backendData = { ...restData };

    if (moment(birthDate).isValid()) {
      backendData.birthDate = birthDate;
    }

    if (!isDeleteMedia && pictureId) {
      backendData.pictureId = pictureId;
    }

    if (isDeleteMedia) {
      backendData.pictureId = '';
    }

    const newProfile = await updateProfile(backendData).catch((e: Error) => { setError(`Не удалось сохранить данные: ${e.message}`); });



    if (
      data.media.length ||
      newProfile.firstName !== user?.profile?.firstName ||
      newProfile.lastName !== user?.profile?.lastName
    ) {
      mutate('/me', (me: IMe) => ({ ...me, profile: newProfile }));
    }

    setOpenSuccessModal(true);
  };

  const handleMediaClear = () => setDeleteMedia(true);
  const handleMediaChange = () => setDeleteMedia(false);

  const tLabelName = formatMessage({ id: 'status.labels.name' });
  const tLabelMiddleName = formatMessage({ id: 'status.labels.middleName' });
  const tLabelSurname = formatMessage({ id: 'status.labels.surname' });
  const tLabelBirthDate = formatMessage({ id: 'status.labels.birthDate' });
  const tLabelSex = formatMessage({ id: 'status.labels.sex' });
  const tMale = formatMessage({ id: 'status.genders.male' });
  const tFemale = formatMessage({ id: 'status.genders.female' });
  const tPlaceholderName = formatMessage({ id: 'status.placeholders.name' });
  const tPlaceholderMiddleName = formatMessage({
    id: 'status.placeholders.middleName',
  });
  const tPlaceholderSurname = formatMessage({
    id: 'status.placeholders.surname',
  });
  const tSave = formatMessage({ id: 'status.save' });

  return (
    <Layout
      withBreadcrumbs
      isAuth={isAuth}
      i18nSeoTitle="seo.title.status"
      i18nTitle="common.status"
    >
      <Container className={b()}>
        {isLoading && (
          <div className={b('Loader-Wrapper')}>
            <Loader size={52} />
          </div>
        )}
        <div className={b('TinkoffButton')}>
          <div id='container-for-tid-button' ></div>
        </div>

        {!isLoading && (
          <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
            <div className={b('Row')}>
              <div className={b('Main-Col')}>
                <div className={b('Block')}>
                  <label className={b('Label')} htmlFor="name">
                    {tLabelName}
                  </label>

                  <BaseInput
                    ref={register}
                    className={b('Field')}
                    defaultValue={user?.profile?.firstName}
                    id="name"
                    name="firstName"
                    placeholder={tPlaceholderName}
                  />
                </div>

                <div className={b('Block')}>
                  <label className={b('Label')} htmlFor="middleName">
                    {tLabelMiddleName}
                  </label>

                  <BaseInput
                    ref={register}
                    className={b('Field')}
                    defaultValue={user?.profile?.middleName}
                    id="middleName"
                    name="middleName"
                    placeholder={tPlaceholderMiddleName}
                  />
                </div>

                <div className={b('Block')}>
                  <label className={b('Label')} htmlFor="surname">
                    {tLabelSurname}
                  </label>

                  <BaseInput
                    ref={register}
                    className={b('Field')}
                    defaultValue={user?.profile?.lastName}
                    name="lastName"
                    id="surname"
                    placeholder={tPlaceholderSurname}
                  />
                </div>

                <div className={b('Block')}>
                  <label className={b('Label')} htmlFor="birthDate">
                    {tLabelBirthDate}
                  </label>

                  <BaseInput
                    ref={register}
                    className={b('Field')}
                    defaultValue={
                      user?.profile?.birthDate &&
                      moment(user?.profile?.birthDate).format(
                        datetime.BACKEND_DATE_FORMAT,
                      )
                    }
                    type="date"
                    name="birthDate"
                    id="birthDate"
                  />
                </div>

                <div className={b('Block')}>
                  <span className={b('Label')}>{tLabelSex}</span>

                  <div className={b('Radio-Group')}>
                    <RadioButton
                      ref={register}
                      id="female"
                      name="sex"
                      currentValue={sex}
                      value="Female"
                      className={b('Radio')}
                      onChange={handleSexChange}
                    >
                      {tFemale}
                    </RadioButton>

                    <RadioButton
                      ref={register}
                      id="male"
                      name="sex"
                      currentValue={sex}
                      value="Male"
                      className={b('Radio')}
                      onChange={handleSexChange}
                    >
                      {tMale}
                    </RadioButton>
                  </div>
                </div>
              </div>

              <UploadAvatar
                ref={register}
                src={user?.profile?.media?.path}
                onClear={handleMediaClear}
                onChange={handleMediaChange}
                name="media"
              />
            </div>

            <Button className={b('Button')} displayType="primary" type="submit">
              {tSave}
            </Button>
            {error && <FormFieldError message={error} />}
          </form>
        )}
      </Container>

      <Modal
        visible={openSuccessModal}
        onCancel={() => setOpenSuccessModal(false)}
      >
        <SuccessChanges onClose={() => setOpenSuccessModal(false)} />
      </Modal>
    </Layout>
  );
};

export default AccountStatusPage;
