import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';

interface IPosterImageProps {
  fluid?: boolean;
}

interface IPageContentProps {
  isMap?: boolean;
  mobileDeviceMode?: boolean;
}

const Page = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const PageContent = styled.div<IPageContentProps>`
  margin-top: 62px;
  flex: 1;

  ${down('md')} {
    margin-top: 120px;
  }

  ${({ isMap, mobileDeviceMode }: IPageContentProps) => {
    if (isMap && !mobileDeviceMode) {
      return css`
        ${down('lg')} {
          margin-top: 0;
          width: 100%;
          height: 100vh;
        }
      `;
    }
    if (isMap && mobileDeviceMode) {
      return css`
        ${down('lg')} {
          margin-top: 0;
          flex: 0;
        }
      `;
    }
    return '';
  }}
`;

const SidebarBlock = styled.div`
  margin-bottom: 40px;
`;

const PosterImage = styled.img<IPosterImageProps>`
  display: block;
  width: ${({ fluid = true }: IPosterImageProps) => (fluid ? '100%' : 'auto')};
  max-width: 100%;
  border-radius: 16px;
`;

export { Page, PageContent, SidebarBlock, PosterImage };
