



import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgWhiteTriangle: FC<ISvgProps> = (props: ISvgProps) => {
	return (
		<svg viewBox="0 0 373 373" {...props}>
			<path fillRule="evenodd" clipRule="evenodd" d="M19.5162 156.074C-5.20264 168.434 -5.20263 203.709 19.5162 216.068L324.464 368.542C346.763 379.692 373 363.476 373 338.545V33.5974C373 8.66607 346.763 -7.54927 324.464 3.60034L19.5162 156.074Z" fill="white" />
		</svg>





	);
};

export default SvgWhiteTriangle;