import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from '@/constants';

interface IProps {
  text: string;
  hLevel?: number;
  fontSize?: number;
  fontWeight?: number;
  className?: string;
}

interface IBaseTitleProps {
  fontSize: number;
  fontWeight: number;
}

const BaseTitle = styled.span<IBaseTitleProps>`
  font-family: Lato;
  font-size: ${({ fontSize }: IBaseTitleProps) => fontSize}px;
  font-weight: ${({ fontWeight }: IBaseTitleProps) => fontWeight};
  color: ${colors.BLACK};
`;

const Title1 = BaseTitle.withComponent('h1');
const Title2 = BaseTitle.withComponent('h2');
const Title3 = BaseTitle.withComponent('h3');
const Title4 = BaseTitle.withComponent('h4');
const Title5 = BaseTitle.withComponent('h5');
const Title6 = BaseTitle.withComponent('h6');

const getTitle = (level: number) => {
  switch (level) {
    case 1:
      return Title1;
    case 2:
      return Title2;
    case 3:
      return Title3;
    case 4:
      return Title4;
    case 5:
      return Title5;
    default:
      return Title6;
  }
};

const MainTitle: FC<IProps> = ({
  text,
  hLevel = 0,
  fontSize = 30,
  fontWeight = 700,
  className,
}: IProps) => {
  const Title = hLevel > 0 && hLevel < 7 ? getTitle(hLevel) : BaseTitle;

  return (
    <Title className={className} fontSize={fontSize} fontWeight={fontWeight}>
      {text}
    </Title>
  );
};

export default MainTitle;
