import * as yup from 'yup';

type TValidateBaseProps = {
  errorText: string;
  errorRequiredText: string;
  errorLimitNumbers: string;
};

type TValidateAccountNumberProps = TValidateBaseProps & {
  bic?: string;
};

type TValidateCorrespondentNumberProps = TValidateAccountNumberProps;

export const validateAccountNumber = ({
  bic = 'bic',
  errorText,
  errorRequiredText,
  errorLimitNumbers,
}: TValidateAccountNumberProps) =>
  yup
    .string()
    .required(errorRequiredText)
    .length(20, errorLimitNumbers)
    .test('test-account-number', errorText, function validate(value) {
      if (!value) {
        return false;
      }

      const bicRs = this.parent[bic].slice(-3) + value;
      let checksum = 0;

      const coefficients = [
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
      ];

      coefficients.forEach((coef, i) => {
        checksum += coef * (Number(bicRs[i]) % 10);
      });

      return checksum % 10 === 0;
    });

export const validateCorrespondentNumber = ({
  bic = 'bic',
  errorText,
  errorRequiredText,
  errorLimitNumbers,
}: TValidateCorrespondentNumberProps) =>
  yup
    .string()
    .required(errorRequiredText)
    .length(20, errorLimitNumbers)
    .test('test-account-number', errorText, function validate(value) {
      if (!value) {
        return false;
      }

      const bicKs = `0${this.parent[bic].slice(4, 6)}${value}`;
      let checksum = 0;

      const coefficients = [
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
      ];

      coefficients.forEach((coef, i) => {
        checksum += coef * (Number(bicKs[i]) % 10);
      });

      return checksum % 10 === 0;
    });

export const validateNaturalPersonInn = ({
  errorText,
  errorRequiredText,
  errorLimitNumbers,
}: TValidateBaseProps) =>
  yup
    .string()
    .required(errorRequiredText)
    .length(12, errorLimitNumbers)
    .test('test-natural-inn', errorText, function validate(value) {
      if (!value) {
        return false;
      }

      const checkDigit = (inn: string, coefficients: number[]) => {
        let n = 0;

        coefficients.forEach((coef, i) => {
          n += coef * Number(inn[i]);
        });

        return parseInt(String((n % 11) % 10), 10);
      };

      const n11 = checkDigit(value, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
      const n12 = checkDigit(value, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);

      return n11 === parseInt(value[10], 10) && n12 === parseInt(value[11], 10);
    });

export const validateOgrnip = ({
  errorText,
  errorRequiredText,
  errorLimitNumbers,
}: TValidateBaseProps) =>
  yup
    .string()
    .required(errorRequiredText)
    .length(15, errorLimitNumbers)
    .test('test-ogrnip', errorText, function validate(value) {
      if (!value) {
        return false;
      }

      const n15 = parseInt(
        (parseInt(value.slice(0, -1), 10) % 13).toString().slice(-1),
        10,
      );

      return n15 === parseInt(value[14], 10);
    });
