import { colors } from '@/constants';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { SvgUser } from '../Svg';
import Modal from './Modal';
import matchAccountApi from 'src/api/matchAccount';

interface IProps {
  name: string;
  phone: string;
  onSuccess: () => void;
  token: string;
}


const Wrapper = styled.div`
  width: 330px;
  display: flex;
  flex-direction: column;
`;
const Question = styled.div`
  margin-top: 20px;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  color: ${colors.MONSOON};
`;

const UserPhotoNameWrapper = styled.div`
display: flex;
flex-direction: row;
margin-top: 25px;


`;

const UserPhoto = styled.div`
width: 40px;

display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFDD2D;
  border-radius: 99%;
  overflow: hidden;

`;

const UserNameWrapper = styled.div`
display: flex;
flex-direction: column;
margin-left: 8px;


`;

const TinkoffText = styled.div`
color: ${colors.MONSOON};
font-size: 14px;
  font-weight: normal;
  line-height: normal;



`;

const UserName = styled.div`
color: #242424;

`;



const TitleWrapper = styled.div`
 
  text-align: center;
  font-size:20px;
`;
const ConfirmMatchButtonCss = css`
  font-size: 14px;
  font-weight: normal;
  line-height: 40px;
  color: ${colors.BLACK};
  background-color: ${colors.TINKOFF_YELLOW};
  border: 0;
  cursor: pointer;
  margin-top 15px;
  border-radius: 6px;
  
  

  &:hover {
    background-color: #FCC521;
  }
`;

const ConfirmMatchButton = styled.button`
  ${() => ConfirmMatchButtonCss}
`;


const SkipMatchButtonCss = css`
  font-size: 14px;
  font-weight: normal;
  line-height: 40px;
  color: ${colors.BLACK};
  background-color: ${colors.TINKOFF_GRAY};
  border: 0;
  cursor: pointer;
  margin-top 15px;
  border-radius: 6px;
  
  

  &:hover {
    background-color: #EBEBEB;
  }
`;




const SkipMatchButton = styled.button`
  ${() => SkipMatchButtonCss}
`;





export default function TinkoffMatchModal({ onSuccess, phone, name, token }: IProps) {
  const [isVisible, setVisible] = useState(true);

  const handleClose = () => {



    setTimeout(() => {
      setVisible(false);
      onSuccess();
      localStorage.removeItem('tinkoffMatchData');
    }, 0);
  };


  const handleConfirm = () => {
    matchAccountApi.MatchAccountConfirm({
      token,
      grantType: 'TinkoffId'
    });
    localStorage.removeItem('tinkoffMatchData');
    setVisible(false);
  };



  return (
    <Modal visible={isVisible} onCancel={handleClose}>
      <Wrapper>
        <TitleWrapper>
          Вы входите в Fartsa
        </TitleWrapper>
        <Question>Возможно вы уже когда-то регистрировались в этом приложении с вашего номера телефона: {phone}</Question>
        <UserPhotoNameWrapper>
          <UserPhoto>
            <SvgUser fill={'#333333'} width="50%" />
          </UserPhoto>
          <UserNameWrapper>
            <UserName>{name}</UserName>
            <TinkoffText>Tinkoff</TinkoffText>
          </UserNameWrapper>
        </UserPhotoNameWrapper>
        <Question>Для вашего удобства мы обьединим учетные записи</Question>
        <ConfirmMatchButton onClick={handleConfirm} >Объединить</ConfirmMatchButton>
        <SkipMatchButton onClick={handleClose}>Пропустить</SkipMatchButton>



      </Wrapper>
    </Modal>
  );
}
