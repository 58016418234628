import { produce } from 'immer';

interface IAction {
  type: string;
  payload?: any;
}

const INITIAL_STATE = {
  locale: 'ru',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = INITIAL_STATE, action: IAction) =>
  produce(state, (draft) => { // eslint-disable-line
    const { type } = action;

    switch (type) {
      default:
        return state;
    }
  });
