
import { SvgLogo } from '@/components/Svg';
import React from 'react';
import styled from 'styled-components';
import optionMan from '../../images/OptionPageMan.png';
import optionGirl from '../../images/OptionPageGirl.png';
import { cn } from '@bem-react/classname';
import { Button } from '@/components/Buttons';
import './OptionPage.less';
import purpleBox from '../../images/purpleBox.png';
import greenBox from '../../images/greenBox.png';
import blueRocket from '../../images/blueRocket.png';
import yellowStar from '../../images/yellowStar.png';
import { useIntl } from 'react-intl';
import LandingFooter from '@/components/LandingFooter/LandingFooter';



const OptionPageContainer = styled.div`
background-color: #F8F9FF;
display: flex;
flex-direction: column;
justify-content: space-between;
height: 1080px;
@media (max-width: 768px) {
	height: 100%;
	
  }

`;
const OptionContainer = styled.div`
margin-left: 10%;
margin-right: 10%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding-top: 50px;
@media (max-width: 768px) {
	margin-left: 5%;
margin-right: 5%;
	
  }

`;

const OptionCardsContainer = styled.div`
display: flex;
flex-direction: row;
gap: 50px;
margin-top: 65px;
@media (max-width: 768px) {
	flex-direction: column;
	
  }
`;
const BlueHoverContainer = styled.div`
        margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		text-align: center;
		border: none;
		bottom: -30%;
		z-index: 0;
		
		position: absolute;
		width: 418px;
		height: 418px;
	border-radius: 50%;
	@media (max-width: 768px) {
		width: 350px;
		height: 350px;
		left: -2%;
	  }
		
`;
const GreenHoverContainer = styled.div`
        margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		text-align: center;
		border: none;
		bottom: -30%;
		z-index: 0;
		
		position: absolute;
		width: 418px;
		height:  418px;
	border-radius: 50%;
	@media (max-width: 768px) {
		width: 350px;
		height: 350px;
		left: -2%;
	  }
		
`;

const OptionBusinessCard = styled.div`
display: flex;
flex-direction: column;
background-color: #FFFFFF;
border-radius: 40px;
align-items: center;
padding-left: 5%;
padding-right: 5%;
padding-top: 5%;

overflow: hidden;
&:hover ${BlueHoverContainer} {
	background-color: #304FFE;
	transition: 400ms;

  }
  @media (max-width: 768px) {

	margin-left: 5%;
margin-right: 5%;
margin-bottom: 20px;
	
  }

`;
const OptionAmbassadorCard = styled.div`
display: flex;

flex-direction: column;
background-color: #FFFFFF;
border-radius: 40px;
align-items: center;
padding-left: 5%;
padding-right: 5%;
padding-top: 5%;
overflow: hidden;
&:hover ${GreenHoverContainer} {
	background-color: #00E676;
	transition: 400ms;

  }
  @media (max-width: 768px) {
	margin-top: 50px;
	margin-left: 5%;
margin-right: 5%;

  }


`;
const OptionCardTitle = styled.div`
font-weight: 900;
font-size: 44px;
line-height: 60px;
`;

const OptionCardText = styled.div`
font-weight: 400;
font-size: 18px;
color: #76767A;
line-height: 28px;
margin-top: 40px;
`;

const OptionImg = styled.img`
 display: block;
  max-width: 400px;
  height: 352px;
   scale: Crop;
   z-index: 5;
   @media (max-width: 768px) {
	  max-width: 385px;
  }
   

`;

const OptionImgContainer = styled.div`
width: 100%;
margin-top: 30px;
position: relative;
justify-content: center;
display: flex;
border-radius: 40%;
align-items: center;
z-index: 1;

  


`;


const GreenBoxContainer = styled.img`
        margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 50%;
		text-align: center;
		border: none;
  	    top: 35%;
		z-index: 4;
		position: absolute;
		width: 80px;
		height:  80px;
		@media (max-width: 768px) {
			right: 75%;
	  
		  
		}

		
`;
const BlueRocketContainer = styled.img`
        margin-left: auto;
		margin-right: auto;
		left: 60%;
		right: 0;
		text-align: center;
		border: none;
  	    top: 12%;
		z-index: 4;
		position: absolute;
		width: 170px;
		height:  170px;
		@media (max-width: 768px) {
			left: 55%;
	  
		  
		}

		
`;
const PurpleBoxContainer = styled.img`
        margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 75%;
		text-align: center;
		border: none;
  	    top: 20%;
		z-index: 4;
		position: absolute;
		width: 80px;
		height:  80px;
		@media (max-width: 768px) {
			right: 85%;
	  
		  
		}

		
`;
const YellowStarContainer = styled.img`
        margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 12%;
		text-align: center;
		border: none;
  	    top: 0%;
		z-index: 4;
		position: absolute;
		width: 133px;
		height:  133px;
		@media (max-width: 768px) {
			left: 20%;
	  
		  
		}

		
`;


export const cnOptionPage = cn('OptionPage');

export default function OptionPage() {

	const intl = useIntl();

	const tBusinessCardTitle = intl.formatMessage({
		id: 'optionPage.BusinessCardTitle',
	});
	const tAmbassadorCardTitle = intl.formatMessage({
		id: 'optionPage.AmbassadorCardTitle',
	});
	const tBusinessCardText = intl.formatMessage({
		id: 'optionPage.BusinessCardText',
	});
	const tAmbassadorCardText = intl.formatMessage({
		id: 'optionPage.AmbassadorCardText',
	});
	const tContinueButton = intl.formatMessage({
		id: 'optionPage.ContinueButton',
	});

	return (
		<OptionPageContainer>
			<OptionContainer>
				<SvgLogo width={125} height={48} />
				<OptionCardsContainer>
					<OptionAmbassadorCard>
						<OptionCardTitle>
							{tAmbassadorCardTitle}
						</OptionCardTitle>
						<OptionCardText>
							{tAmbassadorCardText}
						</OptionCardText>
						<OptionImgContainer>
							<OptionImg src={optionGirl} />
							<Button to='/ambassador' className={cnOptionPage('ContinueButton')}>{tContinueButton}</Button>
							<GreenHoverContainer></GreenHoverContainer>
							<PurpleBoxContainer src={purpleBox} />
							<YellowStarContainer src={yellowStar} />
						</OptionImgContainer>
					</OptionAmbassadorCard>
					<OptionBusinessCard>
						<OptionCardTitle>
							{tBusinessCardTitle}
						</OptionCardTitle>
						<OptionCardText>
							{tBusinessCardText}
						</OptionCardText>
						<OptionImgContainer>
							<OptionImg src={optionMan} />
							<Button className={cnOptionPage('ContinueButton')} to='/business'>{tContinueButton}</Button>
							<BlueHoverContainer></BlueHoverContainer>
							<GreenBoxContainer src={greenBox} />
							<BlueRocketContainer src={blueRocket} />
						</OptionImgContainer>

					</OptionBusinessCard>

				</OptionCardsContainer>
			</OptionContainer>
			<LandingFooter />
		</OptionPageContainer>
	);
}
