import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { cn } from '@bem-react/classname';

import { routesMeta } from '@/components/Routes';
import { Button } from '@/components/Buttons';

import './Breadcrumbs.less';

interface IProps {
  pathname: string;
  className?: string;
}

const b = cn('Breadcrumbs');

const Breadcrumbs: FC<IProps> = (props) => {
  const { pathname, className } = props;

  const getData = () => {
    let currentPath = pathname;
    let lastIndex = currentPath.lastIndexOf('/');
    const paths = [];

    while (lastIndex !== -1) {
      const route = routesMeta[currentPath];

      if (!route) {
        throw Error('Cannot find route');
      }

      paths.push(route);

      const newPath = currentPath.substring(0, lastIndex);
      currentPath = newPath;
      lastIndex = newPath.lastIndexOf('/');
    }

    return paths.reverse();
  };

  const getContent = () => {
    const data = getData();

    if (data.length <= 1) {
      return null;
    }

    return data.map(({ path, name }, i) => {
      if (i === data.length - 1) {
        return (
          <span key={path} className={b('Link')}>
            <FormattedMessage id={name} />
          </span>
        );
      }

      return (
        <Button key={path} to={path} className={b('Link')} displayType="link">
          <FormattedMessage id={name} />
        </Button>
      );
    });
  };

  return <nav className={b({}, [className])}>{getContent()}</nav>;
};

export default Breadcrumbs;
