import React from 'react';
import Notification from './Notification';

import INotification from 'src/types/Notification';
import styled from 'styled-components';



interface IProps {
	notifications: INotification[]
}


const NotificationsEmpty = styled.div`
color: #a0a0a0;
display: flex;
flexDirection: column;
justify-content: center;
align-items: center;
max-height: 290px;
min-height: 125px;
`;



export default function NotificationsList(props: IProps) {
	return (
		<div>
			<div>
				{props.notifications.length == 0 ?
					<NotificationsEmpty>Новых уведомлений нет</NotificationsEmpty>
					:
					props.notifications.map(notification => {
						return (
							<Notification
								key={notification.createdAtUtc}
								id={notification.id}
								title={notification.title}
								message={notification.message}
								imageAction={notification.imageAction}
								createdAtUtc={notification.createdAtUtc}
								actions={notification.actions}
								navigationAction={notification.navigationAction}
							/>);
					})
				}

			</div>
		</div>
	);
}