import useSWR from 'swr';
import axios from 'axios';
import { promotionsAPI } from '@/api';
import { createErrorObject } from '@/helpers/createErrorObject';
import { IPromotion, IMapBounds, IPromotionsFilterParams } from '@/types';

interface IParams {
  take?: number;
  skip?: number;
  bounds?: IMapBounds;
  searchString?: string;
  filters?: IPromotionsFilterParams;
  actualNow?: boolean
}

const usePromotions = ({
  skip,
  take,
  bounds,
  searchString,
  filters,
  actualNow,
}: IParams) => {
  const {
    data,
    mutate,
    error,
  }: { data?: IPromotion[]; mutate: any; error?: any } = useSWR(
    ['catalog/promoactions', take, skip, bounds, searchString, filters, actualNow],
    (
      url,
      takeParam,
      skipParam,
      boundsParams,
      searchStringParam,
      filterParams,
      actualNowParam,
    ) =>
      promotionsAPI.getPromotions(
        url,
        takeParam,
        skipParam,
        boundsParams,
        searchStringParam,
        filterParams,
        actualNowParam
      ),
    {
      revalidateOnFocus: false,
    },
  );
  const isLoading = !data && (!error || axios.isCancel(error));
  const isError = !!error;

  return {
    mutate,
    isLoading,
    isError,
    promotions: data,
    error: error ? createErrorObject(error) : null,
  };
};

export { usePromotions };
