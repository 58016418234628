



import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgWhiteLogo: FC<ISvgProps> = (props: ISvgProps) => {

	return (
		<svg viewBox="0 0 125 40" fill="none" {...props}>
			<path fillRule="evenodd" clipRule="evenodd" d="M10.9862 15.757C12.248 16.3688 12.248 18.1663 10.9862 18.7781L3.08293 22.61C1.96805 23.1505 0.671809 22.3384 0.671809 21.0994V13.4357C0.671809 12.1967 1.96805 11.3846 3.08293 11.9251L10.9862 15.757Z" fill="white" />
			<path fillRule="evenodd" clipRule="evenodd" d="M3.11551 7.45487C1.85371 8.06666 1.85371 9.86419 3.11551 10.476L17.7337 17.5636C18.8486 18.1041 20.1448 17.292 20.1448 16.053V1.87782C20.1448 0.638808 18.8486 -0.173277 17.7337 0.367271L3.11551 7.45487Z" fill="white" />
			<path fillRule="evenodd" clipRule="evenodd" d="M3.11551 23.8956C1.85371 24.5074 1.85371 26.3049 3.11551 26.9167L14.3762 32.3765C15.4911 32.917 16.7873 32.1049 16.7873 30.8659V19.9464C16.7873 18.7074 15.4911 17.8953 14.3762 18.4359L3.11551 23.8956Z" fill="white" />
			<path fillRule="evenodd" clipRule="evenodd" d="M10.9862 32.1994C12.248 32.8112 12.248 34.6087 10.9862 35.2205L3.08293 39.0524C1.96805 39.5929 0.671809 38.7808 0.671809 37.5418V29.8781C0.671809 28.6391 1.96805 27.827 3.08293 28.3675L10.9862 32.1994Z" fill="white" />
			<path d="M43.7949 12.5351H34.2189V19.2551H42.3501V22.5815H34.2189V33.0647H30.7245V9.20874H43.7949V12.5351Z" fill="white" />
			<path d="M47.0727 30.8471C45.4375 29.1223 44.6199 27.0279 44.6199 24.5639C44.6199 22.0999 45.4375 20.0167 47.0727 18.3143C48.7079 16.5895 50.7351 15.7271 53.1543 15.7271C54.6327 15.7271 55.9431 16.0855 57.0855 16.8023C58.2503 17.5191 59.0903 18.4375 59.6055 19.5575L59.8071 16.0631H63.1335V33.0647H59.8071L59.6055 29.5367C59.0903 30.6567 58.2503 31.5863 57.0855 32.3255C55.9431 33.0423 54.6327 33.4007 53.1543 33.4007C50.7351 33.4007 48.7079 32.5495 47.0727 30.8471ZM49.7271 20.6663C48.6519 21.7415 48.1143 23.0407 48.1143 24.5639C48.1143 26.0871 48.6519 27.3863 49.7271 28.4615C50.8023 29.5367 52.1351 30.0743 53.7255 30.0743C55.2935 30.0743 56.6039 29.5367 57.6567 28.4615C58.7095 27.3863 59.2359 26.0871 59.2359 24.5639C59.2359 23.0407 58.7095 21.7415 57.6567 20.6663C56.6039 19.5911 55.2935 19.0535 53.7255 19.0535C52.1351 19.0535 50.8023 19.5911 49.7271 20.6663Z" fill="white" />
			<path d="M74.2893 15.7271C75.1405 15.7271 75.8349 15.9735 76.3725 16.4663L75.8349 19.5239C75.3197 19.2103 74.7373 19.0535 74.0877 19.0535C72.9229 19.0535 72.0269 19.6135 71.3997 20.7335C70.7949 21.8311 70.4925 23.2647 70.4925 25.0343V33.0647H66.9981V16.0631H70.2909L70.4589 18.0455C71.6685 16.4999 72.9453 15.7271 74.2893 15.7271Z" fill="white" />
			<path d="M87.8075 16.0631V19.3895H84.1787V33.0647H80.6507V19.3895H78.1307V16.0631H80.6507V11.0231H84.1787V16.0631H87.8075Z" fill="white" />
			<path d="M89.6059 28.1255H93.1003C93.2795 28.8199 93.7051 29.3799 94.3771 29.8055C95.0715 30.2311 95.9563 30.4439 97.0315 30.4439C97.9275 30.4439 98.6667 30.2759 99.2491 29.9399C99.8315 29.6039 100.123 29.1447 100.123 28.5623C100.123 27.8903 99.8315 27.3863 99.2491 27.0503C98.6891 26.6919 97.6027 26.2327 95.9899 25.6727C95.5867 25.5383 95.2843 25.4375 95.0827 25.3703C93.5371 24.8775 92.3275 24.2503 91.4539 23.4887C90.5803 22.7271 90.1435 21.7079 90.1435 20.4311C90.1435 19.0199 90.7371 17.8887 91.9243 17.0375C93.1339 16.1639 94.5451 15.7271 96.1579 15.7271C98.0619 15.7271 99.5963 16.2087 100.761 17.1719C101.948 18.1127 102.587 19.2999 102.676 20.7335H99.1483C99.0811 20.0615 98.7675 19.5463 98.2075 19.1879C97.6475 18.8295 97.0091 18.6503 96.2923 18.6503C95.6203 18.6503 95.0155 18.8071 94.4779 19.1207C93.9403 19.4343 93.6715 19.8711 93.6715 20.4311C93.6715 20.5879 93.6827 20.7335 93.7051 20.8679C93.7499 20.9799 93.8171 21.0919 93.9067 21.2039C93.9963 21.3159 94.0859 21.4167 94.1755 21.5063C94.2651 21.5959 94.3995 21.6967 94.5787 21.8087C94.7803 21.8983 94.9483 21.9879 95.0827 22.0775C95.2395 22.1447 95.4635 22.2343 95.7547 22.3463C96.0683 22.4583 96.3371 22.5591 96.5611 22.6487C96.7851 22.7159 97.0987 22.8279 97.5019 22.9847C97.9275 23.1415 98.2971 23.2759 98.6107 23.3879C101.971 24.5527 103.651 26.2999 103.651 28.6295C103.651 30.1079 103.023 31.2727 101.769 32.1239C100.515 32.9751 98.9131 33.4007 96.9643 33.4007C94.8587 33.4007 93.1451 32.9527 91.8235 32.0567C90.5019 31.1383 89.7627 29.8279 89.6059 28.1255Z" fill="white" />
			<path d="M108.531 30.8471C106.895 29.1223 106.078 27.0279 106.078 24.5639C106.078 22.0999 106.895 20.0167 108.531 18.3143C110.166 16.5895 112.193 15.7271 114.612 15.7271C116.091 15.7271 117.401 16.0855 118.543 16.8023C119.708 17.5191 120.548 18.4375 121.063 19.5575L121.265 16.0631H124.591V33.0647H121.265L121.063 29.5367C120.548 30.6567 119.708 31.5863 118.543 32.3255C117.401 33.0423 116.091 33.4007 114.612 33.4007C112.193 33.4007 110.166 32.5495 108.531 30.8471ZM111.185 20.6663C110.11 21.7415 109.572 23.0407 109.572 24.5639C109.572 26.0871 110.11 27.3863 111.185 28.4615C112.26 29.5367 113.593 30.0743 115.183 30.0743C116.751 30.0743 118.062 29.5367 119.115 28.4615C120.167 27.3863 120.694 26.0871 120.694 24.5639C120.694 23.0407 120.167 21.7415 119.115 20.6663C118.062 19.5911 116.751 19.0535 115.183 19.0535C113.593 19.0535 112.26 19.5911 111.185 20.6663Z" fill="white" />
		</svg>
	);
};

export default SvgWhiteLogo;
