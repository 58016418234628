export enum LegalEntityType {
  INDIVIDUAL = 'Individual',
  SELF_EMPLOYED = 'SelfEmployed',
  INDIVIDUAL_ENTERPRENEUR = 'IndividualEnterpreneur',

  LEGAL_ENTITY = 'LegalEntity',
}

export type TEntityStatus =
  'InReview' | 'Rejected' | 'Confirmed' | 'None';


export type LegalEntity = {
  id: number;
  name: string;
  inn: string;
  ogrn: string;
  kpp: string;
  address: string;
  registrationDocumentNumber: string;
  registrationDate: string;
  firstName: string;
  lastName: string;
  middleName: string;
  dateOfBirth: string;
  placeOfBirth: string;
  addressRegistration: string;
  documentNumber: string;
  documentIssuedBy: string;
  documentIssuedByCode: string;
  documentIssuedDateAt: string;
  type: LegalEntityType;
  actualityCheckedAtUtc: string;
  isActual: boolean;
};

export type LegalEntityStatus = {
  legalEntity?: LegalEntity
  statusType?: TEntityStatus
  bankAccount?: BankAccount;
};

export type BankAccount = {
  id?: number;
  bankName: string;
  accountNumber: string;
  bik: string;
  korrAccountNumber: string;
};


export type NewLegalEntity = {
  name: string;
  inn: string;
  ogrn: string;
  kpp: string;
  address: string;
  registrationDocumentNumber: string;
  registrationDate: string;
  firstName: string;
  lastName: string;
  middleName: string;
  dateOfBirth: string;
  placeOfBirth: string;
  addressRegistration: string;
  documentNumber: string;
  documentIssuedBy: string;
  documentIssuedByCode: string;
  documentIssuedDateAt: string;
  legalEntityType: LegalEntityType;
};
