import React, { FC } from 'react';
import styled from 'styled-components';
import { MainTitle } from '@/components/MainTitle';
import { Button } from '@/components/Buttons';
import { SvgSuccess } from '@/components/Svg';
import { colors } from '@/constants';

interface IProps {
  title: string;
  message?: string;
  successButtonLabel: string;
  onSuccess: () => void;
}

const Wrapper = styled.div`
  max-width: 330px;
`;

const TitleWrapper = styled.div`
  margin: 0 0 34px 0;
  text-align: center;
`;

const IconWrapper = styled.div`
  margin: 0 0 24px 0;
  text-align: center;
`;

const Message = styled.div`
  margin: 0 0 24px 0;
  font-size: 14px;
  text-align: center;
`;

const SuccessfulAlert: FC<IProps> = ({
  title,
  message = '',
  successButtonLabel,
  onSuccess,
}: IProps) => (
  <Wrapper>
    <TitleWrapper>
      <MainTitle text={title} />
    </TitleWrapper>
    <IconWrapper>
      <SvgSuccess width={80} height={80} fill={colors.BLUE} />
    </IconWrapper>
    {message ? <Message>{message}</Message> : null}
    <Button fluid displayType="primary" onClick={onSuccess}>
      {successButtonLabel}
    </Button>
  </Wrapper>
);

export default SuccessfulAlert;
