import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { colors } from '@/constants';

interface IProps {
  title?: string;
  description?: string;
}

const NotFoundTitle = styled.h3`
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
`;

const NotFoundDescription = styled.p`
  width: 100%;
  max-width: 460px;
  line-height: 1.4;
  color: ${colors.MONSOON};
`;

const NotFoundMessage: FC<IProps> = ({
  title = '',
  description = '',
}: IProps) => (
  <div>
    <NotFoundTitle>
      {title || <FormattedMessage id="common.search.notFound.title" />}
    </NotFoundTitle>
    {description && <NotFoundDescription>{description}</NotFoundDescription>}
  </div>
);

export default NotFoundMessage;
