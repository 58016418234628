
import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgBank: FC<ISvgProps> = (props: ISvgProps) => {
	return (
		<svg viewBox="0 0 32 32" {...props}>
			<g id="Bank">
				<g id="Vector">
					<path fillRule="evenodd" clipRule="evenodd" d="M15.4759 3.14834C15.7973 2.95055 16.2027 2.95055 16.5241 3.14834L29.5241 11.1483C29.8198 11.3303 30 11.6527 30 12C30 12.5523 29.5523 13 29 13H3C2.65274 13 2.33034 12.8198 2.14834 12.5241C1.85889 12.0537 2.00555 11.4378 2.47591 11.1483L15.4759 3.14834ZM6.53304 11L16 5.17418L25.467 11H6.53304Z" fill="#1C1C1C" />
					<path d="M6 12V22C6 22.5523 6.44772 23 7 23C7.55228 23 8 22.5523 8 22V12C8 11.4477 7.55228 11 7 11C6.44772 11 6 11.4477 6 12Z" fill="#1C1C1C" />
					<path d="M12 12V22C12 22.5523 12.4477 23 13 23C13.5523 23 14 22.5523 14 22V12C14 11.4477 13.5523 11 13 11C12.4477 11 12 11.4477 12 12Z" fill="#1C1C1C" />
					<path d="M18 12V22C18 22.5523 18.4477 23 19 23C19.5523 23 20 22.5523 20 22V12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" fill="#1C1C1C" />
					<path d="M24 12V22C24 22.5523 24.4477 23 25 23C25.5523 23 26 22.5523 26 22V12C26 11.4477 25.5523 11 25 11C24.4477 11 24 11.4477 24 12Z" fill="#1C1C1C" />
					<path d="M4 23H28C28.5523 23 29 22.5523 29 22C29 21.4477 28.5523 21 28 21H4C3.44772 21 3 21.4477 3 22C3 22.5523 3.44772 23 4 23Z" fill="#1C1C1C" />
					<path d="M2 27H30C30.5523 27 31 26.5523 31 26C31 25.4477 30.5523 25 30 25H2C1.44772 25 1 25.4477 1 26C1 26.5523 1.44772 27 2 27Z" fill="#1C1C1C" />
				</g>
			</g>
		</svg>
	);
};

export default SvgBank;
