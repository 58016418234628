import React, { FC, useCallback } from 'react';
import { Scrollbars, ScrollbarProps } from 'react-custom-scrollbars';
import styled from 'styled-components';
import { colors } from '@/constants';

interface IProps extends ScrollbarProps {
  style?: any;
  children?: any;
  onScroll?: () => void;
  forwardedRef?: (v: any | null) => void;
}

const ThumbVertical = styled.div`
  background: ${colors.MISCHKA};
  border-radius: 6px;
`;

const CustomScrollbars: FC<IProps> = ({
  onScroll = () => {},
  style = {},
  children = null,
  forwardedRef = () => {},
  ...restProps
}: IProps) => {
  const refSetter = useCallback((scrollbarsRef) => {
    if (forwardedRef) {
      if (scrollbarsRef) {
        forwardedRef(scrollbarsRef.view);
      } else {
        forwardedRef(null);
      }
    }
    // eslint-disable-next-line
  }, []);

  const renderThumbVertical = () => <ThumbVertical />;
  const scrollbarsStyle = { ...style, overflow: 'hidden' };

  return (
    <Scrollbars
      autoHide
      ref={refSetter}
      renderThumbVertical={renderThumbVertical}
      style={scrollbarsStyle}
      onScroll={onScroll}
      {...restProps}
    >
      {children}
    </Scrollbars>
  );
};

export default CustomScrollbars;
