import { FC, useState } from 'react';

interface IProps {
  children(onSelect: (v: string) => void, value: string): JSX.Element;
  defaultValue?: string;
  onChange?: (v: string) => void;
}

const RadioGroup: FC<IProps> = ({
  children,
  defaultValue = '',
  onChange = () => {},
}: IProps) => {
  const [value, setValue] = useState(defaultValue);

  const onSelect = (selectedValue: string) => {
    if (selectedValue && selectedValue !== value) {
      setValue(selectedValue);
      onChange(selectedValue);
    } else {
      setValue('');
      onChange('');
    }
  };

  return children(onSelect, value);
};

export default RadioGroup;
