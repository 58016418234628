import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { authActions } from '@/store/actions';
import AuthContext from '@/context/AuthContext';

const useAuth = () => {
  const isAuth = useContext(AuthContext);
  const dispatch = useDispatch();

  const signOut = () => {
    dispatch(authActions.signOut());
  };

  return {
    isAuth,
    signOut,
  };
};

export { useAuth };
