import { createRoutine } from 'redux-saga-routines';
import { reduxActions } from '@/constants';

const {
  SIGN_IN_WITH_PHONE_NUMBER,
  SIGN_IN_WITH_TINKOFF_TOKEN,
  VERIFY_SMS_CODE,
  SET_PHONE_NUMBER,
  CLEAR_DATA,
} = reduxActions;

const signInWithPhoneNumber = createRoutine(
  SIGN_IN_WITH_PHONE_NUMBER,
);

const signInWithTinkoffToken = createRoutine(
  SIGN_IN_WITH_TINKOFF_TOKEN,
);


const verifySmsCode = createRoutine(VERIFY_SMS_CODE);

const setPhoneNumber = (phoneNumber: string) => ({
  type: SET_PHONE_NUMBER,
  payload: {
    phoneNumber,
  },
});

const clearData = () => ({
  type: CLEAR_DATA,
});

export default {
  signInWithPhoneNumber,
  verifySmsCode,
  signInWithTinkoffToken,


  setPhoneNumber,
  clearData,
};
