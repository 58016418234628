
import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgForkKnife: FC<ISvgProps> = (props: ISvgProps) => {

	return (
		<svg viewBox="0 0 32 32" {...props}>
			<g id="ForkKnife">
				<g id="Vector">
					<path d="M9.5 4V9C9.5 9.55228 9.94772 10 10.5 10C11.0523 10 11.5 9.55228 11.5 9V4C11.5 3.44772 11.0523 3 10.5 3C9.94772 3 9.5 3.44772 9.5 4Z" fill="#1C1C1C" />
					<path d="M9.5 14.5V28C9.5 28.5523 9.94772 29 10.5 29C11.0523 29 11.5 28.5523 11.5 28V14.5C11.5 13.9477 11.0523 13.5 10.5 13.5C9.94772 13.5 9.5 13.9477 9.5 14.5Z" fill="#1C1C1C" />
					<path d="M7.00075 10.0782L7.98639 4.1644C7.99545 4.11007 8 4.05508 8 4L7.99957 3.97071C7.99376 3.77237 7.92907 3.58023 7.81373 3.41876C7.65958 3.20295 7.42601 3.05721 7.1644 3.01361C7.11007 3.00455 7.05508 3 7 3L6.97071 3.00043C6.77237 3.00624 6.58023 3.07093 6.41876 3.18627C6.20295 3.34042 6.05721 3.57399 6.01361 3.8356L5.01361 9.8356C5.00455 9.88993 5 9.94492 5 10C5 12.2782 6.61091 13.8891 6.61091 13.8891C8.22183 15.5 10.5 15.5 10.5 15.5C12.7782 15.5 14.3891 13.8891 14.3891 13.8891C16 12.2782 16 10 16 10C16 9.94492 15.9954 9.88993 15.9864 9.8356L14.9864 3.8356C14.906 3.35341 14.4888 3 14 3L13.9943 3.00002C13.9411 3.00032 13.8881 3.00486 13.8356 3.01361C13.574 3.05721 13.3404 3.20295 13.1863 3.41876C13.0651 3.58836 13 3.79158 13 4L13 4.00757C13.0004 4.06012 13.005 4.11256 13.0136 4.1644L13.9992 10.0782C13.9722 11.4775 12.9749 12.4749 12.9749 12.4749C11.9497 13.5 10.5 13.5 10.5 13.5C9.05025 13.5 8.02513 12.4749 8.02513 12.4749C7.02777 11.4775 7.00075 10.0782 7.00075 10.0782Z" fill="#1C1C1C" />
					<path fillRule="evenodd" clipRule="evenodd" d="M24.5 28V21H18.5C18.4644 21 18.4289 20.9981 18.3935 20.9943C18.1298 20.9661 17.8881 20.8342 17.7216 20.6278C17.5551 20.4213 17.4774 20.1572 17.5057 19.8935C17.5057 19.8935 17.6027 18.9885 17.8342 17.615C17.8342 17.615 18.2964 14.8737 18.9754 12.4774C18.9754 12.4774 21.2399 4.48508 25.1583 3.06021C25.2678 3.02038 25.3834 3 25.5 3C26.0523 3 26.5 3.44772 26.5 4V28C26.5 28.5523 26.0523 29 25.5 29C24.9477 29 24.5 28.5523 24.5 28ZM24.5 5.79751V19H19.6387C19.6387 19 19.7102 18.5179 19.8064 17.9475C19.8064 17.9475 20.2504 15.3138 20.8996 13.0226C20.8996 13.0226 21.7958 9.85963 22.9534 7.84126C22.9534 7.84126 23.6953 6.54771 24.5 5.79751Z" fill="#1C1C1C" />
				</g>
			</g>
		</svg>
	);
};

export default SvgForkKnife;
