import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgCalendar: FC<ISvgProps> = (props: ISvgProps) => {
  return (
    <svg viewBox="0 0 13 12" {...props}>
      <path d="M3.656 5.688h-.812a.38.38 0 00-.001.761h.813a.38.38 0 00.001-.761zm0 1.625h-.812a.38.38 0 00-.001.761h.813a.38.38 0 00.001-.761zm0 1.625h-.812a.38.38 0 00-.001.761h.813a.38.38 0 00.001-.762zm3.25-3.25h-.812a.38.38 0 00-.001.761h.813a.38.38 0 00.001-.761zm0 1.625h-.812a.38.38 0 00-.001.761h.813a.38.38 0 00.001-.761zm0 1.625h-.812a.38.38 0 00-.001.761h.813a.38.38 0 00.001-.762zm3.25-3.25h-.812a.38.38 0 00-.001.761h.813a.38.38 0 00.001-.761zm0 1.625h-.812a.38.38 0 00-.001.761h.813a.38.38 0 00.001-.761zm0 1.625h-.812a.38.38 0 00-.001.761h.813a.38.38 0 00.001-.762z" />
      <path d="M11.857 1.016h-.914V.38a.38.38 0 00-.761-.001v.636H6.88V.38a.38.38 0 10-.76 0v.636H2.817V.38a.38.38 0 00-.761-.001v.636h-.914A1.145 1.145 0 000 2.158v8.43c0 .63.513 1.142 1.143 1.142h10.714c.63 0 1.143-.512 1.143-1.142v-8.43c0-.63-.513-1.142-1.143-1.142zM.762 2.158c0-.21.17-.38.38-.38h.915v.634a.38.38 0 00.761.001v-.636H6.12v.635a.38.38 0 00.761.001v-.636h3.302v.635a.38.38 0 00.761.001v-.636h.914c.21 0 .381.171.381.381v1.498H.762V2.158zm11.095 8.81H1.143a.38.38 0 01-.381-.38v-6.17h11.476v6.17c0 .21-.17.38-.38.38z" />
    </svg>
  );
};

export default SvgCalendar;
