
import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgLandingHeader: FC<ISvgProps> = (props: ISvgProps) => {
	return (
		<svg viewBox="0 0 540 658" fill="none" {...props}>
			<path fillRule="evenodd" clipRule="evenodd" d="M29.6222 345.189C-6.0681 363.034 -6.06806 413.966 29.6223 431.811L469.921 651.961C502.118 668.059 540 644.646 540 608.649V168.351C540 132.354 502.118 108.941 469.921 125.039L29.6222 345.189Z" fill="white" />
			<g filter="url(#filter0_d_4388_12479)">
				<path fillRule="evenodd" clipRule="evenodd" d="M395.348 18.359C387.583 16.0685 380.535 23.6259 383.362 31.2123L409.302 100.833C411.852 107.677 420.712 109.444 425.693 104.103L471.014 55.5028C475.995 50.1614 473.613 41.4457 466.608 39.3794L395.348 18.359Z" fill="url(#paint0_linear_4388_12479)" />
			</g>
			<path fillRule="evenodd" clipRule="evenodd" d="M398.027 15.4852C390.262 13.1947 383.215 20.7522 386.041 28.3385L411.981 97.9591C414.531 104.803 423.392 106.571 428.373 101.229L473.693 52.629C478.674 47.2877 476.293 38.5719 469.288 36.5056L398.027 15.4852Z" fill="url(#paint1_linear_4388_12479)" />
			<g filter="url(#filter1_d_4388_12479)">
				<path fillRule="evenodd" clipRule="evenodd" d="M490.99 374.195C489.6 382.14 479.737 385.088 474.216 379.209L438.525 341.213C433.544 335.91 435.885 327.219 442.856 325.135L487.529 311.781C494.5 309.697 501.227 315.678 499.973 322.845L490.99 374.195Z" fill="url(#paint2_linear_4388_12479)" />
			</g>
			<path fillRule="evenodd" clipRule="evenodd" d="M494.742 373.073C493.352 381.018 483.489 383.966 477.967 378.088L442.277 340.092C437.295 334.789 439.636 326.097 446.607 324.013L491.28 310.66C498.251 308.576 504.978 314.557 503.724 321.723L494.742 373.073Z" fill="#00E676" />
			<path fillRule="evenodd" clipRule="evenodd" d="M102.045 187.127C107.968 189.572 114.14 184.207 112.542 178.002L93.5513 104.223C92.1105 98.626 85.2829 96.5017 80.9207 100.294L29.4944 144.998C25.1323 148.79 26.2857 155.847 31.6281 158.052L102.045 187.127Z" fill="#FF1744" />
			<g filter="url(#filter2_d_4388_12479)">
				<path fillRule="evenodd" clipRule="evenodd" d="M40.3978 357.659C45.0887 351.099 55.2564 352.709 57.6903 360.398L73.4225 410.097C75.6182 417.034 69.7466 423.856 62.5604 422.718L16.5083 415.424C9.32212 414.286 5.84601 405.983 10.0778 400.064L40.3978 357.659Z" fill="url(#paint3_linear_4388_12479)" />
			</g>
			<path fillRule="evenodd" clipRule="evenodd" d="M36.5308 357.046C41.2218 350.486 51.3894 352.096 53.8234 359.785L69.5555 409.484C71.7513 416.421 65.8796 423.243 58.6935 422.105L12.6413 414.811C5.45518 413.673 1.97907 405.37 6.21083 399.452L36.5308 357.046Z" fill="#FFC107" />
			<defs>
				<filter id="filter0_d_4388_12479" x="370.393" y="7.25462" width="123.819" height="122.174" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
					<feOffset dx="4.11068" dy="5.75495" />
					<feGaussianBlur stdDeviation="8.22136" />
					<feColorMatrix type="matrix" values="0 0 0 0 0.239216 0 0 0 0 0 0 0 0 0 0.870588 0 0 0 0.303294 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4388_12479" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4388_12479" result="shape" />
				</filter>
				<filter id="filter1_d_4388_12479" x="423.534" y="300.673" width="97.1437" height="103.829" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
					<feOffset dx="4.11068" dy="5.75495" />
					<feGaussianBlur stdDeviation="8.22136" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 1 0 0 0 0 0.803922 0 0 0 0.29693 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4388_12479" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4388_12479" result="shape" />
				</filter>
				<filter id="filter2_d_4388_12479" x="-4.08979" y="342.874" width="98.5326" height="102.167" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
					<feOffset dx="4.11068" dy="5.75495" />
					<feGaussianBlur stdDeviation="8.22136" />
					<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.862745 0 0 0 0 0 0 0 0 0.303294 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4388_12479" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4388_12479" result="shape" />
				</filter>
				<linearGradient id="paint0_linear_4388_12479" x1="410.003" y1="-23.1262" x2="481.855" y2="43.8769" gradientUnits="userSpaceOnUse" >
					<stop stopColor="#4600FF" />
					<stop offset="1" stopColor="#AF00FF" />
				</linearGradient>
				<linearGradient id="paint1_linear_4388_12479" x1="412.683" y1="-26" x2="484.535" y2="41.0031" gradientUnits="userSpaceOnUse">
					<stop stopColor="#3D00DE" />
					<stop offset="1" stopColor="#304FFE" />
				</linearGradient>
				<linearGradient id="paint2_linear_4388_12479" x1="525.127" y1="382.264" x2="502.702" y2="307.246" gradientUnits="userSpaceOnUse">
					<stop stopColor="#4AFFDC" />
					<stop offset="1" stopColor="#2EBEFF" />
				</linearGradient>
				<linearGradient id="paint3_linear_4388_12479" x1="13.1156" y1="335.612" x2="0.866992" y2="412.946" gradientUnits="userSpaceOnUse">
					<stop stopColor="#FFF300" />
					<stop offset="1" stopColor="#FF7600" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default SvgLandingHeader;
