import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';
import { colors } from '@/constants';

interface IProps {
  size: number;
  thickness?: number;
  color?: string;
}

interface ISpinnerProps {
  size: number;
  thickness: number;
  color: string;
}

const kfRotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div<ISpinnerProps>`
  display: inline-block;
  position: relative;
  width: ${({ size }: ISpinnerProps) => size}px;
  height: ${({ size }: ISpinnerProps) => size}px;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({ size }: ISpinnerProps) => size}px;
    height: ${({ size }: ISpinnerProps) => size}px;
    border: ${({ thickness }: ISpinnerProps) => thickness}px solid
      ${({ color }: ISpinnerProps) => color};
    border-radius: 50%;
    animation: ${kfRotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ color }: ISpinnerProps) => color} transparent transparent
      transparent;
  }

  & div:nth-child(1) {
    animation-delay: -0.45s;
  }

  & div:nth-child(2) {
    animation-delay: -0.3s;
  }

  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

const Loader: FC<IProps> = ({
  size,
  thickness = 4,
  color = colors.BLUE,
}: IProps) => (
  <Spinner size={size} thickness={thickness} color={color}>
    <div />
    <div />
    <div />
    <div />
  </Spinner>
);

export default Loader;
