import React, { FC, ReactElement } from 'react';
import styled, { css } from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import { Dropdown } from '@/components/Dropdown';
import { DropdownOverlay } from '@/components/shared';
import { colors } from '@/constants';

interface IProps {
  children: ReactElement[] | ReactElement;
  opened: boolean;
  label: string;
  disabled?: boolean;
  isActiveFilter?: boolean;
  onDropdownChange: (s: boolean) => void;
}

interface IWrapperProps {
  disabled: boolean;
}

interface IFilterButtonProps {
  isActive: boolean;
}

const Wrapper = styled.div<IWrapperProps>`
  ${({ disabled }: IWrapperProps) => {
    if (disabled) {
      return css`
        pointer-events: none;
        opacity: 0.6;
      `;
    }
    return '';
  }}
`;

const FilterButton = styled.button<IFilterButtonProps>`
  padding: 10px 20px;
  font-size: 12px;
  font-weight: bold;
  color: ${colors.BLACK};
  background-color: transparent;
  border: solid 0.7px ${colors.LILAC_WHITE};
  border-radius: 22px;

  ${({ isActive }: IFilterButtonProps) => {
    if (isActive) {
      return css`
        background-color: ${hexToRgba(colors.HAWKES_BLUE, 0.3)};
        border-color: ${colors.BLUE};
      `;
    }
    return '';
  }}
`;

const FilterDropdown: FC<IProps> = ({
  children,
  opened,
  label,
  disabled = false,
  isActiveFilter = false,
  onDropdownChange,
}: IProps) => {
  const handleDropdownChange = (status: boolean) => {
    onDropdownChange(status);
  };

  const renderDropdownMenu = () => (
    <DropdownOverlay>{children}</DropdownOverlay>
  );

  return (
    <Wrapper disabled={disabled}>
      <Dropdown
        overlay={renderDropdownMenu()}
        placement="bottom-start"
        visible={opened}
        onChange={handleDropdownChange}
      >
        <FilterButton type="button" isActive={opened || isActiveFilter}>
          {label}
        </FilterButton>
      </Dropdown>
    </Wrapper>
  );
};

export default FilterDropdown;
