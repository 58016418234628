import React from 'react';
import styled from 'styled-components';
import { Container, Row } from 'styled-bootstrap-grid';
import { Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { Header } from '@/components/Header';
import { MainTitle } from '@/components/MainTitle';
import { PromotionsToolbar } from '@/components/PromotionsToolbar';
import { PromotionsGrid } from '@/components/PromotionsGrid';
import { SearchFiltersMenu } from '@/components/SearchFilters';
import { Page, PageContent } from '@/components/shared';
import { useAuth } from '@/data/useAuth';
import { colors } from '@/constants';

const PageHead = styled.div`
  margin-bottom: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid ${colors.LILAC_WHITE};
`;

const TitleWrapper = styled.div`
  padding: 45px 0 0 0;
`;

const Message = styled.p`
  margin-bottom: 20px;
`;

const AuthLink = styled(Link)`
  color: ${colors.BLUE};
  text-decoration: none;

  &:hover {
    color: ${colors.AZUL};
  }
`;

const SearchPage = () => {
  const intl = useIntl();
  const { isAuth } = useAuth();

  const tMainTitle = intl.formatMessage({
    id: isAuth ? 'common.search' : 'auth.authPls',
  });

  return (
    <Page>
      <SearchFiltersMenu />
      <Header />
      <PageContent>
        <PageHead>
          <Container>
            <TitleWrapper>
              <MainTitle text={tMainTitle} hLevel={1} fontWeight={900} />
            </TitleWrapper>
            {isAuth ? <PromotionsToolbar /> : null}
          </Container>
        </PageHead>
        <Container>
          {isAuth ? (
            <Row>
              <PromotionsGrid />
            </Row>
          ) : (
            <>
              <Message>
                <FormattedMessage id="promotions.auth" />
              </Message>
              <AuthLink to="/signin">
                <FormattedMessage id="auth.logIn" />
              </AuthLink>
            </>
          )}
        </Container>
      </PageContent>
    </Page>
  );
};

export default SearchPage;
