import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import qs from 'query-string';
import isEqual from 'react-fast-compare';
import { PromotionCard } from '@/components/PromotionCard';
import { Button } from '@/components/Buttons';
import { Loader } from '@/components/Loader';
import { NotFoundMessage } from '@/components/NotFoundMessage';
import { usePromotionsInfinite } from '@/data/usePromotionsInfinite';
import { useSearchFilters } from '@/hooks/useSearchFilters';
import { useSearchString } from '@/hooks/useSearchString';
import { colors } from '@/constants';
import { IPromotionsFilterParams } from '@/types';

import { useFavoriteIds } from '@/data/useFavoriteIds';

const Wrapper = styled.div`
  width: 100%;
`;

const GridLoader = styled.div`
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Items = styled.div`
  padding: 0 0 30px 0;
  display: flex;
  flex-wrap: wrap;
`;

const Item = styled.div`
  width: 33%;
  padding: 15px;

  ${down('md')} {
    width: 50%;
  }

  ${down('sm')} {
    width: 100%;
  }
`;

const LoadMoreWrapper = styled.div`
  margin: 0 auto 30px;
  max-width: 280px;
  text-align: center;
`;

const PromotionLink = styled(Link)`
  text-decoration: none;
`;

const NotFoundWrapper = styled.div`
  padding: 20px 15px;
`;

const PromotionsGrid = () => {
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();

  const {
    currentCategoryId,
    currentSubcategoryIds,
    currentBrandIds,
    getFiltersFromUrl,
  } = useSearchFilters();
  const { searchString, getSearchStringFromUrl } = useSearchString();

  const [isInit, setInit] = useState(false);

  const [isLoadMore, setLoadMore] = useState(false);

  // const [nextPage, setNextPage] = useState(1);

  const [searchStringParam, setSearchStringParam] = useState<string>(
    getSearchStringFromUrl(),
  );
  const [filterParams, setFilterParams] = useState<IPromotionsFilterParams>(
    getFiltersFromUrl().urlParams,
  );

  const {
    size,
    setSize,
    items,
    setPageNumber,
    isLoadingMore,
    isLoadingInitialData,
    isReachingEnd,
  } = usePromotionsInfinite({
    pageSize: 9,
    searchString: searchStringParam,
    filters: filterParams,
    actualNow: true
  });

  const tNotFoundDescription = intl.formatMessage({
    id: 'promotions.search.notFound.description',
  });

  useEffect(() => {
    if (!isInit) {
      return;
    }

    if (isLoadMore) {
      setLoadMore(false);
    }
  }, [location]); // eslint-disable-line

  const { favoriteIds, isIdsLoading } = useFavoriteIds();


  useEffect(() => {
    const categoryIds = currentSubcategoryIds.length
      ? currentSubcategoryIds
      : currentCategoryId;

    const brandIds = currentBrandIds.length ? currentBrandIds : '';

    if (!isInit && setSize) {
      const { urlParams } = getFiltersFromUrl();
      const stringFromUrl = getSearchStringFromUrl();

      const isEqualFilters = isEqual(urlParams, {
        categoryIds,
        brandIds,
      });

      const isEqualSearchString = searchString === stringFromUrl;

      if (isEqualFilters && isEqualSearchString) {
        const qsParams = qs.parse(location.search);

        if (!Array.isArray(qsParams.page) && qsParams.page) {
          const currentPage = parseInt(qsParams.page, 10) || 1;
          setPageNumber(currentPage);
        } else {
          setPageNumber(1);
        }

        setSize(1);
        setInit(true);
      }
    }

    if (isInit) {
      const isEqualFilters = isEqual(filterParams, {
        categoryIds,
        brandIds,
      });

      if (searchString !== searchStringParam) {
        setSearchStringParam(searchString);
      }

      if (!isEqualFilters && setSize) {
        setFilterParams({
          ...filterParams,
          categoryIds,
          brandIds,
        });

        setPageNumber(1);
        setSize(1);
      }
    }
    // eslint-disable-next-line
  }, [
    searchString,
    currentCategoryId,
    currentSubcategoryIds,
    currentBrandIds,
    setSize,
  ]);

  useEffect(() => {
    if (isLoadMore) {
      const { query } = qs.parseUrl(location.search);
      const urlSearch = qs.stringify({
        ...query,
        // page: nextPage,
      });

      history.push({
        search: urlSearch,
      });
    }
  }, [items.length]); // eslint-disable-line

  const loadMore = () => {
    setLoadMore(true);

    const qsParams = qs.parse(location.search);

    if (
      ((!Array.isArray(qsParams.page) && qsParams.page) || !qsParams.page) &&
      size !== undefined &&
      setSize
    ) {
      // const currentPage = qsParams.page ? parseInt(qsParams.page, 10) : 1;
      // setNextPage(currentPage + 1);
      setSize(size + 1);
    }
  };

  function renderItems() {
    if (!items.length) {
      return (
        <NotFoundWrapper>
          <NotFoundMessage description={tNotFoundDescription} />
        </NotFoundWrapper>
      );
    } else if (!isLoadingInitialData && !isIdsLoading) {

      return (
        <Items>
          {items.map((i) => {

            const image = i?.images?.find((item) => item.type === 's665x378');
            const isFavorite = favoriteIds?.includes(i?.id);


            return (
              <Item key={i.id}>
                <PromotionLink to={`/promotions/${i.id}`} target="_blank">
                  <PromotionCard
                    title={i.title}
                    startDateTime={i.startDateTime}
                    endDateTime={i.endDateTime}
                    startPromoCodeActivationDateTime={i.startPromocodeActivationDateTime}
                    endPromoCodeActivationDateTime={
                      i.endPromocodeActivationDateTime
                    }
                    company={i.business?.name}
                    rewards={i.ambassadorRewards}
                    imageSrc={image?.media.path}
                    id={i.id}
                    isFavorite={isFavorite}
                  />
                </PromotionLink>
              </Item>
            );
          }
          )}
        </Items>
      );
    }
  }

  return (
    <Wrapper>
      {isLoadingInitialData && isIdsLoading ? (
        <GridLoader>
          <Loader size={52} thickness={4} color={colors.BLUE} />
        </GridLoader>
      ) : (
        renderItems()
      )}

      {!isLoadingInitialData && !isIdsLoading && !isReachingEnd ? (
        <LoadMoreWrapper>
          <Button
            type="button"
            displayType="secondary"
            fluid
            disabled={isLoadingMore}
            onClick={loadMore}
          >
            {isLoadingMore && isIdsLoading ? (
              <Loader size={22} thickness={3} color={colors.BLUE} />
            ) : (
              <FormattedMessage id="common.showMore" />
            )}
          </Button>
        </LoadMoreWrapper>
      ) : null}
    </Wrapper>
  );
};

export default PromotionsGrid;
