import React, { FC } from 'react';
import styled from 'styled-components';
import { SvgAttention } from '@/components/Svg';
import { colors } from '@/constants';

interface IProps {
  withIcon?: boolean;
  message: string;
}

const FieldError = styled.div`
  margin: 8px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  color: ${colors.RED};
`;

const IconWrapper = styled.div`
  margin: 0 6px 0 0;
  height: auto;
`;

const FormFieldError: FC<IProps> = ({ message, withIcon = false }: IProps) => {
  return (
    <FieldError>
      {withIcon ? (
        <IconWrapper>
          <SvgAttention width={16} height={16} fill={colors.RED} />
        </IconWrapper>
      ) : null}
      {message}
    </FieldError>
  );
};

export default FormFieldError;
