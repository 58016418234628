import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgNotificationsAlert: FC<ISvgProps> = (props: ISvgProps) => {
	return (
		<svg viewBox="0 0 25 25" {...props}>
			<path d="M15.7499 22C15.7499 22.1989 15.6709 22.3897 15.5303 22.5303C15.3896 22.671 15.1988 22.75 14.9999 22.75H8.99993C8.80102 22.75 8.61025 22.671 8.4696 22.5303C8.32895 22.3897 8.24993 22.1989 8.24993 22C8.24993 21.8011 8.32895 21.6103 8.4696 21.4697C8.61025 21.329 8.80102 21.25 8.99993 21.25H14.9999C15.1988 21.25 15.3896 21.329 15.5303 21.4697C15.6709 21.6103 15.7499 21.8011 15.7499 22ZM20.7984 19C20.6682 19.2292 20.4793 19.4196 20.2511 19.5514C20.0228 19.6833 19.7635 19.7518 19.4999 19.75H4.49993C4.23625 19.7496 3.97731 19.6798 3.74923 19.5475C3.52115 19.4151 3.33198 19.225 3.20079 18.9963C3.0696 18.7676 3.00102 18.5083 3.00196 18.2446C3.00291 17.9809 3.07335 17.7222 3.20618 17.4944C3.72649 16.5981 4.49993 14.0631 4.49993 10.75C4.49993 8.76088 5.29011 6.85322 6.69663 5.4467C8.10315 4.04018 10.0108 3.25 11.9999 3.25C13.9891 3.25 15.8967 4.04018 17.3032 5.4467C18.7098 6.85322 19.4999 8.76088 19.4999 10.75C19.4999 14.0622 20.2743 16.5981 20.7946 17.4944C20.9288 17.7225 20.9997 17.9822 21.0002 18.2468C21.0007 18.5114 20.9307 18.7714 20.7974 19H20.7984ZM19.4999 18.25C18.7752 17.0059 17.9999 14.1297 17.9999 10.75C17.9999 9.1587 17.3678 7.63258 16.2426 6.50736C15.1174 5.38214 13.5912 4.75 11.9999 4.75C10.4086 4.75 8.88251 5.38214 7.75729 6.50736C6.63207 7.63258 5.99993 9.1587 5.99993 10.75C5.99993 14.1306 5.22368 17.0069 4.49993 18.25H19.4999Z" />
			<circle cx="19" cy="6" r="4.75" fill="#FF1744" stroke="white" strokeWidth="1.5" />
		</svg>

	);
};

export default SvgNotificationsAlert;

