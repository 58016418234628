import useSWR from 'swr';
import axios from 'axios';
import { commonAPI } from '@/api';
import { createErrorObject } from '@/helpers/createErrorObject';

import ICompany from 'src/types/company';



const useCompany = (id: number) => {
	const {
		data,
		mutate,
		error,
	}: { data?: ICompany; mutate: any; error?: any } = useSWR(
		`company/profile/${id}`,
		commonAPI.fetch,
		{
			revalidateOnFocus: false,
		},
	);

	const isLoading = !data && (!error || axios.isCancel(error));
	const isError = !!error;

	return {
		mutate,
		isLoading,
		isError,
		company: data,
		error: error ? createErrorObject(error) : null,
	};
};

export { useCompany };
