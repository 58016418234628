import { ClassNameFormatter } from '@bem-react/classname';
import React, { FC, useCallback, MouseEvent } from 'react';
import { useAutocomplete } from './hooks';

interface IProps {
  b: ClassNameFormatter;
}

const Options: FC<IProps> = ({ b }) => {
  const {
    options,
    value: inputValue,
    setValue,
    onChange,
    setOptions,
  } = useAutocomplete();

  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      setValue(e.currentTarget.name);

      onChange(options.find((el) => el.value === e.currentTarget.value));

      setOptions([]);
    },
    [options, setValue, setOptions, onChange],
  );

  return (
    <ul className={b('Options')}>
      {options.map(({ label, value }, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={`${value}-${i}`}>
          <button
            className={b('Option', { selected: value === inputValue })}
            name={label}
            type="button"
            value={value}
            onClick={handleClick}
          >
            {label}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default Options;
