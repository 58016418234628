import React, { FC } from 'react';
import { ISvgColorfulProps } from '@/types';

const SvgLogo: FC<ISvgColorfulProps> = (props: ISvgColorfulProps) => {
  return (
    <svg  {...props} viewBox="8 9 79 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M16.1335 19.6832C16.9221 20.0655 16.9221 21.189 16.1335 21.5714L11.194 23.9663C10.4972 24.3041 9.68702 23.7966 9.68702 23.0222V18.2324C9.68702 17.458 10.4972 16.9504 11.194 17.2883L16.1335 19.6832Z" fill="#00E676" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.2145 14.4944C10.4259 14.8767 10.4259 16.0002 11.2145 16.3826L20.3509 20.8123C21.0477 21.1502 21.8578 20.6426 21.8578 19.8682V11.0087C21.8578 10.2343 21.0477 9.72679 20.3509 10.0646L11.2145 14.4944Z" fill="#FF1744" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.2145 24.7704C10.4259 25.1528 10.4259 26.2762 11.2145 26.6586L18.2525 30.0709C18.9493 30.4088 19.7594 29.9012 19.7594 29.1269V22.3022C19.7594 21.5278 18.9493 21.0202 18.2525 21.3581L11.2145 24.7704Z" fill="#304FFE" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.1335 29.9591C16.9221 30.3414 16.9221 31.4649 16.1335 31.8472L11.194 34.2422C10.4972 34.58 9.68702 34.0725 9.68702 33.2981V28.5082C9.68702 27.7339 10.4972 27.2263 11.194 27.5641L16.1335 29.9591Z" fill="#FFC107" />
      <path d="M36.639 17.6688H30.654V21.8688H35.736V23.9478H30.654V30.4998H28.47V15.5898H36.639V17.6688Z" fill="black" />
      <path d="M38.6876 29.1138C37.6656 28.0358 37.1546 26.7268 37.1546 25.1868C37.1546 23.6468 37.6656 22.3448 38.6876 21.2808C39.7096 20.2028 40.9766 19.6638 42.4886 19.6638C43.4126 19.6638 44.2316 19.8878 44.9456 20.3358C45.6736 20.7838 46.1986 21.3578 46.5206 22.0578L46.6466 19.8738H48.7256V30.4998H46.6466L46.5206 28.2948C46.1986 28.9948 45.6736 29.5758 44.9456 30.0378C44.2316 30.4858 43.4126 30.7098 42.4886 30.7098C40.9766 30.7098 39.7096 30.1778 38.6876 29.1138ZM40.3466 22.7508C39.6746 23.4228 39.3386 24.2348 39.3386 25.1868C39.3386 26.1388 39.6746 26.9508 40.3466 27.6228C41.0186 28.2948 41.8516 28.6308 42.8456 28.6308C43.8256 28.6308 44.6446 28.2948 45.3026 27.6228C45.9606 26.9508 46.2896 26.1388 46.2896 25.1868C46.2896 24.2348 45.9606 23.4228 45.3026 22.7508C44.6446 22.0788 43.8256 21.7428 42.8456 21.7428C41.8516 21.7428 41.0186 22.0788 40.3466 22.7508Z" fill="black" />
      <path d="M55.6979 19.6638C56.2299 19.6638 56.6639 19.8178 56.9999 20.1258L56.6639 22.0368C56.3419 21.8408 55.9779 21.7428 55.5719 21.7428C54.8439 21.7428 54.2839 22.0928 53.8919 22.7928C53.5139 23.4788 53.3249 24.3748 53.3249 25.4808V30.4998H51.1409V19.8738H53.1989L53.3039 21.1128C54.0599 20.1468 54.8579 19.6638 55.6979 19.6638Z" fill="black" />
      <path d="M64.1468 19.8738V21.9528H61.8788V30.4998H59.6738V21.9528H58.0988V19.8738H59.6738V16.7238H61.8788V19.8738H64.1468Z" fill="black" />
      <path d="M65.2708 27.4128H67.4548C67.5668 27.8468 67.8328 28.1968 68.2528 28.4628C68.6868 28.7288 69.2398 28.8618 69.9118 28.8618C70.4718 28.8618 70.9338 28.7568 71.2978 28.5468C71.6618 28.3368 71.8438 28.0498 71.8438 27.6858C71.8438 27.2658 71.6618 26.9508 71.2978 26.7408C70.9478 26.5168 70.2688 26.2298 69.2608 25.8798C69.0088 25.7958 68.8198 25.7328 68.6938 25.6908C67.7278 25.3828 66.9718 24.9908 66.4258 24.5148C65.8798 24.0388 65.6068 23.4018 65.6068 22.6038C65.6068 21.7218 65.9778 21.0148 66.7198 20.4828C67.4758 19.9368 68.3578 19.6638 69.3658 19.6638C70.5558 19.6638 71.5148 19.9648 72.2428 20.5668C72.9848 21.1548 73.3838 21.8968 73.4398 22.7928H71.2348C71.1928 22.3728 70.9968 22.0508 70.6468 21.8268C70.2968 21.6028 69.8978 21.4908 69.4498 21.4908C69.0298 21.4908 68.6518 21.5888 68.3158 21.7848C67.9798 21.9808 67.8118 22.2538 67.8118 22.6038C67.8118 22.7018 67.8188 22.7928 67.8328 22.8768C67.8608 22.9468 67.9028 23.0168 67.9588 23.0868C68.0148 23.1568 68.0708 23.2198 68.1268 23.2758C68.1828 23.3318 68.2668 23.3948 68.3788 23.4648C68.5048 23.5208 68.6098 23.5768 68.6938 23.6328C68.7918 23.6748 68.9318 23.7308 69.1138 23.8008C69.3098 23.8708 69.4778 23.9338 69.6178 23.9898C69.7578 24.0318 69.9538 24.1018 70.2058 24.1998C70.4718 24.2978 70.7028 24.3818 70.8988 24.4518C72.9988 25.1798 74.0488 26.2718 74.0488 27.7278C74.0488 28.6518 73.6568 29.3798 72.8728 29.9118C72.0888 30.4438 71.0878 30.7098 69.8698 30.7098C68.5538 30.7098 67.4828 30.4298 66.6568 29.8698C65.8308 29.2958 65.3688 28.4768 65.2708 27.4128Z" fill="black" />
      <path d="M77.0988 29.1138C76.0768 28.0358 75.5658 26.7268 75.5658 25.1868C75.5658 23.6468 76.0768 22.3448 77.0988 21.2808C78.1208 20.2028 79.3877 19.6638 80.8997 19.6638C81.8237 19.6638 82.6427 19.8878 83.3568 20.3358C84.0848 20.7838 84.6098 21.3578 84.9318 22.0578L85.0578 19.8738H87.1368V30.4998H85.0578L84.9318 28.2948C84.6098 28.9948 84.0848 29.5758 83.3568 30.0378C82.6427 30.4858 81.8237 30.7098 80.8997 30.7098C79.3877 30.7098 78.1208 30.1778 77.0988 29.1138ZM78.7578 22.7508C78.0858 23.4228 77.7498 24.2348 77.7498 25.1868C77.7498 26.1388 78.0858 26.9508 78.7578 27.6228C79.4298 28.2948 80.2628 28.6308 81.2568 28.6308C82.2368 28.6308 83.0558 28.2948 83.7138 27.6228C84.3718 26.9508 84.7008 26.1388 84.7008 25.1868C84.7008 24.2348 84.3718 23.4228 83.7138 22.7508C83.0558 22.0788 82.2368 21.7428 81.2568 21.7428C80.2628 21.7428 79.4298 22.0788 78.7578 22.7508Z" fill="black" />
    </svg>
  );
};

export default SvgLogo;
