import React, { useState, useCallback } from 'react';
import { Container } from 'styled-bootstrap-grid';
import { useIntl } from 'react-intl';
import { cn } from '@bem-react/classname';

import { useAuth } from '@/data/useAuth';
import {
  useUser,
  updateProfile,
  confirmEmail,
  sendConfirmationCode,
} from '@/data/useUser';

import { Layout } from '@/components/Layout';
// import { Button } from '@/components/Buttons';
// import { Checkbox } from '@/components/Checkbox';
import { Modal } from '@/components/Modal';
import { EmailConfirm } from '@/components/EmailConfirm';
import { SettingsForm } from '@/components/SettingsForm';

import { common } from '@/constants';

import './AccountSettingsPage.less';

const { DEFAULT_DIAL_CODE } = common;

// type TSubscriptions = {
//   companies: boolean;
//   promo: boolean;
//   bill: boolean;
// };

const b = cn('AccountSettingsPage');

const AccountSettingsPage = () => {
  const { formatMessage } = useIntl();
  const { isAuth } = useAuth();
  const { user, isLoading, mutate } = useUser();

  const [email, setEmail] = useState('');
  const [editingPhone, setEditingPhone] = useState(false);
  const [editingEmail, setEditingEmail] = useState(false);
  // const [subscriptions, setSubscriptions] = useState<TSubscriptions>({
  //   companies: false,
  //   promo: false,
  //   bill: false,
  // });

  const handleSubmitPhone = useCallback(() => {
    setEditingPhone(false);
  }, []);

  const handleSubmitEmail = useCallback(
    async (data: { email: string }) => {
      await updateProfile({ ...user?.profile, email: data.email });
      await mutate();
      setEmail(data.email);
    },
    [user, mutate],
  );

  const handleCloseEmailModal = useCallback(() => {
    setEmail('');
    setEditingEmail(false);
  }, []);

  // const handleSubmitSubscriptions = useCallback(() => null, []);

  // const handleSubscriptionChange = useCallback(
  //   (event: SyntheticEvent<HTMLInputElement>) => {
  //     const { currentTarget } = event;
  //     const { name, checked } = currentTarget;

  //     setSubscriptions((vals) => ({ ...vals, [name]: checked }));
  //   },
  //   [],
  // );

  const confirmEmailRetryHandler = useCallback(async () => {
    await sendConfirmationCode();
    setEmail(user?.profile.email || '');
  }, [user]);

  const confirmEmailRequestHandler = useCallback(
    async ({ confirmEmailCode }: { confirmEmailCode: string }) => {
      await confirmEmail(confirmEmailCode);
      await mutate();
      setEmail(user?.profile.email || '');
    },
    [user, mutate],
  );

  // const tSaveButton = formatMessage({ id: 'settings.buttons.saveChanges' });
  // const tNotificationTitle = formatMessage({ id: 'settings.notifications' });
  const tLabelId = formatMessage({ id: 'settings.labels.id' });
  const tLabelPhone = formatMessage({ id: 'settings.labels.phone' });
  const tLabelEmail = formatMessage({ id: 'settings.labels.email' });
  // const tLabelNotifications = formatMessage({
  //   id: 'settings.labels.notifications',
  // });
  const tPlaceholderPhone = formatMessage({
    id: 'settings.placeholders.phone',
  });
  const tPlaceholderEmail = formatMessage({
    id: 'settings.placeholders.email',
  });
  // const tNotificationCompanies = formatMessage({
  //   id: 'settings.notifications.companies',
  // });
  // const tNotificationPromo = formatMessage({
  //   id: 'settings.notifications.promo',
  // });
  // const tNotificationBill = formatMessage({
  //   id: 'settings.notifications.bill',
  // });

  return (
    <Layout
      withBreadcrumbs
      isAuth={isAuth}
      i18nSeoTitle="seo.title.settings"
      i18nTitle="common.settings"
      isLoading={isLoading}
    >
      <Container>
        <div className={b('Block')}>
          <p className={b('Header')}>
            <span className={b('Label')}>{tLabelId}</span>
          </p>

          <p className={b('Value')}>{user?.id ?? 'Не найден'}</p>
        </div>

        <SettingsForm
          isEditable={false}
          type="phone"
          name="phone"
          defaultValue={user?.profile.phone?.replace(DEFAULT_DIAL_CODE, '')}
          label={tLabelPhone}
          placeholder={tPlaceholderPhone}
          editing={editingPhone}
          setEditing={setEditingPhone}
          handleSubmitForm={handleSubmitPhone}
        />

        <SettingsForm
          type="email"
          name="email"
          needConfirmation={
            Boolean(user?.profile.email) && !user?.profile.isEmailConfirmed
          }
          defaultValue={user?.profile.email}
          label={tLabelEmail}
          placeholder={tPlaceholderEmail}
          editing={editingEmail}
          setEditing={setEditingEmail}
          handleSubmitForm={handleSubmitEmail}
          confirmFieldName="confirmEmailCode"
          confirmRetryHandler={confirmEmailRetryHandler}
          confirmRequestHandler={confirmEmailRequestHandler}
        />

        {/* <div className={b('Block')}>
          <h3 className={b('Subtitle')}>{tNotificationTitle}</h3>

          <p className={b('Header')}>
            <span className={b('Label')}>{tLabelNotifications}</span>
          </p>

          <Checkbox
            className={b('Checkbox')}
            name="companies"
            checked={subscriptions.companies}
            onChange={handleSubscriptionChange}
            label={tNotificationCompanies}
          />

          <Checkbox
            className={b('Checkbox')}
            name="promo"
            checked={subscriptions.promo}
            onChange={handleSubscriptionChange}
            label={tNotificationPromo}
          />

          <Checkbox
            className={b('Checkbox')}
            name="bill"
            checked={subscriptions.bill}
            onChange={handleSubscriptionChange}
            label={tNotificationBill}
          />

          <Button
            onClick={handleSubmitSubscriptions}
            displayType="primary"
            className={b('Submit')}
          >
            {tSaveButton}
          </Button>
        </div> */}
      </Container>

      <Modal visible={Boolean(email)} onCancel={handleCloseEmailModal}>
        <EmailConfirm
          email={email}
          isConfirmed={user?.profile.isEmailConfirmed}
          onClose={handleCloseEmailModal}
        />
      </Modal>
    </Layout>
  );
};

export default AccountSettingsPage;
