interface IGetName {
  (
    profile:
    | Partial<{
      firstName: string;
      middleName: string;
      lastName: string;
    }>
    | undefined,
    type: 'full' | 'short',
  ): string;
}

export const getName: IGetName = (data, type) => {
  if (!data) {
    return '';
  }

  const { firstName = '', middleName = '', lastName = '' } = data;

  if (type === 'full') {
    return `${firstName} ${middleName} ${lastName}`.trim();
  }

  if (type === 'short') {
    return `${firstName} ${lastName ? `${lastName[0]}.` : ''}`.trim();
  }

  return firstName;
};
