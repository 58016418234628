import React, { useState } from 'react';
import { PromotionCard } from '../PromotionCard';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';

import MarkerIcon from './MarkerIcon';
import { useFavoriteIds } from '@/data/useFavoriteIds';
import yandexMap from '@/helpers/yandexMapImport';






const PromotionLink = styled(Link)`
  text-decoration: none;
`;






export const MyMarker = (props: any) => {
	const [isActive, setIsActive] = useState(false);


	const { favoriteIds } = useFavoriteIds();


	const handleOnClick = () => {
		setIsActive(!isActive);
		props.setPopupId(props.item.id);
	};
	return (
		<>
			{props.popupId === props.item.id && (

				<div className={styles.popup}>
					<PromotionLink to={`/promotions/${props.item.id}`} target="_blank">
						<PromotionCard
							isFavorite={favoriteIds?.includes(props.item?.id)}
							title={props.item.title}
							startDateTime={props.item.startDateTime}
							endDateTime={props.item.startPromocodeActivationDateTime}
							startPromoCodeActivationDateTime={props.item.endDateTime}
							endPromoCodeActivationDateTime={props.item.endPromocodeActivationDateTime}
							company={props.item.business.name}
							rewards={props.item.ambassadorRewards}
							imageSrc={props.item.images?.find((mitem: any) => mitem.type === 's665x378')?.media.path}
							mode="markerPopup"
							id={props.item.id}
						/>
					</PromotionLink>
					<div className={styles.popup__close} onClick={() => props.setPopupId(null)}>
						✖
					</div>
				</div>
			)}
			<yandexMap.YMapMarker coordinates={props.coordinates} onClick={() => handleOnClick()} zIndex={0}>
				<MarkerIcon active={props.popupId === props.item.id} imageSrc={props.item.images?.find((mitem: any) => mitem.type === 's665x378')?.media.path} />
			</yandexMap.YMapMarker>
		</>
	);
};
