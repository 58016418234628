import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { cn } from '@bem-react/classname';
import { yupResolver } from '@hookform/resolvers';

import { Button } from '@/components/Buttons';
import { BaseInput } from '@/components/FormControls';

import { useInterval } from '@/hooks/useInterval';

import './ConfirmByCodeForm.less';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IProps<T> {
  name: string;
  containerClassName?: string;
  inputClassName?: string;
  requestHandler: (values: any) => void;
  retryHandler: () => void;
}

const b = cn('ConfirmByCodeForm');

const INTERVAL_SECONDS = 10;

const ConfirmByCodeForm = <T extends object>(props: IProps<T>) => {
  const {
    name,
    requestHandler,
    retryHandler,
    inputClassName,
    containerClassName,
  } = props;

  const { timer, setTimer } = useInterval();

  const { formatMessage } = useIntl();

  const tRepeatButton = formatMessage({
    id: 'confirmByCodeForm.buttons.repeat',
  });
  const tRepeatAfterButton = formatMessage(
    { id: 'confirmByCodeForm.buttons.repeatAfter' },
    { time: timer },
  );
  const tPlaceholder = formatMessage({
    id: 'confirmByCodeForm.placeholders.code',
  });
  const tConfirmButton = formatMessage({ id: 'settings.buttons.confirm' });
  const tRequiredField = formatMessage({ id: 'form.errors.required' });

  const validationSchema = yup.object().shape({
    [name]: yup.string().required(tRequiredField),
  });

  const { register, setValue, handleSubmit, errors, clearErrors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleInputChange = useCallback(
    (v: string) => {
      setValue(name, v);
    },
    [setValue, name],
  );

  const handleInputFocus = useCallback(() => {
    clearErrors(name);
  }, [clearErrors, name]);

  const handleSendConfirmationCode = useCallback(() => {
    retryHandler();
    setTimer(INTERVAL_SECONDS);
  }, [retryHandler, setTimer]);

  const handleSubmitForm = useCallback<SubmitHandler<T>>(
    (values) => {
      requestHandler(values);
    },
    [requestHandler],
  );

  useEffect(() => {
    register(name);
  }, [register, name]);

  return (
    <>
      <form
        onSubmit={handleSubmit(handleSubmitForm)}
        className={b({}, [containerClassName])}
      >
        <BaseInput
          name={name}
          className={inputClassName}
          onInputChange={handleInputChange}
          onInputFocus={handleInputFocus}
          placeholder={tPlaceholder}
          isError={errors[name]}
          errorMessage={errors[name]?.message}
        />

        <Button type="submit" displayType="primary" className={b('Submit')}>
          {tConfirmButton}
        </Button>

        <Button
          disabled={Boolean(timer)}
          onClick={handleSendConfirmationCode}
          className={b('Submit')}
        >
          {timer ? tRepeatAfterButton : tRepeatButton}
        </Button>
      </form>
    </>
  );
};

export default ConfirmByCodeForm;
