import React, { useState, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { Container } from 'styled-bootstrap-grid';
import * as yup from 'yup';
import { cn } from '@bem-react/classname';
import { yupResolver } from '@hookform/resolvers';

import { useAuth } from '@/data/useAuth';
import { useLegal, updateBankAccount } from '@/data/useLegal';

import { dadataAPI } from '@/api';
import { TBankDadataSuggest } from '@/types';

import { Button } from '@/components/Buttons';
import { BaseInput } from '@/components/FormControls';
import { Layout } from '@/components/Layout';
import Autocomplete from '@/components/FormControls/Autocomplete';
import {
  validateAccountNumber,
  validateCorrespondentNumber,
} from '@/helpers/validationHeplers';

import './RequisitesPage.less';
import FormFieldError from '@/components/FormControls/FormFieldError';
import { Loader } from '@/components/Loader';
import { Modal } from '@/components/Modal';
import { SuccessChanges } from '@/components/SuccessChanges';
import { colors } from '@/constants';

const b = cn('RequisitesPage');

const RequisitesPage = () => {
  const { isAuth } = useAuth();
  const { bankAccount, isLoading } = useLegal();
  const { formatMessage } = useIntl();

  const [isSuccessModalShown, setSuccessModalShown] = useState(false);
  const [saveError, setSaveError] = useState('');
  const [isSaving, setSaving] = useState(false);

  const tErrorRequiredCode = formatMessage({ id: 'form.errors.required' });
  const tErrorAccountNumber = formatMessage(
    { id: 'form.errors.controlSum' },
    { name: 'номер лицевого счёта' },
  );
  const tTitle = formatMessage({ id: 'requisites.title' });
  const tLabelAccountNumber = formatMessage({
    id: 'requisites.labels.accountNumber',
  });
  const tLabelCorrespondentNumber = formatMessage({
    id: 'requisites.labels.correspondentNumber',
  });
  const tLabelBankName = formatMessage({ id: 'requisites.labels.bankName' });
  const tLabelBic = formatMessage({ id: 'requisites.labels.bic' });
  const tPlaceholderAccountNumber = formatMessage({
    id: 'requisites.placeholders.accountNumber',
  });
  const tPlaceholderCorrespondentNumber = formatMessage({
    id: 'requisites.placeholders.correspondentNumber',
  });
  const tPlaceholderBankName = formatMessage({
    id: 'requisites.placeholders.bankName',
  });
  const tPlaceholderBic = formatMessage({ id: 'requisites.placeholders.bic' });
  const tSave = formatMessage({ id: 'requisites.save' });

  const validationSchema = yup.object().shape({
    accountNumber: validateAccountNumber({
      errorText: tErrorAccountNumber,
      errorRequiredText: tErrorRequiredCode,
      errorLimitNumbers: formatMessage(
        { id: 'form.errors.limitNumbers' },
        { count: 20 },
      ),
    }),
    bankName: yup.string().required(tErrorRequiredCode),
    bic: yup
      .string()
      .required(tErrorRequiredCode)
      .length(
        9,
        formatMessage({ id: 'form.errors.limitNumbers' }, { count: 9 }),
      ),
    correspondentNumber: validateCorrespondentNumber({
      errorText: tErrorAccountNumber,
      errorRequiredText: tErrorRequiredCode,
      errorLimitNumbers: formatMessage(
        { id: 'form.errors.limitNumbers' },
        { count: 20 },
      ),
    }),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    errors,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleInputChange = useCallback(
    (name: string) => (value: string) => {
      clearErrors(name);
      setValue(name, value);
    },
    [clearErrors, setValue],
  );

  const handleChangeAutocompleteBankName = useCallback(
    (option: any) => {
      setValue('bankName', option.value);
      setValue('bic', option.bic);
      setValue('correspondentNumber', option.correspondentNumber);
      clearErrors('bankName');
      clearErrors('bic');
      clearErrors('correspondentNumber');
    },
    [clearErrors, setValue],
  );

  const handleChangeAutocompleteBic = useCallback(
    (option: any) => {
      setValue('bankName', option.bankName);
      setValue('bic', option.value);
      setValue('correspondentNumber', option.correspondentNumber);
      clearErrors('bankName');
      clearErrors('bic');
      clearErrors('correspondentNumber');
    },
    [clearErrors, setValue],
  );

  const handleAutocompleteBankName = useCallback(
    (value: string | number) => {
      clearErrors('bankName');
      return dadataAPI.getBankDadataSuggest(value);
    },
    [clearErrors],
  );

  const handleCloseModal = useCallback(() => setSuccessModalShown(false), []);

  const onSubmit = useCallback((data: any) => {
    setSaving(true);
    setSaveError('');

    updateBankAccount({
      accountNumber: data.accountNumber,
      bankName: data.bankName,
      bik: data.bic,
      korrAccountNumber: data.correspondentNumber,
    })
      .then(() => setSuccessModalShown(true))
      .catch((e: Error) =>
        setSaveError(`Не удалось сохранить данные: ${e.message}`),
      )
      .finally(() => setSaving(false));
  }, []);

  useEffect(() => {
    register('bankName');
    register('bic');
  }, [register]);

  useEffect(() => {
    setValue('bankName', bankAccount?.bankName || '');
    setValue('bic', bankAccount?.bik || '');
    setValue('correspondentNumber', bankAccount?.korrAccountNumber || '');
  }, [bankAccount, setValue]);

  return (
    <Layout
      withBreadcrumbs
      isAuth={isAuth}
      i18nSeoTitle="seo.title.requisites"
      i18nTitle="common.requisites"
    >
      <Container className={b()}>
        <h2 className={b('Title')}>{tTitle}</h2>

        {isLoading && (
          <div className={b('Loader-Wrapper')}>
            <Loader size={52} />
          </div>
        )}

        {!isLoading && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={b('Block')}>
              <label className={b('Label')} htmlFor="bankName">
                {tLabelBankName}
              </label>

              <Autocomplete
                name="bankName"
                id="bankName"
                controlledValue={getValues('bankName') || bankAccount?.bankName}
                onChange={handleChangeAutocompleteBankName}
                getOptionsScheme={({ value, data }: TBankDadataSuggest) => ({
                  label: value,
                  value,
                  bic: data.bic,
                  correspondentNumber: data.correspondent_account,
                })}
                fetchOptions={handleAutocompleteBankName}
                placeholder={tPlaceholderBankName}
                isError={errors.bankName}
                errorMessage={errors.bankName?.message}
              />
            </div>

            <div className={b('Block')}>
              <label className={b('Label')} htmlFor="bic">
                {tLabelBic}
              </label>

              <Autocomplete
                id="bic"
                name="bic"
                type="number"
                controlledValue={getValues('bic') || bankAccount?.bik}
                onChange={handleChangeAutocompleteBic}
                getOptionsScheme={({ value, data }: TBankDadataSuggest) => ({
                  label: `${value} (${data.bic})`,
                  bankName: value,
                  value: data.bic,
                  correspondentNumber: data.correspondent_account,
                })}
                fetchOptions={handleAutocompleteBankName}
                placeholder={tPlaceholderBic}
                isError={errors.bic}
                errorMessage={errors.bic?.message}
              />
            </div>

            <div className={b('Block')}>
              <label className={b('Label')} htmlFor="correspondentNumber">
                {tLabelCorrespondentNumber}
              </label>

              <BaseInput
                ref={register}
                type="number"
                className={b('Field')}
                id="correspondentNumber"
                name="correspondentNumber"
                value={getValues('correspondentNumber')}
                defaultValue={bankAccount?.korrAccountNumber}
                onInputChange={handleInputChange('correspondentNumber')}
                placeholder={tPlaceholderCorrespondentNumber}
                isError={errors.correspondentNumber}
                errorMessage={errors.correspondentNumber?.message}
              />
            </div>

            <div className={b('Block')}>
              <label className={b('Label')} htmlFor="accountNumber">
                {tLabelAccountNumber}
              </label>

              <BaseInput
                ref={register}
                type="number"
                className={b('Field')}
                id="accountNumber"
                name="accountNumber"
                value={getValues('accountNumber')}
                defaultValue={bankAccount?.accountNumber}
                onInputChange={handleInputChange('accountNumber')}
                placeholder={tPlaceholderAccountNumber}
                isError={errors.accountNumber}
                errorMessage={errors.accountNumber?.message}
              />
            </div>

            <div className={b('Button-Wrapper')}>
              <Button
                displayType="primary"
                type="submit"
                className={b('Button')}
              >
                {isSaving ? (
                  <Loader size={22} thickness={3} color={colors.WHITE} />
                ) : (
                  tSave
                )}
              </Button>

              {saveError && <FormFieldError message={saveError} />}
            </div>

            <Modal visible={isSuccessModalShown} onCancel={handleCloseModal}>
              <SuccessChanges onClose={handleCloseModal} />
            </Modal>
          </form>
        )}
      </Container>
    </Layout>
  );
};

export default RequisitesPage;
