import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgBillDeclined: FC<ISvgProps> = (props: ISvgProps) => {
  return (
    <svg viewBox="0 0 14 14" {...props}>
      <g fill="#FF1744">
        <path d="M13.45 4.28A7.04 7.04 0 004.28.55 7.04 7.04 0 00.55 9.72a7.04 7.04 0 009.17 3.73 7.04 7.04 0 003.73-9.17zm-.86 5.08a6.1 6.1 0 01-7.95 3.23 6.1 6.1 0 01-3.23-7.95 6.1 6.1 0 017.95-3.23 6.1 6.1 0 013.23 7.95z"/>
        <path d="M10.65 6.54h-7.3a.46.46 0 000 .92h7.3a.46.46 0 000-.92z"/>
      </g>
    </svg>
  );
};

export default SvgBillDeclined;
