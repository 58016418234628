import React, { forwardRef } from 'react';
import { cn } from '@bem-react/classname';
import FormFieldError from '../FormFieldError';

import './Autocomplete.less';
import AutocompleteProvider from './provider';
import Search from './Search';
import Options from './Options';

interface IProps {
  id?: string;
  name: string;
  type?: string;
  className?: string;
  placeholder?: string;
  isError?: boolean;
  errorMessage?: string;
  value?: string;
  onInputChange?: any;
  onChange: (val: any) => void;
  fetchOptions: any;
  getOptionsScheme: any;
  controlledValue?: any;
}

export const b = cn('Autocomplete');

const Autocomplete = forwardRef<HTMLInputElement, IProps>((props, ref) => {
  const {
    isError,
    errorMessage,
    className,
    onInputChange,
    onChange,
    name,
    id,
    type,
    placeholder,
    getOptionsScheme,
    fetchOptions,
    controlledValue,
  } = props;

  return (
    <AutocompleteProvider
      controlledValue={controlledValue}
      onChange={onChange}
      fetchOptions={fetchOptions}
      getOptionsScheme={getOptionsScheme}
    >
      <div className={b({}, [className])}>
        <Search
          onInputChange={onInputChange}
          isError={isError}
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          ref={ref}
        />

        <Options b={b} />

        {isError && errorMessage ? (
          <FormFieldError withIcon message={errorMessage} />
        ) : null}
      </div>
    </AutocompleteProvider>
  );
});

export default Autocomplete;
