import React, { FC } from 'react';
import ReactDOMServer from 'react-dom/server';
import styled, { css } from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import Leaflet from 'leaflet';
import { SvgLogoWithoutText } from '@/components/Svg';
import { colors, common } from '@/constants';

interface IProps {
  imageSrc?: string;
  active?: boolean;
  markerWidth?: number;
}

interface IWrapperProps {
  width: number;
}

interface IIconProps {
  active: boolean;
  width: number;
}

type TOptions = {
  promotionId?: string | number;
};

const { DEFAULT_MARKER_WIDTH, MARKER_CLASS_PREFIX } = common;

const Wrapper = styled.div<IWrapperProps>`
  position: relative;
  width: ${({ width }: IWrapperProps) => width}px;
  height: ${({ width }: IWrapperProps) => width}px;
`;

const Triangle = styled.div`
  position: absolute;
  bottom: -5px;
  left: calc(50% - 6px);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 6px 0 6px;
  border-color: ${colors.WHITE} transparent transparent transparent;
`;

const Icon = styled.div<IIconProps>`
  position: relative;
  top: -${({ width }: IIconProps) => width / 2}px;
  left: ${({ width }: IIconProps) => `calc(50% - ${width / 2}px)`};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }: IIconProps) => width}px;
  height: ${({ width }: IIconProps) => width}px;
  box-shadow: 0 5px 6px 0 ${hexToRgba(colors.BLACK, 0.2)};
  background-color: ${colors.WHITE};
  border-radius: 99px;
  transition: all 0.3s;

  ${({ active }: IIconProps) => {
    if (active) {
      return css`
        background-color: ${colors.BLUE};

        & > ${Triangle} {
          border-color: ${colors.BLUE} transparent transparent transparent;
        }
      `;
    }
    return '';
  }}

  &:hover {
    transform: scale(1.1);
  }
`;

const ImageWrapper = styled.div`
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 99px;
  overflow: hidden;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 99px;
  overflow: hidden;
`;

const MarkerIcon: FC<IProps> = ({
  imageSrc = '',
  markerWidth = DEFAULT_MARKER_WIDTH,
  active = false,
}: IProps) => {
  return (
    <Wrapper className="fartsa-marker-icon" width={markerWidth}>
      <Icon width={markerWidth} active={active}>
        <ImageWrapper className="fartsa-marker-icon-image-wrapper">
          {imageSrc ? (
            <Image src={imageSrc} />
          ) : (
            <SvgLogoWithoutText
              width={20}
              height={20}
              fill={active ? colors.WHITE : colors.BLUE}
            />
          )}
        </ImageWrapper>
        <Triangle className="fartsa-marker-icon-triangle" />
      </Icon>
    </Wrapper>
  );
};

export default MarkerIcon;

export const createMarkerIcon = (
  imageSrc: string,
  markerId: string | number,
  activeMarkerId: string | number,
  markerSize: number,
  options?: TOptions,
) => {
  const markerClassName = `${MARKER_CLASS_PREFIX}-${markerId}`;
  const promotionClassName = `p-i${options?.promotionId}-`;

  const domContent = ReactDOMServer.renderToString(
    <MarkerIcon
      imageSrc={imageSrc}
      active={markerId === activeMarkerId}
      markerWidth={markerSize}
    />,
  );
  return Leaflet.divIcon({
    className: `${markerClassName} ${promotionClassName}`,
    iconSize: [markerSize, markerSize],
    html: domContent,
  });
};
