import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import qs from 'query-string';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { store } from '@/store/index';
import { authActions } from '@/store/actions';
import {
  IAccessTokenData,
  IAccessTokenDataFromAPI,
  IApiClientConfig,
} from '@/types';

let attemptsToGetToken = 0;

const getAccessToken = () => store.getState().auth.accessToken || '';

const getRefreshToken = () => store.getState().auth.refreshToken;

const saveAccessToken = (data: IAccessTokenData) => {
  const { dispatch } = store;
  dispatch(authActions.setAuthData(data));
};

const clearAccessToken = () => {
  const { dispatch } = store;
  dispatch(authActions.signOut());
};

const createRefreshAccessTokenHandler = (url: string) => (
  failedRequest: AxiosError,
) => {
  const instanceConfigRefreshAccessToken: any = {
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${btoa(process.env.REACT_APP_AUTH_CLIENT_ID! + ':')}`,
    },
    withCredentials: false,
    baseURL: url || process.env.REACT_APP_API_URL,
  };

  const refreshToken = getRefreshToken();

  const postBody = {
    refresh_token: refreshToken,
    grant_type: 'refresh_token',
  };

  const apiRefreshAccessTokenClient = axios.create(
    instanceConfigRefreshAccessToken,
  );

  if (attemptsToGetToken > 0 || !refreshToken) {
    const error = new Error('Refresh token is empty');
    return Promise.reject(error);
  }

  return apiRefreshAccessTokenClient
    .post('connect/token', qs.stringify(postBody))
    .then((response: { data: IAccessTokenDataFromAPI }) => {
      const { data }: { data: IAccessTokenDataFromAPI } = response;

      const authData = {
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
        expiresIn: data.expires_in,
      };

      saveAccessToken(authData);

      // eslint-disable-next-line
      failedRequest.response!.config.headers.Authorization = `Bearer ${data.access_token}`;

      return Promise.resolve();
    })
    .catch((error: AxiosError) => {
      attemptsToGetToken += 1;
      clearAccessToken();
      return Promise.reject(error);
    });
};

export default (config: IApiClientConfig = {}) => {
  const { requestConfig = {} as AxiosRequestConfig, withAccessToken = true } = config;

  requestConfig.baseURL =
    requestConfig.baseURL || process.env.REACT_APP_API_URL;

  const instance = axios.create(requestConfig);

  instance.interceptors.request.use((request) => {
    const token = getAccessToken();

    if (withAccessToken) {
      if (token) {
        request.headers.Authorization = `Bearer ${token}`;
      } else {
        // eslint-disable-next-line
        throw new axios.Cancel('Request canceled');
      }
    } else if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    } else {
      request.headers.Authorization = `Basic ${process.env.REACT_APP_BASIC_AUTH_KEY}`;
    }
    return request;
  });

  createAuthRefreshInterceptor(
    instance,
    createRefreshAccessTokenHandler(requestConfig.baseURL!),
  );

  return instance;
};
