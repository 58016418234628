import { useLocation } from 'react-router-dom';
import qs, { ParsedQuery } from 'query-string';

const useMapBounds = () => {
  const location = useLocation();

  const getMapBoundsFromUrl = () => {
    const {
      query,
    }: {
      query: ParsedQuery<any>;
    } = qs.parseUrl(location.search);

    if (query.ne_lat && query.ne_lng && query.sw_lat && query.sw_lng) {
      const mapBounds = {
        neLat: Number(query.ne_lat),
        neLng: Number(query.ne_lng),
        swLat: Number(query.sw_lat),
        swLng: Number(query.sw_lng),
      };

      return mapBounds;
    }

    return null;
  };

  return {
    getMapBoundsFromUrl,
  };
};

export { useMapBounds };
