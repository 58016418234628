import React, { FC, SyntheticEvent, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import moment, { Moment } from 'moment';
import { cn } from '@bem-react/classname';

import { RadioButton } from '@/components/RadioButton';
import { SvgCalendar, SvgArrowLeft, SvgArrowRight } from '@/components/Svg';

import { TDatesState, IHandleDatesChange, IHandleFocusChange } from './types';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import './DateRange.less';

interface IProps {
  startDate?: Moment;
  endDate?: Moment;
  className?: string;
  handleClose?: (final: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => void;
}

const cnDateRange = cn('DateRange');

const DateRange: FC<IProps> = (props) => {
  const { className, endDate, startDate, handleClose } = props;

  const [period, setPeriod] = useState('');
  const [dates, setDates] = useState<TDatesState>({
    startDate: startDate || null,
    endDate: endDate || null,
    focusedInput: null,
  });

  const handlePeriodChange = (e: SyntheticEvent<HTMLInputElement>) => {
    let startPeriod = dates.startDate;

    if (e.currentTarget.value === 'week') {
      startPeriod = moment().day(-6);
    }

    if (e.currentTarget.value === 'month') {
      startPeriod = moment().month(-1);
    }

    setPeriod(e.currentTarget.value);
    setDates({
      focusedInput: 'startDate',
      startDate: startPeriod,
      endDate: moment(),
    });
  };

  const handleDatesChange: IHandleDatesChange = (changedDates) => {
    setDates((oldDates) => ({
      ...oldDates,
      startDate: changedDates.startDate,
      endDate: changedDates.endDate,
    }));
  };

  const handleFocusChange: IHandleFocusChange = (focusedInput) => {
    setDates((oldDates) => ({ ...oldDates, focusedInput }));
  };

  return (
    <div
      className={cnDateRange({ focused: Boolean(dates.focusedInput) }, [
        className,
      ])}
    >
      <div className={cnDateRange('Presets')}>
        <RadioButton
          name="range"
          id="weekRange"
          value="week"
          currentValue={period}
          className={cnDateRange('Preset')}
          onChange={handlePeriodChange}
        >
          Неделя
        </RadioButton>

        <RadioButton
          name="range"
          id="monthRange"
          value="month"
          currentValue={period}
          className={cnDateRange('Preset')}
          onChange={handlePeriodChange}
        >
          Месяц
        </RadioButton>
      </div>

      <DateRangePicker
        noBorder
        hideKeyboardShortcutsPanel
        daySize={28}
        minimumNights={0}
        isOutsideRange={() => false}
        transitionDuration={200}
        customInputIcon={<SvgCalendar height={18} width={22} />}
        inputIconPosition="after"
        startDate={dates.startDate}
        startDateId="startDate"
        endDate={dates.endDate}
        endDateId="endDate"
        onDatesChange={handleDatesChange}
        focusedInput={dates.focusedInput}
        onFocusChange={handleFocusChange}
        onClose={handleClose}
        displayFormat="dd, DD MMM YYYY"
        customArrowIcon="—"
        navPrev={<SvgArrowLeft width={13} height={13} />}
        navNext={<SvgArrowRight width={13} height={13} />}
        verticalSpacing={23}
      />
    </div>
  );
};

export default DateRange;
