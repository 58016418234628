import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgClose: FC<ISvgProps> = (props: ISvgProps) => {
  const { fill } = props;
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M8 5.979L2.438.417A1.429 1.429 0 00.421.421a1.423 1.423 0 00-.004 2.017L5.979 8 .417 13.562a1.429 1.429 0 00.004 2.017c.562.562 1.461.56 2.017.004L8 10.021l5.562 5.562a1.429 1.429 0 002.017-.004c.562-.562.56-1.461.004-2.017L10.021 8l5.562-5.562a1.429 1.429 0 00-.004-2.017 1.423 1.423 0 00-2.017-.004L8 5.979z"
      />
    </svg>
  );
};

export default SvgClose;
