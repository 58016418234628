import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { wrapElement } from '@/helpers/wrapElement';
import { colors } from '@/constants';

interface IProps {
  markup: string;
}

const StyledArticle = styled.article`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 8px;
    font-weight: 900;
    line-height: 1.5;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 14px;
  }

  p,
  ul,
  ol,
  blockquote,
  table {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    margin-left: 12px;
    list-style: none;
  }

  ol {
    margin-left: 30px;
    list-style: decimal;
  }

  ul,
  ol {
    li {
      margin-bottom: 8px;
    }
  }

  ul {
    li {
      display: flex;
      flex-direction: row;

      &::before {
        content: '•';
        color: ${colors.BLUE};
        font-weight: bold;
        display: block;
        width: 6px;
        margin-right: 18px;
      }
    }
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  a {
    transition: all 0.2s ease-out 0s;
    text-decoration: none;
    color: ${colors.BLUE};
    border-bottom: 1px solid transparent;

    &:hover {
      color: ${colors.AZUL};
      border-color: ${colors.BLUE};
    }
  }

  img {
    margin: 0 auto;
    max-width: 100%;
  }

  blockquote {
    margin: 16px auto;
    padding: 16px;
    font-style: italic;
    border-left: 5px solid ${colors.BLUE};
  }

  table {
    thead {
      font-weight: 900;

      tr {
        background-color: ${colors.BLUE};
        color: ${colors.WHITE};
      }
    }

    tbody {
      tr {
        &:nth-child(even) {
          background-color: ${colors.GHOST_WHITE};
        }
      }
    }

    th,
    td {
      padding: 10px;
      border: 1px solid ${colors.LILAC_WHITE};
    }
  }

  .table-wrap {
    margin: 0 0 16px;
    overflow-x: auto;
  }
`;

const Article: FC<IProps> = ({ markup }: IProps) => {
  const createMarkup = () => ({ __html: markup });

  useEffect(() => {
    const tables = document.querySelectorAll('table');

    tables.forEach((el) => {
      const wrapper = document.createElement('div');
      wrapper.className = 'table-wrap';

      wrapElement(el, wrapper);
    });
  }, []);

  return <StyledArticle dangerouslySetInnerHTML={createMarkup()} />;
};

export default Article;
