import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '@/constants';

interface IProps {
  title: string;
  children: ReactNode | ReactNode[];
}

const Wrapper = styled.div`
  padding: 15px 0;
  border-bottom: 1px solid ${colors.LILAC_WHITE};
`;

const Title = styled.div`
  margin-top: 6px;
  margin-bottom: 6px;
  padding: 0 16px;
  font-size: 16px;
  font-weight: 500;
  color: ${colors.MISCHKA};
`;

const SearchResultSection: FC<IProps> = ({ title, children }: IProps) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {children}
    </Wrapper>
  );
};

export default SearchResultSection;
