
import React, { useState, useRef, useEffect, FormEvent } from 'react';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { useIntl } from 'react-intl';
import hexToRgba from 'hex-to-rgba';
import { usePopper } from 'react-popper';
import { BaseInput } from '@/components/FormControls';
import { QuickSearchResults } from '@/components/QuickSearchResults';
import { SvgClose, SvgSearch } from '@/components/Svg';
import { useOutsideClick } from '@/hooks/useOutsideClick';
import { useWindowSize } from '@/hooks/useWindowSize';
import { useSearchString } from '@/hooks/useSearchString';
import { colors } from '@/constants';

const Form = styled.form`
  position: relative;
  flex: 1;
  width: 100%;
  height: 36px;

  input {
    font-size: 14px;
    padding: 10px 16px;
  }

  ${down('sm')} {
    display: none;
  }
`;

const SearchButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 8px;
`;

const SearchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 36px;
  border: 0;
  background-color: ${colors.BLUE};
  border-radius: 8px;
`;

const SearchResultsDropdown = styled.div`
  position: absolute;
  z-index: 9999;
  top: 38px;
  left: 0;
  width: 100%;

  ${down('md')} {
    min-width: 600px;
  }

  ${down('sm')} {
    display: none;
  }
`;

const SearchResultsWrapper = styled.div`
  width: 100%;
  background-color: ${colors.WHITE};
  border-radius: 8px;
  box-shadow: 0 6px 20px 0 ${hexToRgba(colors.BLACK, 0.08)};
  overflow: hidden;
`;

const SearchPromotionsForm = () => {
  const {
    searchString,
    setSearchString,
    setSearchStringInUrl,
    getSearchStringFromUrl,
  } = useSearchString();

  const [inputValue, setInputValue] = useState<string>(
    getSearchStringFromUrl(),
  );

  const [visibleResults, setVisibleResults] = useState(false);

  const intl = useIntl();

  const formRef = useRef<HTMLFormElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const tPlaceholder = intl.formatMessage({
    id: 'common.search',
  });

  const clearButtonPosition = {
    right: 46,
  };

  const { width } = useWindowSize();

  const { styles, attributes } = usePopper(
    inputRef.current,
    dropdownRef.current,
    {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [0, 10],
          },
        },
      ],
    },
  );

  useOutsideClick(formRef, () => {
    if (visibleResults) {
      setVisibleResults(false);
    }
  });

  useEffect(() => {
    if (searchString !== inputValue) {
      setInputValue(searchString);
    }
  }, [searchString]); // eslint-disable-line

  useEffect(() => {
    if (width && width < 768) {
      setVisibleResults(false);
    }
  }, [width]);

  const startSearch = () => {
    setVisibleResults(false);
    setSearchStringInUrl(inputValue);
    setSearchString(inputValue);
  };

  const handleInputFocus = () => {
    if (inputValue) {
      setVisibleResults(true);
    }
  };

  const handleInputChange = (value: string) => {
    setInputValue(value);

    if (value) {
      setVisibleResults(true);
    } else {
      setVisibleResults(false);
    }
  };

  const handleClearClick = () => {
    setInputValue('');
  };

  const handleSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    startSearch();
  };

  const handleResultItemClick = () => {
    setVisibleResults(false);
  };

  const renderClearIcon = () => (
    <SvgClose width={10} height={10} fill={colors.MISCHKA} />
  );

  return (
    <Form ref={formRef} onSubmit={handleSubmitForm} autoComplete="off">
      <BaseInput
        ref={inputRef}
        name="search"
        placeholder={tPlaceholder}
        value={inputValue}
        withClearButton
        clearButtonPosition={clearButtonPosition}
        renderClearIcon={renderClearIcon}
        onInputChange={handleInputChange}
        onInputFocus={handleInputFocus}
        onClearClick={handleClearClick}
        autoComplete="off"
      />
      <SearchButtonWrapper>
        <SearchButton type="submit">
          <SvgSearch width={22} fill={colors.WHITE} />
        </SearchButton>
      </SearchButtonWrapper>
      <SearchResultsDropdown
        ref={dropdownRef}
        style={styles.popper}
        {...attributes.popper}
      >
        {visibleResults ? (
          <SearchResultsWrapper>
            <QuickSearchResults
              searchString={inputValue}
              onStartSearch={startSearch}
              onItemClick={handleResultItemClick}
            />
          </SearchResultsWrapper>
        ) : null}
      </SearchResultsDropdown>
    </Form>
  );
};

export default SearchPromotionsForm;
