import { useState, useEffect } from 'react';

interface IGeolocationPosition {
  timestamp: number;
  coords: ICoords;
}

interface ICoords {
  latitude: number;
  longitude: number;
}

interface IGeolocationError {
  code: number;
  message: string;
}

const useGeolocationPosition = () => {
  const [
    geolocationPosition,
    setGeolocationPosition,
  ] = useState<ICoords | null>(null);
  const [geolocationError, setGeolocationError] = useState<string | null>(null);

  const onChange = ({ coords }: IGeolocationPosition) => {
    const { latitude, longitude } = coords;

    setGeolocationPosition({
      latitude,
      longitude,
    });
  };

  const onError = (error: IGeolocationError) => {
    setGeolocationError(error.message);
  };

  useEffect(() => {
    const geo = navigator.geolocation;

    if (!geo) {
      setGeolocationError('Geolocation is not supported');
      return;
    }

    const watcher = geo.watchPosition(onChange, onError);

    // eslint-disable-next-line
    return () => {
      geo.clearWatch(watcher);
    };
  }, []);

  return { geolocationPosition, geolocationError };
};

export { useGeolocationPosition };
