import { useState, useEffect } from 'react';

export const useInterval = () => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (timer) {
      intervalId = setInterval(() => {
        setTimer((val) => val - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [timer]);

  return { timer, setTimer };
};
