import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';
// @ts-ignore
import useMobileDetect from 'use-mobile-detect-hook';
import { down } from 'styled-breakpoints';
import { Container } from 'styled-bootstrap-grid';
import { Helmet } from 'react-helmet';
import { Header } from '@/components/Header';
import { Footer } from '@/components/Footer';
import { Page, PageContent } from '@/components/shared';
import { PromotionsMap } from '@/components/PromotionsMap';
import { useAuth } from '@/data/useAuth';
import { colors } from '@/constants';

const Message = styled.p`
  padding-top: 80px;
  margin-bottom: 20px;
  ${down('lg')} {
    padding-top: 120px;
  }
`;

const AuthLink = styled(Link)`
  color: ${colors.BLUE};
  text-decoration: none;

  &:hover {
    color: ${colors.AZUL};
  }
`;

const MapPage = () => {

  const intl = useIntl();
  const { isAuth } = useAuth();
  const detectMobile = useMobileDetect();
  const isMobile = detectMobile.isMobile();

  const tSeoTitle = intl.formatMessage({
    id: 'seo.title.map',
  });


  const renderPageHead = () => (
    <Helmet>
      <title>{tSeoTitle}</title>
    </Helmet>
  );

  if (isAuth) {
    return (
      <>
        {renderPageHead()}
        <Header fluid mobileDeviceMode={isMobile} mapPageMode />
        <PageContent isMap mobileDeviceMode={isMobile}>
          <PromotionsMap />
        </PageContent>
        {isMobile ? null : <Footer />}
      </>
    );
  }

  return (
    <Page>
      {renderPageHead()}
      <Header mobileDeviceMode={isMobile} />
      <PageContent isMap mobileDeviceMode={isMobile}>
        <Container>
          <Message>
            <FormattedMessage id="promotionsMap.auth" />
          </Message>
          <AuthLink to="/signin">
            <FormattedMessage id="auth.logIn" />
          </AuthLink>
        </Container>
      </PageContent>
      {isMobile ? null : <Footer />}
    </Page>
  );
};

export default MapPage;
