import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgCase: FC<ISvgProps> = (props: ISvgProps) => {
  return (
    <svg viewBox="0 0 13 13" {...props}>
      <path d="M11.6 2.448h-1.456V1.4c0-.772-.628-1.4-1.4-1.4H4.256c-.772 0-1.4.628-1.4 1.4v1.048H1.4c-.772 0-1.4.628-1.4 1.4v3.468c0 .21.17.38.38.38h.028V11.6c0 .773.628 1.401 1.4 1.401h9.383c.773 0 1.401-.628 1.401-1.4V7.696h.027A.38.38 0 0013 7.315V3.848c0-.772-.628-1.4-1.4-1.4zM3.616 1.4a.64.64 0 01.64-.64h4.487a.64.64 0 01.639.64v1.047H3.617V1.4zM.762 3.848a.64.64 0 01.639-.639h10.198a.64.64 0 01.64.64v3.086H7.696v-.843a.38.38 0 00-.381-.38H5.684a.38.38 0 00-.38.38v.843H.761V3.848zm5.303 3.47v-.845h.87v1.686h-.87v-.84zM11.83 11.6a.64.64 0 01-.639.64H1.81a.64.64 0 01-.64-.64V7.697h4.134v.843c0 .21.17.38.381.38h1.632c.21 0 .38-.17.38-.38v-.843h4.134v3.902z" />
    </svg>
  );
};

export default SvgCase;
