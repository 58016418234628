import React, { useCallback, useState } from 'react';
import * as yup from 'yup';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { Container } from 'styled-bootstrap-grid';
import { cn } from '@bem-react/classname';
import { yupResolver } from '@hookform/resolvers';

import { useAuth } from '@/data/useAuth';
import { useUser } from '@/data/useUser';

import { getCurrency } from '@/helpers/getСurrency';

import { Layout } from '@/components/Layout';
import { Button } from '@/components/Buttons';
import { BaseInput } from '@/components/FormControls';

import './WithdrawPage.less';
import { useLegal } from '@/data/useLegal';
import withdrowApi from '../../api/withdraw';
import { Modal } from '@/components/Modal';
import SuccessWithdraw from '@/components/SuccesWithdraw/SuccessWithdraw';

const b = cn('WithdrawPage');

const WithdrawPage = () => {
  const { isAuth } = useAuth();
  const { formatMessage } = useIntl();
  const { user, mutate } = useUser();
  const { bankAccount } = useLegal();
  const balance = user?.balance ? user.balance.availableBalance : 0;

  const stub = {
    accountNumber: bankAccount?.accountNumber,
    bankName: bankAccount?.bankName,
    bic: bankAccount?.bik,
    correspondentNumber: bankAccount?.korrAccountNumber,
  };

  const tErrorRequiredCode = formatMessage({ id: 'form.errors.required' });
  const tErrorMaxCode = formatMessage({ id: 'form.errors.money.max' });
  const tTitle = formatMessage({ id: 'withdraw.title' });
  const tTextAllowed = formatMessage({ id: 'withdraw.text.allowed' });
  const tTextResult = formatMessage({ id: 'withdraw.text.result' });
  const tLabelAccountNumber = formatMessage({
    id: 'withdraw.labels.accountNumber',
  });
  const tLabelCorrespondentNumber = formatMessage({
    id: 'withdraw.labels.correspondentNumber',
  });
  const tLabelBankName = formatMessage({ id: 'withdraw.labels.bankName' });
  const tLabelBic = formatMessage({ id: 'withdraw.labels.bic' });
  const tLabelSum = formatMessage({ id: 'withdraw.labels.sum' });
  const tPlaceholderAccountNumber = formatMessage({
    id: 'withdraw.placeholders.accountNumber',
  });
  const tPlaceholderCorrespondentNumber = formatMessage({
    id: 'withdraw.placeholders.correspondentNumber',
  });
  const tPlaceholderBankName = formatMessage({
    id: 'withdraw.placeholders.bankName',
  });
  const tPlaceholderBic = formatMessage({ id: 'withdraw.placeholders.bic' });
  const tPlaceholderSum = formatMessage({ id: 'withdraw.placeholders.sum' });
  const tSave = formatMessage({ id: 'withdraw.save' });

  const validationSchema = yup.object().shape({
    sum: yup
      .number()
      .required(tErrorRequiredCode)
      .positive()
      .max(balance, tErrorMaxCode),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    errors,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleInputChange = useCallback(
    (name: string) => (value: string) => {
      clearErrors(name);
      setValue(name, value);
    },
    [clearErrors, setValue],
  );
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const onSubmit = useCallback((data: any) => withdrowApi.postWithdraw(data.sum).then(() => {
    setOpenSuccessModal(true);
    mutate();
    setValue('sum', '');
  }), []);


  return (
    <Layout
      isAuth={isAuth}
      i18nSeoTitle="seo.title.withdraw"
      i18nTitle="common.withdraw"
    >
      <Container className={b()}>
        <h2 className={b('Title')}>{tTitle}</h2>

        <div className={b('Block')}>
          <label className={b('Label')} htmlFor="bankName">
            {tLabelBankName}
          </label>

          <BaseInput
            disabled
            className={b('Field')}
            id="bankName"
            name="bankName"
            value={stub.bankName}
            placeholder={tPlaceholderBankName}
          />
        </div>

        <div className={b('Block')}>
          <label className={b('Label')} htmlFor="bic">
            {tLabelBic}
          </label>

          <BaseInput
            disabled
            className={b('Field')}
            id="bic"
            name="bic"
            value={stub.bic}
            placeholder={tPlaceholderBic}
          />
        </div>

        <div className={b('Block')}>
          <label className={b('Label')} htmlFor="correspondentNumber">
            {tLabelCorrespondentNumber}
          </label>

          <BaseInput
            disabled
            className={b('Field')}
            id="correspondentNumber"
            name="correspondentNumber"
            value={stub.correspondentNumber}
            placeholder={tPlaceholderCorrespondentNumber}
          />
        </div>

        <div className={b('Block')}>
          <label className={b('Label')} htmlFor="accountNumber">
            {tLabelAccountNumber}
          </label>

          <BaseInput
            disabled
            className={b('Field')}
            id="accountNumber"
            name="accountNumber"
            value={stub.accountNumber}
            placeholder={tPlaceholderAccountNumber}
          />
        </div>
      </Container>

      <Container>
        <div className={b('Money-Container')}>
          <div className={b('Money-Block')}>
            <p>{tTextAllowed}</p>
            <span className={b('Money')}>{getCurrency(balance)}</span>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={b('Block')}>
              <label className={b('Label')} htmlFor="sum">
                {tLabelSum}
              </label>

              <BaseInput
                ref={register}
                className={b('Field')}
                type="number"
                id="sum"
                name="sum"
                placeholder={tPlaceholderSum}
                value={getValues('sum')}
                onInputChange={handleInputChange('sum')}
                isError={errors.sum}
                errorMessage={errors.sum?.message}
              />
            </div>

            <div className={b('Money-Block', { bold: true })}>
              <p>{tTextResult}</p>
              <span>{getCurrency(Math.min(getValues('sum'), balance))}</span>
            </div>

            <Button type="submit" disabled={!stub.correspondentNumber} className={b('Button')} displayType="primary">
              {tSave}
            </Button>
          </form>
        </div>
      </Container>
      <Modal
        visible={openSuccessModal}
        onCancel={() => setOpenSuccessModal(false)}
      >
        <SuccessWithdraw onClose={() => setOpenSuccessModal(false)} />
      </Modal>
    </Layout>
  );
};

export default WithdrawPage;
