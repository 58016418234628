import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgPassportPhoto: FC<ISvgProps> = (props: ISvgProps) => {
  return (
    <svg viewBox="0 0 82 56" {...props}>
      <defs>
        <path
          id="a"
          d="M0 1.44C0 .64.61 0 1.37 0h11.22c.75 0 1.36.64 1.36 1.44v7.95c0 .8-.6 1.43-1.36 1.43H1.37A1.4 1.4 0 010 9.4V1.44z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g fill="#304FFE" fillRule="nonzero">
          <path d="M40.04 23.12c0 6.53-5.12 11.82-11.44 11.82-6.33 0-11.45-5.3-11.45-11.82 0-6.53 5.13-11.82 11.45-11.82 6.32 0 11.44 5.29 11.44 11.82z" />
          <path d="M39.76 13.32c0 7.05-5.53 12.77-12.35 12.77-6.83 0-12.36-5.72-12.36-12.77S20.58.56 27.4.56c6.82 0 12.35 5.71 12.35 12.76z" />
          <path d="M41.92 11.94c0 2.02.32 9.03-6.02 9.03-3.42 0-6.51-4.5-6.51-9.03 0-4.54 2.77-8.21 6.19-8.21 3.91 0 6.34 3.67 6.34 8.2zM18.43 22.07c.3 1.8-.27 3.4-1.26 3.58-1 .17-2.03-1.16-2.32-2.97-.29-1.8.28-3.4 1.26-3.58 1-.17 2.03 1.16 2.32 2.97z" />
          <path d="M38.44 22.07c-.29 1.8.28 3.4 1.27 3.57.99.17 2.02-1.15 2.31-2.96.3-1.8-.27-3.4-1.26-3.58-.99-.17-2.03 1.16-2.32 2.97zM21.85 31h13.58v24.4H21.85z" />
          <path d="M2.49 48.2C1.05 50.43.87 53.9.96 55.35h25.18V35.57l-4.05-2.05c-.03.87-.4 3.03-1.62 4.74-1.52 2.14-6.38 2.88-10.25 4.18-3.86 1.3-5.93 2.97-7.73 5.76zM54.89 48.2c1.44 2.23 1.62 5.7 1.53 7.15H31.24V35.57l4.05-2.05c.03.87.4 3.03 1.61 4.74 1.53 2.14 6.39 2.88 10.26 4.18 3.86 1.3 5.93 2.97 7.73 5.76z" />
        </g>
        <path
          d="M78.62.69a2.8 2.8 0 012.87 2.73v49.16a2.8 2.8 0 01-2.87 2.73h-34.5a2.8 2.8 0 01-2.88-2.73V3.42A2.8 2.8 0 0144.1.7h34.5z"
          fill="#DFE5FF"
          fillRule="nonzero"
        />
        <path
          d="M77.54 12.34a.7.7 0 00-.72-.68.7.7 0 00-.72.68h1.44zm-1.44 6.9c0 .39.33.7.72.7.4 0 .72-.31.72-.7H76.1zm0-6.9v6.9h1.44v-6.9H76.1zM77.54 35.42a.7.7 0 00-.72-.68.7.7 0 00-.72.68h1.44zm-1.44 6.9c0 .38.33.69.72.69.4 0 .72-.3.72-.68H76.1zm0-6.9v6.9h1.44v-6.9H76.1z"
          fill="#2945E4"
          fillRule="nonzero"
        />
        <path
          d="M80.48 27.96H42.25v1.44h38.23z"
          fill="#F8F9FF"
          fillRule="nonzero"
        />
        <path
          d="M69.97 6.79a.7.7 0 00.68-.72.7.7 0 00-.68-.72V6.8zm-17.2-1.44a.7.7 0 00-.7.72c0 .4.31.72.7.72V5.35zm17.2 0h-17.2V6.8h17.2V5.35zM66.83 10.48a.7.7 0 00.68-.72.7.7 0 00-.68-.72v1.44zM55.9 9.04a.7.7 0 00-.68.72c0 .4.3.72.68.72V9.04zm10.93 0H55.9v1.44h10.93V9.04zM70.91 19.76a.7.7 0 00.68-.72.7.7 0 00-.68-.72v1.44zm-8.74-1.44a.7.7 0 00-.68.72c0 .4.3.72.68.72v-1.44zm8.74 0h-8.74v1.44h8.74v-1.44zM55.44 19.76a.7.7 0 00.68-.72.7.7 0 00-.68-.72v1.44zm-6.56-1.44a.7.7 0 00-.68.72c0 .4.3.72.68.72v-1.44zm6.56 0h-6.56v1.44h6.56v-1.44zM69.42 35.74a.7.7 0 00.68-.72.7.7 0 00-.68-.72v1.44zm-6.37-1.44a.7.7 0 00-.68.72c0 .4.3.72.68.72V34.3zm6.37 0h-6.37v1.44h6.37V34.3zM70.63 44.48a.7.7 0 00.68-.72.7.7 0 00-.68-.72v1.44zm-8.79-1.44a.7.7 0 00-.68.72c0 .4.3.72.68.72v-1.44zm8.79 0h-8.79v1.44h8.79v-1.44zM68 39.87a.7.7 0 00.68-.72.7.7 0 00-.68-.71v1.43zm-3.53-1.43a.7.7 0 00-.69.72c0 .4.31.71.69.71v-1.43zm3.53 0h-3.53v1.43H68v-1.43z"
          fill="#304FFE"
          fillRule="nonzero"
        />
        <path
          d="M56.48 22.59a3.64 3.64 0 01-3.73-3.55c0-1.95 1.67-3.54 3.73-3.54s3.73 1.59 3.73 3.54a3.64 3.64 0 01-3.73 3.55z"
          fill="#FF1744"
          fillRule="nonzero"
          opacity=".49"
        />
        <path
          d="M80.05 48.83l-.01 3.76a1.4 1.4 0 01-1.44 1.36l-34.48-.01a1.4 1.4 0 01-1.44-1.37l.01-3.75 37.36.01z"
          fill="#F8F9FF"
          fillRule="nonzero"
        />
        <path
          d="M56.11 32.22c.8 0 1.44.61 1.44 1.37V44.8a1.4 1.4 0 01-1.44 1.36h-7.95a1.4 1.4 0 01-1.43-1.36V33.59c0-.76.64-1.37 1.43-1.37h7.95z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <g transform="rotate(90 12.5 45.06)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <g mask="url(#b)">
            <path
              fill="#304FFE"
              fillRule="nonzero"
              d="M6.3 2.74a2.81 2.81 0 012.88 2.74A2.81 2.81 0 016.3 8.2a2.81 2.81 0 01-2.88-2.73A2.81 2.81 0 016.3 2.74z"
            />
            <path
              fill="#304FFE"
              fillRule="nonzero"
              d="M3.96 2.81c1.71 0 3.1 1.33 3.1 2.96s-1.39 2.95-3.1 2.95A3.03 3.03 0 01.85 5.77c0-1.63 1.39-2.96 3.1-2.96z"
            />
            <path
              fill="#304FFE"
              fillRule="nonzero"
              d="M3.62 2.26c.5 0 2.2-.08 2.2 1.44 0 .82-1.1 1.55-2.2 1.55s-2-.66-2-1.47c0-.94.9-1.52 2-1.52zM6.1 7.91c.44-.08.83.07.87.34.04.26-.28.54-.72.62-.44.08-.83-.07-.87-.34-.05-.26.28-.54.72-.62z"
            />
            <path
              fill="#304FFE"
              fillRule="nonzero"
              d="M6.1 3.12c.44.08.83-.07.87-.34.04-.26-.28-.54-.72-.62-.44-.08-.83.08-.87.34-.05.27.28.54.72.62zM8.13 7.09V3.84h5.94V7.1z"
            />
            <path
              fill="#304FFE"
              fillRule="nonzero"
              d="M12.3 11.79c.55.34 1.4.38 1.75.36V6.14H9.23l-.5.96c.21 0 .74.1 1.16.39.52.36.7 1.52 1.01 2.45.32.92.73 1.42 1.4 1.85zM12.3-.88a3.33 3.33 0 011.75-.36v6.01H9.23l-.5-.96c.21 0 .74-.1 1.16-.39.52-.36.7-1.52 1.01-2.45.32-.92.73-1.42 1.4-1.85z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgPassportPhoto;
