import React, { FC, createRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { parsePhoneNumber } from '@redkassa/phone-parser';
import { colors, phoneNumberFormats } from '@/constants';
import FormFieldError from './FormFieldError';

interface IProps {
  id?: string;
  name?: string;
  defaultValue?: string;
  dialCode: string;
  countryCode: string;
  placeholder?: string;
  disabled?: boolean;
  isError?: boolean;
  errorMessage?: string;
  withErrorIcon?: boolean;
  className?: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  focusOnOpen?: boolean;
}

interface IInputWrapperProps {
  focused: boolean;
  isError: boolean;
}

interface IInputProps {
  isFilledWithDialCode: boolean;
}

const InputWrapper = styled.div<IInputWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-height: 54px;
  overflow: hidden;
  border-radius: 8px;

  ${({ focused, isError }: IInputWrapperProps) => {
    if (focused) {
      return css`
        box-shadow: inset 0 0 0 2px ${colors.BLUE};
      `;
    }
    if (isError) {
      return css`
        box-shadow: inset 0 0 0 2px ${colors.RED};
      `;
    }
    return css`
      box-shadow: inset 0 0 0 1px ${colors.LILAC_WHITE};
    `;
  }}
`;

const Input = styled.input<IInputProps>`
  width: 100%;
  max-height: 54px;
  padding: 17px 12px 18px 12px;
  font-size: 16px;
  font-weight: 400;
  color: ${colors.BLACK};
  border: 0;
  border-radius: 8px;
  background: transparent;

  ${({ isFilledWithDialCode }) => {
    if (isFilledWithDialCode) {
      return css`
        padding-left: 10px;
      `;
    }
    return '';
  }}

  &::placeholder {
    color: ${colors.MISCHKA};
  }
`;

const DialCode = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 17px 0 18px 12px;
  font-size: 16px;
  font-weight: 400;
  color: ${colors.BLACK};
`;

const DialCodeDivider = styled.div`
  margin-left: 10px;
  border-right: 1px solid ${colors.LILAC_WHITE};
`;

const resolvers = [
  {
    options: [
      {
        value: '7',
        replace: true,
      },
    ],
    target: {
      firstChar: '7',
      code: 'RU',
    },
  },
];

const PhoneInput: FC<IProps> = ({
  dialCode,
  countryCode,
  defaultValue = '',
  id = '',
  name = '',
  placeholder = '',
  disabled = false,
  isError = false,
  errorMessage = '',
  withErrorIcon = true,
  className,
  onChange,
  onFocus = () => { },
  onBlur = () => { },
  focusOnOpen,
}: IProps) => {
  const [value, setValue] = useState(defaultValue);
  const [isFocused, setFocused] = useState(false);

  const inputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (dialCode && value) {
      const result = parsePhoneNumber(`${dialCode}${value}`, {
        resolvers: countryCode === 'RU' ? resolvers : [],
        formats: phoneNumberFormats,
      });

      const start = dialCode ? dialCode.length + 1 : 0;

      setValue(result.formattedNumber.slice(start));
    }
  }, [dialCode, countryCode, value]);

  useEffect(() => {
    if (focusOnOpen && inputRef.current) {
      // иначе не работает автофокус внутри модалки
      requestAnimationFrame(() => {
        inputRef.current?.focus();
      });
    }
    // eslint-disable-next-line
  }, []);

  const handleWrapperClick = () => {
    inputRef.current?.focus();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    const { inputType } = e.nativeEvent;
    const newValue = e.target.value;
    const result = parsePhoneNumber(`${dialCode}${e.target.value}`, {
      resolvers: countryCode === 'RU' ? resolvers : [],
      formats: phoneNumberFormats,
    });

    let tempValue = result.formattedNumber;

    const start = dialCode ? dialCode.length + 1 : 0;
    const formattedNumberWithoutLastChar = tempValue.slice(
      start,
      tempValue.length - 1,
    );

    if (
      formattedNumberWithoutLastChar === newValue &&
      inputType === 'deleteContentBackward'
    ) {
      tempValue = tempValue.slice(0, tempValue.length - 2);
    }

    const resultValue = tempValue.slice(start);

    setValue(resultValue);
    onChange(resultValue);
  };

  const handleFocus = () => {
    setFocused(true);
    onFocus();
  };

  const handleBlur = () => {
    setFocused(false);
    onBlur();
  };

  const otherProps: { id?: string; name?: string } = {};

  if (id) {
    otherProps.id = id;
  }

  if (name) {
    otherProps.name = name;
  }

  return (
    <>
      <InputWrapper
        focused={isFocused}
        isError={isError}
        onClick={handleWrapperClick}
        className={className}
      >
        {(isFocused || value) && dialCode ? (
          <DialCode>
            {dialCode}
            <DialCodeDivider />
          </DialCode>
        ) : null}
        <Input
          ref={inputRef}
          isFilledWithDialCode={!!((isFocused || value) && dialCode)}
          {...otherProps}
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </InputWrapper>
      {isError && errorMessage ? (
        <FormFieldError withIcon={withErrorIcon} message={errorMessage} />
      ) : null}
    </>
  );
};

export default PhoneInput;
