
import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgCar: FC<ISvgProps> = (props: ISvgProps) => {
	return (
		<svg viewBox="0 0 20 20" {...props}>
			<g id="Car">
				<g id="Vector">
					<path d="M1.25 10H18.75C19.0952 10 19.375 9.72018 19.375 9.375C19.375 9.02982 19.0952 8.75 18.75 8.75H1.25C0.904822 8.75 0.625 9.02982 0.625 9.375C0.625 9.72018 0.904822 10 1.25 10Z" fill="#1A1A21" />
					<path d="M15 16.25V14.375C15 14.0298 14.7202 13.75 14.375 13.75C14.0298 13.75 13.75 14.0298 13.75 14.375V16.25C13.75 16.7678 14.1161 17.1339 14.1161 17.1339C14.4822 17.5 15 17.5 15 17.5H16.875C17.3928 17.5 17.7589 17.1339 17.7589 17.1339C18.125 16.7678 18.125 16.25 18.125 16.25V14.375C18.125 14.0298 17.8452 13.75 17.5 13.75C17.1548 13.75 16.875 14.0298 16.875 14.375V16.25H15Z" fill="#1A1A21" />
					<path d="M3.125 16.25V14.375C3.125 14.0298 2.84518 13.75 2.5 13.75C2.15482 13.75 1.875 14.0298 1.875 14.375V16.25C1.875 16.7678 2.24112 17.1339 2.24112 17.1339C2.60723 17.5 3.125 17.5 3.125 17.5H5C5.51777 17.5 5.88388 17.1339 5.88388 17.1339C6.25 16.7678 6.25 16.25 6.25 16.25V14.375C6.25 14.0298 5.97018 13.75 5.625 13.75C5.27982 13.75 5 14.0298 5 14.375V16.25H3.125Z" fill="#1A1A21" />
					<path d="M4.375 11.875C4.375 12.2202 4.65482 12.5 5 12.5H6.25C6.59518 12.5 6.875 12.2202 6.875 11.875C6.875 11.5298 6.59518 11.25 6.25 11.25H5C4.65482 11.25 4.375 11.5298 4.375 11.875Z" fill="#1A1A21" />
					<path d="M13.125 11.875C13.125 12.2202 13.4048 12.5 13.75 12.5H15C15.3452 12.5 15.625 12.2202 15.625 11.875C15.625 11.5298 15.3452 11.25 15 11.25H13.75C13.4048 11.25 13.125 11.5298 13.125 11.875Z" fill="#1A1A21" />
					<path fillRule="evenodd" clipRule="evenodd" d="M18.125 14.375C18.125 14.7202 17.8452 15 17.5 15H2.5C2.15482 15 1.875 14.7202 1.875 14.375V9.375C1.875 9.28746 1.89339 9.2009 1.92897 9.12093L4.26311 3.87501C4.26311 3.87501 4.41167 3.5346 4.722 3.33055C4.722 3.33055 5.03233 3.1265 5.40625 3.125L14.5963 3.12501C14.5963 3.12501 14.9677 3.1265 15.278 3.33055C15.278 3.33055 15.5883 3.5346 15.7351 3.87093L18.071 9.12093C18.1066 9.2009 18.125 9.28746 18.125 9.375V14.375ZM16.875 9.50777V13.75H3.125V9.50777L5.40876 4.375L14.5912 4.375L14.593 4.37907L16.875 9.50777Z" fill="#1A1A21" />
				</g>
			</g>
		</svg>
	);
};

export default SvgCar;
