import React, { useState, useEffect } from 'react';
import 'core-js';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { Container } from 'styled-bootstrap-grid';
import { useIntl } from 'react-intl';
import { throttle } from 'throttle-debounce';
import isEqual from 'react-fast-compare';
import { MainTitle } from '@/components/MainTitle';
import { Map as FartcaMap } from '@/components/Map';
import { MapPromotionsItems } from '@/components/MapPromotionsItems';
import { SearchFilters, SearchFiltersMenu } from '@/components/SearchFilters';
import { Loader } from '@/components/Loader';
import { usePromotions } from '@/data/usePromotions';
import { useSearchFiltersState } from '@/hooks/useSearchFiltersState';
import { useSearchString } from '@/hooks/useSearchString';
import { useSearchFilters } from '@/hooks/useSearchFilters';
import { useMapBounds } from '@/hooks/useMapBounds';
import { useGeolocationPosition } from '@/hooks/useGeolocationPosition';
import { colors } from '@/constants';
import {
  IMapBounds,
  IPromotionsFilterParams,
} from '@/types';
import ClusterSheet from './ClusterSheet';

const HEADER_HEIGHT = 62;
const DEFAULT_MAP_CENTER: [number, number] = [55.755825, 37.617298]; // Moscow


const Columns = styled(Container)`
  position: relative;
  padding: 0;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);

  ${down('lg')} {
    position: absolute;
    top: 62px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: calc(100% - ${HEADER_HEIGHT}px);
    min-height: auto;
    overflow: hidden;
  }
`;

const MapColumn = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  width: calc(100% - 792px);

  ${down('lg')} {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;

const MapColumnInner = styled.div`
  position: sticky;
  top: 0px;
  padding-top: ${HEADER_HEIGHT}px;
  margin-top: -${HEADER_HEIGHT}px;
  width: 100%;
  height: 100vh;
  contain: content;

  ${down('lg')} {
    margin: 0;
    padding: 0;
  }
`;

const CardsColumn = styled.div`
  display: block;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);

  ${down('lg')} {
    display: none;
  }
`;

const CardsColumnInner = styled.div`
  padding: 40px 15px;
  position: relative;
  z-index: 2;
  width: 792px;
`;

const CardsColumnHeader = styled.div`
  padding: 4px 30px 0;
  border-bottom: solid 1px ${colors.LILAC_WHITE};
`;

const TitleWrapper = styled.div`
  margin: 40px 0 30px 0;
`;

const SearchFiltersBarWrapper = styled.div`
  margin-bottom: 14px;
`;

const ClusterItems = styled.div`
  display: none;

  ${down('lg')} {
    display: block;
  }
`;

const MapLoader = styled.div`
  position: absolute;
  z-index: 99;
  top: 30px;
  left: calc(50% - 41px);
  display: flex;
  align-items: center;
  padding: 10px 30px;
  border-radius: 30px;
  background-color: ${colors.WHITE};
`;

const PromotionsMap = () => {
  const intl = useIntl();


  const geoPosition = useGeolocationPosition();


  const { getMapBoundsFromUrl } = useMapBounds();

  const {
    state: { filterValues },
    dispatch: filterStateDispatch,
  } = useSearchFiltersState();

  const {
    currentCategoryId,
    currentSubcategoryIds,
    currentBrandIds,
    getFiltersFromUrl,
  } = useSearchFilters();
  const { getSearchStringFromUrl } = useSearchString();

  const [isInit, setInit] = useState<boolean>(false);

  const [isActiveClusterSheet, setActiveClusterSheet] = useState(false);
  const [isUserPositionReceived, setUserPositionReceived] = useState(false);


  const [mapCenter, setMapCenter] = useState<[number, number]>(
    DEFAULT_MAP_CENTER,
  );
  const [visibleIds, setVisibleIds] = useState<Array<number | string>>([]);
  const [hiddenRender, setHiddenRender] = useState(false);

  const [filterCoordinates, setFilterCoordinates] = useState<IMapBounds | null>(
    getMapBoundsFromUrl(),
  );
  const [filterParams, setFilterParams] = useState<IPromotionsFilterParams>(
    getFiltersFromUrl().urlParams,
  );

  const { promotions, isLoading } = usePromotions({
    take: 100,
    skip: 0,
    bounds: filterCoordinates || undefined,
    searchString: getSearchStringFromUrl(),
    filters: filterParams,
    actualNow: true,
  });

  const throttleSetCurrentBounds = throttle(1000, (value: IMapBounds) => {
    filterStateDispatch({
      type: 'SET_FILTER',
      payload: { filter: 'coordinates', value },
    });
  });

  const tMainTitle = intl.formatMessage({
    id: 'promotions.search.title',
  });




  const filteredPromotions = promotions?.filter((i) =>
    visibleIds?.length ? visibleIds?.indexOf(i.id) !== -1 : i.id,
  );

  useEffect(() => {
    if (isInit) {
      return;
    }

    const categoryIds = currentSubcategoryIds.length
      ? currentSubcategoryIds
      : currentCategoryId;

    const brandIds = currentBrandIds.length ? currentBrandIds : '';

    const isEqualFilterParams = isEqual(filterParams, {
      categoryIds,
      brandIds,
    });

    if (isEqualFilterParams) {
      throttleSetCurrentBounds(filterCoordinates!);
      setInit(true);
    }
  }, [currentCategoryId, currentSubcategoryIds, currentBrandIds]); // eslint-disable-line

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState != 'visible') {
        setHiddenRender(true);
      }
    });
    return () => {
      window.removeEventListener('visibilitychange', () => {
        if (document.visibilityState != 'visible') {
          setHiddenRender(true);
        }
      });
    };
  }, []);

  useEffect(() => {
    if (!isInit) {
      return;
    }

    setActiveClusterSheet(false);
    setVisibleIds([]);

    const categoryIds = currentSubcategoryIds.length
      ? currentSubcategoryIds
      : currentCategoryId;

    const isEqualCoordinates = isEqual(
      filterCoordinates,
      filterValues.coordinates,
    );

    const isEqualFilterParams = isEqual(filterParams, {
      categoryIds,
      brandIds: currentBrandIds || '',
    });

    if (!isEqualCoordinates) {
      setFilterCoordinates(filterValues.coordinates);
    }

    if (!isEqualFilterParams) {
      setFilterParams({
        ...filterParams,
        categoryIds,
        brandIds: currentBrandIds || '',
      });
    }
    // eslint-disable-next-line
  }, [
    currentCategoryId,
    currentSubcategoryIds,
    currentBrandIds,
    filterValues.coordinates,
  ]);


  useEffect(() => {

    const { geolocationPosition } = geoPosition;


    if (!isUserPositionReceived && geolocationPosition) {

      const { latitude, longitude } = geolocationPosition;
      setMapCenter([latitude, longitude]);
      setUserPositionReceived(true);
    }

  }, [geoPosition, isUserPositionReceived, []]);







  return (
    <Columns fluid>
      <SearchFiltersMenu />
      <ClusterItems>
        <ClusterSheet
          items={promotions}
          visiblePromotions={visibleIds}
          loading={isLoading}
          active={isActiveClusterSheet}
        />
      </ClusterItems>
      <CardsColumn>
        <CardsColumnHeader>
          <TitleWrapper>
            <MainTitle text={tMainTitle} hLevel={1} fontWeight={900} />
          </TitleWrapper>
          <SearchFiltersBarWrapper>
            <SearchFilters />
          </SearchFiltersBarWrapper>
        </CardsColumnHeader>
        <CardsColumnInner>
          <MapPromotionsItems items={filteredPromotions} loading={isLoading} />
        </CardsColumnInner>
      </CardsColumn>
      <MapColumn id="map">
        {isLoading && (
          <MapLoader>
            <Loader size={22} thickness={3} color={colors.BLUE} />
          </MapLoader>
        )}
        <MapColumnInner>
          {isInit && (
            <FartcaMap
              center={mapCenter}
              promotions={promotions}
              isGeolocationError={!!geoPosition.geolocationError}
              hiddenRender={hiddenRender}
            />
          )}
        </MapColumnInner>
      </MapColumn>
    </Columns>
  );
};

export default PromotionsMap;
