import useSWR from 'swr';
import axios from 'axios';
import { commonAPI } from '@/api';
import { createErrorObject } from '@/helpers/createErrorObject';

import IFaq from 'src/types/faq';



const useFaqs = () => {
	const {
		data,
		mutate,
		error,
	}: { data?: IFaq[]; mutate: any; error?: any } = useSWR(
		'/about/faq',
		commonAPI.fetch,
		{
			revalidateOnFocus: false,
		},
	);

	const isLoading = !data && (!error || axios.isCancel(error));
	const isError = !!error;

	return {
		mutate,
		isLoading,
		isError,
		faqs: data,
		error: error ? createErrorObject(error) : null,
	};
};

export { useFaqs };
