import { put } from 'redux-saga/effects';
import { AxiosError } from 'axios';
import { Action } from 'redux';
import { createErrorObject } from '@/helpers/createErrorObject';
import { IReduxAction, IError } from '@/types';

interface IParams {
  error: AxiosError;
  action: IReduxAction;
  errorAction: ({ error }: { error: IError }) => Action<any>;
  setFailedAction?: (failedAction: IReduxAction) => Action<any>;
}

function* handleError({
  action,
  error,
  errorAction,
  setFailedAction,
}: IParams) {
  if (error.message === 'Network Error') {
    yield put(errorAction({ error: { statusCode: 0 } }));
  } else if (error.response?.status === 500) {
    yield put(errorAction({ error: { statusCode: 500 } }));
  } else if (error.response?.status === 400) {
    yield put(errorAction({ error: createErrorObject(error) }));
  } else if (error.response?.status === 403) {
    yield put(errorAction({ error: createErrorObject(error) }));
  } else {
    yield put(errorAction({ error: createErrorObject(error) }));
  }

  if (setFailedAction) {
    yield put(setFailedAction(action));
  }
}

export default handleError;
