import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './sagas';

const persistConfig = {
  key: 'fartsa-web-app',
  whitelist: ['auth', 'settings'],
  blacklist: ['phoneAuth'],
  storage,
};

const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, rootReducer);

// @ts-ignore
const windowRDEC = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = windowRDEC || compose;

const enhancer =
  process.env.NODE_ENV !== 'production'
    ? composeEnhancers(applyMiddleware(sagaMiddleware))
    : compose(applyMiddleware(sagaMiddleware));

const store = createStore(persistedReducer, enhancer);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

// @ts-ignore
if (module.hot && process.env.NODE_ENV !== 'production') {

  // @ts-ignore
  module.hot.accept(
    './reducers',
    () => store.replaceReducer(require('./reducers').default), // eslint-disable-line
  );
}

export { store, persistor };


