import { AxiosRequestConfig } from 'axios';
import basicFetcher from './utils/basicFetcher';

interface IProps {
	businessId: number
	message: string
	title: string

}

export default {
	async postCompanyOffer(props: IProps) {
		const url = '/ambassador/promoaction/offer';


		try {
			const response = await basicFetcher(url, props, { requestConfig: { method: 'post' } as AxiosRequestConfig });
			return response;
		} catch (error) {
			throw error;
		}
	},

	async getCompanyPromoations(
		businessId: number,
		pageNumber: number,
		actualNow: boolean,
	) {
		try {
			let skip = (pageNumber * 9) - 9;
			let take = 9;


			const params = { skip, take, businessId, actualNow };

			const url = 'catalog/promoactions';
			const response = await basicFetcher(url, params);
			return response;
		} catch (e) {
			console.log(e);
		}

	}
};