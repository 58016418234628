import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from '@/constants';

interface IProps {
  classNames?: string;
  name: string;
  value: string;
  label: string;
  checked: boolean;
  type?: 'checkbox' | 'radio';
  onSelect: (value: string) => void;
}

const Label = styled.label`
  margin: 0;
  display: inline-block;
  width: 100%;
`;

const FakeButton = styled.span`
  margin: 0;
  padding: 12px 24px;
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: ${colors.BLACK};
  background-color: ${colors.WHITE};
  user-select: none;
  cursor: pointer;
  transition: all 0.2s;

  &:active {
    transform: scale(0.95);
  }
`;

const Input = styled.input`
  display: none;
  padding: 0;

  &[type='radio']:checked
    + ${FakeButton},
    &[type='checkbox']:checked
    + ${FakeButton} {
    color: ${colors.WHITE};
    background-color: ${colors.BLUE};
  }
`;

const SelectionButton: FC<IProps> = ({
  value,
  name,
  label,
  checked,
  type = 'checkbox',
  onSelect,
  classNames = '',
}: IProps) => {
  const handleSelect = () => {
    onSelect(value);
  };

  return (
    <Label>
      <Input
        className={classNames}
        type={type}
        name={name}
        value={value}
        onChange={handleSelect}
        checked={checked}
      />
      <FakeButton className={classNames}>{label}</FakeButton>
    </Label>
  );
};

export default SelectionButton;
