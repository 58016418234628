
import apiClient from './utils/apiClient';
import basicFetcher from './utils/basicFetcher';
import { AxiosRequestConfig } from 'axios';

export default {
	async getMyNotifications(url: string) {
		try {
			const response = await basicFetcher(url);
			return response;
		} catch (error) {
			throw error;
		}
	},
	async deleteNotification(notificationId: Number) {
		const api = apiClient({});
		try {
			await api.delete(`${process.env.REACT_APP_API_URL}notifications`, {
				params: {
					notificationId
				}
			} as AxiosRequestConfig);
		} catch (e) {
			console.error(e);
		}
	}
};