import useSWR from 'swr';
import axios from 'axios';
import { commonAPI } from '@/api';
import { createErrorObject } from '@/helpers/createErrorObject';
import { IStatus } from '@/types';

const useStatus = () => {
	const {
		data,
		mutate,
		error,
	}: { data?: IStatus; mutate: any; error?: any } = useSWR(
		'ambassador/status',
		commonAPI.fetch,
		{
			revalidateOnFocus: false,
		},
	);

	const isLoading = !data && (!error || axios.isCancel(error));
	const isError = !!error;

	return {
		mutate,
		isLoading,
		isError,
		status: data,
		error: error ? createErrorObject(error) : null,
	};
};

export { useStatus };