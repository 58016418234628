import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import Container from 'styled-bootstrap-grid/dist/components/Container';

import { cn } from '@bem-react/classname';

import { Header } from '@/components/Header';
import { Footer } from '@/components/Footer';
import { Loader } from '@/components/Loader';
import { MainTitle } from '@/components/MainTitle';
import { Button } from '@/components/Buttons';
import { Page, PageContent } from '@/components/shared';
import { Breadcrumbs } from '@/components/Breadcrumbs';

import './Layout.less';
import { useLocation } from 'react-router-dom';

interface IProps {
  isAuth: boolean;
  i18nTitle: string;
  i18nSeoTitle: string;
  withBreadcrumbs?: boolean;
  isLoading?: boolean;
}

const b = cn('Layout');

const Layout: FC<IProps> = (props) => {
  const {
    isAuth,
    withBreadcrumbs,
    i18nTitle,
    i18nSeoTitle,
    children,
    isLoading,
  } = props;
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();

  const tMainTitle = formatMessage({ id: isAuth ? i18nTitle : 'auth.authPls' });
  const tSeoTitle = formatMessage({ id: i18nSeoTitle });

  return (
    <Page>
      <Helmet>
        <title>{tSeoTitle}</title>
      </Helmet>

      <Header />

      <PageContent>
        <Container>
          {withBreadcrumbs && (
            <Breadcrumbs pathname={pathname} className={b('Breadcrumbs')} />
          )}

          <MainTitle
            className={b('Title')}
            text={tMainTitle}
            hLevel={1}
            fontWeight={900}
          />
        </Container>

        {isAuth &&
          (isLoading ? (
            <div className={b('Loader-Wrapper')}>
              <Loader size={52} />
            </div>
          ) : (
            children
          ))}

        {!isAuth && (
          <Container>
            <p className={b('Message')}>
              <FormattedMessage id="account.auth" />
            </p>

            <Button displayType="link" className={b('Auth')} to="/signin">
              <FormattedMessage id="auth.logIn" />
            </Button>
          </Container>
        )}
      </PageContent>

      <Footer />
    </Page>
  );
};

export default Layout;
