import React, { FC, useEffect, useState, useRef, FormEvent } from 'react';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';
import { motion, useAnimation } from 'framer-motion';
// @ts-ignore

import useMobileDetect from 'use-mobile-detect-hook';
import { BaseInput } from '@/components/FormControls';
import { QuickSearchResults } from '@/components/QuickSearchResults';
import { SvgSearch } from '@/components/Svg';
import { useWindowSize } from '@/hooks/useWindowSize';
import { useSearchString } from '@/hooks/useSearchString';
import { colors } from '@/constants';

interface IProps {
  mapPageMode?: boolean;
  onFocus: () => void;
  onCancelClick: () => void;
}

interface ISearchResultsProps {
  mapPageMode: boolean;
  isMobile: boolean;
}

const Form = styled.form`
  position: relative;
  width: 100%;

  input {
    padding: 10px 16px;
    width: 100%;
  }
`;

const SearchButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 8px;
`;

const SearchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 36px;
  border: 0;
  background-color: transparent;
  border-radius: 8px;
`;

const SearchResults = styled(motion.div) <ISearchResultsProps>`
  position: fixed;
  z-index: 9999;
  top: 120px;
  left: 0;
  width: 100%;
  height: calc(100vh - 62px);
  background-color: ${colors.WHITE};

  ${({ mapPageMode }: ISearchResultsProps) => {
    if (mapPageMode) {
      return css`
        top: 62px;
      `;
    }
    return '';
  }}

  ${({ isMobile }: ISearchResultsProps) => {
    if (isMobile) {
      return css`
        padding-bottom: 62px;
      `;
    }
    return '';
  }}
`;

const CancelSearchButton = styled(motion.button)`
  margin-left: 10px;
  padding: 8px 12px;
  font-weight: 700;
  border: 0;
  background-color: transparent;
`;

const searchResultsVariants = {
  show: {
    y: 0,
    transition: {
      duration: 0.2,
    },
  },
  hidden: {
    y: '100%',
    transition: {
      duration: 0.2,
    },
  },
};

const cancelButtonVariants = {
  show: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.2,
      duration: 0.2,
    },
  },
  hidden: {
    x: '20%',
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
};

const SearchPromotionsFormSmall: FC<IProps> = ({
  mapPageMode = false,
  onFocus,
  onCancelClick,
}: IProps) => {
  const {
    searchString,
    setSearchString,
    setSearchStringInUrl,
    getSearchStringFromUrl,
  } = useSearchString();

  const [inputValue, setInputValue] = useState<string>(
    getSearchStringFromUrl(),
  );
  const [activeSearch, setActiveSearch] = useState(false);

  const intl = useIntl();
  const detectMobile = useMobileDetect();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const tPlaceholder = intl.formatMessage({
    id: 'common.search',
  });

  const tCancel = intl.formatMessage({ id: 'common.cancel' });

  const clearButtonPosition = {
    right: 46,
  };

  const { width } = useWindowSize();
  const controls = useAnimation();

  const isMobile = detectMobile.isMobile();

  const bodyEl = document.querySelector('body');

  const showSearch = () => {
    controls.start('show');
    bodyEl?.setAttribute('style', 'overflow: hidden');
    setActiveSearch(true);
  };

  const hideSearch = () => {
    controls.start('hidden');
    bodyEl?.removeAttribute('style');
    setActiveSearch(false);
  };

  const startSearch = () => {
    hideSearch();
    setSearchStringInUrl(inputValue);
    setSearchString(inputValue);
  };

  useEffect(() => {
    if (searchString !== inputValue) {
      setInputValue(searchString);
    }
  }, [searchString]); // eslint-disable-line

  useEffect(() => {
    if (width && width >= 768) {
      hideSearch();
      onCancelClick();
    }
  }, [width]); // eslint-disable-line

  useEffect(() => {
    if (mapPageMode) {
      showSearch();
    }
  }, [mapPageMode]); // eslint-disable-line

  useEffect(() => {
    if (activeSearch) {
      showSearch();
    }
  }, [activeSearch]); // eslint-disable-line

  const handleInputChange = (value: string) => {
    setInputValue(value);
  };

  const handleInputFocus = () => {
    if (!activeSearch) {
      showSearch();
      onFocus();
    }
  };

  const handleClearClick = () => {
    setInputValue('');
  };

  const handleCancelSearchClick = () => {
    hideSearch();
    onCancelClick();
  };

  const handleSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    startSearch();
  };

  const handleResultItemClick = () => {
    hideSearch();
  };

  return (
    <>
      <Form onSubmit={handleSubmitForm} autoComplete="off">
        <BaseInput
          ref={inputRef}
          name="search"
          placeholder={tPlaceholder}
          value={inputValue}
          withClearButton
          clearButtonPosition={clearButtonPosition}
          onInputChange={handleInputChange}
          onInputFocus={handleInputFocus}
          onClearClick={handleClearClick}
          autoComplete="off"
        />
        <SearchButtonWrapper>
          <SearchButton type="submit">
            <SvgSearch width={22} fill={colors.MISCHKA} />
          </SearchButton>
        </SearchButtonWrapper>
      </Form>
      {activeSearch && (
        <CancelSearchButton
          initial="hidden"
          variants={cancelButtonVariants}
          animate={controls}
          onClick={handleCancelSearchClick}
        >
          {tCancel}
        </CancelSearchButton>
      )}

      <SearchResults
        initial="hidden"
        variants={searchResultsVariants}
        animate={controls}
        mapPageMode={mapPageMode}
        isMobile={isMobile}
      >
        <QuickSearchResults
          smallScreenMode
          searchString={inputValue}
          onStartSearch={startSearch}
          onItemClick={handleResultItemClick}
        />
      </SearchResults>
    </>
  );
};

export default SearchPromotionsFormSmall;
