import React, { useCallback } from 'react';
import { Container } from 'styled-bootstrap-grid';
import { useIntl } from 'react-intl';
import { cn } from '@bem-react/classname';

import { useAuth } from '@/data/useAuth';
import { sendConfirmationCode } from '@/data/useUser';

import { Button } from '@/components/Buttons';
import { Layout } from '@/components/Layout';

import { useInterval } from '@/hooks/useInterval';

import { useEmailConfirmation } from './hooks';
import './EmailConfirmationPage.less';

const b = cn('EmailConfirmationPage');

const INTERVAL_SECONDS = 10;

const EmailConfirmationPage = () => {
  const { loading, response } = useEmailConfirmation();
  const { timer, setTimer } = useInterval();

  const { formatMessage } = useIntl();
  const { isAuth } = useAuth();

  const tHomeButton = formatMessage({ id: 'emailConfirmation.buttons.home' });
  const tRetryButton = formatMessage({ id: 'emailConfirmation.buttons.retry' });
  const tSuccessText = formatMessage({ id: 'emailConfirmation.text.success' });
  const tFailureText = formatMessage({ id: 'emailConfirmation.text.failure' });
  const tFailureTextCommon = formatMessage({
    id: 'emailConfirmation.text.failure.common',
  });
  const tFailureText404 = formatMessage({
    id: 'emailConfirmation.text.failure.404',
  });
  const tRepeatAfterButton = formatMessage(
    { id: 'confirmByCodeForm.buttons.repeatAfter' },
    { time: timer },
  );

  const isFailure = !loading && !response.ok;

  const failureReason =
    response.statusCode === 404 ? tFailureText404 : tFailureTextCommon;

  const handleRetry = useCallback(() => {
    sendConfirmationCode();
    setTimer(INTERVAL_SECONDS);
  }, [setTimer]);

  return (
    <Layout
      isAuth={isAuth}
      isLoading={loading}
      i18nSeoTitle="seo.title.emailConfirmation"
      i18nTitle="common.emailConfirmation"
    >
      <Container>
        <p className={b('Text')}>
          {isFailure ? `${failureReason} ${tFailureText}` : tSuccessText}
        </p>

        {isFailure ? (
          <Button
            disabled={Boolean(timer)}
            displayType="primary"
            onClick={handleRetry}
          >
            {timer ? tRepeatAfterButton : tRetryButton}
          </Button>
        ) : (
          <Button displayType="primary" to="/">
            {tHomeButton}
          </Button>
        )}
      </Container>
    </Layout>
  );
};

export default EmailConfirmationPage;
