export default {
  BLACK: '#1a1a21',
  WHITE: '#ffffff',
  NERO: '#262626',
  MONSOON: '#76767a',
  MISCHKA: '#a6a7b0',
  LILAC_WHITE: '#ededed',
  GHOST_WHITE: '#f8f9ff',
  GREEN: '#00e676',
  FRUIT_SALAD: '#00D96F',
  RED: '#ff1744',
  YELLOW: '#ffc107',
  BLUE: '#304ffe',
  AZUL: '#2945e4',
  HAWKES_BLUE: '#dfe5ff',
  TINKOFF_YELLOW: '#FFDD2D',
  TINKOFF_GRAY: '#F5F5F6',
};
