import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { cn } from '@bem-react/classname';
import { yupResolver } from '@hookform/resolvers';
import { common } from '@/constants';
import { Button } from '@/components/Buttons';
import { ConfirmByCodeForm } from '@/components/ConfirmByCodeForm';
import { PhoneInput, BaseInput } from '@/components/FormControls';
import './SettingsForm.less';

interface IProps<T> {
  type: 'phone' | 'email';
  defaultValue?: string;
  isEditable?: boolean;
  needConfirmation?: boolean;
  confirmFieldName?: string;
  confirmRequestHandler?: (val: any) => void;
  confirmRetryHandler?: () => void;
  name: string;
  label: string;
  placeholder: string;
  editing: boolean;
  setEditing: Dispatch<SetStateAction<boolean>>;
  handleSubmitForm: SubmitHandler<T>;
}

const b = cn('SettingsForm');

const { DEFAULT_DIAL_CODE, DEFAULT_COUNTRY_CODE } = common;

const SettingsForm = <T extends object>(props: IProps<T>) => {
  const {
    type,
    name,
    label,
    placeholder,
    editing,
    setEditing,
    handleSubmitForm,
    confirmFieldName = 'confirm',
    isEditable = true,
    defaultValue,
    needConfirmation,
    confirmRequestHandler = () => { },
    confirmRetryHandler = () => { },
  } = props;

  const [wasCodeSent, setWasCodeSent] = useState(false);

  const isEmail = type === 'email';

  const { formatMessage } = useIntl();

  const tErrorAlreadyExists = formatMessage({
    id: 'form.errors.alreadyExists',
  });
  const tErrorEmail = formatMessage({ id: 'form.errors.email.incorrect' });
  const tRequiredField = formatMessage({
    id: `form.errors.${isEmail ? 'email' : 'phoneNumber'}.required`,
  });
  const tEditButton = formatMessage({ id: 'settings.buttons.edit' });
  const tCloseButton = formatMessage({ id: 'settings.buttons.close' });
  const tSaveButton = formatMessage({ id: 'settings.buttons.save' });
  const tConfirmButton = formatMessage({ id: 'settings.buttons.confirm' });

  const validationSchema = yup.object().shape({
    [name]: isEmail
      ? yup
        .string()
        .email(tErrorEmail)
        .notOneOf([defaultValue], tErrorAlreadyExists)
        .required(tRequiredField)
      : yup
        .string()
        .notOneOf([defaultValue], tErrorAlreadyExists)
        .required(tRequiredField),
  });

  const { register, setValue, handleSubmit, errors, clearErrors } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { [name]: defaultValue },
  });

  const handleToggleEditing = useCallback(() => {
    setEditing((val: boolean) => !val);
    clearErrors(name);
  }, [clearErrors, setEditing, name]);

  const handleInputChange = useCallback(
    (v: string) => {
      setValue(name, v);
    },
    [setValue, name],
  );

  const handleInputFocus = useCallback(() => {
    clearErrors(name);
  }, [clearErrors, name]);

  const handleSendCode = useCallback(() => {
    confirmRetryHandler();
    setWasCodeSent(true);
  }, [confirmRetryHandler]);

  const handleSubmitLocal = useCallback(
    async (data: any) => {
      await handleSubmitForm(data);
      setWasCodeSent(true);
    },
    [handleSubmitForm],
  );

  useEffect(() => {
    register(name);
  }, [register, name]);

  return (
    <>
      <form onSubmit={handleSubmit(handleSubmitLocal)} className={b()}>
        <p className={b('Header')}>
          <span className={b('Label')}>{label}</span>

          {isEditable && (
            <Button
              onClick={handleToggleEditing}
              className={b('Header')}
              displayType="link"
            >
              {editing ? tCloseButton : tEditButton}
            </Button>
          )}
        </p>

        {type === 'phone' && (
          <PhoneInput
            defaultValue={defaultValue}
            name={name}
            disabled={!editing}
            className={b('Field')}
            dialCode={DEFAULT_DIAL_CODE}
            countryCode={DEFAULT_COUNTRY_CODE}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            isError={Boolean(errors[name])}
            errorMessage={errors[name]?.message}
          />
        )}

        {isEmail && (
          <BaseInput
            defaultValue={defaultValue}
            name={name}
            disabled={!editing}
            className={b('Field')}
            placeholder={placeholder}
            onFocus={handleInputFocus}
            onInputChange={handleInputChange}
            isError={Boolean(errors[name])}
            errorMessage={errors[name]?.message}
          />
        )}

        {editing && (
          <Button type="submit" displayType="primary" className={b('Submit')}>
            {tSaveButton}
          </Button>
        )}

        {needConfirmation && !wasCodeSent && !editing && (
          <Button
            displayType="primary"
            className={b('Submit')}
            onClick={handleSendCode}
          >
            {tConfirmButton}
          </Button>
        )}
      </form>

      {needConfirmation && wasCodeSent && !editing && (
        <ConfirmByCodeForm
          name={confirmFieldName}
          inputClassName={b('Field')}
          containerClassName={b('Confirm-Form')}
          requestHandler={confirmRequestHandler}
          retryHandler={confirmRetryHandler}
        />
      )}
    </>
  );
};

export default SettingsForm;
