import React from 'react';
import ReactDOM from 'react-dom';

const loadPackage = async () => {

	const [ymaps3React] = await Promise.all([ymaps3.import('@yandex/ymaps3-reactify'), ymaps3.ready]);
	const reactify = ymaps3React.reactify.bindTo(React, ReactDOM);
	const { YMap, YMapDefaultSchemeLayer, YMapControls, YMapDefaultFeaturesLayer, YMapControlButton, YMapMarker } = reactify.module(ymaps3);
	const { YMapZoomControl, YMapGeolocationControl } = reactify.module(await ymaps3.import('@yandex/ymaps3-controls@0.0.1'));
	return { YMap, YMapDefaultSchemeLayer, YMapControls, YMapDefaultFeaturesLayer, YMapControlButton, YMapZoomControl, YMapGeolocationControl, YMapMarker };


};
const scriptPromise = new Promise((resolve, reject) => {
	const script = document.createElement('script');
	document.body.appendChild(script);
	script.onload = resolve;
	script.onerror = reject;
	script.async = true;
	script.src = `https://api-maps.yandex.ru/v3/?apikey=${process.env.REACT_APP_YANDEX_API_KEY}&lang=ru_RU`;
});

const yandexMapImport = async () => {

	try {
		return await scriptPromise.then(async () => {
			return loadPackage();
		});
	} catch {
		return { YMap: () => null, YMapDefaultSchemeLayer: () => null, YMapControls: () => null, YMapDefaultFeaturesLayer: () => null, YMapControlButton: () => null, YMapZoomControl: () => null, YMapGeolocationControl: () => null, YMapMarker: () => null };
	}



};
const yandexMap = await yandexMapImport();
export default yandexMap;