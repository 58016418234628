import { currency } from '@/constants';
import { TCurrency } from '@/types';

const getCurrencySymbol = (value: TCurrency) => {
  switch (value) {
    case 'euro':
      return currency.EURO;
    case 'dollar':
      return currency.DOLLAR;
    default:
      return currency.ROUBLE;
  }
};

export { getCurrencySymbol };
