import useSWR from 'swr';
import axios from 'axios';
import { promotionsAPI } from '@/api';
import { createErrorObject } from '@/helpers/createErrorObject';
import { IFavoritePromotion } from '@/types';



const useFavoritePromotions = () => {
	const {
		data,
		mutate,
		error,
	}: { data?: IFavoritePromotion[]; mutate: any; error?: any } = useSWR(
		['favorites/promoactions'],
		promotionsAPI.getFavouritePromotions,
		{
			revalidateOnFocus: false,
		},
	);

	const isLoading = !data && (!error || axios.isCancel(error));
	const isError = !!error;
	const favoriteIds = data?.map((card) => {
		return card.id;
	}) || [];

	return {
		mutate,
		isLoading,
		isError,
		promoactions: data || [],
		error: error ? createErrorObject(error) : null,
		favoriteIds

	};
};

export { useFavoritePromotions };