import React, { FC, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { motion, useAnimation } from 'framer-motion';
import { useEmblaCarousel } from 'embla-carousel/react';
import { PromotionCardHorizontal } from '@/components/PromotionCard';
import { useWindowSize } from '@/hooks/useWindowSize';
import { IPromotion } from '@/types';

interface IProps {
  active: boolean;
  loading: boolean;
  visiblePromotions: Array<number | string>;
  items?: Array<IPromotion>;
}

const Wrapper = styled(motion.div)`
  position: absolute;
  z-index: 999;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 240px;
`;

const Carousel = styled.div`
  position: relative;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const CarouselViewport = styled.div`
  overflow-x: hidden;
  width: 100%;
`;

const CarouselItems = styled.div`
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin-left: -10px;
`;

const Item = styled.div`
  position: relative;
  min-width: 80%;

  ${down('xs')} {
    min-width: 86%;
  }
`;

const ItemInner = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 240px;
`;

const variants = {
  visible: {
    y: 0,
    transition: {
      duration: 0.3,
    },
  },
  hidden: {
    y: 240,
    transition: {
      duration: 0.3,
    },
  },
};

const ClusterSheet: FC<IProps> = ({
  items = [],
  loading,
  active,
  visiblePromotions,
}: IProps) => {
  const [filterParams, setFilterParams] = useState<Array<number | string>>([]);
  const controls = useAnimation();
  const { width } = useWindowSize();
  const [carouselViewportRef, emblaApi] = useEmblaCarousel({ loop: false });
  const timeout = useRef<NodeJS.Timeout>();

  const filteredItems = items?.filter((i) =>
    filterParams.length ? filterParams.indexOf(i.id) !== -1 : i.id,
  );

  useEffect(() => {
    clearTimeout(timeout.current);
    if (visiblePromotions.length && emblaApi) {
      setFilterParams(visiblePromotions);
      emblaApi.scrollTo(0);
      timeout.current = setTimeout(() => {
        emblaApi.reInit();
      }, 100);
    }
    return () => {
      clearTimeout(timeout.current);
    };
  }, [visiblePromotions, emblaApi]);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.reInit();
    }
  }, [width, emblaApi]);

  useEffect(() => {
    if (active) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
    // eslint-disable-next-line
  }, [active]);

  return (
    <Wrapper variants={variants} animate={controls} initial={variants.hidden}>
      {loading ? null : (
        <Carousel>
          <CarouselViewport ref={carouselViewportRef}>
            <CarouselItems>
              {filteredItems.map((i) => {
                const image = i.images?.find(
                  (item) => item.type === 's665x378',
                );

                return (
                  <Item key={i.id}>
                    <ItemInner>
                      <PromotionCardHorizontal
                        promotionId={i.id}
                        title={i.title}
                        startDateTime={i.startDateTime}
                        endDateTime={i.startPromocodeActivationDateTime}
                        rewards={i.ambassadorRewards}
                        imageSrc={image?.media.path}
                      />
                    </ItemInner>
                  </Item>
                );
              })}
            </CarouselItems>
          </CarouselViewport>
        </Carousel>
      )}
    </Wrapper>
  );
};

export default ClusterSheet;
