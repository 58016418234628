import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgAttention: FC<ISvgProps> = (props: ISvgProps) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm0 1.6a6.4 6.4 0 11-.002 12.802A6.4 6.4 0 018 1.6zm-.8 3.2V8a.8.8 0 101.6 0V4.8a.8.8 0 10-1.6 0zm0 6.4a.8.8 0 101.6 0 .8.8 0 00-1.6 0z" />
    </svg>
  );
};

export default SvgAttention;
