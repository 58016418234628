import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Link, Redirect, useParams } from 'react-router-dom';
import { Container, Row } from 'styled-bootstrap-grid';
import { Header } from '@/components/Header';
import { Footer } from '@/components/Footer';
import { MainTitle } from '@/components/MainTitle';
import { MainColumn } from '@/components/MainColumn';
import { SidebarColumn } from '@/components/SidebarColumn';
import { PromotionMainContent } from '@/components/PromotionMainContent';
import { AboutCompanySidebar } from '@/components/AboutCompanySidebar';
import { PageErrorMessage } from '@/components/PageErrorMessage';
import { PromotionJoin } from '@/components/PromotionJoin';
import { LabelWithIcon } from '@/components/LabelWithIcon';
import { Loader } from '@/components/Loader';
import { SvgCase } from '@/components/Svg';
import { Page, PageContent, SidebarBlock } from '@/components/shared';
import { useAuth } from '@/data/useAuth';
import { usePromotion } from '@/data/usePromotion';
import { colors } from '@/constants';
import SvgFreeze from '@/components/Svg/SvgFreeze';
import dateWritingHelper from '@/helpers/dateWritingHelper';

const PromotionBaseInfoWrapper = styled.div`
  padding: 46px 0 10px 0;
`;

const PromotionTitleWrapper = styled.div`
  margin-bottom: 22px;
`;

const PromotionBaseInfoRow = styled.div`
  margin-bottom: 16px;
`;
const CompanyLink = styled(Link)`
  text-decoration: none;
`;


const LoaderWrapper = styled.div`
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PromotionPage = () => {
  const intl = useIntl();
  const { id } = useParams();
  const { isAuth } = useAuth();
  const { promotion, isLoading, error } = usePromotion(id);

  const pageTitle = promotion?.title || '';
  const currentUTCDateTime = new Date().toISOString();

  const tError404Title = intl.formatMessage({ id: 'errorPage.title404' });
  const tSeoTitle = intl.formatMessage(
    { id: 'seo.title.promotion' },
    {
      name: pageTitle,
    },
  );

  const renderContent = () => (
    <>
      <PromotionBaseInfoWrapper>
        <PromotionTitleWrapper>
          <MainTitle
            text={pageTitle}
            hLevel={1}
            fontSize={30}
            fontWeight={900}
          />
        </PromotionTitleWrapper>
        {promotion?.business?.name ? (
          <CompanyLink to={`/company/${promotion?.business?.id}`} target="_blank" >
            <PromotionBaseInfoRow>
              <LabelWithIcon
                icon={<SvgCase width={18} fill={colors.BLUE} />}
                text={promotion.business.name}
              />
            </PromotionBaseInfoRow>
          </CompanyLink>
        ) : null}
        {
          promotion && promotion.freezingMoneyDays > 0 ?
            <LabelWithIcon
              icon={<SvgFreeze width={18} fill={colors.BLUE} />}
              text={`Время заморозки средств: ${dateWritingHelper(promotion.freezingMoneyDays, ['день', 'дня', 'дней'])}`}
            /> : null
        }

      </PromotionBaseInfoWrapper>
      <Row justifyContent="between">
        <MainColumn>
          {promotion ? <PromotionMainContent promotion={promotion} /> : null}
        </MainColumn>
        <SidebarColumn>
          <aside>
            {promotion?.ambassadorRewards.length ? (
              <SidebarBlock>
                <PromotionJoin
                  rewards={promotion.ambassadorRewards}
                  isActiveJoin={
                    promotion.ambassadorParticipation
                      ?.participationRequestStatus === 'Accepted'
                  }
                  asCard
                  isEnd={currentUTCDateTime > promotion.endDateTime}
                />
              </SidebarBlock>
            ) : null}
            {promotion?.legalEntity?.name ? (
              <SidebarBlock>
                <AboutCompanySidebar promotion={promotion} />
              </SidebarBlock>
            ) : null}
          </aside>
        </SidebarColumn>
      </Row>
    </>
  );

  if (!isAuth) {
    return <Redirect to="/signin" />;
  }

  if (error && error.statusCode === 404) {
    return (
      <PageContent>
        <Container>
          <PageErrorMessage
            statusCode={error.statusCode}
            title={tError404Title}
          />
        </Container>
      </PageContent>
    );
  }

  return (
    <Page>
      <Helmet>
        <title>{tSeoTitle}</title>
      </Helmet>
      <Header />
      <PageContent>
        <Container>
          {isLoading ? (
            <LoaderWrapper>
              <Loader size={52} thickness={4} color={colors.BLUE} />
            </LoaderWrapper>
          ) : (
            renderContent()
          )}
        </Container>
      </PageContent>
      <Footer />
    </Page>
  );
};

export default PromotionPage;
