import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgPassportAddress: FC<ISvgProps> = (props: ISvgProps) => {
  return (
    <svg viewBox="0 0 80 56" {...props}>
      <g fillRule="nonzero" fill="none">
        <path
          d="M0 4a4 4 0 014-4h72a4 4 0 014 4v48a4 4 0 01-4 4H4a4 4 0 01-4-4V4z"
          fill="#DFE5FF"
        />
        <path d="M40 0v56h2V0z" fill="#F8F9FF" />
        <path
          d="M27.8 27.46a7.8 7.8 0 11-15.6 0 7.8 7.8 0 0115.6 0zM67.8 27.46a7.8 7.8 0 11-15.6 0 7.8 7.8 0 0115.6 0z"
          fill="#F8F9FF"
          opacity=".5"
        />
        <path
          d="M53.13 5.5a1 1 0 000 2v-2zm14.38 2a1 1 0 000-2v2zm-14.39 0h14.4v-2h-14.4v2z"
          fill="#304FFE"
        />
        <path
          d="M48.54 13.84l24.49 1.78.15-2-24.5-1.78-.14 2zm24.49 1.78l-.94 12.85 2 .15.93-12.85-1.99-.15zm-.94 12.85L47.61 26.7l-.15 2 24.49 1.78.14-2zM47.61 26.7l.93-12.85-2-.15-.93 12.85 2 .15zm0 0l-2-.15a2 2 0 001.85 2.14l.15-2zm24.48 1.78l-.14 2a2 2 0 002.14-1.85l-2-.15zm.94-12.85l2 .15a2 2 0 00-1.85-2.14l-.15 2zm-24.34-3.78a2 2 0 00-2.14 1.85l2 .15.14-2zM52.86 17.4a1 1 0 00-.14 1.99l.14-2zm15.24 3.1a1 1 0 10.14-1.99l-.14 2zM52.72 19.4L68.1 20.5l.14-2-15.38-1.12-.14 2z"
          fill="#FF1744"
          opacity=".5"
        />
        <path
          d="M50.12 46.75a1 1 0 000 2v-2zm22 2a1 1 0 000-2v2zm-22 0h22v-2h-22v2z"
          fill="#304FFE"
        />
      </g>
    </svg>
  );
};

export default SvgPassportAddress;
