import axios from 'axios';
import IFaq from 'src/types/faq';

export default {
	async getMyFaqs() {
		try {
			const response: IFaq[] = (await axios.get(`${process.env.REACT_APP_API_URL}about/faq`)).data;
			return response;
		} catch (error) {
			throw error;
		}
	},
};