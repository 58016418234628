import React, { useState } from 'react';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { useParams } from 'react-router-dom';
import { MainTitle } from '@/components/MainTitle';
import { Article } from '@/components/Article';
import { Accordion, AccordionItem } from '@/components/Accordion';
import { Loader } from '@/components/Loader';
import { usePromotionFaq } from '@/data/usePromotionFaq';
import { colors } from '@/constants';

const AccordionWrapper = styled.div`
  width: 100%;
  min-width: 600px;
  max-width: 992px;

  ${down('md')} {
    min-width: 280px;
  }
`;

const TitleWrapper = styled.div`
  margin-bottom: 30px;
  text-align: center;
`;

const LoaderWrapper = styled.div`
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DistributorFaq = () => {
  const [activeAccordionKey, setActiveAccordionKey] = useState<
    number | string | null
  >(null);

  const { id } = useParams();
  const { faqItems, isLoading } = usePromotionFaq(id);

  const handleAccordionChange = (key: number | string) => {
    if (key === activeAccordionKey) {
      setActiveAccordionKey(null);
    } else {
      setActiveAccordionKey(key);
    }
  };

  const renderFaq = () =>
    faqItems?.length ? (
      <Accordion
        activeKey={activeAccordionKey}
        onChange={handleAccordionChange}
      >
        {faqItems.map((i) => (
          <AccordionItem key={i.id} title={i.title} itemKey={i.id}>
            <Article markup={i.description} />
          </AccordionItem>
        ))}
      </Accordion>
    ) : null;

  return (
    <>
      <TitleWrapper>
        <MainTitle text="FAQ" fontWeight={900} />
      </TitleWrapper>
      <AccordionWrapper>
        {isLoading ? (
          <LoaderWrapper>
            <Loader size={52} thickness={4} color={colors.BLUE} />
          </LoaderWrapper>
        ) : (
          renderFaq()
        )}
      </AccordionWrapper>
    </>
  );
};

export default DistributorFaq;
