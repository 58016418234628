import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgLogoWithoutText: FC<ISvgProps> = (props: ISvgProps) => {
  return (
    <svg viewBox="0 0 21 41" {...props}>
      <g fillRule="evenodd">
        <path d="M10.621 19.27l-7.653 3.826a1.607 1.607 0 01-2.326-1.437v-7.653a1.607 1.607 0 012.326-1.437l7.653 3.827a1.605 1.605 0 010 2.873z" />
        <path d="M2.874 11.076l14.08 7.04a1.606 1.606 0 002.325-1.437V2.599a1.607 1.607 0 00-2.326-1.436l-14.08 7.04a1.607 1.607 0 00.001 2.873z" />
        <path d="M2.874 27.302l10.866 5.433a1.607 1.607 0 002.326-1.437V20.432a1.608 1.608 0 00-2.326-1.437L2.874 24.428a1.608 1.608 0 000 2.874z" />
        <path d="M10.621 35.495l-7.653 3.827a1.607 1.607 0 01-2.326-1.437v-7.653a1.607 1.607 0 012.326-1.437l7.653 3.826a1.606 1.606 0 010 2.874z" />
      </g>
    </svg>
  );
};

export default SvgLogoWithoutText;
