import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgAccountStatus: FC<ISvgProps> = (props: ISvgProps) => {
  return (
  <svg viewBox="0 0 32 32" {...props}>
    <path d="M0 0h32v32H0V0z" fill="none"/>
    <path d="M20.5 23h-5a.938.938 0 000 1.875h5a.938.938 0 000-1.875zM15.5 20.875H19A.938.938 0 0019 19h-3.5a.938.938 0 000 1.875z" fill="#304FFE"/>
    <path d="M32 9.438C32 5.335 28.664 2 24.562 2c-3.247 0-6.015 2.092-7.026 5H2.812A2.816 2.816 0 000 9.813v16.25a2.816 2.816 0 002.813 2.812h23.312a2.816 2.816 0 002.813-2.813V15.448A7.432 7.432 0 0032 9.438zm-7.438-5.563a5.569 5.569 0 015.563 5.563A5.569 5.569 0 0124.562 15 5.569 5.569 0 0119 9.437a5.569 5.569 0 015.563-5.562zm2.5 22.188c0 .516-.42.937-.937.937H2.812a.939.939 0 01-.937-.938V9.813c0-.516.42-.937.938-.937h14.333a7.536 7.536 0 00-.021.563c0 4.1 3.336 7.437 7.438 7.437.876 0 1.718-.153 2.5-.433v9.62z" fill="#304FFE"/>
    <path d="M22.724 11.357a.938.938 0 001.559.18l2.5-3a.938.938 0 00-1.44-1.2l-1.583 1.9-.359-.719a.937.937 0 10-1.677.839l1 2zM10.443 18.353a3.426 3.426 0 00.995-2.416A3.441 3.441 0 008 12.5a3.441 3.441 0 00-3.438 3.438c0 .94.38 1.794.995 2.415a3.932 3.932 0 00-1.494 3.084v2.5c0 .518.42.938.937.938h6c.518 0 .938-.42.938-.938v-2.5a3.932 3.932 0 00-1.495-3.084zm-4.005-2.416c0-.861.7-1.562 1.562-1.562.862 0 1.563.7 1.563 1.563 0 .861-.701 1.562-1.563 1.562s-1.563-.7-1.563-1.563zM10.063 23H5.936v-1.563c0-1.137.926-2.062 2.063-2.062s2.063.925 2.063 2.063V23z" fill="#304FFE"/>
  </svg>
  );
};

export default SvgAccountStatus;
