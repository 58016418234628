import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgNotificationsActive: FC<ISvgProps> = (props: ISvgProps) => {
	return (
		<svg viewBox="0 0 32 32" {...props}>

			<path d="M12 29H20C20.5523 29 21 28.5523 21 28C21 27.4477 20.5523 27 20 27H12C11.4477 27 11 27.4477 11 28C11 28.5523 11.4477 29 12 29Z" />
			<path fillRule="evenodd" clipRule="evenodd" d="M25.975 13.9999C25.975 13.9999 25.975 19.187 27.6029 21.9989C27.6029 21.9989 27.871 22.4632 27.8715 22.9981C27.8715 22.9981 27.872 23.5331 27.6053 23.9969C27.6053 23.9969 27.3386 24.4606 26.8761 24.7293C26.8761 24.7293 26.4135 24.998 25.8785 24.9999L6.12502 24.9999C6.12502 24.9999 5.58654 24.998 5.12396 24.7293C5.12396 24.7293 4.66139 24.4606 4.39473 23.9969C4.39473 23.9969 4.12807 23.5331 4.12854 22.9981C4.12854 22.9981 4.12901 22.4632 4.3965 21.9999C4.3965 21.9999 6.02502 19.187 6.02502 13.9999C6.02502 13.9999 6.02502 12.0158 6.78432 10.1826C6.78432 10.1826 7.54362 8.34952 8.94663 6.94651C8.94663 6.94651 10.3496 5.5435 12.1828 4.7842C12.1828 4.7842 14.0159 4.0249 16 4.0249C16 4.0249 17.9842 4.0249 19.8173 4.7842C19.8173 4.7842 21.6504 5.5435 23.0534 6.94651C23.0534 6.94651 24.4564 8.34951 25.2157 10.1826C25.2157 10.1826 25.975 12.0157 25.975 13.9999ZM25.8715 22.9999C25.8715 22.9999 23.975 19.7233 23.975 13.9999C23.975 13.9999 23.975 12.4136 23.368 10.948C23.368 10.948 22.7609 9.48242 21.6392 8.36073C21.6392 8.36073 20.5175 7.23902 19.0519 6.63196C19.0519 6.63196 17.5863 6.0249 16 6.0249C16 6.0249 14.4137 6.0249 12.9481 6.63196C12.9481 6.63196 11.4825 7.23902 10.3608 8.36072C10.3608 8.36072 9.23914 9.48243 8.63208 10.948C8.63208 10.948 8.02502 12.4136 8.02502 13.9999C8.02502 13.9999 8.02502 19.7242 6.12854 22.9999H25.8715Z" />
			<path d="M26.7789 8.70631C26.9478 9.04649 27.2952 9.2625 27.675 9.2625C27.6864 9.2625 27.6977 9.26231 27.709 9.26192C27.8517 9.25708 27.9918 9.22171 28.1197 9.1582C28.4599 8.98932 28.675 8.6423 28.675 8.2625C28.675 8.25119 28.6749 8.23987 28.6745 8.22856C28.6696 8.08581 28.6343 7.94574 28.5707 7.81781C26.8091 4.26956 23.4588 2.15439 23.4588 2.15439C23.2991 2.05353 23.114 2 22.925 2L22.9218 2.00001C22.8487 2.00024 22.7759 2.00849 22.7046 2.02461C22.4459 2.08309 22.221 2.24194 22.0794 2.46621C21.9786 2.62598 21.925 2.81106 21.925 3L21.9251 3.00323C21.9253 3.07633 21.9335 3.14919 21.9497 3.22049C22.0081 3.47918 22.167 3.70404 22.3913 3.84561C25.2664 5.66054 26.7789 8.70631 26.7789 8.70631Z" />
			<path d="M5.22065 8.70716C6.7329 5.66099 9.60874 3.84561 9.60874 3.84561C9.83302 3.70404 9.99186 3.47918 10.0503 3.22049C10.0667 3.14813 10.075 3.07418 10.075 3L10.0747 2.97853C10.0708 2.79692 10.0175 2.61981 9.92057 2.46621C9.779 2.24194 9.55413 2.08309 9.29544 2.02461C9.22309 2.00825 9.14913 2 9.07495 2L9.05348 2.00023C8.87187 2.00413 8.69476 2.05742 8.54116 2.15439C5.19095 4.2692 3.42925 7.81784 3.42925 7.81784C3.36065 7.95603 3.32495 8.10822 3.32495 8.2625L3.32509 8.2789C3.33122 8.65275 3.54539 8.99194 3.88029 9.1582C4.01848 9.2268 4.17067 9.2625 4.32495 9.2625L4.33141 9.26248C4.43768 9.26179 4.54316 9.24417 4.64388 9.21028C4.89525 9.12569 5.10272 8.94472 5.22065 8.70716Z" />
		</svg>

	);
};

export default SvgNotificationsActive;

