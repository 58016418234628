import { FC, useState } from 'react';

interface IProps {
  children(
    onSelect: (onSelect: string | string[]) => void,
    value: string[],
  ): JSX.Element;
  defaultValue?: Array<string>;
  onChange?: (v: string[]) => void;
}

const CheckboxGroup: FC<IProps> = ({
  children,
  defaultValue = [],
  onChange = () => {},
}: IProps) => {
  const [value, setValue] = useState(defaultValue);

  const onSelect = (selectedValue: string | string[]) => {
    if (Array.isArray(selectedValue)) {
      if (selectedValue.length) {
        let nextValue = value.concat(selectedValue);
        nextValue = [...new Set(nextValue)];
        setValue(nextValue);
        onChange(nextValue);
      } else {
        setValue(selectedValue);
        onChange(selectedValue);
      }
    } else {
      const index = value.indexOf(selectedValue);
      if (index === -1) {
        const nextValue = [...value, selectedValue];
        setValue(nextValue);
        onChange(nextValue);
      } else {
        const nextValue = value.filter((i: string) => i !== selectedValue);
        setValue(nextValue);
        onChange(nextValue);
      }
    }
  };

  return children(onSelect, value);
};

export default CheckboxGroup;
