import { ISharedToken, ISharedTokenWithPromoCodeFormAPI } from '@/types';
import apiClient from './utils/apiClient';
import { AxiosRequestConfig } from 'axios';

export default {
  async sharePromoCode(id: number | string) {
    const api = apiClient();

    try {
      const response = await api.post('promocode/share', null, {
        params: {
          promoactionId: id,
        },
      } as AxiosRequestConfig);

      const { data }: { data: ISharedToken } = response;

      return {
        data,
      };
    } catch (error) {
      throw error;
    }
  },

  async buildPromoCode(shareToken: string) {
    const api = apiClient({
      withAccessToken: false,
    });

    try {
      const response = await api.post(`promocode/issue/${shareToken}`);

      const { data }: { data: ISharedTokenWithPromoCodeFormAPI } = response;

      return {
        data: {
          token: data.token,
          promoCode: data.promocode,
        },
      };
    } catch (error) {
      throw error;
    }
  },

  async sendPromoCodeByEmail(email: string, token: string) {
    const api = apiClient({
      withAccessToken: false,
    });

    try {
      await api.post(`promocode/send/email/${email}/${token}`);

      return true;
    } catch (error) {
      throw error;
    }
  },

  async sendPromoCodeBySMS(phone: string, token: string) {
    const api = apiClient({
      withAccessToken: false,
    });

    try {
      await api.post(`promocode/send/sms/${phone}/${token}`);

      return true;
    } catch (error) {
      throw error;
    }
  },

  async addPromoCodeToAppleWallet(token: string): Promise<Blob> {
    const api = apiClient({
      withAccessToken: false,
    });

    try {
      const response = await api.get<Blob>(`promocode/pass/apple/${token}`, {
        headers: {
          Accept: 'application/vnd.apple.pkpass',
        },
        responseType: 'blob',
      } as AxiosRequestConfig);

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async addPromoCodeToGoogleWallet(token: string): Promise<string> {
    const api = apiClient({
      withAccessToken: false,
    });

    try {
      const response = await api.get(`promocode/pass/google/${token}`);

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getGuidForTelegram(token: string | undefined): Promise<string> {
    const api = apiClient({
      withAccessToken: false,
    });
    try {
      const response = await api.post(`${process.env.REACT_APP_TELEGRAM_API_URL}promocode/token/save`, null, {
        params: {
          tokenBase64: token,
        },
      } as AxiosRequestConfig);
      return response.data;
    } catch (error) {
      throw error;
    }
  },


};
