




import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgLittleRedTriangle: FC<ISvgProps> = (props: ISvgProps) => {
	return (
		<svg viewBox="0 0 96 97" {...props}>
			<g filter="url(#filter0_d_4388_15433)">
				<path fillRule="evenodd" clipRule="evenodd" d="M61.3016 70.1909C65.4032 71.8844 69.6778 68.1685 68.5717 63.8711L55.4186 12.7727C54.4207 8.89601 49.6919 7.42474 46.6708 10.051L11.0533 41.0128C8.0321 43.6391 8.83093 48.5266 12.531 50.0543L61.3016 70.1909Z" fill="url(#paint0_linear_4388_15433)" />
			</g>
			<path fillRule="evenodd" clipRule="evenodd" d="M59.6762 71.604C63.7778 73.2975 68.0524 69.5816 66.9462 65.2842L53.7932 14.1858C52.7953 10.3091 48.0665 8.83782 45.0453 11.4641L9.42785 42.4259C6.40667 45.0522 7.2055 49.9397 10.9056 51.4674L59.6762 71.604Z" fill="#FF1744" />
			<defs>
				<filter id="filter0_d_4388_15433" x="0.0894666" y="0.754889" width="82.325" height="84.659" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
					<feOffset dx="2.27762" dy="3.41643" />
					<feGaussianBlur stdDeviation="5.69405" />
					<feColorMatrix type="matrix" values="0 0 0 0 0.968627 0 0 0 0 0 0 0 0 0 0.454902 0 0 0 0.300016 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4388_15433" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4388_15433" result="shape" />
				</filter>
				<linearGradient id="paint0_linear_4388_15433" x1="46.8718" y1="95.4983" x2="4.47743" y2="46.7291" gradientUnits="userSpaceOnUse">
					<stop stopColor="#FF1A86" />
					<stop offset="1" stopColor="#F854FF" />
				</linearGradient>
			</defs>
		</svg>



	);
};

export default SvgLittleRedTriangle;


