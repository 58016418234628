import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NotFoundMessage } from '../NotFoundMessage';
import { useIntl } from 'react-intl';
import companyApi from '../../api/company';
import { IPromotion } from '@/types';
import { useFavoriteIds } from '@/data/useFavoriteIds';
import { down } from 'styled-breakpoints';
import { Link } from 'react-router-dom';
import { PromotionCard } from '../PromotionCard';




const NotFoundWrapper = styled.div`
padding: 20px 15px;
`;


const Items = styled.div`
  padding: 0 0 30px 0;
  display: flex;
  flex-wrap: wrap;
`;

const Item = styled.div`
  width: 33%;
  padding: 15px;

  ${down('md')} {
    width: 50%;
  }

  ${down('sm')} {
    width: 100%;
  }
`;

const PromotionLink = styled(Link)`
  text-decoration: none;
`;



export default function PromotionsCompany({ businessId }: any) {
	const [reachingEnd, setReachingEnd] = useState(false);
	const [fetching, setFetching] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);
	const [items, setItems] = useState<IPromotion[]>([]);
	const { favoriteIds, isIdsLoading } = useFavoriteIds();



	const intl = useIntl();


	const tNotFoundDescription = intl.formatMessage({
		id: 'promotions.search.notFound.description',
	});

	useEffect(() => {

		companyApi.getCompanyPromoations(businessId, pageNumber, true).then(response => {
			setItems([...items!, ...response]);
			setPageNumber(pageNumber + 1);
			if (response.length < 9) {
				setReachingEnd(true);
			}
		});

	}, []);

	useEffect(() => {
		if (fetching) {

			companyApi.getCompanyPromoations(businessId, pageNumber, true).then(response => {
				setItems([...items!, ...response]);
				setPageNumber(pageNumber + 1);
				if (response.length < 9) {
					setReachingEnd(true);
				}
			}).finally(() => {
				setFetching(false);
			}
			);
		}
	}, [fetching]);
	const scrollHandler = (e: any) => {
		if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 150 && !reachingEnd) {

			setFetching(true);
		}
	};
	useEffect(() => {
		document.addEventListener('scroll', scrollHandler);

		return function () {
			document.removeEventListener('scroll', scrollHandler);
		};

	}, [reachingEnd]);



	const renderItems = () => {


		if (!items?.length) {
			return (
				<NotFoundWrapper>
					<NotFoundMessage description={tNotFoundDescription} />
				</NotFoundWrapper>
			);
		} else if (!isIdsLoading) {

			return (
				<Items>
					{items?.map((i) => {
						const image = i?.images?.find((item) => item.type === 's665x378');
						const isFavorite = favoriteIds?.includes(i?.id);

						return (
							<Item key={i.id}>
								<PromotionLink to={`/promotions/${i.id}`} target="_blank">
									<PromotionCard
										title={i.title}
										startDateTime={i.startDateTime}
										endDateTime={i.endDateTime}
										startPromoCodeActivationDateTime={i.startPromocodeActivationDateTime}
										endPromoCodeActivationDateTime={
											i.endPromocodeActivationDateTime
										}
										company={i.business.name}
										rewards={i.ambassadorRewards}
										imageSrc={image?.media.path}
										id={i.id}
										isFavorite={isFavorite}
									/>
								</PromotionLink>
							</Item>
						);
					}
					)}
				</Items>
			);
		}
		return <></>;
	};

	return (
		renderItems()
	);
}





