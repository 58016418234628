import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import SignUpContainer from '@/containers/SignUpContainer';
import SignInContainer from '@/containers/SignInContainer';
import { authSelectors } from '@/store/selectors';
import { TAuthMode } from '@/types';

interface IProps {
  onSuccessSignIn?: () => void;
  onSuccessSignUp?: () => void;
}

const AuthCenter: FC<IProps> = ({
  onSuccessSignIn = () => {},
  onSuccessSignUp = () => {},
}: IProps) => {
  const activeMode: TAuthMode = useSelector(authSelectors.activeMode);

  return activeMode === 'signIn' ? (
    <SignInContainer insideModal onSuccessAuth={onSuccessSignIn} />
  ) : (
    <SignUpContainer insideModal onSuccessAuth={onSuccessSignUp} />
  );
};

export default AuthCenter;
