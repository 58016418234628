import useSWR from 'swr';
import axios from 'axios';
import { quickSearchAPI } from '@/api';
import { createErrorObject } from '@/helpers/createErrorObject';
import { IQuickSearchResult } from '@/types';

const useQuickSearch = (text: string) => {
  const {
    data,
    mutate,
    error,
  }: { data?: IQuickSearchResult; mutate: any; error?: any } = useSWR(
    ['catalog/quicksearch', text],
    quickSearchAPI.getQuickSearchResult,
    {
      revalidateOnFocus: false,
    },
  );

  const isLoading = !data && (!error || axios.isCancel(error));
  const isError = !!error;

  return {
    mutate,
    isLoading,
    isError,
    quickSearchResult: data,
    error: error ? createErrorObject(error) : null,
  };
};

export { useQuickSearch };
