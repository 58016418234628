import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { SignInForm, PhoneVerificationForm } from '@/components/Forms';
import { SvgLogo } from '@/components/Svg';
import { useUser } from '@/data/useUser';
import {
  TConfirmationResult,
  IError,
  TAuthFormData,
  TAuthStage,
  TAuthMode,
  TReCaptchaVerifier,
} from '@/types';
import { tinkoffButton } from '@/components/Buttons/TinkoffButton';
import OrLine from '@/components/OrLine/OrLine';
import TinkoffMatchModal from '@/components/Modal/TinkoffMatchModal';








export interface IPropsSignInScene {
  phoneNumber: string;
  activeStage: TAuthStage;
  confirmationResult: TConfirmationResult;
  loadingSignIn: boolean;
  loadingCodeVerification: boolean;
  errorSignIn: IError;
  errorCodeVerification: IError;
  signInWithPhoneNumber: (data: TSignInWithPhoneNumberData) => void;
  verifySmsCode: (data: TVerifySmsCodeData) => void;
  signInWithTinkoffToken: (data: TVerifyTinkoffCodeData) => void;
  setPhoneNumber: (phoneNumber: string) => void;
  setAuthActiveMode: (mode: TAuthMode) => void;
  setAuthActiveStage: (mode: TAuthMode, stage: TAuthStage) => void;
}

type TSignInWithPhoneNumberData = {
  mode: TAuthMode;
  phoneNumber: string;
  reCaptchaVerifier: TReCaptchaVerifier;
};

type TVerifySmsCodeData = {
  mode: TAuthMode;
  code: string;
  phoneNumber: TConfirmationResult;
};

type TVerifyTinkoffCodeData = {
  mode: TAuthMode;
  code: string;

};

interface IProps extends IPropsSignInScene {
  insideModal?: boolean;
  onSuccessAuth?: () => void;
}

const DEFAULT_DIAL_CODE = '+7';
const REGEXP_NON_DIGIT = new RegExp('\\D', 'g');

const Root = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LogoWrapper = styled.div`
  margin: 2px 0 40px 0;
  text-align: center;
`;

const SignUpScene: FC<IProps> = ({
  phoneNumber,
  activeStage,
  loadingSignIn,
  loadingCodeVerification,
  errorSignIn,
  errorCodeVerification,
  insideModal = false,
  signInWithPhoneNumber,
  verifySmsCode,
  signInWithTinkoffToken,
  setPhoneNumber,
  setAuthActiveMode,
  setAuthActiveStage,
  onSuccessAuth = () => { },
}: IProps) => {
  const { mutate } = useUser();


  useEffect(() => {
    if (activeStage === 'success') {
      onSuccessAuth();
      setAuthActiveStage('signIn', 'dataInput');
      mutate();




    }


  }, [activeStage, onSuccessAuth, setAuthActiveStage, mutate]);

  const handleSignIn = (data: TAuthFormData) => {
    const processedPhoneNumber = data.phoneNumber.replace(REGEXP_NON_DIGIT, '');

    localStorage.setItem('phone', `+7 ${data.phoneNumber}`);


    setPhoneNumber(`${DEFAULT_DIAL_CODE} ${data.phoneNumber}`);
    signInWithPhoneNumber({
      mode: 'signIn',
      phoneNumber: `+7${processedPhoneNumber}`,
      reCaptchaVerifier: data.reCaptchaVerifier,
    });
  };

  const messageHandler = (event: MessageEvent) => {
    if (event.origin === process.env.REACT_APP_TINKOFF_AUTH_ORIGIN) {
      const eventData = JSON.parse(event.data);

      signInWithTinkoffToken({
        mode: 'signIn',
        code: eventData.code
      });
    }
  };





  useEffect(() => {
    tinkoffButton({ size: 'l', text: 'Tinkoff' });

  }, [activeStage]);


  useEffect(() => {
    window.addEventListener('message', messageHandler);

    return function () {
      window.removeEventListener('message', messageHandler);
    };

  }, []);

  const handleMatchButtonClick = () => {
    onSuccessAuth();
    mutate();
  };


  const handleResendSmsCode = () => {
    setAuthActiveStage('signIn', 'dataInput');
  };

  const handleVerifySmsCode = (data: { code: string }) => {
    const currentNumber = phoneNumber != '' ? phoneNumber : localStorage.getItem('phone');
    const processedPhoneNumber = currentNumber!.replace(REGEXP_NON_DIGIT, '');
    verifySmsCode({
      mode: 'signIn',
      code: data.code,
      phoneNumber: processedPhoneNumber
    });
  };

  const renderForm = () => {
    if (activeStage === 'codeVerification') {
      return (
        <PhoneVerificationForm
          defaultVerificationType="sms"
          phoneNumber={phoneNumber}
          isSingleVerificationType
          loading={loadingCodeVerification}
          error={errorCodeVerification}
          verifyCode={handleVerifySmsCode}
          resendCode={handleResendSmsCode}
        />
      );
    }
    if (activeStage === 'dataInput') {
      return (
        <>
          <div id='container-for-tid-button'></div>
          <br />
          <OrLine />
          <br />
          <SignInForm
            loading={loadingSignIn}
            error={errorSignIn}
            signIn={handleSignIn}
            insideModal={insideModal}
            setAuthActiveMode={setAuthActiveMode}
          />
        </>
      );
    }

    if (activeStage === 'tinkoffMatch') {
      return (
        <TinkoffMatchModal {...JSON.parse(localStorage.getItem('tinkoffMatchData')!)} onSuccess={handleMatchButtonClick} />
      );
    }
    return null;
  };

  return (
    <Root>
      {insideModal ? (
        <LogoWrapper>
          <SvgLogo width={120} height={41} />
        </LogoWrapper>
      ) : null}
      {renderForm()}
    </Root>
  );
};

export default SignUpScene;
