import moment from 'moment';

import { datetime } from '@/constants';
import { LegalEntityStatus, LegalEntityType } from '@/types';

import { TDocument } from './types';

export const prepareData = ({
  legalEntity,
  bankAccount,
  statusType
}: LegalEntityStatus): TDocument[] => {
  const hasLegalData =
    legalEntity?.inn &&
    legalEntity?.documentNumber &&
    legalEntity?.documentIssuedBy;



  const hasBankAccountData = bankAccount?.accountNumber;
  const legalTitle =
    legalEntity?.type === LegalEntityType.INDIVIDUAL
      ? 'legalStatus.legal.options.individual'
      : legalEntity?.type === LegalEntityType.SELF_EMPLOYED ? 'legalStatus.legal.options.selfWorking' : 'legalStatus.legal.options.entrepreneur';

  return hasLegalData ? [
    {
      title: legalEntity?.type ? legalTitle : '',
      defaultTitleId: 'common.legalStatus',
      addLink: '/documents/legal-status',
      status: statusType,
      details: hasLegalData
        ? [
          {
            title: 'legalStatus.labels.inn',
            text: legalEntity?.inn || '',
            isBold: true,
          },
          {
            title: 'legalStatus.labels.passportNumber',
            text: legalEntity?.documentNumber || '',
            isBold: true,
          },
          {
            title: 'legalStatus.labels.byWhom',
            text: legalEntity?.documentIssuedBy || '',
          },
          {
            title: 'legalStatus.labels.issueDate',
            text:
              moment(legalEntity?.documentIssuedDateAt).format(
                datetime.DATE_FORMAT,
              ) || '',
          },
        ]
        : [],
    },
    {
      defaultTitleId: 'common.requisites',
      addLink: '/documents/requisites',
      details: hasBankAccountData
        ? [
          {
            title: 'requisites.labels.accountNumber',
            text: bankAccount?.accountNumber || '',
            isBold: true,
          },
        ]
        : [],
    },
  ] : [{
    title: legalEntity?.type ? legalTitle : '',
    defaultTitleId: 'common.legalStatus',
    addLink: '/documents/legal-status',
    status: statusType,
    details: hasLegalData
      ? [
        {
          title: 'legalStatus.labels.inn',
          text: legalEntity?.inn || '',
          isBold: true,
        },
        {
          title: 'legalStatus.labels.passportNumber',
          text: legalEntity?.documentNumber || '',
          isBold: true,
        },
        {
          title: 'legalStatus.labels.byWhom',
          text: legalEntity?.documentIssuedBy || '',
        },
        {
          title: 'legalStatus.labels.issueDate',
          text:
            moment(legalEntity?.documentIssuedDateAt).format(
              datetime.DATE_FORMAT,
            ) || '',
        },
      ]
      : [],
  }];
};
