
import { TReCaptchaVerifier } from '@/types';
import axios, { AxiosRequestConfig } from 'axios';
import createUuid from '@/helpers/createUuid';
import createUrlParam from '@/helpers/createUrlParam';




interface ISignInWithPhoneNumberParams {
	phoneNumber: string;
	reCaptchaVerifier: TReCaptchaVerifier;
}

interface IVerifySmsCode {
	code: string;
	phoneNumber: string;
}

interface IVerifyTinkoffCode {
	code: string;

}



export default {

	async signInWithPhoneNumber({
		phoneNumber,
		reCaptchaVerifier,
	}: ISignInWithPhoneNumberParams) {

		try {
			const uuid = createUuid();
			const response = await axios.post(`${process.env.REACT_APP_SMS_AUTH_URL}auth/sms`, {}, {
				params: {
					phone: phoneNumber, captcha: reCaptchaVerifier, sessionId: uuid
				}
			} as AxiosRequestConfig);
			createUrlParam('sessionId', uuid);
			const { data } = response;


			return {
				data
			};
		} catch (error) {
			throw error;
		}
	},



	async verifySmsCode({ code, phoneNumber }: IVerifySmsCode) {


		try {

			const urlParams = new URLSearchParams(window.location.search);
			const sessionId = urlParams.get('sessionId');
			const response = await axios.post(`${process.env.REACT_APP_SMS_AUTH_URL}auth/code`, {}, {
				params: {
					phone: `+${phoneNumber}`, code, sessionId
				}
			} as AxiosRequestConfig);
			const { data } = response;
			const idToken = await data.token;

			return {
				data: {
					idToken,

				},
			};

		} catch (error) {
			throw error;
		}
	},

	async verifyTinkoffCode({ code }: IVerifyTinkoffCode) {


		try {


			const response = await axios.post(`${process.env.REACT_APP_TINKOFF_AUTH_URL}auth/complete`, {}, {
				params: {
					code
				}
			} as AxiosRequestConfig);
			const { data } = response;
			const accessToken = await data.token;

			return {
				data: {
					accessToken,

				},
			};

		} catch (error) {
			throw error;
		}
	},

};


