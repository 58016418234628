import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgBalanceRouble: FC<ISvgProps> = (props: ISvgProps) => {
  const { fill } = props;

  return (
    <svg width={18} height={18} viewBox="0 0 18 18" {...props}>
      <g fill={fill}>
        <path d="M9.562 3.902H7.63a.528.528 0 00-.528.528v4.675h-1.02a.529.529 0 00.001 1.055h1.02v1.055H5.835a.528.528 0 100 1.054H7.1v1.512a.528.528 0 101.055 0V12.27h2.567a.528.528 0 000-1.054H8.156V10.16h1.406a3.133 3.133 0 003.13-3.129 3.133 3.133 0 00-3.13-3.129zm0 5.203H8.156V4.957h1.406c1.144 0 2.075.93 2.075 2.074 0 1.144-.93 2.074-2.075 2.074z" />
        <path d="M9 0C4.037 0 0 4.037 0 9c0 4.962 4.037 9 9 9 4.962 0 9-4.038 9-9 0-4.963-4.038-9-9-9zm7.5 11.62l-.371-.158a.527.527 0 10-.413.97l.37.158a7.977 7.977 0 01-1.11 1.64l-.283-.283a.528.528 0 00-.746.746l.283.283a8.024 8.024 0 01-1.813 1.196l-.15-.375a.526.526 0 10-.979.389l.149.376a7.84 7.84 0 01-1.91.365v-.404a.528.528 0 00-1.054 0v.404a7.888 7.888 0 01-2.093-.426l.158-.372a.527.527 0 10-.97-.413l-.158.37a7.977 7.977 0 01-1.64-1.11l.283-.283a.528.528 0 00-.746-.746l-.283.283a7.99 7.99 0 01-1.196-1.813l.375-.149a.528.528 0 00-.39-.98l-.375.15a7.857 7.857 0 01-.365-1.91h.403a.528.528 0 000-1.055h-.403a7.888 7.888 0 01.426-2.093l.372.158a.529.529 0 00.692-.278.527.527 0 00-.279-.692l-.37-.158a7.937 7.937 0 011.11-1.64l.283.283a.525.525 0 00.746 0 .528.528 0 000-.746l-.283-.283a7.968 7.968 0 011.813-1.196l.149.375a.527.527 0 10.98-.39l-.15-.375a7.857 7.857 0 011.91-.365v.403a.528.528 0 001.055 0v-.403c.729.048 1.43.194 2.093.426l-.158.372a.527.527 0 10.97.414l.158-.372a7.981 7.981 0 011.64 1.111l-.283.283a.528.528 0 00.746.746l.283-.283c.476.544.88 1.153 1.196 1.813l-.375.149a.527.527 0 00.389.98l.376-.15c.197.609.321 1.249.365 1.91h-.404a.528.528 0 000 1.055h.404a7.896 7.896 0 01-.426 2.093z" />
      </g>
    </svg>
  );
};

export default SvgBalanceRouble;
