import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { Container } from 'styled-bootstrap-grid';
import { cn } from '@bem-react/classname';

import { useAuth } from '@/data/useAuth';
import { useLegal } from '@/data/useLegal';

import { Button } from '@/components/Buttons';
import { Layout } from '@/components/Layout';


import { Loader } from '@/components/Loader';
import { prepareData } from './config';
import './DocumentsPage.less';
import { Status } from '@/components/Status';

const b = cn('DocumentsPage');

const DocumentsPage = () => {
  const { isAuth } = useAuth();
  const { formatMessage } = useIntl();
  const { legalEntity, bankAccount, isLoading, statusType } = useLegal();

  const getDocuments = () =>
    prepareData({ legalEntity, bankAccount, statusType }).map(
      ({ details, title, defaultTitleId, addLink, status }) => {
        const isEmpty = !details.length;

        return (
          <article
            key={defaultTitleId}
            className={b('Document', { empty: isEmpty })}
          >
            <header className={b('Document-Header')}>
              <div>
                {formatMessage({ id: title || defaultTitleId })}


                <p className={b('Document-Status')}>

                  <Status className={b('Status')} status={status} />

                </p>

              </div>

              {!isEmpty && (
                <Button
                  className={b('Document-Edit')}
                  displayType="link"
                  to={addLink}
                >
                  {formatMessage({ id: 'settings.buttons.edit' })}
                </Button>
              )}
            </header>

            <div className={b('Document-Content')}>
              {isEmpty && (
                <Button
                  className={b('Document-Add')}
                  displayType="link"
                  to={addLink}
                >
                  {formatMessage({ id: 'settings.buttons.add' })}
                </Button>
              )}

              {!isEmpty && (
                <dl>
                  {details.map((el) => (
                    <Fragment key={el.title}>
                      <dt className={b('Details-Title')}>
                        {formatMessage({ id: el.title })}
                      </dt>
                      <dd className={b('Details-Text', { bold: el?.isBold })}>
                        {el.text}
                      </dd>
                    </Fragment>
                  ))}
                </dl>
              )}
            </div>
          </article>
        );
      },
    );

  return (
    <Layout
      isAuth={isAuth}
      i18nSeoTitle="seo.title.documents"
      i18nTitle="common.documents"
    >
      <Container className={b()}>
        {isLoading && (
          <div className={b('Loader-Wrapper')}>
            <Loader size={52} />
          </div>
        )}

        {!isLoading && getDocuments()}
      </Container>
    </Layout>
  );
};

export default DocumentsPage;
