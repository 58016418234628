import React, { FC, useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';
import { FormattedMessage } from 'react-intl';
import hexToRgba from 'hex-to-rgba';

import { colors } from '@/constants';

import { TAuthMode } from '@/types';

import { useAuth } from '@/data/useAuth';
import { useUser } from '@/data/useUser';

import { Avatar } from '@/components/Avatar';
import { SvgArrowDown } from '@/components/Svg';
import { ProfileMenu } from '@/components/ProfileMenu';
import { Loader } from '@/components/Loader';
import { Dropdown } from '@/components/Dropdown';
import { DropdownOverlay } from '@/components/shared';

import { useWindowSize } from '@/hooks/useWindowSize';
import { useOutsideClick } from '@/hooks/useOutsideClick';

import { getCurrencySymbol } from '@/helpers/getCurrencySymbol';
import { getName } from '@/helpers/getName';

interface IProps {
  showAuthModal: (mode: TAuthMode) => void;
}

interface ICommonProps {
  isActive: boolean;
}

const Overlay = styled.div<ICommonProps>`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  display: ${({ isActive }: ICommonProps) => (isActive ? 'block' : 'none')};
`;

const Profile = styled.div`
  min-height: 38px;
  max-width: 300px;
  padding: 4px 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: ${colors.WHITE};
  box-shadow: 0 3px 6px 0 ${hexToRgba(colors.BLACK, 0.06)};
  cursor: pointer;
  transition: all 0.2s;
`;

const ProfileButton = styled.button`
  border: 0;
  background: transparent;
`;

const MenuProfileName = styled.div`
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
  color: ${colors.BLACK};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ProfileName = styled(MenuProfileName)`
  ${down('sm')} {
    display: none;
  }
`;

const ProfileAvatar = styled(Avatar)`
  flex: none;
`;

const ArrowWrapper = styled.div<ICommonProps>`
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transform-origin: 50% 50%;
  transition: all 0.2s;

  ${({ isActive }: ICommonProps) => {
    if (isActive) {
      return css`
        transform: rotate(180deg);
      `;
    }
    return '';
  }}
`;

const ProfileMenuWrapper = styled.div`
  width: 240px;

  ${down('xs')} {
    width: 100%;
  }
`;

const SmallScreenMenuWrapper = styled.div`
  position: fixed;
  z-index: 9999;
  top: 62px;
  left: 0;
  width: 100%;
  background-color: ${colors.WHITE};
  box-shadow: 0 6px 20px 0 ${hexToRgba(colors.BLACK, 0.08)};
  transition: all 0.3s;

  ${({ isActive }: ICommonProps) => {
    if (isActive) {
      return css`
        transform: translateY(0);
      `;
    }
    return css`
      transform: translateY(-100vh);
    `;
  }}
`;

const MenuProfile = styled.div`
  padding: 20px 15px;
  border-bottom: 1px solid ${colors.LILAC_WHITE};
`;

const Balance = styled.div`
  padding-top: 6px;
  display: flex;
  align-items: flex-end;
`;

const BalanceLabel = styled.span`
  margin-right: 6px;
  font-size: 12px;
  color: ${colors.MONSOON};
`;

const HeaderProfile: FC<IProps> = ({ showAuthModal }: IProps) => {
  const [isActive, setActive] = useState(false);

  const smallScreenMenuRef = useRef(null);

  const { isAuth } = useAuth();
  const { user, isLoading } = useUser();

  const size = useWindowSize();

  const isSmallScreen = size && size.width! < 744;
  const isVerySmallScreen = size && size.width! < 576;

  useEffect(() => {
    setActive(false);
  }, [size.width, setActive]);

  useOutsideClick(smallScreenMenuRef, () => {
    if (isActive && isVerySmallScreen) {
      setActive(false);
    }
  });

  const handleProfileButtonClick = () => {
    setActive(true);
  };

  const handleOverlayClick = () => {
    setActive(false);
  };

  const handleDropdownChange = (status: boolean) => {
    setActive(status);
  };

  const renderProfile = () => {
    return (
      <Profile>
        {isAuth && isLoading ? (
          <Loader size={22} thickness={3} color={colors.BLUE} />
        ) : (
          <>
            {isAuth && user && user.profile && (
              <ProfileName>{getName(user.profile, 'short')}</ProfileName>
            )}
            <ProfileAvatar
              width="30px"
              imageSrc={user?.profile?.media?.path || ''}
            />
          </>
        )}
        <ArrowWrapper isActive={isActive}>
          <SvgArrowDown width={11} height={6} fill={colors.BLACK} />
        </ArrowWrapper>
      </Profile>
    );
  };

  const renderMenu = () => (
    <ProfileMenuWrapper>
      <ProfileMenu
        isSmallScreen={isSmallScreen}
        showAuthModal={showAuthModal}
      />
    </ProfileMenuWrapper>
  );

  const renderDropdownMenu = () => (
    <DropdownOverlay>{renderMenu()}</DropdownOverlay>
  );

  return isVerySmallScreen ? (
    <>
      <ProfileButton type="button" onClick={handleProfileButtonClick}>
        {renderProfile()}
      </ProfileButton>
      <Overlay isActive={isActive} />
      <SmallScreenMenuWrapper
        ref={smallScreenMenuRef}
        isActive={isActive}
        onClick={handleOverlayClick}
      >
        <MenuProfile>
          {isAuth && user && user.profile ? (
            <MenuProfileName>{getName(user.profile, 'short')}</MenuProfileName>
          ) : null}
          <Balance>
            <BalanceLabel>
              <FormattedMessage id="profile.balance" />:
            </BalanceLabel>
            {isAuth && user?.balance?.availableBalance
              ? `${user.balance.availableBalance} ${getCurrencySymbol(
                  'rouble',
                )}`
              : null}
          </Balance>
        </MenuProfile>
        {renderMenu()}
      </SmallScreenMenuWrapper>
    </>
  ) : (
    <Dropdown overlay={renderDropdownMenu()} onChange={handleDropdownChange}>
      {renderProfile()}
    </Dropdown>
  );
};

export default HeaderProfile;
