import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgBillProcessing: FC<ISvgProps> = (props: ISvgProps) => {
  return (
    <svg viewBox="0 0 14 14" {...props}>
      <g fill="#FFC107">
        <path d="M13.45 4.28A7.04 7.04 0 004.28.55 7.04 7.04 0 00.55 9.72a7.04 7.04 0 009.17 3.73 7.04 7.04 0 003.73-9.17zm-.86 5.08a6.1 6.1 0 01-7.95 3.23 6.1 6.1 0 01-3.23-7.95 6.1 6.1 0 017.95-3.23 6.1 6.1 0 013.23 7.95z"/>
        <path d="M9.44 4.3l.02-.03.02-.04a.32.32 0 00.02-.08V3a.32.32 0 00-.31-.32H4.66a.32.32 0 00-.32.32V4.15l.01.03v.01l.01.04.02.04.03.03L6.52 7 4.41 9.67l-.02.03v.01l-.02.04a.31.31 0 00-.03.08V10.97c0 .17.14.32.32.32h4.53c.17 0 .31-.15.31-.32v-1.1-.04-.04l-.02-.04a.32.32 0 00-.04-.07l-2.12-2.7 2.12-2.67zm-.57-.98v.48h-3.9v-.48h3.9zm-3.9 7.34v-.48h3.9v.48h-3.9zm3.57-1.1H5.31L6.92 7.5l1.62 2.05zM6.92 6.47L5.31 4.43h3.23L6.92 6.48z"/>
      </g>
    </svg>
  );
};

export default SvgBillProcessing;
