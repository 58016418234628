<svg width="66" height="77" viewBox="0 0 66 77" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g filter="url(#filter0_d_4388_15436)">
		<path fillRule="evenodd" clipRule="evenodd" d="M27.9789 15.7113C31.2278 11.1674 38.2699 12.2828 39.9556 17.6082L50.8516 52.0294C52.3723 56.8336 48.3057 61.5586 43.3286 60.7703L11.4332 55.7186C6.45614 54.9303 4.04863 49.1799 6.97952 45.0808L27.9789 15.7113Z" fill="url(#paint0_linear_4388_15436)" />
	</g>
	<path fillRule="evenodd" clipRule="evenodd" d="M25.3018 15.2872C28.5507 10.7433 35.5927 11.8587 37.2785 17.1841L48.1745 51.6054C49.6952 56.4095 45.6285 61.1346 40.6515 60.3463L8.75609 55.2945C3.77902 54.5062 1.37151 48.7558 4.3024 44.6567L25.3018 15.2872Z" fill="#FFC107" />
	<defs>
		<filter id="filter0_d_4388_15436" x="-2.83283" y="5.47102" width="68.2429" height="70.7599" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
			<feFlood floodOpacity="0" result="BackgroundImageFix" />
			<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
			<feOffset dx="2.84703" dy="3.98584" />
			<feGaussianBlur stdDeviation="5.69405" />
			<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.862745 0 0 0 0 0 0 0 0 0.303294 0" />
			<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4388_15436" />
			<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4388_15436" result="shape" />
		</filter>
		<linearGradient id="paint0_linear_4388_15436" x1="9.0835" y1="0.441406" x2="0.600201" y2="54.0028" gradientUnits="userSpaceOnUse">
			<stop stopColor="#FFF300" />
			<stop offset="1" stopColor="#FF7600" />
		</linearGradient>
	</defs>
</svg>;







import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgLittleYellowTriangle: FC<ISvgProps> = (props: ISvgProps) => {
	return (
		<svg viewBox="0 0 66 77" {...props}>
			<g filter="url(#filter0_d_4388_15436)">
				<path fillRule="evenodd" clipRule="evenodd" d="M27.9789 15.7113C31.2278 11.1674 38.2699 12.2828 39.9556 17.6082L50.8516 52.0294C52.3723 56.8336 48.3057 61.5586 43.3286 60.7703L11.4332 55.7186C6.45614 54.9303 4.04863 49.1799 6.97952 45.0808L27.9789 15.7113Z" fill="url(#paint0_linear_4388_15436)" />
			</g>
			<path fillRule="evenodd" clipRule="evenodd" d="M25.3018 15.2872C28.5507 10.7433 35.5927 11.8587 37.2785 17.1841L48.1745 51.6054C49.6952 56.4095 45.6285 61.1346 40.6515 60.3463L8.75609 55.2945C3.77902 54.5062 1.37151 48.7558 4.3024 44.6567L25.3018 15.2872Z" fill="#FFC107" />
			<defs>
				<filter id="filter0_d_4388_15436" x="-2.83283" y="5.47102" width="68.2429" height="70.7599" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
					<feOffset dx="2.84703" dy="3.98584" />
					<feGaussianBlur stdDeviation="5.69405" />
					<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.862745 0 0 0 0 0 0 0 0 0.303294 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4388_15436" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4388_15436" result="shape" />
				</filter>
				<linearGradient id="paint0_linear_4388_15436" x1="9.0835" y1="0.441406" x2="0.600201" y2="54.0028" gradientUnits="userSpaceOnUse">
					<stop stopColor="#FFF300" />
					<stop offset="1" stopColor="#FF7600" />
				</linearGradient>
			</defs>
		</svg>




	);
};

export default SvgLittleYellowTriangle;


