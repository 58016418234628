import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgArrowDown: FC<ISvgProps> = (props: ISvgProps) => {
  const { fill } = props;
  return (
    <svg viewBox="0 0 11 6" {...props}>
      <path
        fill={fill}
        d="M0 .88c0 .247.091.444.266.613l4.469 4.183c.236.218.472.317.761.324.29 0 .54-.106.761-.317l4.469-4.19A.805.805 0 0011 .88c0-.486-.426-.88-.952-.88-.258 0-.502.106-.692.282l-3.86 3.633L1.636.282A.974.974 0 00.945 0C.426 0 0 .394 0 .88z"
      />
    </svg>
  );
};

export default SvgArrowDown;
