import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { cn } from '@bem-react/classname';

import { Button } from '@/components/Buttons';

import './EmailConfirm.less';

interface IProps {
  email: string;
  isConfirmed?: boolean;
  onClose: () => void;
}

const cnEmailConfirm = cn('EmailConfirm');

const EmailConfirm: FC<IProps> = ({ email, isConfirmed, onClose }) => {
  const intl = useIntl();

  const tButton = intl.formatMessage({ id: 'settings.modals.email.button' });
  const tTitleProgress = intl.formatMessage({
    id: 'settings.modals.email.title.progress',
  });
  const tTitleDone = intl.formatMessage({
    id: 'settings.modals.email.title.done',
  });
  const tTextProgress = intl.formatMessage(
    {
      id: 'settings.modals.email.text.progress',
    },
    { email },
  );
  const tTextDone = intl.formatMessage(
    {
      id: 'settings.modals.email.text.done',
    },
    { email },
  );

  return (
    <div className={cnEmailConfirm()}>
      <h2 className={cnEmailConfirm('Title')}>
        {!isConfirmed ? tTitleProgress : tTitleDone}
      </h2>

      <p className={cnEmailConfirm('Text')}>
        {!isConfirmed ? tTextProgress : tTextDone}
      </p>

      <div>
        <Button
          fluid
          onClick={onClose}
          className={cnEmailConfirm('Button')}
          displayType="primary"
        >
          {tButton}
        </Button>
      </div>
    </div>
  );
};

export default EmailConfirm;
