export const filters = [
	{
		id: 'formed',
		title: 'myStat.filters.formed',
		filterBy: {
			formed: true,
			expired: false,
			extinguished: false,
			all: false


		},
	},
	{
		id: 'expired',
		title: 'myStat.filters.expired',
		filterBy: {
			formed: false,
			expired: true,
			extinguished: false,
			all: false
		},
	},
	{
		id: 'extinguished',
		title: 'myStat.filters.extinguished',
		filterBy: {
			formed: false,
			expired: false,
			extinguished: true,
			all: false
		},
	},
	{
		id: 'all',
		title: 'myStat.filters.all',
		filterBy: {
			formed: false,
			expired: false,
			extinguished: false,
			all: true
		},
	},
];

