import useSWR from 'swr';
import axios from 'axios';
import { commonAPI } from '@/api';
import { createErrorObject } from '@/helpers/createErrorObject';
import { IPromotionCategoryFromAPI, IPromotionCategory } from '@/types';

const getCategories = (
  categories?: IPromotionCategoryFromAPI[],
  subcategories?: IPromotionCategoryFromAPI[],
) => {
  if (!categories) {
    return [];
  }

  const result: IPromotionCategory[] = categories.map((i) => {
    return {
      ...i,
      subcategories: [],
    };
  });

  subcategories!.forEach((i) => {
    const categoryIndex = result.findIndex(
      (item) => item.id === i.parentCategory!.id,
    );

    result[categoryIndex].subcategories.push(i);
  });

  return result;
};

const useCategories = () => {
  const {
    data,
    mutate,
    error,
  }: { data?: IPromotionCategoryFromAPI[]; mutate: any; error?: any } = useSWR(
    'catalog/categories',
    commonAPI.fetch,
    {
      revalidateOnFocus: false,
    },
  );

  const categories = data ? data.filter((i) => !i.parentCategory) : undefined;
  const subcategories = data ? data.filter((i) => i.parentCategory) : undefined;

  const result = getCategories(categories, subcategories);

  const isLoading = !data && (!error || axios.isCancel(error));
  const isError = !!error;

  return {
    mutate,
    isLoading,
    isError,
    categoriesFlat: data || [],
    categories: result,
    error: error ? createErrorObject(error) : null,
  };
};

export { useCategories };
