import React, { useState, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import moment, { Moment } from 'moment';
import Container from 'styled-bootstrap-grid/dist/components/Container';

import { cn } from '@bem-react/classname';

import { useAuth } from '@/data/useAuth';
import { useUser } from '@/data/useUser';
import { useOperations } from '@/data/useOperations';

import { colors, datetime } from '@/constants';

import { getCurrency } from '@/helpers/getСurrency';

import { Layout } from '@/components/Layout';
import { Loader } from '@/components/Loader';
import { Button } from '@/components/Buttons';
import { SvgBalanceRouble } from '@/components/Svg';
import { Table } from '@/components/Table';
import { DateRange } from '@/components/DateRange';
import { Modal } from '@/components/Modal';
import { BillWithdraw } from '@/components/BillWithdraw';
import { BillTransfer } from '@/components/BillTransfer';
import { FilterButtons } from '@/components/FilterButtons';

import { columns, data, filters, getIcon } from './config';
import { TBillFilter, TTransferModal } from './types';
import './BillPage.less';

const cnBill = cn('BillPage');

const BillPage = () => {
  const { formatMessage } = useIntl();
  const { isAuth } = useAuth();
  const { user } = useUser();

  const [isOpenedWithdrawModal, setOpenedWithdrawModal] = useState(false);
  const [activeFilter, setBillFilter] = useState<TBillFilter>(filters[0]);
  const [isTransferModal, setTransferModal] = useState<TTransferModal>({
    type: 'income',
    visible: false,
    data: null,
  });

  const { operations, isLoading } = useOperations(activeFilter.filterBy);

  const [showLoader, setShowLoader] = useState(isLoading);

  useEffect(() => {
    if (showLoader) {
      setTimeout(() => setShowLoader(false), 500);
    }
  }, [showLoader]);

  useEffect(() => {
    setShowLoader(true);
  }, [isLoading]);

  const tBalanceTitle = formatMessage({ id: 'bill.balance.title' });
  const tBalanceButton = formatMessage({ id: 'bill.balance.button' });
  const tHistoryTitle = formatMessage({ id: 'bill.history.title' });
  const tNoDataTitle = formatMessage({ id: 'bill.noData.title' });
  const tNoDataText = formatMessage({ id: 'bill.noData.text' });

  const handleTransferModalClose = useCallback(
    () => setTransferModal((v) => ({ ...v, visible: false })),
    [],
  );

  const getData = () =>
    operations.map(
      ({ dateTimeUtc, description, operationAmount, type, status }) => {
        const handleStatusBtnClick = () =>
          setTransferModal({
            type,
            visible: true,
            data: {
              sum: operationAmount,
              datetime: dateTimeUtc,
              status,
              link: '/',
            },
          });

        return {
          datetime: moment(dateTimeUtc).format(datetime.DATE_TIME_FORMAT),
          info: description,
          sum: (
            <span className={cnBill('Sum', { [type.toLowerCase()]: true })}>
              {getCurrency(Math.abs(operationAmount))}
            </span>
          ),
          operation: formatMessage({
            id: `bill.operation.type.${type.toLowerCase()}`,
          }),
          status: (
            <button
              className={cnBill('Status-Btn')}
              type="button"
              onClick={handleStatusBtnClick}
              title={formatMessage({
                id: `bill.operation.status.${status.toLowerCase()}`,
              })}
            >
              {getIcon(status)}
            </button>
          ),
        };
      },
    );

  const handleDateRangeChange = useCallback(
    (final: { startDate: Moment | null; endDate: Moment | null }) => {
      setBillFilter((currentFilter) => ({
        ...currentFilter,
        filterBy: {
          ...currentFilter.filterBy,
          from: final.startDate?.utc().format(),
          to: final.endDate?.utc().format(),
        },
      }));
    },
    [],
  );

  const handleFilter = useCallback((filter: TBillFilter) => {
    const { id, filterBy } = filter;
    setBillFilter((currentFilter) => ({
      id,
      filterBy: {
        ...currentFilter.filterBy,
        ...filterBy,
      },
    }));
  }, []);

  return (
    <Layout
      isAuth={isAuth}
      i18nSeoTitle="seo.title.bill"
      i18nTitle="common.bill"
    >
      {isAuth ? (
        <>
          <Container className={cnBill('Row')}>
            <div className={cnBill('Balance')}>
              <SvgBalanceRouble width={54} height={54} fill={colors.BLUE} />

              <div className={cnBill('Balance-Info')}>
                <p className={cnBill('Balance-Title')}>{tBalanceTitle}</p>

                <p className={cnBill('Balance-Count')}>
                  {getCurrency(
                    user?.balance ? user.balance.availableBalance : 0,
                  )}
                </p>
              </div>

              <Button
                displayType="primary"
                to="/account/withdraw"
              // onClick={() => setOpenedWithdrawModal(true)}
              >
                {tBalanceButton}
              </Button>
            </div>

            <DateRange
              className={cnBill('Calendar')}
              startDate={moment()}
              endDate={moment()}
              handleClose={handleDateRangeChange}
            />
          </Container>

          <Container className={cnBill('History')}>
            <h2 className={cnBill('History-Title')}>{tHistoryTitle}</h2>

            <FilterButtons
              activeByFilter
              separateAfter={[0, 2]}
              className={cnBill('Filters')}
              defaultId={filters[0].id}
              filters={filters}
              handleFilter={handleFilter}
            />

            {showLoader && (
              <div className={cnBill('Loader-Wrapper')}>
                <Loader size={52} />
              </div>
            )}

            {!showLoader && (
              <div className={cnBill('Table-Wrapper')}>
                <Table
                  className={cnBill('Table')}
                  thClassNames={[cnBill('Table-Th')]}
                  rowClassNames={[cnBill('Table-Row')]}
                  colClassNames={[cnBill('Table-Cell')]}
                  data={getData()}
                  columns={columns}
                />
              </div>
            )}

            {!data.length && (
              <>
                <p className={cnBill('No-Data-Title')}>{tNoDataTitle}</p>

                <p className={cnBill('No-Data-Text')}>{tNoDataText}</p>
              </>
            )}
          </Container>
        </>
      ) : (
        <Container>
          <p className={cnBill('Message')}>
            <FormattedMessage id="account.auth" />
          </p>

          <Link className={cnBill('Auth')} to="/signin">
            <FormattedMessage id="auth.logIn" />
          </Link>
        </Container>
      )}

      <Modal
        visible={isOpenedWithdrawModal}
        onCancel={() => setOpenedWithdrawModal(false)}
      >
        <BillWithdraw />
      </Modal>

      <Modal
        visible={isTransferModal.visible}
        onCancel={handleTransferModalClose}
      >
        <BillTransfer type={isTransferModal.type} data={isTransferModal.data} />
      </Modal>
    </Layout>
  );
};

export default BillPage;
