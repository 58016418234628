import React, { FC } from 'react';
import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import { SvgLogoWithoutText } from '@/components/Svg';
import { colors } from '@/constants';

interface IProps {
  logoSrc?: string;
  text: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 16px;
  font-size: 14px;
  transition: all 0.2s;

  &:hover {
    background-color: ${colors.GHOST_WHITE};
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  overflow: hidden;
  background-color: ${hexToRgba(colors.HAWKES_BLUE, 0.3)};
`;

const Logo = styled.img`
  display: block;
  width: 100%;
`;

const SearchResultItem: FC<IProps> = ({ logoSrc = '', text }: IProps) => {
  return (
    <Wrapper>
      <LogoWrapper>
        {logoSrc ? (
          <Logo src={logoSrc} alt={text} />
        ) : (
          <SvgLogoWithoutText
            width={16}
            height={16}
            fill={colors.HAWKES_BLUE}
          />
        )}
      </LogoWrapper>
      {text}
    </Wrapper>
  );
};

export default SearchResultItem;
