
import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgBuildings: FC<ISvgProps> = (props: ISvgProps) => {

	return (
		<svg viewBox="0 0 32 32" {...props}>
			<g id="Buildings">
				<g id="Vector">
					<path d="M2 28H30C30.5523 28 31 27.5523 31 27C31 26.4477 30.5523 26 30 26H2C1.44772 26 1 26.4477 1 27C1 27.5523 1.44772 28 2 28Z" fill="#1C1C1C" />
					<path d="M3 5V27C3 27.5523 3.44772 28 4 28C4.55228 28 5 27.5523 5 27V5H17V27C17 27.5523 17.4477 28 18 28C18.5523 28 19 27.5523 19 27V5C19 4.17157 18.4142 3.58579 18.4142 3.58579C17.8284 3 17 3 17 3H5C4.17157 3 3.58579 3.58579 3.58579 3.58579C3 4.17157 3 5 3 5Z" fill="#1C1C1C" />
					<path d="M27 11H18C17.4477 11 17 11.4477 17 12C17 12.5523 17.4477 13 18 13H27V27C27 27.5523 27.4477 28 28 28C28.5523 28 29 27.5523 29 27V13C29 12.1716 28.4142 11.5858 28.4142 11.5858C27.8284 11 27 11 27 11Z" fill="#1C1C1C" />
					<path d="M8 10H12C12.5523 10 13 9.55228 13 9C13 8.44772 12.5523 8 12 8H8C7.44772 8 7 8.44772 7 9C7 9.55228 7.44772 10 8 10Z" fill="#1C1C1C" />
					<path d="M10 18H14C14.5523 18 15 17.5523 15 17C15 16.4477 14.5523 16 14 16H10C9.44771 16 9 16.4477 9 17C9 17.5523 9.44771 18 10 18Z" fill="#1C1C1C" />
					<path d="M8 23H12C12.5523 23 13 22.5523 13 22C13 21.4477 12.5523 21 12 21H8C7.44772 21 7 21.4477 7 22C7 22.5523 7.44772 23 8 23Z" fill="#1C1C1C" />
					<path d="M21 22C21 22.5523 21.4477 23 22 23H24C24.5523 23 25 22.5523 25 22C25 21.4477 24.5523 21 24 21H22C21.4477 21 21 21.4477 21 22Z" fill="#1C1C1C" />
					<path d="M21 17C21 17.5523 21.4477 18 22 18H24C24.5523 18 25 17.5523 25 17C25 16.4477 24.5523 16 24 16H22C21.4477 16 21 16.4477 21 17Z" fill="#1C1C1C" />
				</g>
			</g>
		</svg>
	);
};

export default SvgBuildings;
