
import { AxiosRequestConfig } from 'axios';
import apiClient from './utils/apiClient';



export default {

	async postWithdraw(amount: number) {
		const api = apiClient();
		console.log(amount);

		try {
			const response = await api.post('account/withdraw', null, {
				params: {
					amount
				}
			} as AxiosRequestConfig);
			return response;
		} catch (error) {
			throw error;
		}
	},
};
