import React, { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';

import { cn } from '@bem-react/classname';

import { datetime as DATETIME } from '@/constants';

import { TOperationType } from '@/data/useOperations';

import { Button } from '@/components/Buttons';
import { Table } from '@/components/Table';
import { SvgMoneyTransfer } from '@/components/Svg';

import './BillTransfer.less';


const cnBillTransfer = cn('BillTransfer');

type TData = {
  sum: number;
  datetime: string;
  status: string;
  link: string;
};

export type TTransferType = Omit<TOperationType, 'Undefined'>;

interface IProps {
  data: TData | null;
  type: TTransferType;
}

const BillTransfer: FC<IProps> = (props) => {
  const { data, type } = props;

  const intl = useIntl();

  const isIncome = type === 'Incoming';
  const isPending = data?.status === 'Pending';

  const tTitleName = intl.formatMessage({
    id: 'bill.transfer.titleName',
  });

  const tTitleTextWithdraw = intl.formatMessage({

    id: 'bill.transfer.titleText.Withdraw',
  });
  const tTitleTextIncome = intl.formatMessage({

    id: 'bill.transfer.titleText.Income',
  });


  const tContent = intl.formatMessage({
    id: 'bill.transfer.content',
  });

  const columns = useMemo(
    () => [{ accessor: 'key' }, { accessor: 'value' }],
    [],
  );

  const getData = useCallback(
    ({ sum, datetime, status, link } = {}) => [
      { key: 'Сумма', value: `${isIncome ? '+' : '-'} ${Math.abs(sum)} ₽` },
      {
        key: 'Дата',
        value: moment(datetime).format(DATETIME.DATE_TIME_FORMAT),
      },
      {
        key: `Статус ${isIncome ? 'начисления' : 'вывода'}`,
        value: intl.formatMessage({
          id: `bill.operation.status.${status.toLowerCase()}`,
        }),
      },
      isIncome ?
        {
          key: 'Промоакция',
          value: (
            <Button displayType="link" to={link}>
              Открыть
            </Button>
          ),
        } : {},
    ],
    [isIncome, intl],
  );

  return (
    <div className={cnBillTransfer()}>
      <div className={cnBillTransfer('Header')}>
        <div className={cnBillTransfer('Header-Icon')}>
          <SvgMoneyTransfer />

        </div>

        <div className={cnBillTransfer('Header-Content')}>
          <p className={cnBillTransfer('Header-Title')}>{tTitleName}</p>

          <p className={cnBillTransfer('Header-Text')}>{isIncome ? tTitleTextIncome : tTitleTextWithdraw}</p>
        </div>
      </div>

      <div className={cnBillTransfer('Content')}>
        <p className={cnBillTransfer('Content-Text')}>{isPending ? 'Для зачисления денег подтвердите свои документы' : tContent}</p>

        <Table
          className={cnBillTransfer('Table')}
          thClassNames={[cnBillTransfer('Table-Th')]}
          rowClassNames={[cnBillTransfer('Table-Row')]}
          colClassNames={[cnBillTransfer('Table-Cell')]}
          columns={columns}
          data={getData(data)}
        />
      </div>
    </div>
  );
};

export default BillTransfer;
