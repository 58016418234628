import React, {
  FC,
  useState,
  useEffect,
  useRef,
  ReactNode,
  SyntheticEvent,
} from 'react';
import styled, { css } from 'styled-components';
import { MainTitle } from '@/components/MainTitle';
import { SvgArrowDown } from '@/components/Svg';
import { colors } from '@/constants';

interface IProps {
  children: ReactNode;
  title: string;
  itemKey: string | number;
  isOpen?: boolean;
  onClick?: (k: string | number) => void;
}

interface ICommonProps {
  isOpen: boolean;
}

interface IContentProps extends ICommonProps {
  contentHeight: number;
}

const Wrapper = styled.div`
  width: 100%;
  padding: 14px 0;
  border-bottom: 1px solid ${colors.LILAC_WHITE};

`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0;
  cursor: pointer;
`;

const ArrowWrapper = styled.div<ICommonProps>`
  margin-left: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transform-origin: 50% 50%;
  transition: all 0.2s;

  ${({ isOpen }: ICommonProps) => {
    if (isOpen) {
      return css`
        transform: rotate(180deg);
      `;
    }
    return '';
  }}
`;

const Content = styled.div<IContentProps>`
  width: 100%;
  overflow: hidden;
  will-change: height;
  transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);

  ${({ isOpen, contentHeight }: IContentProps) => {
    if (isOpen) {
      return css`
        height: ${contentHeight}px;
      `;
    }
    return css`
      height: 0px;
    `;
  }}
`;

const ContentInner = styled.div<ICommonProps>`
  padding: 0 0 16px 0;
  opacity: 0;
  transition: opacity 0.1s linear 0.18s;

  ${({ isOpen }: ICommonProps) => {
    if (isOpen) {
      return css`
        opacity: 1;
      `;
    }
    return '';
  }}
`;

const AccordionItem: FC<IProps> = ({
  children,
  title,
  itemKey,
  isOpen = false,
  onClick = () => { },
}: IProps) => {
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollHeight = contentRef.current?.scrollHeight;

    if (scrollHeight) {
      setContentHeight(scrollHeight);
    }
  }, [isOpen]);

  const handleClick = () => {
    onClick(itemKey);
  };

  const handleContentClick = (event: SyntheticEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <Wrapper onClick={handleClick}>
      <Title>
        <MainTitle text={title} fontSize={24} fontWeight={700} />
        <ArrowWrapper isOpen={isOpen}>
          <SvgArrowDown width={11} fill={colors.BLACK} />
        </ArrowWrapper>
      </Title>
      <Content
        ref={contentRef}
        isOpen={isOpen}
        contentHeight={contentHeight}
        onClick={handleContentClick}
      >
        <ContentInner isOpen={isOpen}>{children}</ContentInner>
      </Content>
    </Wrapper>
  );
};

export default AccordionItem;
