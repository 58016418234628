import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { MainTitle } from '@/components/MainTitle';
import { Button } from '@/components/Buttons';
import { PhoneInput, FieldLabel } from '@/components/FormControls';
import { Loader } from '@/components/Loader';
import { FormField, FormControlWrapper } from '@/components/shared';
import { colors, common } from '@/constants';
import { promoCodesAPI } from '@/api';
import { IPromoCode, ISharedToken } from '@/types';
import { SuccessfulAlert } from '@/components/SuccessfulAlert';
import { FailureAlert } from '@/components/FailureAlert';

interface IProps {
  promoCodeToken?: string;
  shareToken?: string;
  onSuccess?: (args?: { token: ISharedToken; promoCode?: IPromoCode }) => void;
  onCancel?: () => void;
}

type TFormData = {
  phoneNumber: string;
};

const Wrapper = styled.div`
  width: 100%;
`;

const TitleWrapper = styled.div`
  margin: 0 0 30px 0;
  text-align: center;
`;

const PhoneTariffs = styled.p`
  margin-top: 12px;
  font-size: 12px;
  color: ${colors.MONSOON};
`;

const Message = styled.p`
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${colors.BLACK};
`;

const SubmitButtonWrapper = styled.div`
  padding: 16px 0 0 0;
  width: 100%;
`;

const ButtonCancelWrapper = styled.div`
  padding: 16px 0 0 0;
`;

const { DEFAULT_DIAL_CODE, DEFAULT_COUNTRY_CODE } = common;

const PromoCodeBySMSForm: FC<IProps> = ({
  promoCodeToken,
  shareToken,
  onSuccess = () => { },
  onCancel,
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [error, setError] = useState('');
  const [phone, setPhone] = useState('');
  const [createdPromoCode, setCreatedPromoCode] = useState<
    | {
      token: ISharedToken;
      promoCode?: IPromoCode;
    }
    | undefined
  >();

  const REGEXP_NON_DIGIT = new RegExp('\\D', 'g');
  const intl = useIntl();

  const tPhonePlaceholder = intl.formatMessage({
    id: 'form.placeholders.phoneNumber',
  });
  const tRequiredField = intl.formatMessage({
    id: 'form.errors.phoneNumber.required',
  });
  const tFailureTitle = intl.formatMessage({
    id: 'promoCodes.title.failureSMS',
  });
  const tSuccessTitle = intl.formatMessage({
    id: 'promoCodes.title.successSMS',
  });
  const tSuccessText = intl.formatMessage(
    {
      id: 'promoCodes.message.sentTo',
    },
    { source: phone },
  );
  const tClose = intl.formatMessage({
    id: 'promoCodes.close',
  });

  const validationSchema = yup.object().shape({
    phoneNumber: yup.string().required(tRequiredField),
  });

  const { register, setValue, handleSubmit, errors, clearErrors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    register('phoneNumber');
  }, [register]);

  const onSubmit = async (data: TFormData) => {
    setLoading(true);
    try {
      if (shareToken) {
        const preparedPhone = `+7${data.phoneNumber.replace(REGEXP_NON_DIGIT, '')}`;

        if (!promoCodeToken) {
          const { data: buildedPromoCode } = await promoCodesAPI.buildPromoCode(
            shareToken,
          );
          await promoCodesAPI.sendPromoCodeBySMS(
            preparedPhone,
            buildedPromoCode.token.token,
          );

          setCreatedPromoCode(buildedPromoCode);
        } else {
          await promoCodesAPI.sendPromoCodeBySMS(preparedPhone, promoCodeToken);

          setCreatedPromoCode(undefined);
        }

        setError('');
        setPhone(`+7 ${data.phoneNumber}`);
        setOpenSuccessAlert(true);
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (v: string) => {
    setValue('phoneNumber', v);
  };

  const handleInputFocus = () => {
    clearErrors('phoneNumber');
  };

  const handleSuccess = () => {
    onSuccess(createdPromoCode);
  };

  if (error) {
    return (
      <FailureAlert
        title={tFailureTitle}
        message={error}
        failureButtonLabel={tClose}
        onFailure={onCancel}
      />
    );
  }

  if (openSuccessAlert) {
    return (
      <SuccessfulAlert
        title={tSuccessTitle}
        message={tSuccessText}
        successButtonLabel={tClose}
        onSuccess={handleSuccess}
      />
    );
  }

  return (
    <>
      <Wrapper>
        <TitleWrapper>
          <MainTitle text="SMS" fontWeight={900} />
        </TitleWrapper>
        <Message>
          <FormattedMessage id="promoCodes.message.sendBySMS" />
        </Message>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormField>
            <FieldLabel forAttr="phone-number-input-send-by-sms">
              <FormattedMessage id="form.labels.phoneNumber" />
              <FormControlWrapper>
                <PhoneInput
                  focusOnOpen
                  id="phone-number-input-send-by-sms"
                  dialCode={DEFAULT_DIAL_CODE}
                  countryCode={DEFAULT_COUNTRY_CODE}
                  name="phoneNumber"
                  placeholder={tPhonePlaceholder}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  disabled={loading}
                  isError={errors.phoneNumber}
                  errorMessage={errors.phoneNumber?.message}
                />
              </FormControlWrapper>
            </FieldLabel>
          </FormField>
          <SubmitButtonWrapper>
            <Button
              fluid
              type="submit"
              displayType="primary"
              disabled={loading}
            >
              {loading ? (
                <Loader size={22} thickness={3} color={colors.WHITE} />
              ) : (
                <FormattedMessage id="common.send" />
              )}
            </Button>
          </SubmitButtonWrapper>
          <PhoneTariffs>
            <FormattedMessage id="form.info.phoneTariffs" />
          </PhoneTariffs>
        </form>
      </Wrapper>
      <ButtonCancelWrapper>
        <Button type="button" displayType="link" fluid onClick={onCancel}>
          <FormattedMessage id="common.cancel" />
        </Button>
      </ButtonCancelWrapper>
    </>
  );
};

export default PromoCodeBySMSForm;
