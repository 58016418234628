export default [
  {
    code: 'US',
    mask: '(###) ###-####',
    appendTail: true,
  },

  {
    code: 'RU',
    mask: '### ###-##-##',
  },
];
