import React, { FC } from 'react';
import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import Leaflet from 'leaflet';
import { colors, common } from '@/constants';

interface IProps {
  count: number;
}

const { MARKER_CLUSTER_CLASS_PREFIX } = common;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-size: 13px;
  font-weight: 800;
  color: ${colors.BLACK};
  box-shadow: 0 3px 6px 0 ${hexToRgba(colors.BLACK, 0.2)};
  border: solid 3px ${colors.BLUE};
  background-color: ${colors.WHITE};
  border-radius: 99%;
`;

const MarkerClusterIcon: FC<IProps> = ({ count }: IProps) => {
  return <Wrapper className="fartsa-marker-icon-cluster">{count}</Wrapper>;
};

export default MarkerClusterIcon;

export const createMarkerClusterIcon = (
  cluster: any,
  clusterId: string,
  markerSize: number,
) => {
  const count = cluster.getChildCount();
  const promotionIds = cluster
    .getAllChildMarkers()
    .map((i: any) => `i${i.options.promotionId}`);

  const promotionsClassName = `p-${promotionIds.join('-').toString()}-`;
  const clusterClassName = `${MARKER_CLUSTER_CLASS_PREFIX}-${clusterId}`;

  const domContent = ReactDOMServer.renderToString(
    <MarkerClusterIcon count={count} />,
  );

  return Leaflet.divIcon({
    className: `${clusterClassName} ${promotionsClassName}`,
    iconSize: [markerSize, markerSize],
    html: domContent,
  });
};
