import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Redirect, useParams } from 'react-router-dom';
import { Container } from 'styled-bootstrap-grid';
import { Header } from '@/components/Header';
import { Footer } from '@/components/Footer';
import { MainTitle } from '@/components/MainTitle';
import { PageErrorMessage } from '@/components/PageErrorMessage';
import { Loader } from '@/components/Loader';
import { MenuDivider, Page, PageContent } from '@/components/shared';
import { useAuth } from '@/data/useAuth';
import { colors } from '@/constants';
import { useCompany } from '@/data/useCompany';
import { PosterImage } from '@/components/shared';
import { Article } from '@/components/Article';
import { Button } from '@/components/Buttons';
import Textarea from '@/components/FormControls/Textarea';
import { cn } from '@bem-react/classname';
import './CompanyPage.less';
import companyApi from '../../api/company';
import PromotionsCompany from '@/components/PromotionsCompany/PromotionsCompany';
import { BaseInput } from '@/components/FormControls';

import { Modal } from '@/components/Modal';


import SuccessOffer from '@/components/SuccessOffer/SuccessOffer';
import { down } from 'styled-breakpoints';



const PromotionBaseInfoWrapper = styled.div`
  padding: 0 0 10px 0;
  ${down('sm')} {
	margin-top: 190px;
  }x

`;

const PosterWrapper = styled.div`
  margin-bottom: auto;

`;

const AdvertiserInfoWrapper = styled.div`
display: flex;
flex-direction: row;
margin-top: 50px;
${down('sm')} {
	flex-direction: column;
	jusify-content: center;
	align-items: center;
	
  }


`;



const OfferPromoaction = styled.div`
width: 312px;
height: 50px;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
`;

const AboutUsWrapper = styled.div`
display: flex;
flex-direction: column;
margin-left: 50px;
max-width: 65%;
${down('sm')} {

	max-width: 100%;
  }
`;

const LoaderWrapper = styled.div`
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const OfferWrapper = styled.div`
display: flex;
flex-direction: column;
margin-top: 160px;
width: 312px;
`;

const Description = styled.div`
  margin-bottom: 40px;
  ${down('sm')} {
	margin-left: 15px;
	margin-top: 20px;
  }
`;

const DividerWrapper = styled.div`
margin-top: 50px;

`;


const TitleWrapper = styled.div`
  margin-bottom: 16px;
`;
const b = cn('CompanyPage');

const CompanyPage = () => {
	const intl = useIntl();
	const { id } = useParams();
	const { isAuth } = useAuth();
	const { company, isLoading, error } = useCompany(id);
	const [isOfferopened, setIsOfferOpened] = useState(false);
	const [offerMessage, setOfferMessage] = useState('');
	const [offerTitle, setOfferTitle] = useState('');
	const [successModalShown, setSuccessModalShown] = useState(false);


	const handleCloseModal = useCallback(() => setSuccessModalShown(false), []);



	const pageTitle = company?.name || '';

	const submitHandler = () => {
		try {
			companyApi.postCompanyOffer({ businessId: id, message: offerMessage, title: offerTitle });
			setIsOfferOpened(false);
			setSuccessModalShown(true);
			setOfferMessage('');
			setOfferTitle('');
		} catch (e) {
			console.log(e);
		}
	};

	const tError404Title = intl.formatMessage({ id: 'errorPage.title404' });
	const tSeoTitle = intl.formatMessage(
		{ id: 'seo.title.company' },
		{
			name: pageTitle,
		},
	);
	const tDescription = intl.formatMessage({ id: 'common.aboutUs' });

	const renderContent = () => (
		<>
			<AdvertiserInfoWrapper>


				<PosterWrapper>
					<PosterImage fluid={false} src={company?.logoPath} alt={pageTitle} width={312} height={312} />
					<OfferPromoaction>

						{isOfferopened ? (
							<OfferWrapper>
								<BaseInput className={b('TitleArea')} value={offerTitle} placeholder='Название' onInputChange={(x) => setOfferTitle(x)} />
								<Textarea className={b('TextArea')} value={offerMessage} placeholder='Опишите ваше предложение' onChange={(x) => setOfferMessage(x)} />
								<Button children={'Отправить предложение'} disabled={!offerMessage || !offerTitle} className={b('SubmitButton')}
									onClick={() => submitHandler()} />
							</OfferWrapper>
						) : <div style={{ marginTop: 50 }}> <Button children={'Предложить промоакцию'} onClick={() => setIsOfferOpened(true)} /></div>}

					</OfferPromoaction>
				</PosterWrapper>
				<AboutUsWrapper>

					<PromotionBaseInfoWrapper>

						<MainTitle
							text={pageTitle}
							hLevel={1}
							fontSize={26}
							fontWeight={900}
						/>
					</PromotionBaseInfoWrapper>
					{company?.aboutUs ? (

						<Description>
							<TitleWrapper>
								<MainTitle text={tDescription} fontSize={24} fontWeight={900} />
							</TitleWrapper>
							<Article markup={company.aboutUs} />
						</Description>

					) : null}

				</AboutUsWrapper>
			</AdvertiserInfoWrapper>
			<DividerWrapper style={isOfferopened ? { marginTop: 170 } : { marginTop: 50 }}>
				<MenuDivider />
			</DividerWrapper>
			<PromotionsCompany businessId={id} />
		</>
	);

	if (!isAuth) {
		return <Redirect to="/signin" />;
	}

	if (error && error.statusCode === 404) {
		return (
			<PageContent>
				<Container>
					<PageErrorMessage
						statusCode={error.statusCode}
						title={tError404Title}
					/>
				</Container>
			</PageContent>
		);
	}

	return (
		<Page>
			<Helmet>
				<title>{tSeoTitle}</title>
			</Helmet>
			<Header />
			<PageContent>
				<Container>
					{isLoading ? (
						<LoaderWrapper>
							<Loader size={52} thickness={4} color={colors.BLUE} />
						</LoaderWrapper>
					) : (
						renderContent()
					)}
				</Container>
			</PageContent>
			<Footer />
			<Modal
				visible={successModalShown}
				onCancel={() => handleCloseModal()}
			>
				<SuccessOffer onClose={() => handleCloseModal()} />
			</Modal>
		</Page>

	);
};

export default CompanyPage;
