import React, { FC, ReactNode, SyntheticEvent } from 'react';
import { cn } from '@bem-react/classname';

import './Button.less';
import { Link } from 'react-router-dom';

interface IProps {
  children: ReactNode;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit';
  displayType?: 'primary' | 'secondary' | 'default' | 'link' | 'text';
  fluid?: boolean;
  onClick?: (e: SyntheticEvent<any>) => void;
  className?: string;
  to?: string;
}

const cnButton = cn('Button');

const PrimaryButton: FC<IProps> = ({
  className,
  children,
  type = 'button',
  displayType = 'default',
  fluid = false,
  disabled = false,
  onClick = () => {},
  to,
}: IProps) => {
  const renderButton = () => {
    if (to) {
      return (
        <Link
          to={to}
          className={cnButton({ [displayType]: true, fluid }, [className])}
          onClick={onClick}
        >
          {children}
        </Link>
      );
    }

    return (
      <button
        className={cnButton({ [displayType]: true, fluid }, [className])}
        type={type} // eslint-disable-line react/button-has-type
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </button>
    );
  };

  return renderButton();
};

export default PrimaryButton;
