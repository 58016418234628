import { IApiClientConfig } from '@/types';
import basicFetcher from './utils/basicFetcher';

export default {
  async fetch(
    url: string,
    params: any = {},
    config: IApiClientConfig = {},
    // method?: 'put' | 'get' | 'post',
  ) {
    try {
      const response = await basicFetcher(url, params, config);
      return response;
    } catch (error) {
      throw error;
    }
  },
};
