import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { down } from 'styled-breakpoints';
import { colors } from '@/constants';

const StyledFooter = styled.footer`
  padding: 36px 0;
  background-color: ${colors.GHOST_WHITE};
`;

const Copyright = styled.div`
  width: 100%;
  max-width: 200px;
  font-size: 12px;

  & p {
    margin-bottom: 6px;
  }
`;

const Contact = styled.div`
  padding: 0;

  ${down('sm')} {
    padding-bottom: 30px;
  }
`;

const ContactTitle = styled.h6`
  margin-bottom: 6px;
`;

const EmailLink = styled.a`
  font-weight: 700;
  text-decoration: none;
  color: ${colors.BLACK};
  transition: all 0.2s;

  &:hover {
    color: ${colors.BLUE};
  }
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <StyledFooter>
      <Container>
        <Row>
          <Col order={1} xsOrder={2} smOrder={2} mdOrder={1} sm={12} md={4}>
            <Copyright>
              <p>{`© ${currentYear} Fartsa`}</p>
              <p>
                <FormattedMessage id="copyright.allRightsReserved" />
              </p>
            </Copyright>
          </Col>
          <Col order={2} xsOrder={1} smOrder={1} sm={12} md={8}>
            <Row xlJustifyContent="end">
              <Col sm={12} md={6} xl={4}>
                <Contact>
                  <ContactTitle>
                    <FormattedMessage id="contacts.forGeneralQuestions" />
                  </ContactTitle>
                  <EmailLink
                    href={`mailto:${process.env.REACT_APP_GENERAL_EMAIL}`}
                  >
                    {process.env.REACT_APP_GENERAL_EMAIL}
                  </EmailLink>
                </Contact>
              </Col>
              <Col sm={12} md={6} xl={4}>
                <Contact>
                  <ContactTitle>
                    <FormattedMessage id="contacts.cooperation" />
                  </ContactTitle>
                  <EmailLink
                    href={`mailto:${process.env.REACT_APP_PARTNERS_EMAIL}`}
                  >
                    {process.env.REACT_APP_PARTNERS_EMAIL}
                  </EmailLink>
                </Contact>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
