import { ISvgProps } from '@/types';
import * as React from 'react';



const SvgTelegram: React.FC<ISvgProps> = (props: ISvgProps) => {

	return (
		<svg
			viewBox="0 0 139.85709 139.85709"
			{...props}
		>
			<path
				style={{
					mixBlendMode: 'normal'
				}}
				d="M507.39 2.463a500 500 0 00-500 500 500 500 0 00500 500 500 500 0 00500-500 500 500 0 00-500-500zM711.242 305.41c8.69-.175 14.473 2.35 19.653 7.53l7.56 7.564-1.478 23.847c-3.983 64.145-52.8 357.352-63.723 382.745-10.007 23.261-28.998 30.336-52.542 19.576-9.574-4.376-153.597-100.122-171.541-114.041-4.92-3.817-10.77-10.792-13.003-15.497-6.627-13.965-2.085-20.293 50.521-70.418 84.987-80.978 118.194-114.338 119.668-120.211.899-3.58-.009-7.215-2.405-9.61-3.35-3.35-4.92-3.416-12.024-.507-4.492 1.84-56.111 35.322-114.71 74.405-58.597 39.083-112.785 73.919-120.417 77.413-12.436 5.693-15.958 6.213-33.883 4.992-19.234-1.31-67.317-13.878-100.23-26.197-25.785-9.652-31.44-21.729-15.776-33.676 17.642-13.457 402.594-177.61 467.233-199.239 16.808-5.624 28.407-8.5 37.097-8.676z"
				transform="translate(-39.293 -81.328) matrix(.13986 0 0 .13986 38.26 80.983)"
				stroke="none"
				strokeWidth={1}
				fill="#fff"
				fillRule="evenodd"
				display="inline"
			/>
		</svg>
	);
};

export default SvgTelegram;
