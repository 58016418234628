import React from 'react';
import './OrLine.css';
import { useIntl } from 'react-intl';

export default function OrLine() {

	const { formatMessage } = useIntl();
	const tOrText = formatMessage({ id: 'auth.or' });
	return (
		<div className='hr-lines'><span>{tOrText}</span></div>
	);
}



