import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import hexToRgba from 'hex-to-rgba';
import { Bonuses } from '@/components/Bonuses';
import { SvgCase, SvgCalendar, SvgLogoWithoutText, SvgHeart } from '@/components/Svg';
import { useDateRange } from '@/hooks/useDateRange';
import { colors } from '@/constants';
import { processRewards } from '@/helpers/processRewards';
import { IPromoterReward } from '@/types';
import { promotionsAPI } from '@/api';

interface IProps {
  title: string;
  startDateTime?: string;
  endDateTime?: string;
  startPromoCodeActivationDateTime?: string;
  endPromoCodeActivationDateTime?: string;
  company: string;
  imageSrc?: string;
  rewards: IPromoterReward[];
  mode?: 'markerPopup' | 'default';
  className?: string;
  isFavorite?: boolean;
  id?: Number | string
}

interface ICommonProps {
  mode?: 'markerPopup' | 'default';
  isFavorite?: boolean;
}

interface IDataRowProps {
  last?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IBonusProps {
  bgColor?: string;
  last?: boolean;
}

const Card = styled.div<ICommonProps>`
  position: relative;
  width: 100%;
  border-radius: ${({ mode }: ICommonProps) =>
    mode === 'markerPopup' ? 6 : 8}px;
  box-shadow: 0 6px 20px 0 ${hexToRgba(colors.BLACK, 0.06)};
  background-color: ${colors.WHITE};
  overflow: hidden;
`;

const CardDate = styled.div<ICommonProps>`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  font-weight: 800;
  color: ${colors.WHITE};
  background-color: ${colors.BLUE};

  ${({ mode }: ICommonProps) => {
    if (mode === 'markerPopup') {
      return css`
        padding: 7px;
        font-size: 10px;
        border-top-left-radius: 6px;
        border-bottom-right-radius: 6px;
      `;
    }

    return css`
      padding: 10px;
      font-size: 12px;
      border-top-left-radius: 8px;
      border-bottom-right-radius: 8px;
    `;
  }};
`;

const CoverImageWrapper = styled.div<ICommonProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  background-color: ${colors.LILAC_WHITE};
  overflow: hidden;

  ${({ mode }: ICommonProps) => {
    if (mode === 'markerPopup') {
      return css`
        height: 110px;
      `;
    }

    return css`
      height: 166px;

      ${down('md')} {
        height: 200px;
      }
    `;
  }};
`;

const CoverImage = styled.img`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  object-fit: cover;
  vertical-align: middle;
`;

const InfoWrapper = styled.div<ICommonProps>`
  position: relative;

  ${({ mode }: ICommonProps) => {
    if (mode === 'markerPopup') {
      return css`
        padding: 16px 12px;
      `;
    }

    return css`
      padding: 24px 12px;
    `;
  }};
`;

const Title = styled.h5<ICommonProps>`
  font-weight: 800;
  color: ${colors.BLACK};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ mode }: ICommonProps) => {
    if (mode === 'markerPopup') {
      return css`
        margin-bottom: 8px;
        font-size: 13px;
      `;
    }

    return css`
      margin-bottom: 20px;
      font-size: 14px;
    `;
  }};
`;

const DataRow = styled.div<IDataRowProps>`
  margin-bottom: ${({ last }: IDataRowProps) => (last ? 0 : 10)}px;
`;

const PromoCodeRangeTitle = styled.div<ICommonProps>`
  margin-bottom: 8px;
  font-size: ${({ mode }: ICommonProps) =>
    mode === 'markerPopup' ? 10 : 12}px;
  font-weight: 600;
  color: ${colors.BLACK};
`;

const DataItem = styled.div<ICommonProps>`
  display: flex;
  flex-direction: row;
  font-size: ${({ mode }: ICommonProps) =>
    mode === 'markerPopup' ? 10 : 12}px;
  color: ${colors.BLACK};
`;

const DataItemIconWrapper = styled.div`
  margin-right: 8px;
`;

const WrapperBonuses = styled.div<ICommonProps>`
  position: absolute;
  z-index: 2;
  top: ${({ mode }: ICommonProps) => (mode === 'markerPopup' ? -16 : -14)}px;
  right: 4px;
`;

const FavoriteBtn = styled.button`
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 12px;
  right: 12px;
  width: 38px;
  height: 38px;
  border: 0;
  border-radius: 50%;
  background: white;
  fill: ${({ isFavorite }: ICommonProps) => (isFavorite ? 'red' : 'white')};
  stroke: ${({ isFavorite }: ICommonProps) => (isFavorite ? 'red' : 'black')};
  stroke-width: 2;
  z-index: 1;
`;

const PromotionCard: FC<IProps> = ({
  title,
  imageSrc = '',
  startDateTime,
  endDateTime,
  startPromoCodeActivationDateTime,
  endPromoCodeActivationDateTime,
  company,
  rewards,
  mode = 'default',
  className,
  isFavorite,
  id,
}: IProps) => {
  const [favorite, setFavorite] = useState(isFavorite);

  const handleFavorite = (e: any) => {
    e.preventDefault();



    setFavorite((val) => !val);
    if (favorite) {
      promotionsAPI.deleteFavouritePromotion(id);

    } else {
      promotionsAPI.setFavouritePromotion(id);
    }
  };

  const startDate = startDateTime
    ? moment(startDateTime).format('DD MMM')
    : null;
  const endDate = endDateTime ? moment(endDateTime).format('DD MMM') : null;

  const startPromoCodeDate = startPromoCodeActivationDateTime
    ? moment(startPromoCodeActivationDateTime).format('DD.MM.YYYY')
    : null;
  const endPromoCodeDate = endPromoCodeActivationDateTime
    ? moment(endPromoCodeActivationDateTime).format('DD.MM.YYYY')
    : null;

  const cardDate = useDateRange(startDate, endDate);
  const promoCodeDate = useDateRange(startPromoCodeDate, endPromoCodeDate);

  const processedRewards = processRewards(rewards, 'rouble');

  const distributionReward = processedRewards.find(
    (i) => i.type === 'PromocodeDistribution',
  );

  const activationReward = processedRewards.find(
    (i) => i.type === 'PromocodeActivation',
  );

  const purchaseReward = processedRewards.find(
    (i) => i.type === 'PromocodePurchase',
  );

  return (
    <Card mode={mode} className={className}>
      <CardDate mode={mode}>{cardDate}</CardDate>
      <CoverImageWrapper mode={mode}>
        {imageSrc ? (
          <CoverImage src={imageSrc} alt={title} />
        ) : (
          <SvgLogoWithoutText
            width={40}
            fill={hexToRgba(colors.MISCHKA, 0.2)}
          />
        )}
      </CoverImageWrapper>
      <FavoriteBtn isFavorite={favorite} onClick={handleFavorite}>
        <SvgHeart width={20} />
      </FavoriteBtn>
      <InfoWrapper mode={mode}>
        <WrapperBonuses mode={mode}>
          <Bonuses
            distributionReward={distributionReward?.value}
            activationReward={activationReward?.value}
            purchaseReward={purchaseReward?.value}
          />
        </WrapperBonuses>
        <Title mode={mode}>{title}</Title>
        {mode === 'markerPopup' ? null : (
          <>
            <DataRow>
              <DataItem mode={mode}>
                <DataItemIconWrapper>
                  <SvgCase width={13} height={13} fill={colors.BLUE} />
                </DataItemIconWrapper>
                {company}
              </DataItem>
            </DataRow>
          </>
        )}
        <DataRow last>
          <PromoCodeRangeTitle mode={mode}>
            <FormattedMessage id="promoCodes.durationRange" />
          </PromoCodeRangeTitle>
          <DataItem mode={mode}>
            <DataItemIconWrapper>
              <SvgCalendar width={13} height={12} fill={colors.BLUE} />
            </DataItemIconWrapper>
            {promoCodeDate}
          </DataItem>
        </DataRow>
      </InfoWrapper>
    </Card>
  );
};

export default PromotionCard;
