import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgHeart: FC<ISvgProps> = (props: ISvgProps) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M11.51922834 21.7483999c.1269.1296.2997.2016.4806.2016s.3537-.072.4806-.2016l8.6346-8.7489c3.6009-3.6477 1.0422-9.9495-4.0788-9.9495-3.0753 0-4.4865 2.2599-5.0364 2.682-.5526-.4239-1.9548-2.682-5.0364-2.682-5.1048 0-7.695 6.2856-4.0779 9.9495z" />
    </svg>
  );
};

export default SvgHeart;
