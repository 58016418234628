import useSWR from 'swr';
import axios from 'axios';

import { createErrorObject } from '@/helpers/createErrorObject';
import INotification from 'src/types/Notification';
import notificationsApi from '../api/notifications';



const useNotifications = () => {
	const {
		data,
		mutate,
		error,
	}: { data?: INotification[]; mutate: any; error?: any } = useSWR(

		['notifications'],
		notificationsApi.getMyNotifications,
		{
			revalidateOnFocus: false,
		},
	);

	const isLoading = !data && (!error || axios.isCancel(error));
	const isError = !!error;

	return {
		mutate,
		isLoading,
		isError,
		notifications: data || [],
		error: error ? createErrorObject(error) : null,
	};
};

export { useNotifications };
