import React, { FC } from 'react';
import { ISvgProps } from '@/types';

const SvgComputerScreen: FC<ISvgProps> = (props: ISvgProps) => {
  return (
    <svg viewBox="0 0 32 29" {...props}>
      <path
        d="M28.9 0H3.1A3.1 3.1 0 000 3.08v17.45a3.1 3.1 0 003.1 3.08h8.56l-.8 3.34h-2.4c-.58 0-1.04.46-1.04 1.02 0 .57.46 1.03 1.03 1.03h15.1a1.03 1.03 0 100-2.05h-2.41l-.8-3.34h8.56a3.1 3.1 0 003.1-3.08V3.08A3.1 3.1 0 0028.9 0zM3.1 2.05h25.8c.57 0 1.04.46 1.04 1.03v14.85h-6.9v-.7c0-1.1-.66-2.09-1.66-2.54-2.93-1.3-2.28-1.13-3.58-1.13-.48 0-.9.33-1 .78-.2.81-1.37.81-1.57 0a1.03 1.03 0 00-1-.78c-.87 0-.99-.02-1.24.1l-2.34 1.03a2.79 2.79 0 00-1.66 2.54v.7H2.06V3.08c0-.57.47-1.03 1.04-1.03zm17.87 15.88h-9.92v-.7c0-.28.17-.54.44-.66l2.07-.92a2.88 2.88 0 004.9 0l2.07.92c.27.12.44.38.44.66v.7zm-1.95 9.02h-6.04l.8-3.34h4.44l.8 3.34zm9.88-5.4H3.1c-.57 0-1.03-.45-1.03-1.02V20h27.87v.54c0 .57-.47 1.03-1.04 1.03zm-12.87-9.03c2.5 0 4.53-2.02 4.53-4.5a4.52 4.52 0 00-9.06 0c0 2.48 2.03 4.5 4.53 4.5zm0-6.95c1.36 0 2.46 1.1 2.46 2.45a2.46 2.46 0 01-4.93 0c0-1.35 1.1-2.45 2.47-2.45z"
        fill="#304FFE"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default SvgComputerScreen;
