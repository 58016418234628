import { Selector } from 'reselect';
import { IReduxState } from '@/types';

const idToken: Selector<IReduxState, any> = ({ phoneAuth }) =>
  phoneAuth.idToken;

const phoneNumber: Selector<IReduxState, any> = ({ phoneAuth }) =>
  phoneAuth.phoneNumber;

const confirmationResult: Selector<IReduxState, any> = ({ phoneAuth }) =>
  phoneAuth.confirmationResult;

const loadingSignIn: Selector<IReduxState, any> = ({ phoneAuth }) =>
  phoneAuth.loading.signIn;

const loadingCodeVerification: Selector<IReduxState, any> = ({
  phoneAuth,
}) => phoneAuth.loading.codeVerification;

const errorSignIn: Selector<IReduxState, any> = ({ phoneAuth }) =>
  phoneAuth.error.signIn;

const errorCodeVerification: Selector<IReduxState, any> = ({ phoneAuth }) =>
  phoneAuth.error.codeVerification;

export default {
  idToken,
  phoneNumber,
  confirmationResult,
  loadingSignIn,
  loadingCodeVerification,
  errorSignIn,
  errorCodeVerification,
};
