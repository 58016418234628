import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { cn } from '@bem-react/classname';

import { Button } from '@/components/Buttons';

import './SuccessWithdraw.less';

interface IProps {
	onClose: () => void;
}

const b = cn('SuccessWithdraw');

const SuccessWithdraw: FC<IProps> = (props) => {
	const { onClose } = props;

	const { formatMessage } = useIntl();

	const tTitle = formatMessage({ id: 'status.withdrawSuccess.title' });
	const tOk = formatMessage({ id: 'status.changesSuccess.ok' });

	return (
		<div className={b()}>
			<h2 className={b('Title')}>{tTitle}</h2>

			<div>
				<Button onClick={onClose} className={b('Button')} displayType="primary">
					{tOk}
				</Button>
			</div>
		</div>
	);
};

export default SuccessWithdraw;
